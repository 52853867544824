import { BrandConfig, DefaultValueConfig } from '@headless-workspace/config';
import { Image } from '@headless-workspace/typings';
import { ValueHelpers } from '@headless-workspace/utils';
import { BrandDTO, PriceDTO, VipBrandsValue } from '../../../../src';
// FIXME: move to server/
import { mapURLToImage } from '../../../client';

export type ProductPriceData = {
    price: number;
    currency: string;
    discountPercentage?: number;
    priceBeforeDiscount?: number;
    originalPrice?: number;
    priorPrice?: number;
};

export const mapProductPriceData = (dto: PriceDTO, currency: string): ProductPriceData => {
    return {
        price: dto.price ?? 0,
        priceBeforeDiscount: ValueHelpers.mapOptional(dto.formerPrice),
        discountPercentage: ValueHelpers.mapOptional(dto.discountPercentage),
        originalPrice: ValueHelpers.mapOptional(dto.originalPrice),
        priorPrice: ValueHelpers.mapOptional(dto.priorPrice),
        currency,
    };
};

export type ProductBrandData = {
    brand: {
        id: string;
        name: string;
        logo?: Image;
        url: string;
    };
    priceAlwaysBlack: boolean;
    isArialFont: boolean;
    enableDiscountInfo: boolean;
    isUnderlinedBrand: boolean;
    hasProductDetailLogoBanner: boolean;
    hasWhiteLogoBackground: boolean;
    hasFullWidthLogoBanner: boolean;
    brandLogoPosition: string;
    isVipBrand: boolean;
    squaredShade: boolean;
};

export enum PromotionType {
    OMNIBUS = 'OMNIBUS',
    STANDARD = 'STANDARD',
    REGULAR = 'REGULAR',
}

export const mapProductBrandData = (dto: BrandDTO, vipBrands: VipBrandsValue): ProductBrandData => {
    return {
        brand: {
            id: dto.brand.id ?? '',
            name: dto.brand.name,
            logo: mapURLToImage(dto.brand.logoURL, dto.brand.name),
            url: dto.brand.url ?? DefaultValueConfig.href,
        },
        priceAlwaysBlack: vipBrands[dto.brand.id ?? '']?.productAllBlackPrice ?? false,
        isArialFont: vipBrands[dto.brand.id ?? '']?.productNameArialFont ?? false,
        isVipBrand: !!vipBrands[dto.brand.id ?? ''],
        enableDiscountInfo: vipBrands[dto.brand.id ?? '']?.enablePromotionPercentage ?? true,
        hasProductDetailLogoBanner: vipBrands[dto.brand.id ?? '']?.pdp?.logoBanner ?? false,
        hasFullWidthLogoBanner: vipBrands[dto.brand.id ?? '']?.fullWidthLogoBanner ?? false,
        hasWhiteLogoBackground: vipBrands[dto.brand.id ?? '']?.whiteLogoBackground ?? false,
        brandLogoPosition: vipBrands[dto.brand.id ?? '']?.brandLogoPosition ?? '',
        isUnderlinedBrand: !BrandConfig.notUnderlined.includes(dto.brand.id ?? ''),
        squaredShade: vipBrands[dto.brand.id ?? '']?.squaredShade ?? false,
    };
};

export type BaseProductValue = ProductPriceData &
    ProductBrandData & {
        id: string;
        description: string;
        name: string;
        image: Image;
        href: string;
        promotion?: PromotionType;
    };
