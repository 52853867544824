'use client';

import { useEffect, useRef } from 'react';
import { DomainConfig, SessionStorageKey } from '@headless-workspace/config';
import { Logger, UrlHelpers } from '@headless-workspace/utils';
import { provideClientErrorStrategy } from './clientErrorStrategy';
import { ErrorHandlerStrategy } from './errorHandlerStrategy';

const CLIENT_MAX_RETRY_COUNT = 1;

export const useClientErrorStrategy = (
    logger: Logger,
    fallbackUrl?: string,
    maxRetryCount = CLIENT_MAX_RETRY_COUNT,
) => {
    const errorStrategy = useRef<ErrorHandlerStrategy | null>(null);

    useEffect(() => {
        if (fallbackUrl) {
            errorStrategy.current = provideClientErrorStrategy(logger, fallbackUrl);
        }
    }, [fallbackUrl, logger]);

    const shouldRecover = () => {
        const subdomain = UrlHelpers.getUrlSubDomain(window.location.hostname, DomainConfig.hostPattern);
        return subdomain !== undefined && DomainConfig.forceReloadSubdomains.includes(subdomain);
    };

    const canRetry = () => {
        const storedRetryCount = Number(window.sessionStorage.getItem(SessionStorageKey.ErrorRetryCount));
        const retryCount = isNaN(storedRetryCount) ? 0 : storedRetryCount;

        const shouldRetry = retryCount < maxRetryCount;
        if (shouldRetry) {
            window.sessionStorage.setItem(SessionStorageKey.ErrorRetryCount, String(retryCount + 1));
        }
        return shouldRetry;
    };

    const onFallback = () => {
        window.sessionStorage.removeItem(SessionStorageKey.ErrorRetryCount);
    };

    return { errorStrategy, shouldRecover, canRetry, onFallback };
};
