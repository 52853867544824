import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    GlowClickable,
    GlowClickableWithRightIcon,
    GlowIcon,
    GlowTextBody,
    Icons,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithCTA,
} from '../../../../src';

type GlowMenuButton = PropsWithAriaLabel &
    PropsWithClassName &
    PropsWithCTA & {
        iconClassName?: string;
        label: string | ReactNode;
        labelColor?: string;
        Icon?: React.ComponentType<{
            fill?: string;
        }>;
        iconFill?: string;
        showArrow?: boolean;
        isCollapsible?: boolean;
        isCollapsed?: boolean;
    };

/**
 * Component called MenuCategories and MenuSubCategories/Head on FIGMA
 */
export const GlowMenuButton: Component<GlowMenuButton> = ({
    className,
    iconClassName,
    ariaLabel,
    label,
    labelColor,
    Icon,
    iconFill,
    showArrow = false,
    isCollapsible = false,
    isCollapsed = false,
    ...props
}) => {
    if (!showArrow) {
        return (
            <GlowClickable
                className={clsx('p-1 desktop:px-0 desktop:py-0.5 gap-0.5', className)}
                style={{ color: labelColor }}
                ariaLabel={ariaLabel}
                content={{
                    labelElement: (
                        <GlowTextBody
                            text={label}
                            fontWeight={isCollapsed ? 'bold' : 'medium'}
                            className={'text-wrap text-left desktop:text-bodySmall'}
                            style={labelColor ? { color: 'inherit' } : undefined}
                            TagName={'span'}
                        />
                    ),
                    icon: Icon && <GlowIcon className={iconClassName} Icon={Icon} type={'large'} fill={iconFill} />,
                }}
                {...props}
            />
        );
    }
    return (
        <GlowClickableWithRightIcon
            className={clsx('p-1 desktop:px-0 desktop:py-0.5 gap-0.5', className)}
            ariaLabel={ariaLabel}
            content={{
                labelElement: (
                    <GlowTextBody
                        text={label}
                        fontWeight={isCollapsed ? 'bold' : 'medium'}
                        className={'text-wrap text-left'}
                        style={{ color: labelColor }}
                        TagName={'span'}
                    />
                ),
                icon: Icon && <GlowIcon className={iconClassName} Icon={Icon} type={'large'} fill={iconFill} />,
            }}
            rightIcon={isCollapsible ? Icons.ChevronDown : Icons.ChevronRight}
            iconClassName={clsx(
                isCollapsible && 'duration-moderate1 ease-linear fill-text-primary',
                isCollapsed && 'rotate-180',
            )}
            {...props}
        />
    );
};
