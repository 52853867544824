export const da_DK_NavRoutes = {
    home: '',
    connection: 'log-ind',
    storesAndServices: 'alle-butikker',
    wishlist: 'wishlist',
    basket: 'varekurv',
    payment: '', // FIXME: To Update
    account: 'min-konto',
    orders: 'mine-kob',
    followOrder: 'on/demandware.store/Sites-Sephora_Scandinavia-Site/da_DK/Order-TrackMyOrder',
    loyalty: 'loyalty-scandinavia.html',
    offers: '',
    profile: 'profil',
    help: 'hjalp',
    allServices: 'sephora-store-services.html',
    mail: 'profil/?is_communication_link=true',
    storeInfo: 'butik',
    allStores: 'alle-butikker',
    countries: 'international_SC.html',
    beautyTips: 'udsalg',
    logout: 'log-ud/?redirectToReferer=true',
    loyaltyProgram: '',
    allBrands: 'maerker-fra-a---z-brands',
    clickAndCollect: 'click-and-collect',
    securePayment: 'https://faq.sephora.dk/s/article/Hvilke-betalingsmetoder-accepteres?language=da',
    returnProduct: 'https://faq.sephora.dk/s/topic/0TO070000008P7OGAU/betalning?language=da',
    privacyPolicy: 'privatlivsogcookispolitik/cookies_SC.html',
    search: 'sog/?q=',
    reviewConditions: '',
};
