import { PredicateHelpers } from '@headless-workspace/utils';
import { CategoryDTO } from './CategoriesDTO';

export type CategoryValue = Readonly<{
    id: string;
    name: string;
    subCategories: CategoryValue[];
    showInMenu: boolean;
    url?: string;
    imageUrl?: string;
    ariaLabel?: string;
    color?: string;
    dataLayerId?: string;
    isSeeAllEnabled?: boolean;
    seeAllUrl?: string;
}>;

export const mapCategory = (dto: CategoryDTO): CategoryValue | undefined => {
    if (!dto.name) {
        return undefined;
    }

    return {
        id: dto.id,
        name: dto.name,
        subCategories: dto.subCategories
            ? dto.subCategories.map((element) => mapCategory(element)).filter(PredicateHelpers.notNullOrUndefined)
            : [],
        showInMenu: dto.showInMenu ?? false,
        url: dto.url,
        imageUrl: dto.imageUrl,
        ariaLabel: dto.ariaLabel,
        color: dto.color,
        dataLayerId: dto.tcDisplayName,
        isSeeAllEnabled: dto.isViewAllCTAEnabled,
        seeAllUrl: dto.viewAllCTAUrl,
    };
};
