export const es_ES_NavRoutes = {
    home: '',
    connection: 'iniciar-sesion',
    storesAndServices: 'tienda-sephora',
    wishlist: 'mis-favoritos',
    basket: 'cesta',
    payment: '', // FIXME: To Update
    account: 'mi-cuenta',
    orders: 'historial-pedidos',
    followOrder: 'on/demandware.store/Sites-Sephora_ES-Site/es_ES/Order-TrackMyOrder',
    loyalty: 'loyalty-program.html',
    offers: '',
    profile: 'datos-personales',
    mail: '',
    help: 'preguntas-frecuentes-faq-sephora',
    allServices: 'nuestros-servicios.html',
    storeInfo: 'informacion-tienda',
    allStores: 'tienda-sephora',
    countries: 'internacional/internacional.html',
    beautyTips: 'beauty-ofertas',
    logout: 'cerrar-sesion/?redirectToReferer=true',
    loyaltyProgram: '',
    allBrands: 'marcas-de-a-a-z',
    clickAndCollect: 'nuestros-servicios-tienda-online',
    securePayment:
        'https://faq.sephora.es/s/article/Cu%C3%A1les-son-los-modos-de-pago-aceptados-en-Sephora-y-Sephora-es',
    returnProduct:
        'https://faq.sephora.es/s/article/Cu%C3%A1les-son-los-modos-de-pago-aceptados-en-Sephora-y-Sephora-es',
    privacyPolicy: 'politica-privacidad-y-cookies/politica-privacidad-y-cookies.html',
    search: 'buscar/?q=',
    reviewConditions: '',
};
