'use client';

import { createContext, ReactNode } from 'react';
import { Position } from '@headless-workspace/config';
import { FunctionHelpers } from '@headless-workspace/utils';

export type SideMenuModalMap = { default: ReactNode } & Record<string, ReactNode>;

export type SideMenuModal = {
    side: Position;
    isOpen: boolean;
    map: SideMenuModalMap;
    className?: string;
    navigationStack: string[];
};

export const SideMenuModal = (
    side: Position,
    map: SideMenuModalMap,
    className?: string,
    isOpen = false,
): SideMenuModal => ({
    side,
    map,
    isOpen,
    className,
    navigationStack: [],
});

export type SideMenuModalList = Record<string, SideMenuModal>;

export type UIMenuModalContextType = {
    /**
     * Open a menuModal
     * @param id the id of the menuModal
     * @param content sets the default content of the menuModal (optional if the content is already set)
     */
    open: (id: string, content?: ReactNode) => void;
    /**
     * Add a menuModal
     * @param id the id of the menuModal
     * @param menuModal the menuModal to add
     */
    add: (id: string, menuModal: SideMenuModal) => void;
    /**
     * Add a menuModal and open it
     * @param id the id of the menuModal
     * @param menuModal the menuModal to add
     */
    addAndOpen: (id: string, menuModal: SideMenuModal) => void;
    /**
     * Add a menuModal if it doesn't exist and open it
     * @param id the id of the menuModal
     * @param menuModal the menuModal to add
     * @param override if true, override the menuModal if it exists
     */
    addIfAbsentAndOpen: (id: string, menuModal: SideMenuModal, override?: boolean) => void;
    /**
     * Close a menuModal
     * @param id the id of the menuModal
     * @param remove if true, remove the menuModal from the list
     */
    close: (id: string, remove?: boolean) => void;
    /**
     * Navigate to a menuModal
     * @param menuModalId the id of the menuModal
     * @param contentId the id of the content
     * @param content sets the content of the contentId (optional if the content is already set)
     */
    navigate: (menuModalId: string, contentId: string, content?: ReactNode) => void;
    /**
     * Go back to the previous menuModal
     * @param id the id of the menuModal
     */
    goBack: (id: string) => void;
    /**
     * Hydrate the menuModal list
     * @param menuModals the list of menuModals
     */
    hydrate: (menuModals: SideMenuModalList) => void;
    sideMenuModalList: SideMenuModalList;
    isOpen: boolean;
};

export type UIContextValueType = {
    menuModal: UIMenuModalContextType;
};

const UIContextInitialState: UIContextValueType = {
    menuModal: {
        open: FunctionHelpers.emptyFn,
        add: FunctionHelpers.emptyFn,
        addAndOpen: FunctionHelpers.emptyFn,
        addIfAbsentAndOpen: FunctionHelpers.emptyFn,
        close: FunctionHelpers.emptyFn,
        navigate: FunctionHelpers.emptyFn,
        goBack: FunctionHelpers.emptyFn,
        hydrate: FunctionHelpers.emptyFn,
        sideMenuModalList: {},
        isOpen: false,
    },
};

export const UIContext = createContext<UIContextValueType>(UIContextInitialState);
