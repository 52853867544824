import { z } from 'zod';

const AddressElementBase = z.object({
    short_name: z.string(),
    long_name: z.string(),
    types: z.array(z.string()),
});

export const SearchGeolocationAddressDTO = z.object({
    address_components: z.array(AddressElementBase),
});

export type SearchGeolocationAddressDTO = z.infer<typeof SearchGeolocationAddressDTO>;

export const SearchGeolocationsAddressDTO = z.object({ results: z.array(SearchGeolocationAddressDTO) });

export type SearchGeolocationsAddressDTO = z.infer<typeof SearchGeolocationsAddressDTO>;
