import { getTranslations } from 'next-intl/server';
import clsx from 'clsx';
import { buildRoute, envConfig, featureFlipping } from '@headless-workspace/config';
import { UIFooterLinkListItems, UIFooterShell, UIThemeSwitcher } from '@headless-workspace/core-ui';
import {
    GlowClickable,
    GlowDivider,
    GlowIcon,
    GlowReinsuranceListItem,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTextCaption,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { AsyncComponent, checkFeatureFlagServerAction, CountriesLink, FooterLegalLinks } from '../../../../src';
import { CommonDI, FooterSlotsRepositoryType } from '../../../DI';
import { InnerHTML, RichText } from '../../../client';

type FooterProps = PropsWithLocale;

export const Footer: AsyncComponent<FooterProps> = async ({ locale }) => {
    const t = await getTranslations('Footer');
    const footerInformationResult = await CommonDI.get(FooterSlotsRepositoryType).getFooterInformation(locale);

    const isClickAndCollectDisplayed = await checkFeatureFlagServerAction('isClickAndCollectDisplayed', locale);

    const reinsuranceData = [
        ...(isClickAndCollectDisplayed
            ? [
                  {
                      id: 'reinsurance-0',
                      icon: Icons.Bag,
                      title: t('reinsurance.label.pickup'),
                      description: t('reinsurance.label.clickAndCollect'),
                      href: buildRoute(locale, 'clickAndCollect'),
                      ctaLabel: t('reinsurance.action.collectMyOrder'),
                  },
              ]
            : []),
        {
            id: 'reinsurance-1',
            icon: Icons.Truck,
            title: t('reinsurance.label.shipping'),
            description: t('reinsurance.label.shippingSubtitle'),
            href: buildRoute(locale, 'clickAndCollect'),
            ctaLabel: t('reinsurance.action.exploreOffer'),
        },
        {
            id: 'reinsurance-2',
            icon: Icons.SecurePayment,
            title: t('reinsurance.label.securePayment'),
            description: t('reinsurance.label.securePaymentSubtitle'),
            href: buildRoute(locale, 'securePayment'),
            ctaLabel: t('reinsurance.action.learnMore'),
        },
        {
            id: 'reinsurance-3',
            icon: Icons.Returns,
            title: t('reinsurance.label.returns'),
            description: t('reinsurance.label.returnsSubtitle'),
            href: buildRoute(locale, 'returnProduct'),
            ctaLabel: t('reinsurance.action.returnMyProduct'),
        },
    ];

    // FIXME: reinsuranceData should be replace by footerInformationResult.data.reinsurance when API is ready
    const reinsuranceSectionContent = reinsuranceData.map((item) => (
        <GlowReinsuranceListItem
            key={item.id}
            Icon={item.icon}
            title={item.title}
            description={item.description}
            href={item.href}
            ctaLabel={item.ctaLabel}
        />
    ));

    return (
        footerInformationResult.type === 'success' && (
            <UIFooterShell>
                <div className={'bg-background-l0'}>
                    <div className={clsx('px-1 py-1.5 desktop:!p-1.5', 'desktop:mx-auto desktop:w-maxWidth')}>
                        <ul
                            className={clsx(
                                'grid grid-cols-1 tablet:grid-cols-2 desktopS:grid-cols-2 desktop:grid-cols-4',
                                'gap-1.5',
                            )}
                        >
                            {reinsuranceSectionContent}
                        </ul>
                    </div>
                </div>
                <div className={'bg-background-brand'}>
                    <div
                        className={clsx(
                            'flex flex-col gap-1.5',
                            'px-1 pb-1.5 desktop:px-1.5 desktop:pt-1.5',
                            'bg-background-brand',
                            'desktop:mx-auto desktop:w-maxWidth',
                        )}
                    >
                        <ul className={'flex flex-col desktop:flex-row desktop:gap-1.5 pt-1.5'}>
                            <UIFooterLinkListItems links={footerInformationResult.data.links} />
                            <li className={'flex flex-col justify-between gap-3 pt-1.5 desktop:pt-0'}>
                                <div className={'flex flex-col gap-0.5 desktop:gap-1'}>
                                    <GlowTextBody
                                        text={t('links.label.followUs')}
                                        fontWeight={'bold'}
                                        color={'primary-inverse-fixed'}
                                        className={'text-wrap'}
                                    />
                                    <ul className={'flex flex-row gap-1.5'}>
                                        {footerInformationResult.data.socialNetwork.map((socialNetwork) => (
                                            <li key={socialNetwork.id}>
                                                <GlowClickable
                                                    href={socialNetwork.href}
                                                    content={{
                                                        icon: <InnerHTML rawHtml={socialNetwork.iconRaw} />,
                                                    }}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={'flex flex-col gap-0.5'}>
                                    <GlowTextBody
                                        text={t('links.label.downloadApp')}
                                        color={'primary-inverse-fixed'}
                                        className={'text-wrap'}
                                    />
                                    <div className={'flex flex-row gap-0.5'}>
                                        <GlowClickable
                                            ariaLabel={t('links.ariaLabel.googlePlay')}
                                            href={t('appStore.externalLink.googlePlayStore')}
                                            content={{
                                                icon: (
                                                    <GlowIcon
                                                        Icon={Icons.DownloadAppFromGooglePlay}
                                                        className={'h-appDownloadIcon'}
                                                        type={'fullSize'}
                                                    />
                                                ),
                                            }}
                                        />
                                        <GlowClickable
                                            ariaLabel={t('links.ariaLabel.appStore')}
                                            href={t('appStore.externalLink.appleAppStore')}
                                            content={{
                                                icon: (
                                                    <GlowIcon
                                                        Icon={Icons.DownloadAppFromAppStore}
                                                        className={'h-appDownloadIcon'}
                                                        type={'fullSize'}
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                        {featureFlipping.isThemeSwitcherEnabled && <UIThemeSwitcher />}
                        <div>
                            <RichText
                                messageKey={'Footer.links.additionalInformation.excludedBrands'}
                                fontSize={'caption'}
                                color={'secondary-inverse-fixed'}
                                TagName={'p'}
                                links={{
                                    brands: {
                                        href: t('links.additionalInformation.externalLink.brandsExcludedFromPromotion'),
                                    },
                                }}
                            />
                            <RichText
                                messageKey={'Footer.links.additionalInformation.conditions'}
                                fontSize={'caption'}
                                color={'secondary-inverse-fixed'}
                                TagName={'span'}
                                links={{
                                    beautyTips: { href: buildRoute(locale, 'beautyTips') },
                                }}
                            />
                            <GlowTextCaption
                                text={t('links.additionalInformation.exclusivity')}
                                color={'secondary-inverse-fixed'}
                                TagName={'span'}
                                className={'flex'}
                            />
                        </div>
                    </div>

                    <div className={'bg-background-brand px-1 desktop:px-0'}>
                        <GlowDivider />
                    </div>
                    <div
                        className={clsx(
                            'flex flex-col desktop:flex-row justify-between desktop:gap-2.5',
                            'px-1 py-1.5 desktop:px-1.5',
                            'bg-background-brand',
                            'desktop:mx-auto desktop:w-maxWidth',
                        )}
                    >
                        <FooterLegalLinks legalLinks={footerInformationResult.data.compliance} />
                        <CountriesLink className={'pb-1.5 desktopS:pb-0'} locale={locale} />
                    </div>
                </div>
                {featureFlipping.isAppVersionEnabled && envConfig.appVersion && (
                    <div className={'bg-background-brand text-center'}>
                        <GlowTextBodySmall
                            className={'py-1'}
                            text={t('version.label', { version: envConfig.appVersion })}
                            color={'secondary-inverse-fixed'}
                        />
                    </div>
                )}
            </UIFooterShell>
        )
    );
};
