import { z } from 'zod';

export const CategoryBase = z.object({
    id: z.string(),
    name: z.string().optional(),
    showInMenu: z.boolean().optional(),
    url: z.string().optional(),
    imageUrl: z.string().optional(),
    ariaLabel: z.string().optional(),
    color: z.string().optional(),
    tcDisplayName: z.string().optional(),
    isViewAllCTAEnabled: z.boolean().optional(),
    viewAllCTAUrl: z.string().optional(),
});

export type CategoryDTO = z.infer<typeof CategoryBase> & { subCategories?: CategoryDTO[] };

export const CategoryDTO: z.ZodType<CategoryDTO> = CategoryBase.extend({
    subCategories: z.optional(z.lazy(() => CategoryDTO.array())),
});

export const CategoriesDTO = z.object({ categories: z.array(CategoryDTO) });

export const CategoriesContainerDTO = z.object({ categories: z.array(z.unknown()) });

export type CategoriesDTO = z.infer<typeof CategoriesDTO>;
