import { z } from 'zod';

export const PopularSearchDTO = z.object({
    link: z.string(),
    name: z.string(),
});

export type PopularSearchDTO = z.infer<typeof PopularSearchDTO>;

export const PopularSearchesDTO = z.object({
    searchSuggestions: z.object({
        popular: z.array(PopularSearchDTO),
    }),
});

export const PopularSearchesContainerDTO = z.object({
    searchSuggestions: z.object({
        popular: z.array(z.unknown()),
    }),
});
