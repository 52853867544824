'use client';

import { useState } from 'react';
import { ServerActionName } from '@headless-workspace/config';
import {
    isResultServerAction,
    ResultServerActionSuccessOrEmpty,
    ResultServerActionType,
} from '@headless-workspace/core-domain';
import { MAX_RETRY_ATTEMPTS } from '@headless-workspace/data';
import { RumLogger } from '@headless-workspace/utils';
import useSWR, { Fetcher, Middleware, SWRConfiguration, SWRResponse } from 'swr';
import { useAppLocale } from '../i18n';
import { useClientData } from '../providers';
import { revalidateSessionDebounced } from '../user/revalidateSessionDebounced';

const DEFAULT_ERROR_MESSAGE = 'An error occurred';
type SafeSWRFetcher<T> = Fetcher<ResultServerActionType<T>, string>;

export const useSafeSWR = <T>(
    validateKey: string | null,
    actionName: ServerActionName,
    fetcher: SafeSWRFetcher<T>,
    options: Partial<SWRConfiguration> = {},
): SWRResponse<ResultServerActionSuccessOrEmpty<T>> => {
    const locale = useAppLocale();
    const { isSessionInit } = useClientData();

    const triggerSessionRefreshMiddleware: Middleware = (useSWRNext) => {
        return (key, fetcher, config) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useSWRNext(
                key,
                fetcher
                    ? async (...args: Parameters<Fetcher>) => {
                          const data = await fetcher(...args);

                          if (data && isResultServerAction(data)) {
                              // If revalidation is needed, trigger a session refresh by updating cookies
                              if (data.revalidation?.key) {
                                  revalidateSessionDebounced(locale, data.revalidation.key);
                              }

                              // data.revalidation should not be accessible in client components
                              delete data.revalidation;
                          }
                          return data;
                      }
                    : null,
                config,
            );
        };
    };

    const handleValidationMiddleware: Middleware = (useSWRNext) => {
        return (key, fetcher, config) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [lastSuccessData, setLastSuccessData] = useState<never>();

            const onSuccess: SWRConfiguration['onSuccess'] = (data, key, config) => {
                // Save last data to be used in case of future error
                setLastSuccessData(data);

                options?.onSuccess && options.onSuccess(data, key, config);
            };

            const onError: SWRConfiguration['onError'] = (error, key, config) => {
                // FIXME: Use CommonDI when ready
                const errorMessage = error instanceof Error ? error.message : DEFAULT_ERROR_MESSAGE;
                new RumLogger().error(actionName, new Error(errorMessage));

                options?.onError && options.onError(error, key, config);
            };

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const swr = useSWRNext(key, fetcher, { ...config, onSuccess, onError });
            const { data } = swr;

            // If data returned by swr is a failure, manually populate cache with the last successful data if it exists
            if (data && isResultServerAction(data) && data.type === 'failure' && lastSuccessData) {
                return { ...swr, data: lastSuccessData };
            }

            return swr;
        };
    };

    // Extends fetcher to type useSafeSwr data's return type correctly
    const fetcherGuard: Fetcher<ResultServerActionSuccessOrEmpty<T>> = async (
        ...args: Parameters<SafeSWRFetcher<T>>
    ) => {
        const data = await fetcher(...args);

        // Throw error in case of failure to trigger exponential backoff
        if (isResultServerAction(data) && data.type === 'failure') {
            throw new Error(data.meta.message);
        }

        return data;
    };

    return useSWR(isSessionInit ? validateKey : null, fetcherGuard, {
        ...options,
        use: options.use
            ? [handleValidationMiddleware, triggerSessionRefreshMiddleware, ...options.use]
            : [handleValidationMiddleware, triggerSessionRefreshMiddleware],
        errorRetryCount: options.errorRetryCount || MAX_RETRY_ATTEMPTS,
    });
};
