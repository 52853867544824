import { z } from 'zod';

export const TextSuggestionDTO = z.object({
    id: z.string().optional(),
    name: z.string(),
    highlights: z
        .object({
            name: z.string(),
        })
        .optional(),
    url: z.string().optional(),
});

export type TextSuggestionDTO = z.infer<typeof TextSuggestionDTO>;

export const TextSuggestionsDTO = z.array(TextSuggestionDTO);

export type TextSuggestionsDTO = z.infer<typeof TextSuggestionsDTO>;
