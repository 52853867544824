import { DefaultValueConfig } from '@headless-workspace/config';
import { ObjectHelpers } from '@headless-workspace/utils';
// FIXME: move to server/
import { mapURLToImage } from '../../../../client';
import { BaseProductValue, mapProductBrandData, mapProductPriceData, VipBrandsValue } from '../../../../server';
import { SearchProductDTO } from './SearchProductsDTO';

export type SearchProductValue = Readonly<
    BaseProductValue & {
        minPrice?: number;
        isEngravable: boolean;
    }
>;

export const mapSearchProduct = (dto: SearchProductDTO, vipBrands: VipBrandsValue): SearchProductValue => {
    return {
        id: dto.id,
        name: dto.highlights?.name ?? dto.name,
        description: dto.description ?? '',
        href: dto.url ?? DefaultValueConfig.href,
        image: mapURLToImage(dto.imageUrl) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder),
        isEngravable: dto.isEngravable,
        minPrice: dto.minPrice,
        ...mapProductBrandData(dto, vipBrands),
        ...mapProductPriceData(dto, dto.currency),
    };
};
