export const ro_RO_NavRoutes = {
    home: '',
    connection: 'autentificare',
    storesAndServices: 'magazine-sephora',
    wishlist: 'favorite',
    basket: 'cos-de-cumparaturi',
    payment: '', // FIXME: To Update
    account: 'on/demandware.store/Sites-Sephora_RO-Site/ro_RO/Account-Show',
    orders: 'comenzile-mele',
    followOrder: 'on/demandware.store/Sites-Sephora_RO-Site/ro_RO/Order-TrackMyOrder',
    loyalty: 'discover-loyalty-card.html',
    offers: '',
    profile: 'contul-meu',
    mail: '',
    help: 'faq-sephora',
    allServices: 'servicii-sephora/servicii-magazin.html',
    storeInfo: 'informatii-magazin',
    allStores: 'magazine-sephora',
    beautyTips: 'special-offers',
    countries: 'international-ro.html',
    logout: 'delogare?redirectToReferer=true',
    loyaltyProgram: '',
    allBrands: 'branduri/de-la-a-la-z',
    clickAndCollect: 'click-and-collect',
    securePayment: 'faq-sephora?question=payments-topic2-question2',
    returnProduct: 'faq-sephora?question=payments-topic2-question2_FR',
    privacyPolicy: 'politica-de-confidentialitate-si-cookies.html',
    search: 'cautare?q=',
    reviewConditions: '',
};
