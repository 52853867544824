'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute, DataTestConfig } from '@headless-workspace/config';
import { IS_SEARCH_OPEN, UISearchBar, useDetectScroll, useIsSearchOpen } from '@headless-workspace/core-ui';
import {
    GlowClickable,
    GlowIcon,
    GlowTextBodySmall,
    Icons,
    PropsWithClassName,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { useOnClickOutside } from 'usehooks-ts';
import { DataLayerEvent, DataLayerEventCategory, useDataLayer, useDeviceType, useNavigation } from '../../../client';
import { VipBrandsValue } from '../../../server';
import { PopularSearches } from './popular';
import { RecentSearches, useRecentSearches } from './recent';
import { SearchSuggestionsContainer } from './suggestions';

const DATA_LAYER_SEARCH_ACTION = 'page search - click';

type SearchContainerProps = PropsWithClassName &
    PropsWithLocale & {
        isMobile?: boolean;
        vipBrands: VipBrandsValue;
    };

type CloseMenuProps = PropsWithClassName & {
    handleClose: () => void;
};
const CloseMenu: Component<CloseMenuProps> = ({ className, handleClose }) => {
    const t = useTranslations('Header.search.ariaLabel');
    return (
        <GlowClickable
            className={className}
            ariaLabel={t('closeCross')}
            onClick={handleClose}
            content={{
                icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
            }}
            dataTestId={DataTestConfig.testIds.closeSearchPanelButton}
        />
    );
};

export const SearchContainer: Component<SearchContainerProps> = ({
    className,
    isMobile = false,
    vipBrands,
    locale,
}) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const searchMenuRef = useRef<HTMLDivElement>(null);
    const [isFocus, setIsFocus] = useState(false);
    const t = useTranslations('Header.search');
    const navigation = useNavigation();
    const { addRecentSearch } = useRecentSearches();
    const [searchInput, setSearchInput] = useState('');
    const { trackEvent } = useDataLayer();
    const isSearchOpen = useIsSearchOpen();
    const currentScrollPosition = useRef<number>(0);
    const device = useDeviceType();

    if (StringHelpers.isDevice('Android')) {
        const mobileSearchInput = document.getElementById('mobile-search-input');
        const mobileClearSearchInput = document.getElementById('mobile-clear-search-button');

        if (mobileSearchInput) {
            mobileSearchInput.style.width = `calc(100% - ${mobileClearSearchInput?.clientWidth}px)`;
        }
    }

    const onSearch = (search: string) => {
        addRecentSearch(search);
        trackEvent(DataLayerEvent.Click, {
            id: 'click',
            category: DataLayerEventCategory.Search,
            action: DATA_LAYER_SEARCH_ACTION,
            label: encodeURIComponent(search),
        });
        navigation.push(`${buildRoute(locale, 'search')}${encodeURIComponent(search)}`);
    };

    useEffect(() => {
        if (isFocus) {
            currentScrollPosition.current = window.scrollY;
        }
    }, [isFocus]);

    const onSearchFocus = useCallback(() => {
        setIsFocus(true);
        const searchInput = document.getElementById('mobile-search-input');
        document.body.setAttribute(IS_SEARCH_OPEN, 'true');

        if (searchInput && isMobile) {
            searchInput.style.display = 'flex';
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (searchMenuRef.current) {
            searchMenuRef.current.style.display = 'flex';
        }
    }, [isMobile]);

    const commonCloseLogic = () => {
        setIsFocus(false);

        document.body.removeAttribute(IS_SEARCH_OPEN);

        if (searchMenuRef.current) {
            searchMenuRef.current.style.display = 'none';
        }
    };

    const handleClose = useCallback(() => {
        commonCloseLogic();
    }, []);

    const handleMobileClose = useCallback(() => {
        commonCloseLogic();
        const mobileSearchBar = document.getElementById('mobile-search-bar');

        if (mobileSearchBar) {
            document.querySelector('header')?.classList.remove('search-isOpen');
            document.body.classList.remove('overflow-hidden');

            window.scrollTo({
                top: currentScrollPosition.current,
                behavior: 'smooth',
            });

            // HACK IOS: delete the 100dvh when the search it's close
            if (StringHelpers.isDevice('iPhone')) {
                mobileSearchBar.style.height = '';
            }
        }
    }, []);

    const handleIsomorphicClose = isMobile && isSearchOpen ? handleMobileClose : handleClose;

    const onClearSearches = useCallback(() => {
        setSearchInput('');
    }, []);

    const onSearchInputChange = useCallback(
        (input: string) => {
            setSearchInput(input);
            if (!isFocus) {
                onSearchFocus();
            }
        },
        [isFocus, onSearchFocus],
    );

    // @ts-expect-error useOnClickOutside takes a ref as an argument, so it should expect a null
    useOnClickOutside(parentRef, handleIsomorphicClose);

    useDetectScroll('mobile-search-bar', 'mobile-search-input', isMobile);

    if ((isMobile && device !== 'mobile') || (!isMobile && device === 'mobile')) {
        return null;
    }

    return (
        <div ref={parentRef} className={'w-full'}>
            <UISearchBar
                className={className}
                placeholder={t('placeholder')}
                onSearch={onSearch}
                onFocus={onSearchFocus}
                clearButton={
                    searchInput && (
                        <GlowClickable
                            className={'py-0.5'}
                            id={StringHelpers.addPrefix('clear-search-button', 'mobile-', isMobile)}
                            onClick={onClearSearches}
                            content={{
                                labelElement: (
                                    <GlowTextBodySmall
                                        text={t('action.clear')}
                                        textDecoration={'underline'}
                                        TagName={'span'}
                                    />
                                ),
                            }}
                        />
                    )
                }
                rightNode={
                    isFocus && (
                        <CloseMenu
                            className={'close-search-menu flex tablet:hidden text-text-primary'}
                            handleClose={handleIsomorphicClose}
                        />
                    )
                }
                searchInput={searchInput}
                onSearchInputChange={onSearchInputChange}
                searchLabel={t('label.search')}
                isMobile={isMobile}
            />
            <div className={'relative tablet:fixed w-fullViewport tablet:w-full -left-1 tablet:left-0'}>
                <div
                    id={StringHelpers.addPrefix('search-menu', 'mobile-', isMobile)}
                    ref={searchMenuRef}
                    className={clsx(
                        'w-full',
                        'absolute hidden',
                        'bg-background-l0 shadow-banner',
                        'justify-center items-center',
                    )}
                >
                    <div
                        className={clsx(
                            'flex flex-col tablet:flex-row flex-1 desktop:gap-2.5',
                            'px-1 desktopS:px-2.5 py-2',
                            'max-w-largestDesktop',
                            isMobile ? 'min-h-fullViewport' : 'h-full',
                        )}
                    >
                        <div
                            className={clsx(
                                'flex flex-col flex-1 gap-2.5',
                                searchInput ? 'desktop:flex-row' : 'tablet:flex-row',
                                isSearchOpen ? 'opacity-100' : 'opacity-0',
                                'duration-moderate3 delay-moderate3',
                            )}
                        >
                            {searchInput ? (
                                <SearchSuggestionsContainer
                                    searchInput={searchInput}
                                    onSearch={onSearch}
                                    onEmpty={handleClose}
                                    vipBrands={vipBrands}
                                />
                            ) : (
                                <>
                                    <PopularSearches />
                                    <RecentSearches locale={locale} />
                                </>
                            )}
                        </div>
                        <CloseMenu
                            className={'close-search-menu hidden tablet:flex !justify-end self-start text-text-primary'}
                            handleClose={handleIsomorphicClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
