import { RequestStrategy, SessionCookiesDTO } from '@headless-workspace/data';
import { Repository, Result, ResultFailure, ResultSuccess } from '../types';
import { mapSessionCookiesEntity, SessionCookiesEntity } from './SessionCookiesEntity';

export interface SessionCookiesRepositorySpec {
    getSessionCookies(locale: string): Promise<Result<SessionCookiesEntity>>;
}

export class SessionCookiesRepository extends Repository implements SessionCookiesRepositorySpec {
    path = '/login/v1';

    constructor(requestStrategy: RequestStrategy) {
        super(requestStrategy.authStrategy, requestStrategy.refreshTokenStrategy, requestStrategy.queryParamStrategy);
    }

    async getSessionCookies(locale: string): Promise<Result<SessionCookiesEntity>> {
        const url = `${this.path}/sessionCookies`;
        try {
            const response = await this.datasource.getResource(url, {
                locale,
            });
            const tokenResult = mapSessionCookiesEntity(SessionCookiesDTO.parse(response));
            return ResultSuccess(tokenResult);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailure(apiError.code, apiError.message, {
                locale,
                url,
            });
        }
    }
}
