import {
    fr_FR_NavRoutes,
    de_DE_NavRoutes,
    de_CH_NavRoutes,
    fr_CH_NavRoutes,
    tr_TR_NavRoutes,
    da_DK_NavRoutes,
    sv_SE_NavRoutes,
    el_GR_NavRoutes,
    ro_RO_NavRoutes,
    pl_PL_NavRoutes,
    cs_CZ_NavRoutes,
    pt_PT_NavRoutes,
    es_ES_NavRoutes,
    it_IT_NavRoutes,
} from '../navRoutes';
import { Locale, SUPPORTED_LOCALES } from './Locale';

export const navRoutes: Record<Locale, Partial<typeof fr_FR_NavRoutes>> = {
    [SUPPORTED_LOCALES.DEFAULT]: fr_FR_NavRoutes,
    [SUPPORTED_LOCALES.de_DE]: de_DE_NavRoutes,
    [SUPPORTED_LOCALES.de_CH]: de_CH_NavRoutes,
    [SUPPORTED_LOCALES.fr_CH]: fr_CH_NavRoutes,
    [SUPPORTED_LOCALES.tr_TR]: tr_TR_NavRoutes,
    [SUPPORTED_LOCALES.da_DK]: da_DK_NavRoutes,
    [SUPPORTED_LOCALES.sv_SE]: sv_SE_NavRoutes,
    [SUPPORTED_LOCALES.el_GR]: el_GR_NavRoutes,
    [SUPPORTED_LOCALES.ro_RO]: ro_RO_NavRoutes,
    [SUPPORTED_LOCALES.pl_PL]: pl_PL_NavRoutes,
    [SUPPORTED_LOCALES.cs_CZ]: cs_CZ_NavRoutes,
    [SUPPORTED_LOCALES.pt_PT]: pt_PT_NavRoutes,
    [SUPPORTED_LOCALES.es_ES]: es_ES_NavRoutes,
    [SUPPORTED_LOCALES.it_IT]: it_IT_NavRoutes,
};

const PATH_SEPARATOR = '/';

export type NavRoute = keyof (typeof navRoutes)[keyof typeof navRoutes];

export const buildRoute = (locale: Locale, route: NavRoute, params?: Record<string, string>): string => {
    const routePath = navRoutes[locale][route];
    const queryString = params ? `?${new URLSearchParams(params)}` : '';
    return `${PATH_SEPARATOR}${routePath}${queryString}`;
};
