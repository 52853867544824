'use client';

import React from 'react';
import { GlowSkeleton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const SearchCategoriesSkeleton: Component = () => {
    return (
        <>
            <GlowSkeleton width={'80%'} height={'2rem'} containerClassName={'w-full'} />
            <GlowSkeleton width={'100%'} height={'2rem'} containerClassName={'w-full'} />
        </>
    );
};
