'use client';

import { useState } from 'react';
import { CommonDI, SearchGeolocationRepositoryType } from '../../../../DI';
import { LocalityDetails } from '../../../../server';
import { ClientEnvKey, useClientData } from '../../providers';

export const useLocalityDetails = () => {
    const { getClientEnvVariable } = useClientData();
    const [localityDetails, setLocalityDetails] = useState<LocalityDetails | null>(null);

    const apiBaseUrl = getClientEnvVariable(ClientEnvKey.WoosmapApiBaseUrl);
    const woosmapKey = getClientEnvVariable(ClientEnvKey.WoosmapKey);

    const getLocalityDetails = async (localityId: string) => {
        try {
            const localityDetailsResult = await CommonDI.get(
                SearchGeolocationRepositoryType,
                apiBaseUrl,
            ).fetchLocalityDetails(localityId, woosmapKey);

            if (localityDetailsResult.type === 'success') {
                setLocalityDetails(localityDetailsResult.data);
            } else {
                setLocalityDetails(null);
            }
        } catch (e) {
            setLocalityDetails(null);
        }
    };

    return { localityDetails, getLocalityDetails };
};
