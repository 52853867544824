'use client';

import { useEffect, useState } from 'react';

export const useScroll = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [firstScrollUserEnd, setFirstScrollUserEnd] = useState(false);

    useEffect(() => {
        let scrollTimeout: NodeJS.Timeout;

        const onScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                setFirstScrollUserEnd(true);
                if (scrollTop === 0) {
                    setFirstScrollUserEnd(false);
                }
                setIsVisible(window.scrollY > 100);
            }, 200);
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return { isVisible, firstScrollUserEnd };
};
