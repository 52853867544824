'use client';

import { createContext } from 'react';
import { CountryCode, Locale } from '@headless-workspace/config';
import { FunctionHelpers } from '@headless-workspace/utils';

export type CountryContextValueType = {
    countryCode?: CountryCode;
    changeCountry: (countryCode: CountryCode, locale: Locale) => void;
};

const CountryContextInitialState: CountryContextValueType = {
    countryCode: undefined,
    changeCountry: FunctionHelpers.emptyFn,
};

export const CountryContext = createContext<CountryContextValueType>(CountryContextInitialState);
