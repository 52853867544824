import { PopularSearchDTO } from './PopularSearchDTO';

export type PopularSearchValue = Readonly<{
    link: string;
    name: string;
}>;

export const mapPopularSearch = (dto: PopularSearchDTO): PopularSearchValue => {
    return {
        link: dto.link,
        name: dto.name,
    };
};
