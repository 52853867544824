import { z } from 'zod';

export const SessionCookiesDTO = z.object({
    sessionCookies: z.array(
        z.object({
            id: z.string(),
            cookie: z.string(),
        }),
    ),
});

export type SessionCookiesDTO = z.infer<typeof SessionCookiesDTO>;
