import clsx from 'clsx';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component, Image } from '@headless-workspace/typings';
import { CardBanner, GlowTextBody, PropsWithClassName } from '../../../../src';

type GlowOpenBarServiceTileProps = PropsWithClassName & {
    title: string;
    description: string;
    href: string;
    image: Image;
};

export const GlowOpenBarServiceTile: Component<GlowOpenBarServiceTileProps> = ({
    className,
    title,
    description,
    href,
    image,
}) => {
    return (
        <CardBanner
            classNames={{
                container: clsx(
                    // CSS to create an “internal border” => prevents offset
                    'inline-block relative pointer-events-none',
                    'before:absolute',
                    'before:w-full before:h-full',
                    'hover:before:rounded-0.5',
                    'hover:before:border-bold hover:before:border-border-brand',
                    'hover:before:z-hoverBeforeBorder',
                ),
                wrapper: clsx(
                    'flex flex-row',
                    'w-serviceTile',
                    'border-medium border-solid border-border-primary rounded-0.5 overflow-hidden pointer-events-auto',
                    className,
                ),
            }}
            href={href}
            image={{
                src: image,
                classNames: {
                    wrapper: 'h-serviceTileImg aspect-1/1',
                    image: 'object-cover h-full',
                },
                sizes: tailwindTheme.width.serviceTileImg,
                fill: true,
            }}
        >
            <div className={'flex flex-col gap-0.5 p-1'}>
                <GlowTextBody text={title} fontWeight={'bold'} className={'line-clamp-1 text-ellipsis'} />
                <GlowTextBody text={description} className={'line-clamp-2 text-ellipsis'} />
            </div>
        </CardBanner>
    );
};
