import { StringHelpers } from '@headless-workspace/utils';
import { DataLayerYesNo } from './DataLayerEvent';

const addEventListeners = (
    selector: string,
    handler: (element: Element) => EventListener,
    refs: [Element, EventListener][],
) => {
    if (typeof document !== 'undefined') {
        const elements = document.querySelectorAll(selector);
        if (elements.length) {
            Array.from(elements).forEach((element) => {
                const eventHandler = handler(element);
                if (eventHandler) {
                    element.addEventListener('click', eventHandler);
                    refs.push([element, eventHandler]);
                }
            });
        }
    }
};

const mapBooleanToYesNo = (value?: boolean): DataLayerYesNo => {
    return value ? DataLayerYesNo.Yes : DataLayerYesNo.No;
};

const mapStringToYesNo = (value?: string): DataLayerYesNo => {
    return value && StringHelpers.isNotEmpty(value) ? DataLayerYesNo.Yes : DataLayerYesNo.No;
};

export const DataLayerUtils = {
    addEventListeners,
    mapBooleanToYesNo,
    mapStringToYesNo,
};
