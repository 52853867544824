export enum HeaderSlotType {
    StoreAndServices = 'storesItem',
    MyBeautyProgram = 'myBeautyProgramItem',
    MyAccount = 'myAccountItem',
}

export const mapHeaderSlotType = (type: string): HeaderSlotType | null => {
    switch (type) {
        case 'storesItem':
            return HeaderSlotType.StoreAndServices;
        case 'myBeautyProgramItem':
            return HeaderSlotType.MyBeautyProgram;
        case 'myAccountItem':
            return HeaderSlotType.MyAccount;
        default:
            return null;
    }
};
