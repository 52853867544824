'use client';

import React, { useState } from 'react';
import clsx from 'clsx';
import { GlowClickable, GlowIcon, Icons, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type AccordionProps = PropsWithClassName & {
    title: React.ReactNode;
    children: React.ReactNode;
    accordionId: string;
};

/*
 * UIAccordion is a React functional component that provides a collapsible section of content,
 * commonly known as an accordion. It allows users to toggle the visibility of the content
 * by clicking on a title bar.
 *
 * @component
 * @param {AccordionProps} props - The properties object.
 * @param {React.ReactNode} props.title - The title of the accordion, which is always visible.
 * @param {React.ReactNode} props.children - The content of the accordion, which is shown or hidden
 * based on the accordion's expanded state.
 * @param {string} props.accordionId - A unique identifier for the accordion, used for accessibility
 * attributes.
 * @param {string} [props.className] - Additional CSS classes to apply to the accordion for custom styling.
 *
 * @returns {JSX.Element} A JSX element representing the accordion component.
 *
 * @example
 * <UIAccordion title={<h3>Section Title</h3>} accordionId="unique-id" className="custom-class">
 *   <p>This is the content of the accordion.</p>
 * </UIAccordion>
 */

export const UIAccordion: Component<AccordionProps> = ({ title, children, className, accordionId }) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    return (
        <div className={'flex flex-col gap-0.5'}>
            <GlowClickable
                onClick={toggleExpand}
                className={clsx('flex items-center', className)}
                ariaControls={`${accordionId}-accordion-content`}
                ariaExpanded={isExpanded}
                id={`${accordionId}-accordion-title`}
                content={{
                    labelElement: (
                        <>
                            {title}
                            <GlowIcon
                                Icon={Icons.ChevronDown}
                                type={'medium'}
                                className={clsx('duration-moderate1 ease-linear', isExpanded && 'rotate-180')}
                            />
                        </>
                    ),
                }}
            />
            <div
                id={`${accordionId}-accordion-content`}
                className={clsx(!isExpanded && 'hidden')}
                aria-labelledby={`${accordionId}-accordion-title`}
            >
                {children}
            </div>
        </div>
    );
};
