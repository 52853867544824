import React from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowClickable, GlowTextCaption, PropsWithAriaLabel } from '../../../../src';

type GlowSearchTagProps = PropsWithAriaLabel & {
    text: string | React.ReactNode;
    href: string;
};

/**
 * Component called tag_search on FIGMA
 * A component that renders a clickable tag.
 * @param text - The text to render in the tag.
 * @param href - The href for the tag.
 * @param ariaLabel - The aria-label for the tag.
 */
export const GlowSearchTag: Component<GlowSearchTagProps> = ({ text, href, ariaLabel }) => {
    return (
        <GlowClickable
            className={
                '!inline-block bg-surface-neutral hover:bg-surface-neutral-hover active:bg-surface-neutral-pressed rounded-infinity px-1 py-0.5 text-center'
            }
            ariaLabel={ariaLabel}
            href={href}
            content={{
                labelElement: <GlowTextCaption text={text} />,
            }}
        />
    );
};
