import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ArrayHelpers } from '@headless-workspace/utils';

type GlowSkeletonProps = PropsWithChildren & {
    containerClassName?: string;
    isLoaded?: boolean;
    height?: number | string;
    width?: number | string;
    line?: number;
    itemClassName?: string;
};

export const GlowSkeleton: Component<GlowSkeletonProps> = ({
    children,
    isLoaded = false,
    height = '100%',
    width = '100%',
    line = 1,
    containerClassName,
    itemClassName,
}) => {
    if (isLoaded) {
        return children;
    }

    return (
        <div className={clsx('flex flex-col gap-0.5', 'animate-pulse', containerClassName)}>
            {ArrayHelpers.createSequentialArray(line).map((_, index) => (
                <span
                    key={index}
                    style={{
                        height,
                        width,
                    }}
                    className={clsx('bg-background-skeleton rounded-0.5', itemClassName)}
                />
            ))}
        </div>
    );
};
