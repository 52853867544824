'use client';

import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    GlowClickable,
    GlowClickableWithRightIcon,
    GlowTextBody,
    GlowTextBodySmall,
    Icons,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithCTA,
    PropsWithStyle,
} from '../../../../src';
import { Accordion } from './Accordion';
import { GlowAccordion } from './GlowAccordion';

type GlowLinksAccordionProps = {
    title: string;
    contents: Accordion[];
    isCollapsible: boolean;
};

/**
 * This component is used to display the footer accordion.
 * A component that renders an accordion.
 * @param title - The title of the accordion.
 * @param contents - The contents of the accordion.
 * @param isCollapsible - A boolean that determines if the accordion is collapsible.
 */
export const GlowLinksAccordion: Component<GlowLinksAccordionProps> = ({ title, contents, isCollapsible }) => {
    return (
        <GlowAccordion
            title={title}
            contents={contents}
            isCollapsible={isCollapsible}
            containerClassName={'flex flex-col flex-1 py-1.5 desktop:py-0'}
            titleClassName={'text-text-primary-inverse-fixed'}
            itemsContainerClassName={'gap-1'}
            itemContainerClassName={'first:pt-1'}
            TitleComponent={LinksAccordionTitle}
            ItemComponent={LinksAccordionItem}
            isTitleBold={true}
        />
    );
};

type LinksAccordionTitleProps = PropsWithStyle &
    PropsWithAriaLabel &
    PropsWithClassName &
    PropsWithCTA & {
        label: string;
        labelColor?: string;
        isCollapsible?: boolean;
        isCollapsed?: boolean;
        showArrow?: boolean;
    };

const LinksAccordionTitle: Component<LinksAccordionTitleProps> = ({
    style,
    className,
    ariaLabel,
    label,
    labelColor,
    isCollapsible = false,
    isCollapsed = false,
    showArrow,
    ...props
}) => {
    if (!isCollapsible) {
        return (
            <GlowTextBody
                text={label}
                fontWeight={'bold'}
                color={'primary-inverse-fixed'}
                className={'text-wrap line-clamp-2 desktop:line-clamp-1'}
            />
        );
    }

    return (
        <GlowClickableWithRightIcon
            content={{
                labelElement: (
                    <GlowTextBody
                        text={label}
                        fontWeight={'bold'}
                        color={'primary-inverse-fixed'}
                        className={'text-wrap text-left line-clamp-2 desktop:line-clamp-1'}
                        TagName={'span'}
                    />
                ),
            }}
            className={clsx('fill-text-primary-inverse', className)}
            rightIcon={Icons.ChevronDown}
            iconClassName={clsx('duration-moderate1 ease-linear fill-text-primary', isCollapsed && 'rotate-180')}
            {...props}
        />
    );
};

const LinksAccordionItem: Component<{ label: string; href: string }> = ({ label, href }) => {
    return (
        <GlowClickable
            href={href}
            content={{
                labelElement: (
                    <GlowTextBodySmall
                        text={label}
                        className={'text-wrap line-clamp-1 hover:text-underline'}
                        color={'secondary-inverse-fixed'}
                    />
                ),
            }}
        />
    );
};
