'use client';

import { useEffect } from 'react';
import { DataLayerEvent } from './DataLayerEvent';
import { DataLayerEventParams } from './DataLayerEventParams';
import { DataLayerKey } from './DataLayerKey';
import { DataLayerManager } from './DataLayerManager';

const dataLayerManager = new DataLayerManager();

export type UseDataLayerReturnType = {
    trackEvent: (eventName: DataLayerEvent, params: DataLayerEventParams) => void;
    showPrivacyCenter: () => void;
    addVariable: (name: DataLayerKey, value: string) => void;
    addVariables: (variables: Partial<Record<DataLayerKey, string>>) => void;
};

export const useDataLayer = (variables: Partial<Record<DataLayerKey, string>> = {}): UseDataLayerReturnType => {
    useEffect(() => {
        dataLayerManager.addVariables(variables);
    }, [variables]);

    const addVariable = (name: DataLayerKey, value: string) => {
        if (typeof window !== 'undefined') {
            dataLayerManager.addVariable(name, value);
        }
    };

    const addVariables = (variables: Partial<Record<DataLayerKey, string>>) => {
        if (typeof window !== 'undefined') {
            dataLayerManager.addVariables(variables);
        }
    };

    return {
        trackEvent: (eventName: DataLayerEvent, params: DataLayerEventParams) =>
            dataLayerManager.trackEvent(eventName, params),
        showPrivacyCenter: dataLayerManager.showPrivacyCenter,
        addVariable,
        addVariables,
    };
};
