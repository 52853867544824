import { z } from 'zod';

export const ApiErrorPayloadDecoder = z.object({
    errors: z.array(
        z.object({
            errorCode: z.string(),
            errorMessage: z.string().optional(),
            tips: z.string().optional(),
        }),
    ),
});

export type ApiErrorPayloadDto = z.infer<typeof ApiErrorPayloadDecoder>;
