import { StringHelpers } from '@headless-workspace/utils';
import { HTMLString } from '../../HTMLString';
import { FooterComplianceDTO } from '../FooterSlotsDTO';

export type FooterComplianceValue = Readonly<{
    id: string;
    label: HTMLString;
    href: string;
}>;

export const mapFooterCompliance = (dto: FooterComplianceDTO): FooterComplianceValue[] => {
    return dto.details.items.map((item, index) => ({
        id: `compliance-${index}`,
        label: HTMLString(StringHelpers.decodeSpecialChars(item.title)),
        href: item.link,
    }));
};
