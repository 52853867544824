import { Component, Image as ImageType } from '@headless-workspace/typings';
import { GlowClickable } from '../../molecules';
import { NextImageType } from '../../props';
import { GlowImage } from './GlowImage';

export type ImageClickableProps = {
    href?: string;
    src?: ImageType;
    TagName?: NextImageType;
    priority?: boolean;
    fill?: boolean;
    sizes?: string;
    loading?: 'lazy' | 'eager';
    classNames?: {
        cta?: string;
        image?: string;
        wrapper?: string;
    };
};

export const GlowImageClickable: Component<ImageClickableProps> = ({
    src,
    href,
    classNames,
    sizes,
    TagName,
    priority,
    loading,
    fill = true,
}) => {
    if (!src) {
        return null;
    }

    const imageContent = (
        <GlowImage
            image={src}
            className={classNames?.wrapper}
            imgClassName={classNames?.image}
            sizes={sizes}
            TagName={TagName}
            fill={fill}
            priority={priority}
            loading={loading}
        />
    );
    return href ? (
        <GlowClickable className={classNames?.cta} href={href} content={{ icon: imageContent }} />
    ) : (
        imageContent
    );
};
