const defaultImageLoader = ({ src, width, quality }) => {
    const q = quality || 75;

    if (src.indexOf('?') !== -1) {
        const [url, queryString] = src.split('?');
        const params = new URLSearchParams(queryString);

        params.set('w', params.get('w') || width);
        params.set('q', q);
        return `${url}?${params.toString()}`;
    }
    return `${src}?w=${width}&q=${q}`;
}

module.exports = defaultImageLoader;
