'use client';

import { useState } from 'react';
import { Component, NoEmptyArray } from '@headless-workspace/typings';
import { GlowPill, Pill, PropsWithClassName, PropsWithFontFamily } from '../../../../src';

type GlowPillsProps = PropsWithClassName &
    Partial<PropsWithFontFamily> & {
        contents: NoEmptyArray<Pill>;
    } & (
        | {
              type: 'checkbox';
              onChange?: (selectedIds: string[]) => void;
              defaultSelected?: string[];
          }
        | {
              type: 'radio';
              onChange?: (selectedId: string) => void;
              defaultSelected?: string;
          }
    );

export const GlowPills: Component<GlowPillsProps> = ({
    type,
    contents,
    defaultSelected,
    onChange,
    className,
    fontFamily,
}) => {
    const [selectedPills, setSelectedPills] = useState<string[]>(() => {
        if (type === 'radio') {
            // For radio, select first by default if no defaultSelected provided
            return defaultSelected ? [defaultSelected] : [contents[0].id];
        } else {
            // For checkbox, use defaultSelected or an empty array if not provided
            return defaultSelected ?? [];
        }
    });

    const handlePillChange = (selectedId: string, checked: boolean) => {
        if (type === 'radio') {
            // For radio, only one selection is allowed
            const newSelectedPills = checked ? [selectedId] : [];

            setSelectedPills(newSelectedPills);
            onChange?.(selectedId);
        } else {
            // For checkbox, multiple selections are allowed
            const newSelectedPills: string[] = checked
                ? [...selectedPills, selectedId]
                : selectedPills.filter((id) => id !== selectedId);

            setSelectedPills(newSelectedPills);
            onChange?.(newSelectedPills);
        }
    };

    return (
        <div className={className}>
            {contents.map((content, index) => (
                <GlowPill
                    key={`pill-${index}`}
                    type={type}
                    content={content}
                    checked={selectedPills.includes(content.id)}
                    onChange={handlePillChange}
                    fontFamily={fontFamily}
                />
            ))}
        </div>
    );
};
