import React from 'react';
import clsx from 'clsx';
import { Component, Image, isImageWithSrc } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { PropsWithClassName, PropsWithStyle } from '../../props';
import { ImageProps, isImgProps } from './ImageProps';

type GlowImageProps = PropsWithStyle &
    PropsWithClassName &
    ImageProps & {
        image: Image;
        aspectRatio?: number;
        loading?: 'lazy' | 'eager';
        imgClassName?: string;
        onMouseOver?: () => void;
        containerRef?: React.ForwardedRef<HTMLSpanElement>;
    };

/**
 * Component that renders an image wrapped in <div /> with a defined size if an ImageWithSrc is passed in props.
 * Component that renders an image wrapped in <picture /> with a defined size if an ImageWithSizes is passed in props.
 * The image can be a <img /> or a <Image /> (from next/image) depending on the TagName prop.
 * @param image - The image to render. Can be an ImageWithSrc or an ImageWithSizes.
 * @param aspectRatio - The aspect ratio of the image.
 * @param loading - The loading strategy of the image.
 * @param imgClassName - Additional styles for the image.
 * @param onMouseOver - Mouse over event handler.
 * @param className - Additional styles for the container.
 * @param props - Can contain the following:
 * @param props.TagName - The tag name of the image. Can be 'img' or Image (from next/image).
 * @param props.height - The height of the image. If the image is an <img />, this prop can be a number | string | undefined. If the image is an <Image />, this prop can be a number | undefined.
 * @param props.width - The width of the image. If the image is an <img />, this prop can be a number | string | undefined. If the image is an <Image />, this prop can be a number | undefined.
 * The following props are only available if the image is an <img />:
 * @param props.fetchPriority - The fetch priority of the image. Can be 'auto' | 'high' | 'low'.
 * The following props are only available if the image is an <Image />:
 * @param props.priority - The priority of the image. By default, it is false.
 * @param props.placeholder - The placeholder of the image. Can be 'empty' | 'blur' | `data:image/${string}`.
 * @param props.blurDataURL - The blur data URL of the image. It is required if the placeholder is 'blur'. Must be a base64-encoded image.
 * @param props.fill - If true, the image will fill the container.
 * @param props.sizes - A string, similar to a media query, that provides information about how wide the image will be at different breakpoints. It is required if fill is true.
 * @param props.containerRef - A reference to the image's container.
 * @param containerStyle - Additional styles for the image's container.
 */
export const GlowImage: Component<GlowImageProps> = ({
    image,
    aspectRatio,
    loading,
    imgClassName,
    className,
    onMouseOver,
    style: containerStyle,
    ...props
}) => {
    const isImg = isImgProps(props);
    const isRelative = isImg ? false : props.fill;

    const renderImage = (src: string, alt: string) => {
        if (isImg) {
            return (
                <img
                    className={imgClassName}
                    src={src}
                    alt={alt}
                    width={props.width}
                    height={props.height}
                    style={{ aspectRatio }}
                    loading={loading}
                    fetchPriority={props.fetchPriority}
                />
            );
        }
        return (
            <props.TagName
                className={imgClassName}
                src={src}
                alt={alt}
                width={props.width}
                height={props.height}
                loading={loading}
                priority={props.priority}
                placeholder={props.placeholder}
                blurDataURL={props.blurDataURL}
                fill={props.fill}
                sizes={props.sizes}
                style={{ aspectRatio }}
            />
        );
    };

    if (isImageWithSrc(image)) {
        return (
            <span
                className={clsx('inline-block', isRelative && 'relative', onMouseOver && 'cursor-pointer', className)}
                onMouseOver={onMouseOver}
                onFocus={onMouseOver}
                ref={props.containerRef}
                style={containerStyle}
            >
                {renderImage(image.src, image.alt)}
            </span>
        );
    }

    const defaultImage = image.small ?? image.medium ?? image.large;
    if (!defaultImage) {
        // This case should never occur
        return null;
    }

    return (
        <picture
            className={clsx(isRelative && 'relative', onMouseOver && 'cursor-pointer', className)}
            onMouseOver={onMouseOver}
            onFocus={onMouseOver}
        >
            {image.large && <source srcSet={image.large} media={StringHelpers.makeMediaQuery('desktopS')} />}
            {image.medium && <source srcSet={image.medium} media={StringHelpers.makeMediaQuery('tablet')} />}
            {image.small && <source srcSet={image.small} media={StringHelpers.makeMediaQuery('tablet', true)} />}
            {renderImage(defaultImage, image.alt)}
        </picture>
    );
};
