import { OpenHours } from './OpenHours';

const formatOpenHours = (
    openHours: OpenHours[],
    translatedDays: string[],
    defaultText: string,
): { day: string; hours: string }[] => {
    const currentDateDay = new Date().getDay();

    const startDateIndex = openHours.findIndex(({ day }) => day === currentDateDay);

    const reorderArray = [...openHours.slice(startDateIndex), ...openHours.slice(0, startDateIndex)];

    return reorderArray.map(({ day, hours }) => ({
        day: translatedDays[day - 1],
        hours: hours.length > 0 ? hours.map(({ from, to }) => `${from} - ${to}`).join(' - ') : defaultText,
    }));
};

const formatAddress = (address: string, city: string, postalCode: string): string => {
    // TODO: Implement according to other languages format
    return `${address}, ${postalCode} ${city}`;
};

export const FormatHelpers = {
    formatOpenHours,
    formatAddress,
};
