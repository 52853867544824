import { SessionCookiesDTO } from '@headless-workspace/data';

export type SessionCookiesEntity = ReadonlyArray<{
    id: string;
    cookie: string;
}>;

export const mapSessionCookiesEntity = (dto: SessionCookiesDTO): SessionCookiesEntity => {
    return dto.sessionCookies.map((sessionCookies) => ({
        id: sessionCookies.id,
        cookie: sessionCookies.cookie,
    }));
};
