'use client';

import React, { ChangeEvent, FormEvent, ReactNode } from 'react';
import clsx from 'clsx';
import { DataTestConfig } from '@headless-workspace/config';
import { GlowIcon, GlowInput, Icons, PropsWithAriaLabel, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';

type SearchBarProps = PropsWithClassName &
    PropsWithAriaLabel & {
        placeholder: string;
        onFocus: () => void;
        onSearch: (value: string) => void;
        clearButton: ReactNode | ReactNode[];
        rightNode?: ReactNode | ReactNode[];
        searchInput: string;
        onSearchInputChange: (input: string) => void;
        searchLabel: string;
        isMobile?: boolean;
    };

export const UISearchBar: Component<SearchBarProps> = ({
    className,
    placeholder,
    onFocus,
    onSearch,
    ariaLabel,
    clearButton,
    rightNode,
    searchInput,
    onSearchInputChange,
    searchLabel,
    isMobile,
}) => {
    const onSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSearch(searchInput);
    };

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        onSearchInputChange(event.target.value);
    };

    return (
        <div className={'flex justify-between items-center gap-6'}>
            <form
                className={clsx(
                    'flex flex-row items-center justify-between',
                    'bg-surface-neutral rounded-infinity w-full h-searchBar py-0.5 px-0.75',
                    className,
                )}
                id={StringHelpers.addPrefix('search-form', 'mobile-', isMobile)}
                role={'search'}
                onSubmit={onSearchSubmit}
            >
                <div className={'flex flex-row items-center w-full'}>
                    <label className={'hidden'} htmlFor={'search-input'}>
                        {searchLabel}
                    </label>
                    <GlowIcon Icon={Icons.Search} type={'large'} className={'text-text-primary'} />

                    <GlowInput
                        className={clsx(
                            'mx-0.25 flex-grow-1 h-auto bg-surface-neutral',
                            'focus:outline-none scale-0.875 origin-left -mr-2 text-text-primary placeholder:text-text-tertiary',
                            'overflow-ellipsis',
                        )}
                        id={StringHelpers.addPrefix('search-input', 'mobile-', isMobile)}
                        type={'search'}
                        name={'search'}
                        inputMode={'search'}
                        enterKeyHint={'search'}
                        placeholder={placeholder}
                        value={searchInput}
                        onChange={onSearchChange}
                        onFocus={onFocus}
                        autoComplete={'off'}
                        aria-label={ariaLabel}
                        aria-autocomplete={'none'}
                        dataTestId={DataTestConfig.testIds.searchBar}
                    />
                </div>
                {clearButton}
            </form>
            {rightNode}
        </div>
    );
};
