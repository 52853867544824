import { LocalityDetailsDTO } from './LocalityDetailsDTO';

export type LocalityDetails = Readonly<{
    geometry: {
        location: {
            latitude: number;
            longitude: number;
        };
    };
}>;

export const mapLoyaltyDetails = (localityDetailsDTO: LocalityDetailsDTO): LocalityDetails => {
    return {
        geometry: {
            location: {
                latitude: localityDetailsDTO.result.geometry.location.lat,
                longitude: localityDetailsDTO.result.geometry.location.lng,
            },
        },
    };
};
