import { isConnectedUserInfo, isGuestUserInfo, UserInfo, UserSephoraCard } from '../../../server';
import {
    DataLayerCustomerCategory,
    DataLayerCustomerLoyaltyCategory,
    DataLayerEventCategory,
    DataLayerEventLabel,
} from './DataLayerEvent';
import { DataLayerEventParams } from './DataLayerEventParams';

/** Utils **/
export const getUserLoyaltyCategory = (userInfo?: UserInfo): DataLayerCustomerLoyaltyCategory => {
    if (!userInfo || isGuestUserInfo(userInfo)) {
        return DataLayerCustomerLoyaltyCategory.UnactiveNonBuyer;
    }
    if (userInfo.loyalty) {
        return userInfo.loyalty.sephoraCard as Omit<
            UserSephoraCard,
            UserSephoraCard.PreBuy
        > as DataLayerCustomerLoyaltyCategory;
    }
    if (userInfo.ordersCount > 0) {
        return DataLayerCustomerLoyaltyCategory.UnactiveBuyer;
    }
    return DataLayerCustomerLoyaltyCategory.UnactiveNonBuyer;
};

export const getCustomerCategory = (userInfo?: UserInfo): DataLayerCustomerCategory => {
    if (isConnectedUserInfo(userInfo)) {
        if (userInfo.ordersCount > 0) {
            return DataLayerCustomerCategory.Repeat;
        }
        return DataLayerCustomerCategory.New;
    }
    return DataLayerCustomerCategory.NotLogged;
};

/** Newsletter **/
export const trackNewsletterClick = (label: DataLayerEventLabel, userInfo?: UserInfo): DataLayerEventParams => {
    return {
        id: 'click',
        category: DataLayerEventCategory.Newsletter,
        action: 'inscription',
        label,
        user_newcustomer: getCustomerCategory(userInfo),
        user_loyalty_category: getUserLoyaltyCategory(userInfo),
        user_logged: isConnectedUserInfo(userInfo),
    };
};
