import { GlowTextCaption } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type RedDotInformationProps = {
    redDotText?: string;
};

export const UIRedDotInformation: Component<RedDotInformationProps> = ({ redDotText }) => {
    return (
        <div className={'p-0.5 flex items-center justify-center bg-surface-secondary rounded-0.25'}>
            <GlowTextCaption text={redDotText} color={'primary'} />
        </div>
    );
};
