import { Result } from '@headless-workspace/core-domain';
import { CustomerValue, UserSephoraCard, UserSephoraCardUnlimited, UserValue } from '../customer';

export enum UserConnectionStatus {
    Guest = 'guest',
    Registered = 'registered',
}

export type ConnectedUserInfo = Readonly<
    {
        status: UserConnectionStatus.Registered;
        email: string;
        customerEncryptedEmail: string;
    } & UserValue
>;

export type GuestUserInfo = Readonly<{
    status: UserConnectionStatus.Guest;
}>;

export type UserInfo = ConnectedUserInfo | GuestUserInfo;

export const isConnectedUserInfo = (userInfo: UserInfo | undefined): userInfo is ConnectedUserInfo => {
    return userInfo?.status === UserConnectionStatus.Registered;
};

export const isGuestUserInfo = (userInfo: UserInfo | undefined): userInfo is GuestUserInfo => {
    return userInfo?.status === UserConnectionStatus.Guest;
};

export const getConnectedUserInfo = (userInfo: Result<UserInfo>): ConnectedUserInfo | undefined => {
    return userInfo.type === 'success' && isConnectedUserInfo(userInfo.data) ? userInfo.data : undefined;
};

export const mapConnectedUserInfo = (
    customer: CustomerValue,
    email: string,
    customerEncryptedEmail: string,
): ConnectedUserInfo => {
    return {
        status: UserConnectionStatus.Registered,
        email,
        customerEncryptedEmail,
        ...customer,
    };
};

export const isUserGold = (userInfo: ConnectedUserInfo): boolean => {
    return (
        userInfo.loyalty?.sephoraCard === UserSephoraCard.Gold ||
        userInfo.loyalty?.sephoraCard === UserSephoraCardUnlimited.Star
    );
};

export const hasFidelityProgram = (userInfo: ConnectedUserInfo): boolean => {
    return !!userInfo.loyalty;
};
