export enum CookieName {
    ShopperToken = 'shopper-token',
    ShopperRefreshToken = 'shopper-refresh-token',
    ShopperRefreshExtendedToken = 'shopper-refresh-token-extended',
    DWSID = 'dwsid',
    FavoriteStore = 'favStore',
    RedirectLocale = 'redirectLocale',
    EffectiveDate = 'effectiveDate',
    GuestWishlistProductId = 'guestWishlistedProduct',
    ColorSetting = 'colorSetting',
}
