import { AuthorizationStrategy, CacheStrategy, QueryParamStrategy, RefreshTokenStrategy } from '../../strategy';
import { ApiBehaviourDelegate, ApiDatasource } from '../api';
import { HttpMethod } from '../http';
import { BffBehaviourDelegate } from './BffBehaviourDelegate';

export class BffDatasource implements ApiDatasource {
    constructor(
        readonly baseUrl: string,
        authStrategyProvider: Partial<AuthorizationStrategy>,
        refreshTokenStrategy: Partial<RefreshTokenStrategy>,
        cacheStrategy: CacheStrategy,
        queryParamStrategy: Partial<QueryParamStrategy>,
        private readonly apiBehaviourDelegate: ApiBehaviourDelegate = new BffBehaviourDelegate(
            authStrategyProvider,
            refreshTokenStrategy,
            cacheStrategy,
            queryParamStrategy,
        ),
    ) {}

    async getResource<T>(path: string, params?: Record<string, unknown> | undefined): Promise<T | null> {
        const url = this.apiBehaviourDelegate.buildUrl(this.baseUrl, path, params);
        const response = await this.apiBehaviourDelegate.callResource(url, HttpMethod.GET);
        return response.data as T;
    }

    async createResource<Input, Output>(
        path: string,
        resource: Input,
        params?: Record<string, unknown>,
    ): Promise<Output> {
        const url = this.apiBehaviourDelegate.buildUrl(this.baseUrl, path, params);
        const response = await this.apiBehaviourDelegate.callResource(url, HttpMethod.POST, {
            body: resource,
        });
        return response.data as Output;
    }

    async patchResource<Input, Output = Input>(
        path: string,
        resource: Input,
        params?: Record<string, unknown>,
    ): Promise<Output> {
        const url = this.apiBehaviourDelegate.buildUrl(this.baseUrl, path, params);
        const response = await this.apiBehaviourDelegate.callResource(url, HttpMethod.PATCH, {
            body: resource,
        });
        return response.data as Output;
    }

    async deleteResource<Input, Output = Input>(
        path: string,
        resource: Input,
        params?: Record<string, unknown>,
    ): Promise<Output> {
        const url = this.apiBehaviourDelegate.buildUrl(this.baseUrl, path, params);
        const response = await this.apiBehaviourDelegate.callResource(url, HttpMethod.DELETE, {
            body: resource,
        });
        return response.data as Output;
    }
}
