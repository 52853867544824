'use client';

import { useEffect } from 'react';
import { useDataLayer } from '../dataLayer';
import { Hash } from './Hash';

export const useHashObserver = () => {
    const { showPrivacyCenter } = useDataLayer();

    useEffect(() => {
        const handleHashChange = () => {
            switch (window.location.hash) {
                case Hash.CookiesPolicy: {
                    showPrivacyCenter();
                    break;
                }
            }
        };

        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [showPrivacyCenter]);
};
