import { ValueOf } from 'next/constants';
import { DEFAULT_COUNTRY, Locale } from '@headless-workspace/config';
import { LanguageHelpers, ObjectHelpers } from '@headless-workspace/utils';
import { VipBrandsDTO } from './VipBrandsDTO';

export type VipBrandsValue = Record<string, VipBrandValue>;

export type VipBrandValue = {
    pdp?: {
        logoBanner?: boolean;
    };
    hub?: {
        logoBanner?: boolean;
    };
    universe?: {
        logoBanner?: boolean;
    };
    brandListing?: {
        logoBanner?: boolean;
    };
    hideProductFlag?: boolean;
    hidePromotionFlag?: boolean;
    filterUppercase?: boolean;
    enableEndOfDate?: boolean;
    hideSocialSharing?: boolean;
    brandLogoPosition?: string;
    productAllBlackPrice?: boolean;
    enablePromotionPercentage?: boolean;
    enablePricePromoInfo?: boolean;
    showVipFooterLogo?: {
        enable: boolean;
        imageSrc: string;
    };
    whiteLogoBackground?: boolean;
    fullWidthLogoBanner?: boolean;
    squaredShade?: boolean;
    hideFromSearch?: boolean;
    productNameArialFont?: boolean;
    blackAddToBasketButton?: boolean;
    carouselTitlesArial?: boolean;
    hideSeoTitleOnCategoryPage?: boolean;
    underlineBlackTab?: boolean;
    hideReviewTab?: boolean;
    isNotSocialProof?: boolean;
};

export const mapVipBrands = (dto: VipBrandsDTO, locale: string): VipBrandsValue => {
    return ObjectHelpers.mapObject(dto, mapVipBrand);
};

export const mapVipBrand = (dto: ValueOf<VipBrandsDTO>, locale?: string): VipBrandValue => {
    const imgVipFooterLogo = (() => {
        const img = dto?.showVipFooterLogo?.imageSrc;
        const lang = locale ? LanguageHelpers.parseCountry(locale as Locale).toLocaleLowerCase() : DEFAULT_COUNTRY;

        if (img && typeof img === 'string') {
            return img;
        } else if (img && typeof img === 'object') {
            return img[lang];
        }
    })();

    return {
        pdp: {
            logoBanner: dto.pdp?.logobanner,
        },
        hub: {
            logoBanner: dto.hub?.logobanner,
        },
        universe: {
            logoBanner: dto.universe?.logobanner,
        },
        brandListing: {
            logoBanner: dto.brandlisting?.logobanner,
        },
        hideProductFlag: dto.hideproductflag,
        hidePromotionFlag: dto.hidepromotionflag,
        filterUppercase: dto.filteruppercase,
        enableEndOfDate: dto.enableEndOfDate,
        hideSocialSharing: dto.hidesocialsharing,
        brandLogoPosition: dto.brandLogoPosition,
        productAllBlackPrice: dto.productAllBlackPrice,
        enablePromotionPercentage: dto.enablePromotionPercentage,
        enablePricePromoInfo: dto.enablePricePromoInfo,
        showVipFooterLogo: dto.showVipFooterLogo && {
            enable: dto?.showVipFooterLogo?.enable ?? false,
            imageSrc: imgVipFooterLogo ?? '',
        },
        // The whiteLogoChanel property from SODA is used to set the banner's background color to white but also to set the banner's full width.
        // We decided to divide this property into two separate properties in our model to avoid confusion.
        whiteLogoBackground: dto.whiteLogoChanel,
        fullWidthLogoBanner: dto.whiteLogoChanel,
        squaredShade: dto.squaredShade,
        hideFromSearch: dto.hideFromSearch,
        productNameArialFont: dto.productNameArialFont,
        blackAddToBasketButton: dto.blackAddToCartButton,
        carouselTitlesArial: dto.carouselTitlesArial,
        hideSeoTitleOnCategoryPage: dto.hideSeoTitleOnCategoryPage,
        underlineBlackTab: dto.underlineBlackTab,
        hideReviewTab: dto.hideReviewTab,
        isNotSocialProof: dto.isNotSocialProof,
    };
};
