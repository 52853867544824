import { CountryKeyValue, Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultEmpty, ResultSuccess } from '@headless-workspace/core-domain';
import { LanguageHelpers } from '@headless-workspace/utils';
import { RedDotDTO } from './RedDotDTO';
import { EnableRedDot, RedDotValue, mapRedDotValue } from './RedDotValue';

const RED_DOT_CONFIGS_KEY = 'redDotJSON';

export interface RedDotRepositorySpec {
    fetchRedDotConfig(locale: Locale): Promise<Result<RedDotValue>>;
}

export class RedDotRepository extends ConfigByKeyRepository implements RedDotRepositorySpec {
    async fetchRedDotConfig(locale: Locale): Promise<Result<RedDotValue>> {
        const redDotConfigResult = await this.getConfigByKey(RED_DOT_CONFIGS_KEY, { locale }, (value) =>
            RedDotDTO.parse(LanguageHelpers.retrieveDataByCountry(value as CountryKeyValue<EnableRedDot>, locale)),
        );

        if (redDotConfigResult.type !== 'success') {
            return ResultEmpty();
        }

        return ResultSuccess(mapRedDotValue(redDotConfigResult.data.enable, redDotConfigResult.data.redDotTxt));
    }
}
