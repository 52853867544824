import { envConfig } from '../EnvConfig';

export const scriptConfig = {
    Booxi: 'https://www.booxi.eu/booknow/booknow.js',
    TagCommander: `${envConfig.trackingBaseUrl}${envConfig.trackingPath}`,
    TagCommanderWebAnalytics: `${envConfig.trackingBaseUrl}${envConfig.trackingAnalyticsPath}`,
    TagCommanderMedia: `${envConfig.trackingBaseUrl}${envConfig.trackingMediaPath}`,
    ReactScan: 'https://unpkg.com/react-scan/dist/auto.global.js',
} as const;

export const tagCommanderScript = `
//<![CDATA[
var tc_vars = {};
`;

const redirectOnObfuscatedLinkClick: EventListener = (event) => {
    const target = event.currentTarget;
    if (target instanceof HTMLElement) {
        const obfuscatedHref = target.dataset['obfuscatedHref'];
        if (obfuscatedHref) {
            const decodedHref = decodeURIComponent(window.atob(obfuscatedHref));
            window.location.assign(decodedHref);
        }
    }
};

export const obfuscateLinks = () =>
    document.querySelectorAll('span[role=link]')?.forEach((element) => {
        element?.removeEventListener('click', redirectOnObfuscatedLinkClick);
        element?.addEventListener('click', redirectOnObfuscatedLinkClick);
    });

export const appsflyerInitScript = (appsFlyerKey: string) => `
  !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
  (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
  t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
  o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
  p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: '${appsFlyerKey}'}});

  var mobilePatterns = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  var isMobile = mobilePatterns.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });

  if (isMobile) {
    AF('banners', 'showBanner');
  }
`;

export const appsflyerAfterInitScript = `
setTimeout(function() {
    var afbContainer = document.querySelector('.afb-container');
    var footerTcPrivacy = document.querySelector('#footer_tc_privacy');

    if (afbContainer && footerTcPrivacy) {
        footerTcPrivacy.style.bottom = afbContainer.offsetHeight + 'px';
    }
}, 1000);`;
