import { ReactNode } from 'react';
import { useTranslations } from 'next-intl';
import { GlowClickable, GlowTypography, GlowTypographyProps, PropsWithCTA } from '@headless-workspace/glow-ds';
import { Component, IntlMessagesKeys } from '@headless-workspace/typings';

export type RichTextProps = Omit<GlowTypographyProps, 'text'> & {
    TagName?: keyof HTMLElementTagNameMap;
    messageKey: IntlMessagesKeys;
    values?: Record<string, string | number | undefined>;
    links?: Record<string, PropsWithCTA>;
};

export const RichText: Component<RichTextProps> = ({
    fontSize,
    messageKey,
    values,
    links = {},
    TagName,
    className,
    dataTestId,
    ...props
}) => {
    const t = useTranslations();
    return (
        <GlowTypography
            TagName={TagName}
            className={className}
            dataTestId={dataTestId}
            fontSize={fontSize}
            {...props}
            innerHTML={t.rich(messageKey, {
                b: (text: ReactNode) => (
                    <GlowTypography
                        fontSize={fontSize}
                        TagName={'span'}
                        innerHTML={text}
                        fontWeight={'bold'}
                        className={'inline-flex'}
                        {...props}
                    />
                ),
                important: (text: ReactNode) => (
                    <GlowTypography
                        fontSize={fontSize}
                        TagName={'strong'}
                        innerHTML={text}
                        className={'inline-flex'}
                        {...props}
                    />
                ),
                u: (text: ReactNode) => (
                    <GlowTypography
                        fontSize={fontSize}
                        TagName={'span'}
                        innerHTML={text}
                        textDecoration={'underline'}
                        className={'inline-flex'}
                        {...props}
                    />
                ),
                br: () => <br />,
                ...values,
                ...Object.keys(links).reduce(
                    (linkCreators, linkId) => ({
                        ...linkCreators,
                        [linkId]: (chunk: ReactNode) => (
                            <GlowClickable
                                className={'!inline'}
                                content={{
                                    labelElement: chunk,
                                }}
                                {...links[linkId]}
                            />
                        ),
                    }),
                    {},
                ),
            })}
        />
    );
};
