import { z } from 'zod';
import { PredicateHelpers } from './PredicateHelpers';
import { IsomorphicLogger } from './logger';

const Logger = new IsomorphicLogger();

const safeParseArray = <T extends z.ZodTypeAny>(value: unknown[] | undefined, type: T): z.infer<T>[] => {
    if (!value) {
        return [];
    }
    return value
        .map((item) => {
            const result = type.safeParse(item);
            if (result.success) {
                return result.data;
            }
            Logger.error('Parsing error', item, result.error);
            return undefined;
        })
        .filter(PredicateHelpers.notNullOrUndefined);
};

export const DecoderHelpers = {
    safeParseArray,
};
