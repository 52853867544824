import clsx from 'clsx';
import { GlowImage, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component, Image } from '@headless-workspace/typings';

type UIBrandBannerProps = {
    logo?: Image;
    redirectionURL?: string;
    hasWhiteLogoBackground?: boolean;
    logoPosition?: string;
} & PropsWithClassName;

export const UIBrandBanner: Component<UIBrandBannerProps> = ({
    logo,
    redirectionURL,
    hasWhiteLogoBackground,
    logoPosition,
    className: containerClassName,
}) => {
    if (!redirectionURL || !logo) {
        return null;
    }

    return (
        <a
            className={clsx(
                'flex',
                logoPosition === 'left' ? 'justify-start' : logoPosition === 'right' ? 'justify-end' : 'justify-center',
                hasWhiteLogoBackground
                    ? 'h-brandBannerWhiteLogoBackground border-solid border-t-chanelBannerDesktopS desktopS:border-t-chanelBannerDesktop border-t-background-brand'
                    : 'h-brandBanner bg-background-brand',
                containerClassName,
            )}
            href={redirectionURL}
        >
            <GlowImage
                className={clsx('w-full flex justify-center', hasWhiteLogoBackground && 'h-logoChanel mt-1.25 mb-1.5')}
                image={logo}
                fetchPriority={'high'}
            />
        </a>
    );
};
