import { z } from 'zod';
import { ExtendedProductDTO } from '../../types';

export const SearchProductDTO = ExtendedProductDTO.extend({
    highlights: z
        .object({
            name: z.string(),
        })
        .optional(),
});

export type SearchProductDTO = z.infer<typeof SearchProductDTO>;

export const SearchProductsDTO = z.array(SearchProductDTO);

export type SearchProductsDTO = z.infer<typeof SearchProductsDTO>;
