export enum HeadersName {
    ApiKey = 'x-api-key',
    CsrfProtection = 'X-HEADLESS-CSRF-PROTECTION',
    ShopperToken = 'x-shopper-token',
    SetCookie = 'Set-Cookie',
    Host = 'Host',
    Origin = 'Origin',
    ForwardedHost = 'X-Forwarded-Host',
    ForwardedProto = 'X-Forwarded-Proto',
}

export type HeadersRecord = Partial<Record<HeadersName, string>>;
