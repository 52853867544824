'use client';

import { ChangeEvent } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { PropsWithFontFamily } from '../../props';
import { GlowTextBody } from '../typography';

export type Pill = {
    id: string;
    label: string;
};

export type GlowPillProps = Partial<PropsWithFontFamily> & {
    type: 'radio' | 'checkbox';
    content: Pill;
    checked: boolean;
    onChange: (id: string, checked: boolean) => void;
};

export const GlowPill: Component<GlowPillProps> = ({ type, content, checked, onChange, fontFamily = 'brand' }) => {
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(content.id, event.target.checked);
    };

    return (
        <div
            className={clsx(
                // CSS to create an “internal border” => prevents offset
                'inline-flex relative before:absolute',
                'pointer-events-none',
                'before:w-full before:h-full',
                'before:rounded-infinity before:border-border-selected',
                checked && 'before:border-bold',
                'has-[:focus-visible]:outline-thin has-[:focus-visible]:outline has-[:focus-visible]:rounded-infinity',
            )}
        >
            <label
                className={clsx(
                    'flex flex-row',
                    'px-1 py-0.75',
                    'pointer-events-auto',
                    'rounded-infinity border-solid border-medium border-border-primary',
                    checked ? 'bg-surface-primary' : 'bg-background-neutral',
                    'hover:bg-surface-neutral-hover active:bg-surface-neutral-pressed',
                )}
            >
                <input type={type} className={'sr-only'} checked={checked} onChange={onChangeHandler} />
                <GlowTextBody text={content.label} TagName={'span'} fontFamily={fontFamily} />
            </label>
        </div>
    );
};
