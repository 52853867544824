import React from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowTextBody, PropsWithClassName, PropsWithStyle } from '../../../';
import { GlowList } from './GlowList';

type GlowTextListProps = PropsWithClassName &
    PropsWithStyle & {
        texts: string[];
    };

export const GlowTextList: Component<GlowTextListProps> = ({ texts, style, className }) => {
    return (
        <GlowList
            items={texts}
            className={className}
            renderItem={(text) => <GlowTextBody text={text} className={'inline'} />}
        />
    );
};
