import { ConfigByKeyRepository, Result } from '@headless-workspace/core-domain';
import { VipBrandsDTO } from './VipBrandsDTO';
import { mapVipBrands, VipBrandsValue } from './VipBrandsValue';

export interface VipBrandsRepositorySpec {
    fetchVipBrands(locale: string): Promise<Result<VipBrandsValue>>;
}

const VIP_BRANDS_KEY = 'vipBrands';

export class VipBrandsRepository extends ConfigByKeyRepository implements VipBrandsRepositorySpec {
    async fetchVipBrands(locale: string): Promise<Result<VipBrandsValue>> {
        return await this.getConfigByKey(VIP_BRANDS_KEY, { locale }, (value) =>
            mapVipBrands(VipBrandsDTO.parse(value), locale),
        );
    }
}
