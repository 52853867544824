import { z } from 'zod';

export const LocalityDetailsDTO = z.object({
    result: z.object({
        geometry: z.object({
            location: z.object({
                lat: z.number(),
                lng: z.number(),
            }),
        }),
    }),
});

export type LocalityDetailsDTO = z.infer<typeof LocalityDetailsDTO>;
