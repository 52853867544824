import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { PropsWithClassName } from '../../props';
import styles from './rating.module.scss';

type GlowRatingStarProps = {
    fillRatio: number;
};

/**
 * A component that render a single star in a rating.
 * @param fillRatio - Represent the filling percentage of the star.
 */
export const GlowRatingStar: Component<GlowRatingStarProps> = ({ fillRatio }) => {
    return (
        <span
            className={clsx('font-medium', styles.star)}
            style={{ '--fillRatio': fillRatio } as React.CSSProperties}
        />
    );
};

type GlowRatingProps = PropsWithClassName & {
    rating: number;
};

/**
 * A component that renders a star rating.
 * @param rating - The rating to render.
 * @param className - Additional classes to apply.
 */
export const GlowRating: Component<GlowRatingProps> = ({ rating, className }) => {
    const arrayStars = Array.from(Array(5), (_, index) => {
        return Math.floor(rating) > index ? 1 : Math.floor(rating) === index ? rating % 1 : 0;
    });

    return (
        <div className={clsx('flex flex-row', className)}>
            {Array.from(arrayStars, (_, index) => {
                return <GlowRatingStar key={`star-${index}`} fillRatio={arrayStars[index]} />;
            })}
        </div>
    );
};
