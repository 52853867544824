import { z } from 'zod';

export type BrandDTO = z.infer<typeof BrandDTO>;
export const BrandDTO = z.object({
    brand: z.object({
        id: z.string().optional(),
        name: z.string(),
        url: z.string().nullish(),
        logoURL: z.string().nullish(),
    }),
});

export type PriceDTO = z.infer<typeof PriceDTO>;
export const PriceDTO = z.object({
    price: z.number().nullish(),
    valuePrice: z.number().nullish(),
    formerPrice: z.number().nullish(),
    discountPercentage: z.number().nullish(),
    originalPrice: z.number().nullish(),
    priorPrice: z.number().nullish(),
});

export type BaseProductDTO = z.infer<typeof BaseProductDTO>;
export const BaseProductDTO = z
    .object({
        id: z.string(),
        name: z.string(),
        imageUrl: z.string().optional(),
        description: z.string().optional(),
        url: z.string().optional(),
    })
    .merge(BrandDTO)
    .merge(PriceDTO);

export type ExtendedProductDTO = z.infer<typeof ExtendedProductDTO>;
export const ExtendedProductDTO = BaseProductDTO.extend({
    isEngravable: z.boolean(),
    currency: z.string(),
    minPrice: z.number().optional(),
});
