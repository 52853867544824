'use client';

import { MutableRefObject, useRef, useState } from 'react';

// Use this custom hook to keep a state logic oustide of the react context (for instance in an event listener)
export const useReferencedState = <T>(initialValue: T): [MutableRefObject<T>, (value: T) => void] => {
    const [state, setState] = useState<T>(initialValue);
    const stateRef = useRef<T>(state);

    const setReferencedState = (value: T) => {
        setState(value);
        stateRef.current = value;
    };

    return [stateRef, setReferencedState];
};
