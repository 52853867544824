export const specialCharsMap: { [key: string]: string } = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&apos;': "'",
    '&agrave;': 'à',
    '&Agrave;': 'À',
    '&eacute;': 'é',
    '&Eacute;': 'É',
    '&egrave;': 'è',
    '&Egrave;': 'È',
    '&ecirc;': 'ê',
    '&Ecirc;': 'Ê',
    '&ccedil;': 'ç',
    '&Ccedil;': 'Ç',
    '&iuml;': 'ï',
    '&Iuml;': 'Ï',
    '&ocirc;': 'ô',
    '&Ocirc;': 'Ô',
    '&ugrave;': 'ù',
    '&Ugrave;': 'Ù',
    '&ucirc;': 'û',
    '&Ucirc;': 'Û',
    '&ntilde;': 'ñ',
    '&Ntilde;': 'Ñ',
    '&auml;': 'ä',
    '&Auml;': 'Ä',
    '&ouml;': 'ö',
    '&Ouml;': 'Ö',
    '&uuml;': 'ü',
    '&Uuml;': 'Ü',

    // Greek letters
    '&Alpha;': 'Α',
    '&Beta;': 'Β',
    '&Gamma;': 'Γ',
    '&Delta;': 'Δ',
    '&Epsilon;': 'Ε',
    '&Zeta;': 'Ζ',
    '&Eta;': 'Η',
    '&Theta;': 'Θ',
    '&Iota;': 'Ι',
    '&Kappa;': 'Κ',
    '&Lambda;': 'Λ',
    '&Mu;': 'Μ',
    '&Nu;': 'Ν',
    '&Xi;': 'Ξ',
    '&Omicron;': 'Ο',
    '&Pi;': 'Π',
    '&Rho;': 'Ρ',
    '&Sigma;': 'Σ',
    '&Tau;': 'Τ',
    '&Upsilon;': 'Υ',
    '&Phi;': 'Φ',
    '&Chi;': 'Χ',
    '&Psi;': 'Ψ',
    '&Omega;': 'Ω',

    '&alpha;': 'α',
    '&beta;': 'β',
    '&gamma;': 'γ',
    '&delta;': 'δ',
    '&epsilon;': 'ε',
    '&zeta;': 'ζ',
    '&eta;': 'η',
    '&theta;': 'θ',
    '&iota;': 'ι',
    '&kappa;': 'κ',
    '&lambda;': 'λ',
    '&mu;': 'μ',
    '&nu;': 'ν',
    '&xi;': 'ξ',
    '&omicron;': 'ο',
    '&pi;': 'π',
    '&rho;': 'ρ',
    '&sigma;': 'σ',
    '&sigmaf;': 'ς',
    '&tau;': 'τ',
    '&upsilon;': 'υ',
    '&phi;': 'φ',
    '&chi;': 'χ',
    '&psi;': 'ψ',
    '&omega;': 'ω',

    // Common Greek symbols
    '&thetasym;': 'ϑ',
    '&upsih;': 'ϒ',
    '&piv;': 'ϖ',
    '&Gammad;': 'Ϝ',
    '&gammad;': 'ϝ',
    '&kappav;': 'ϰ',
    '&rhov;': 'ϱ',
    '&phiv;': 'ϕ',
    '&epsiv;': 'ϵ',
};
