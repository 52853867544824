import Image from 'next/image';
import clsx from 'clsx';
import { Component, Image as ImageType } from '@headless-workspace/typings';
import { GlowClickable, GlowImage, GlowTextCaption } from '../../../../src';

type GlowFastAccessListItemProps = {
    image: ImageType;
    href: string;
    label: string;
    subLabel?: string;
};

const FAST_ACCESS_WIDTH_IMAGE = 72;
const FAST_ACCESS_HEIGHT_IMAGE = 72;

export const GlowFastAccessListItem: Component<GlowFastAccessListItemProps> = ({ image, label, subLabel, href }) => {
    return (
        <li className={'list-none inline-block'}>
            <GlowClickable
                className={clsx('flex flex-col items-center justify-center', 'w-fastAccess', 'gap-0.5')}
                href={href}
                content={{
                    icon: (
                        <GlowImage
                            image={image}
                            height={FAST_ACCESS_WIDTH_IMAGE}
                            width={FAST_ACCESS_HEIGHT_IMAGE}
                            className={clsx(
                                'border-medium border-solid border-border-primary rounded-4.5 object-cover',
                                'overflow-hidden',
                            )}
                            TagName={Image}
                            loading={'lazy'}
                        />
                    ),
                    labelElement: subLabel ? (
                        <div className={'flex flex-col'}>
                            <GlowTextCaption text={label} className={'line-clamp-1 text-center'} />
                            <GlowTextCaption text={subLabel} className={'line-clamp-1 text-center'} />
                        </div>
                    ) : (
                        <GlowTextCaption text={label} className={'line-clamp-2 text-center'} />
                    ),
                }}
            />
        </li>
    );
};
