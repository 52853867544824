import React, { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import styles from './hoverBanner.module.css';

type UIHoverBannerProps = PropsWithChildren &
    PropsWithClassName & {
        hoverItem: ReactNode | ReactNode[];
        trailingRight?: boolean;
    };

export const UIHoverBanner: Component<UIHoverBannerProps> = ({ className, children, hoverItem, trailingRight }) => {
    return (
        <>
            <div className={clsx('h-full relative', 'group', styles.hoverContainer)}>
                <div className={clsx('h-full relative', !trailingRight && styles.hoverItem)}>{hoverItem}</div>
                <span
                    className={clsx(
                        'hidden desktop:flex desktop:invisible absolute top-full left-1/2 z-hoverBannerArrow',
                        'w-hoverBannerArrow h-hoverBannerArrow',
                        'bg-background-l1',
                        'desktop:group-hover:visible desktop:delay-moderate2',
                        styles.hoverBannerArrow,
                    )}
                />

                <div
                    className={clsx(
                        'flex justify-center items-center z-hoverBannerBackground',
                        trailingRight ? 'relative w-auto right-basketBanner' : 'fixed left-0 w-full',
                    )}
                >
                    <div
                        className={clsx(
                            'hidden desktop:flex desktop:invisible absolute top-0',
                            'px-2.5 py-2',
                            'shadow-light bg-background-l1',
                            'border-t-border-brand border-t-bold border-solid',
                            'desktop:group-hover:visible desktop:delay-moderate2',
                            className,
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
            <div
                className={clsx(
                    'hidden desktop:flex desktop:invisible fixed left-0 delay-moderate2',
                    'w-full',
                    'bg-background-overlay',
                    styles.hoverBannerBackground,
                )}
            />
        </>
    );
};
