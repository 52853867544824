import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableBannerCard, ComposableBannerCardProps, ComposableCard } from '../../composables';

/**
 * GlowMarketingBanner component.
 *
 * This component renders a banner card with a title, label, content, and a button.
 *
 * @param {ComposableBannerCardProps} props - The properties for the GlowMarketingBanner component.
 * @param {string} props.className - The className for the component
 * @param {string} props.title - The title of the banner.
 * @param {object} props.button - The button properties.
 */
export const GlowMarketingBanner: Component<ComposableBannerCardProps> = ({
    className,
    title,
    button,
}: ComposableBannerCardProps) => {
    return (
        <ComposableBannerCard.Container className={clsx('flex-1 my-auto', className)}>
            <ComposableBannerCard.MainBlock className={'p-1 gap-1'}>
                <ComposableCard.Wrapper className={'flex flex-col gap-0.5'}>
                    <ComposableBannerCard.Title
                        content={title}
                        fontSize={'body'}
                        fontWeight={'bold'}
                        className={'line-clamp-2'}
                        TagName={'h2'}
                        innerHTML
                    />
                </ComposableCard.Wrapper>
                <ComposableBannerCard.Button button={button} width={'fit'} />
            </ComposableBannerCard.MainBlock>
        </ComposableBannerCard.Container>
    );
};
