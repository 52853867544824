import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableBannerCard, ComposableBannerCardProps, ComposableCard } from '../../composables';

/**
 * GlowGWPBanner component.
 *
 * This component renders a banner card with a title, label, content, and a button.
 *
 * @param {ComposableBannerCardProps} props - The properties for the GlowGWPBanner component.
 * @param {string} props.title - The title of the banner.
 * @param {string} props.label - The label of the banner.
 * @param {string} props.content - The content of the banner.
 * @param {object} props.button - The button properties.
 */
export const GlowGWPBanner: Component<ComposableBannerCardProps> = ({
    className,
    title,
    label,
    content,
    accordion,
}: ComposableBannerCardProps) => {
    return (
        <ComposableBannerCard.Container className={clsx('flex-1', className)}>
            <ComposableBannerCard.MainBlock className={'p-1 gap-0'}>
                <ComposableCard.Wrapper className={'flex flex-col'}>
                    <ComposableBannerCard.Title
                        content={title}
                        fontSize={'body'}
                        fontWeight={'bold'}
                        TagName={'h2'}
                        innerHTML
                    />
                    <ComposableBannerCard.Content
                        label={label}
                        content={content}
                        fontSize={'bodySmall'}
                        TagName={'p'}
                        text
                    />
                </ComposableCard.Wrapper>
                <ComposableBannerCard.Accordion accordion={accordion} />
            </ComposableBannerCard.MainBlock>
        </ComposableBannerCard.Container>
    );
};
