import { Config } from 'tailwindcss';

export const discoverTailwindTheme = {
    height: {
        /** 56px + 72px = 128px */
        headerDesktop: '8rem',
        /** 72px */
        headerFixedMainDesktop: '4.5rem',
        /** 56px */
        headerFixedNavDesktop: '3.5rem',
        /** 56px */
        tablist: '3.5rem',
        /** 64px + 56px = 120px */
        headerTablet: '7.5rem',
        /** 64px */
        headerFixedTablet: '4rem',
        /** 56px */
        headerScrollableTablet: '3.5rem',
        /** 168px */
        headerMobile: '10.5rem',
        /** 48px */
        headerFixedMobile: '3rem',
        /** 48px */
        searchBar: '3rem',
        /** 16px */
        sephoraLogoSmall: '1rem',
        /** 32px */
        countrySwitcherFlag: '2rem',
        /** 56px */
        countrySelectorItem: '3.5rem',
        /** 12px */
        hoverBannerArrow: '0.75rem',
        /** 40px */
        appDownloadIcon: '2.5rem',
        /** 56px */
        badgeDescriptionImg: '3.5rem',
        /** 1px */
        divider: '0.06rem',
        /** 8px */
        progressBar: '0.5rem',
        /** 112px */
        brandoramaImg: '7rem',
        /** 67px */
        homeBannerLabel: '4.1875rem',
        /** 24px */
        carouselSlider: '1.5rem',
        /** 4px */
        carouselSliderBar: '0.25rem',
        /** 8px */
        carouselSliderDraggableBar: '0.5rem',
        /** 152px */
        productTileImgMobile: '9.5rem',
        /** 208px */
        productTileImg: '13rem',
        /** 48px */
        backToTop: '3rem',
        /** 208px */
        underBannerTablet: '13rem',
        /** 213px */
        brandCornerTablet: '13.3125rem',
        /** 210px */
        heightBrandoramaCard: '13.125rem',
        /** 112px */
        serviceTileImg: '7rem',
        /** 40px */
        productTileDetails: '2.5rem',
        /** 495px */
        beautyTipsMainImgMaxWidth: '30.9375rem',
        /** 80px */
        basketProductImg: '5rem',
        /** 80px */
        newsletterIcon: '5rem',
        /** 16px */
        iconChipWithDigit: '1rem',
        /** 1500px */
        originalProductVisualDesktop: '93.75rem',
        /** 48px */
        brandBanner: '3rem',
        /** 72px */
        brandBannerWhiteLogoBackground: '4.5rem',
        /** 8px */
        iconChipSmall: '0.5rem',
        /** 100vh - (56px (modal header) + 56px(tab list height) + 80px(close button height)) */
        productInfoModalContainer: 'calc(100dvh - (56px + 56px + 80px))',
        /** 100vh - (56px (modal header) + 164px(Selector Cart/list + Input) + 80px(close button height)) */
        StoresDeliveryInfoModalContainer: 'calc(100dvh - (56px + 204px + 80px))',
        /** 112px */
        ProductVideoPlayPauseIconTablet: '7rem',
        /** 64px */
        ProductVideoPlayPauseIcon: '4rem',
        /** 48px */
        fixedAddToCart: '3rem',
        /** 24px */
        fixedAddToCartBrandName: '1.5rem',
        /** 24px */
        logoChanel: '1.5rem',
    },
    maxHeight: {
        /** 48px */
        3: '3rem',
        /** 680px */
        headerPanel: '42.5rem',
        /** 48px */
        underBannerContent: '3rem',
        /** 8px */
        iconChipSmall: '0.5rem',
        /** 12px */
        iconChipMedium: '0.75rem',
        /** 128px */
        productDescription: '8rem',
    },
    minHeight: {
        /** 208px */
        newsletter: '13rem',
        /** 380px */
        newsletterMobile: '23.75rem',
        /** 8px */
        iconChipSmall: '0.5rem',
        /** 12px */
        iconChipMedium: '0.75rem',
    },
    width: {
        /** 375px */
        sideMenuMobile: '23.4375rem',
        /** 472px */
        sideMenuTablet: '29.5rem',
        /** 472px + 15px (scrollbar) = 487px */
        sideMenuWithScrollbarTablet: '30.4375rem',
        /** 343px */
        modalAddToCartMobile: '21.4375rem',
        /** 684px */
        modalAddToCartTablet: '42.75rem',
        /** 1440px */
        maxWidth: '90rem',
        /** 960px */
        storesBanner: '60rem',
        /** 448px **/
        basketBanner: '28rem',
        /** 32px */
        countrySwitcherFlag: '2rem',
        connectionBannerInfo: '28.125rem',
        /** 360px */
        buttonLarge: '22.5rem',
        /** 56px */
        badgeDescriptionImg: '3.5rem',
        /** 240px */
        hoverBannerArrow: '0.75rem',
        /** 343px */
        redirectionModalMobile: '21.438rem',
        /** 480px */
        redirectionModalTablet: '30rem',
        /** 1px */
        divider: '0.06rem',
        /** 318px */
        reinsurance: '19.875rem',
        /** 119px */
        sephoraLogoSmall: '7.4375rem',
        /** 178px */
        sephoraLogo: '11.125rem',
        /** 246px */
        menuAccordionItemContainer: '15.375rem',
        /** 260px */
        brandoramaCard: '16.25rem',
        /** 213px */
        brandoramaTitle: '13.313rem',
        /** 384px */
        mainBannerCard: '24rem',
        /** 485px */
        mainBannerCardDesktop: '30.3125rem',
        /** 83px */
        homeBannerLabel: '5.1875rem',
        /** 127px */
        openHoursDayLabel: '7.9375rem',
        /** 204px */
        productTileMobile: '12.75rem',
        /** 188px */
        productTileMobileWithoutPadding: '11.75rem',
        /** 240px */
        productTile: '15rem',
        /** 208px */
        productTileWithoutPadding: '13rem',
        /** 152px */
        productTileImgMobile: '9.5rem',
        /** 208px */
        productTileImg: '13rem',
        /** 150px */
        carouselSliderDraggableBar: '9.375rem',
        /** 48px */
        backToTop: '3rem',
        /** 80px */
        fastAccess: '5rem',
        /** 318px */
        reinsuranceListItem: '19.875rem',
        /** 342px */
        serviceTile: '21.375rem',
        /** 331px */
        underBannerImg: '20.6875rem',
        /** 457px */
        brandCornerImg: '28.5625rem',
        /** 269px */
        marketingBannerImg: '16.8125rem',
        /** 104px */
        gwpImg: '6.5rem',
        /** 260px */
        sitePreviewButton: '16.25rem',
        /** 343px */
        wishlistModalMobile: '21.4375rem',
        /** 480px */
        wishlistModal: '30rem',
        /** 80px */
        basketProductImg: '5rem',
        /** 112px */
        serviceTileImg: '7rem',
        /** 343px */
        newsletterModalMobile: '21.4375rem',
        /** 480px */
        newsletterModalDesktopS: '30rem',
        /** 80px */
        newsletterIcon: '5rem',
        /** 146px */
        storesAndServicesSkeleton: '9.125rem',
        /** 96px */
        accountSkeleton: '6rem',
        /** 16px */
        iconChipWithDigit: '1rem',
        /** 32px */
        animatedIconDotPdp: '2rem',
        /** 720px */
        productVisualCarouselTablet: '45rem',
        /** 343px */
        productVisualCarousel: '21.438rem',
        /** 640px */
        productVisualTablet: '40rem',
        /** 128px */
        productVisual: '16rem',
        /** 1500px */
        originalProductVisual: '93.75rem',
        /** 40px */
        productPreviewDesktopS: '2.5rem',
        /** 32px */
        productPreviewAnimatedDesktopS: '2rem',
        /** 48px */
        productPreviewDesktop: '3rem',
        /** 384px */
        productVisualDesktopS: '24rem',
        /** 714px */
        productVisualDesktop: '44.625rem',
        /** 8px */
        iconChipSmall: '0.5rem',
        /** 343px */
        productModal: '21.438rem',
        /** 736px */
        productModalTablet: '46rem',
        /** 640px */
        productModalDesktopS: '40rem',
        /** 528px */
        productModalImgDesktopS: '33rem',
        /** 832px */
        productModalDesktop: '52rem',
        /** 720px */
        productModalImgDesktop: '45rem',
        /** 48px */
        productModalThumbImg: '3rem',
        /** 40px */
        productModalAnimatedThumbImg: '2.5rem',
        /** 336px */
        productThumb: '21rem',
        /** 112px */
        ProductVideoPlayPauseIconTablet: '7rem',
        /** 64px */
        ProductVideoPlayPauseIcon: '4rem',
        /** 488px */
        fixedAddToCartDesktopS: '30.5rem',
        /** 680px */
        fixedAddToCartDesktop: '42.5rem',
        /** 40px */
        paletteItem: '2.5rem',
        /** 246px */
        reviewCounter: '15.375rem',
    },
    maxWidth: {
        /** 1440px */
        largestDesktop: '90rem',
        /** 343px */
        mainBannerFastAccessLinksTablet: '21.4375rem',
        /** 100% + 2rem (padding) */
        mainBannerFastAccessLinks: 'calc(100% + 2rem)',
        /** 212px */
        brandoramaBrandName: '13.25rem',
        /** 384px */
        beautyTipsCarouselDesktop: '24rem',
        /** 626 + 24 (padding) = 650px */
        beautyTipsCarouselMaxWidth: '40.625rem',
        /** 1156px */
        notFoundPageContent: '72.25rem',
        /** 194px */
        basketProductInformation: '12.125rem',
        /** 8px */
        iconChipSmall: '0.5rem',
        /** 12px */
        iconChipMedium: '0.75rem',
        /** 343px */
        themeContainer: '21.4375rem',
        /** 440px */
        toastContainer: '27.5rem',
        /** 100% - productVisualDesktop - gap - Thumbnail  */
        productInfosDesktop: 'calc(100% - 44.625rem - 1.5rem - 1rem - 3rem)',
        /** 100% - productVisualDesktopS - gap - Thumbnail  */
        productInfosDesktopS: 'calc(100% - 24rem - 1.5rem - 0.5rem - 3rem)',
    },
    minWidth: {
        /** 119px */
        sephoraLogoSmall: '7.4375rem',
        /** 178px */
        sephoraLogo: '11.125rem',
        /** 368 + 16 (padding) = 384px */
        beautyTipsCarouselDesktop: '24rem',
        /** 8px */
        iconChipSmall: '0.5rem',
        /** 12px */
        iconChipMedium: '0.75rem',
        /** 343px */
        dropDown: '21.438rem',
    },
    zIndex: {
        /**
         * Layer priority definitions for consistent z-index management:
         * - `hoverBannerBackground` must always be **less than** `headerFixed`.
         * - `hoverBannerArrow` must always be **greater than** `headerFixed`.
         * - `searchOpenOverlay` must always be **less than** `headerFixed`.
         * - `searchBanner` must always be **greater than** `headerFixed`.
         * - `SearchCloseAnimation` must always be **less than** `searchBanner`.
         */
        hoverBannerBackground: '2',
        hoverBannerArrow: '4',
        headerFixed: '3',
        searchOpenOverlay: '2',
        searchBanner: '5',
        searchCloseAnimation: '4',

        sideMenuBackground: '6',
        sideMenu: '7',
        modalBackground: '8',
        modal: '9',
        iframe: '10',
        productTileTag: '1',
        backToTop: '12',
        hoverBeforeBorder: '1',
        sitePreview: '10',
        dropDown: '2',
        addToBasketSticky: '3',
        paletteSelectorNavigationButton: '2',
    },
    aspectRatio: {
        mainBanner: '1440/446',
        mainBannerMobile: '385/580',
        mainBannerTablet: '768/320',
    },
    scale: {
        '0.8': '0.8',
        '1': '1',
    },
    extend: {
        gridTemplateColumns: {
            '1fr_auto': '1fr auto',
        },
        keyframes: {
            lightMove: {
                '0%': {
                    left: '-100%',
                    top: '-100%',
                },
                '100%': {
                    left: '100%',
                    top: '100%',
                },
            },
            socialProofAnimation: {
                '0%': {
                    left: '-100%',
                },
                '15.38%': {
                    left: '0',
                },
                '84.62%': {
                    left: '0',
                },
                '100%': {
                    left: '-100%',
                },
            },
            slideInRight: {
                '0%': { transform: 'translateX(100%)', opacity: '0' },
                '100%': { transform: 'translateX(0)', opacity: '1' },
            },
            slideOutRight: {
                '0%': { transform: 'translateX(0)', opacity: '1' },
                '100%': { transform: 'translateX(100%)', opacity: '0' },
            },
            slideInBottom: {
                '0%': { transform: 'translateY(100%)', opacity: '0' },
                '100%': { transform: 'translateY(0)', opacity: '1' },
            },
            slideOutBottom: {
                '0%': { transform: 'translateY(0)', opacity: '1' },
                '100%': { transform: 'translateY(100%)', opacity: '0' },
            },
        },
        animation: {
            lightMove: 'lightMove 2s infinite ease-out',
            socialProofAnimation: 'socialProofAnimation 2.6s 0.8s 1 ease-in-out forwards',
            'slide-in-right': 'slideInRight 0.5s ease-out',
            'slide-out-right': 'slideOutRight 0.5s ease-in',
            'slide-in-bottom': 'slideInBottom 0.5s ease-out',
            'slide-out-bottom': 'slideOutBottom 0.5s ease-in',
        },
        backgroundImage: {
            'light-gradient':
                'linear-gradient(90deg, var(--text-text-primary-inverse) 0%, var(--text-text-primary-inverse/80) 50%, var(--text-text-primary-inverse) 100%)',
        },
        transitionProperty: {
            backgroundOutline: 'background-color, outline',
        },
        spacing: ({ theme }) => ({
            /** 28px */
            chipPosition: '1.75rem',
            headerFixed: theme('height.headerFixedMobile'),
            headerFixedTablet: theme('height.headerFixedTablet'),
            headerDesktop: theme('height.headerDesktop'),
            /** 4.5px */
            paletteSelectedImg: '0.281rem',
        }),
        borderWidth: {
            /** 6px */
            chanelBannerDesktopS: '0.375rem',
            /** 8px */
            chanelBannerDesktop: '0.5rem',
        },
        margin: {
            /** 24px */
            productTabInfo: '1.5rem',
        },
    },
} satisfies Config['theme'];
