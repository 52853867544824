// FIXME: we use de-DE meta-data we didn't have de_CH meta-data yet

export const de_CH_NavRoutes = {
    home: 'de',
    connection: 'de/einloggen',
    storesAndServices: 'de/Storefinder',
    wishlist: 'de/wunschliste',
    basket: 'de/warenkorb',
    payment: '', // FIXME: To Update
    account: 'de/mein-konto',
    orders: 'de/meine-bestellungen',
    followOrder: 'de',
    loyalty: 'de/Sephora-Unlimited.html',
    offers: 'de',
    profile: 'de/meine-persoenlichen-informationen',
    mail: 'de',
    help: 'de/faq-sephora',
    allServices: 'de/sephora-store-services.html',
    storeInfo: 'de/store-information',
    allStores: 'de/Storefinder',
    countries: 'international/international_DE.html',
    beautyTips: 'de',
    logout: 'de/ausloggen?redirectToReferer=true',
    loyaltyProgram: 'de',
    allBrands: 'de/marken/alle-marken-von-a---z-brands',
    clickAndCollect: 'de/click-and-collect',
    securePayment: 'https://faq.sephora.de/s/article/Welche-Zahlungsm%C3%B6glichkeiten-gibt-es',
    returnProduct: 'https://faq.sephora.de/s/article/Wie-kann-ich-ein-Produkt-zur%C3%BCckgeben',
    privacyPolicy: 'de/datenschutzerklaerung/donnees-personnelles_DE.html',
    search: 'de/suche?q=',
    reviewConditions: '',
};
