import { ExtendMetadata, MetadataHttpsEquivValue, MetadataSchemaValue } from '@headless-workspace/core-domain';
import { Component } from '@headless-workspace/typings';

export const MetaDataSchema: Component<{ schemas: MetadataSchemaValue[] }> = ({ schemas }) => {
    return schemas.map((schema, index) => (
        <script
            type={'application/ld+json'}
            key={`meta-${index}`}
            id={`script-${index}`}
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(schema),
            }}
        />
    ));
};

export const MetaDataHttpEquiv: Component<{ httpsEquivs: MetadataHttpsEquivValue[] }> = ({ httpsEquivs }) => {
    return httpsEquivs.map((meta, index) => <meta key={`http-equiv-${index}`} {...meta} />);
};

export const MetaData: Component<ExtendMetadata> = ({ schemas, httpsEquivs }) => {
    return (
        <>
            <MetaDataSchema schemas={schemas} />
            <MetaDataHttpEquiv httpsEquivs={httpsEquivs} />
        </>
    );
};
