import React from 'react';
import { type OnLoadingComplete, PlaceholderValue } from 'next/dist/shared/lib/get-img-props';

export type ImageLoaderProps = {
    src: string;
    width: number;
    quality?: number;
};

export interface StaticImageData {
    src: string;
    height: number;
    width: number;
    blurDataURL?: string;
    blurWidth?: number;
    blurHeight?: number;
}

export interface StaticRequire {
    default: StaticImageData;
}

export type StaticImport = StaticRequire | StaticImageData;

export type NextImageType = React.ComponentType<{
    src: string | StaticImport;
    alt: string;
    width?: number | `${number}`;
    height?: number | `${number}`;
    sizes?: string;
    fill?: boolean;
    loader?: (p: ImageLoaderProps) => string;
    quality?: number | `${number}`;
    priority?: boolean;
    loading?: 'eager' | 'lazy';
    placeholder?: PlaceholderValue;
    blurDataURL?: string;
    unoptimized?: boolean;
    overrideSrc?: string;
    onLoadingComplete?: OnLoadingComplete;
    layout?: string;
    objectFit?: string;
    objectPosition?: string;
    lazyBoundary?: string;
    lazyRoot?: string;
    className?: string;
    style?: React.CSSProperties;
}>;
