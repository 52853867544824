'use client';

import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { GlowSkeleton, PropsWithClassName } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { FunctionHelpers } from '@headless-workspace/utils';
import { EmblaOptionsType } from 'embla-carousel';
import { CarouselNavigationButton } from './CarouselNavigationButton';
import { CarouselSlider } from './CarouselSlider';
import { useCarouselNavigation } from './useCarouselNavigation';
import { usePrevNextButtons } from './usePrevNextButtons';

const CAROUSEL_DEFAULT_OPTIONS: EmblaOptionsType = {
    align: 'start',
    // By default (mobile), carousel is drag free (no snap points)
    dragFree: true,
    slidesToScroll: 1,
    skipSnaps: true,
    breakpoints: {
        // for min-width tablet, enable snap points
        [`(min-width: ${tailwindTheme.screens.tablet})`]: { dragFree: false },
    },
};
const SLIDER_DEFAULT_VALUE = 0;
const SLIDER_MAX_VALUE = 100;
const SLIDER_STEP = 1;

type UICarouselProps<T> = PropsWithClassName & {
    items: T[];
    renderItem: (item: T, inView: boolean, index: number) => ReactNode | ReactNode[];
    options?: EmblaOptionsType;
    prevBtnAriaLabel: string;
    nextBtnAriaLabel: string;
    thumbArialLabel: string;
    navigationButtonClassName?: string;
    type?: 'beautyTips';
};

export const UICarousel = <T,>({
    items,
    renderItem,
    options,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    thumbArialLabel,
    navigationButtonClassName,
    type,
}: UICarouselProps<T>): ReactNode => {
    const { progressValue, slidesInProgressView, slidesInView, emblaRef, emblaApi, onSlideChange, onSlideEnd } =
        useCarouselNavigation(options ?? CAROUSEL_DEFAULT_OPTIONS);
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(
        emblaApi,
        'block',
        slidesInProgressView,
    );

    return (
        <>
            <CarouselNavigationButton
                type={'prev'}
                onClick={onPrevButtonClick}
                disabled={prevBtnDisabled}
                className={navigationButtonClassName}
                ariaLabel={prevBtnAriaLabel}
            />
            <section className={'flex flex-col gap-1 overflow-hidden'} aria-label={'carousel'}>
                <div
                    className={'w-full m-auto overflow-hidden relative'}
                    ref={emblaRef}
                    role={'group'}
                    aria-roledescription={'carousel'}
                >
                    <ul className={'flex flex-row gap-1 touch-pan-y touch-pinch-zoom'}>
                        {items.map((item, index) => {
                            const inView = slidesInView.indexOf(index) > -1;
                            return (
                                <li
                                    key={`carousel-item-${index}`}
                                    className={clsx(
                                        'first:ml-1 last:mr-1',
                                        type === 'beautyTips'
                                            ? 'first:desktopS:ml-0 last:desktopS:mr-0'
                                            : 'first:desktop:ml-0 last:desktop:mr-0',
                                    )}
                                >
                                    {renderItem(item, inView, index)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={'px-1 desktop:px-0'}>
                    <CarouselSlider
                        value={progressValue}
                        defaultValue={SLIDER_DEFAULT_VALUE}
                        max={SLIDER_MAX_VALUE}
                        step={SLIDER_STEP}
                        onSlideUpdate={onSlideChange}
                        onSlideCommit={onSlideEnd}
                        thumbArialLabel={thumbArialLabel}
                    />
                </div>
            </section>
            <CarouselNavigationButton
                type={'next'}
                onClick={onNextButtonClick}
                disabled={nextBtnDisabled}
                className={navigationButtonClassName}
                ariaLabel={nextBtnAriaLabel}
            />
        </>
    );
};

const UICarouselSkeleton = <T,>({
    items,
    renderItem,
    navigationButtonClassName,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    type,
}: UICarouselProps<T>): ReactNode => {
    return (
        <>
            <CarouselNavigationButton
                type={'prev'}
                onClick={FunctionHelpers.emptyFn}
                disabled={true}
                className={navigationButtonClassName}
                ariaLabel={prevBtnAriaLabel}
            />
            <section className={'flex flex-col gap-1 overflow-hidden'} aria-label={'carousel'}>
                <div
                    className={'w-full m-auto overflow-hidden relative'}
                    role={'group'}
                    aria-roledescription={'carousel'}
                >
                    <ul className={'flex flex-row gap-1 touch-pan-y touch-pinch-zoom'}>
                        {items.map((item, index) => {
                            return (
                                <li
                                    key={`carousel-item-${index}`}
                                    className={clsx(
                                        'first:ml-1 last:mr-1',
                                        type === 'beautyTips'
                                            ? 'first:desktopS:ml-0 last:desktopS:mr-0'
                                            : 'first:desktop:ml-0 last:desktop:mr-0',
                                    )}
                                >
                                    {renderItem(item, false, index)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={'px-1 desktop:px-0'}>
                    <GlowSkeleton
                        height={'0.25rem'}
                        containerClassName={'rounded-infinity h-carouselSlider justify-center'}
                    />
                </div>
            </section>
            <CarouselNavigationButton
                type={'next'}
                onClick={FunctionHelpers.emptyFn}
                disabled={true}
                className={navigationButtonClassName}
                ariaLabel={nextBtnAriaLabel}
            />
        </>
    );
};

let globalFrameCounter = 0;
const FRAME_DELAY = 30; // ~500ms

export const UICarouselContainer = <T,>({
    className,
    items,
    renderItem,
    options,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    thumbArialLabel,
    navigationButtonClassName,
}: UICarouselProps<T>): ReactNode => {
    const [shouldMount, setShouldMount] = useState(false);

    useEffect(() => {
        const startFrame = globalFrameCounter;
        globalFrameCounter += FRAME_DELAY; // Stagger each component by FRAME_DELAY frames

        let frameCount = 0;
        let animationId: number;

        const animate = () => {
            if (frameCount >= startFrame) {
                setShouldMount(true);
            } else {
                frameCount++;
                animationId = requestAnimationFrame(animate);
            }
        };

        animationId = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(animationId);
        };
    }, []);

    return (
        <div className={clsx('flex flex-row items-center gap-1 h-full', className)}>
            {!shouldMount ? (
                <UICarouselSkeleton
                    items={items}
                    renderItem={renderItem}
                    navigationButtonClassName={navigationButtonClassName}
                    prevBtnAriaLabel={prevBtnAriaLabel}
                    nextBtnAriaLabel={nextBtnAriaLabel}
                    thumbArialLabel={thumbArialLabel}
                />
            ) : (
                <UICarousel
                    className={className}
                    items={items}
                    renderItem={renderItem}
                    options={options}
                    prevBtnAriaLabel={prevBtnAriaLabel}
                    nextBtnAriaLabel={nextBtnAriaLabel}
                    thumbArialLabel={thumbArialLabel}
                    navigationButtonClassName={navigationButtonClassName}
                />
            )}
        </div>
    );
};
