'use client';

import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Component } from '@headless-workspace/typings';
import { DateHelpers } from '@headless-workspace/utils';
import { PopularSearchValue } from '../../../../server';
import { ClientDataContext, ClientDataContextValueType } from './ClientDataContext';
import { ClientEnvKey, ClientEnvKeysRecord } from './ClientEnvKeysRecord';
import { ClientFlagsRecord, ClientFlagsRecordKey } from './ClientFlagsRecord';

type ClientDataProviderProps = {
    clientEnvs: ClientEnvKeysRecord;
    clientFlags: ClientFlagsRecord;
    popularSearches: PopularSearchValue[];
} & PropsWithChildren;

export const ClientDataProvider: Component<ClientDataProviderProps> = ({
    clientEnvs,
    clientFlags,
    popularSearches,
    children,
}) => {
    const [isSessionInit, setIsSessionInit] = useState<boolean>(false);
    const [timeMachineDate, setTimeMachineDate] = useState<string>('');

    const updateTimeMachineDate = useCallback((value: string) => {
        if (DateHelpers.isISODate(value)) {
            setTimeMachineDate(value);
        }
    }, []);

    const getClientEnvVariable = useCallback(
        (clientEnvKey: ClientEnvKey): string => {
            return clientEnvs[clientEnvKey];
        },
        [clientEnvs],
    );

    const getClientFlags = useCallback(
        (flagKey: ClientFlagsRecordKey): boolean => {
            return clientFlags[flagKey] ?? false;
        },
        [clientFlags],
    );

    const providerValue: ClientDataContextValueType = useMemo(
        () => ({
            clientEnvs,
            getClientEnvVariable,
            getClientFlags,
            timeMachineDate,
            updateTimeMachineDate,
            isSessionInit,
            setSessionInit: setIsSessionInit,
            clientValues: {
                popularSearches,
            },
        }),
        [clientEnvs, getClientEnvVariable, getClientFlags, popularSearches, timeMachineDate, updateTimeMachineDate, isSessionInit],
    );

    return <ClientDataContext.Provider value={providerValue}>{children}</ClientDataContext.Provider>;
};
