import { z } from 'zod';

export type CountriesDTO = z.infer<typeof CountriesDTO>;

export const CountriesDTO = z.record(
    z.object({
        siteid: z.string().optional(),
        locales: z.union([
            z.record(
                z.object({
                    redirectUrl: z.string().optional(),
                    bubbleText: z.record(z.string()).optional(),
                }),
            ),
            z.array(z.string()),
        ]),
        countryLabel: z.string().optional(),
        defaultHostName: z.string().optional(),
        noRefresh: z.boolean().optional(),
    }),
);
