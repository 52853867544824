'use client';

import React, { PropsWithChildren, useCallback, useContext } from 'react';
import { BURGER_MENU_ID } from '@headless-workspace/config';
import { GlowClickable, GlowIcon, GlowTitle, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { UIContext } from '../../../../src';

type UIBurgerSubMenuShellProps = PropsWithChildren & {
    title: string;
    goBackAriaLabel: string;
};

export const UIBurgerSubMenuShell: Component<UIBurgerSubMenuShellProps> = ({ title, children, goBackAriaLabel }) => {
    const {
        menuModal: { goBack },
    } = useContext(UIContext);

    const handleGoBack = useCallback(() => {
        goBack(BURGER_MENU_ID);
    }, [goBack]);

    return (
        <div>
            <div className={'flex flex-row items-center justify-between p-1'}>
                <GlowClickable
                    ariaLabel={goBackAriaLabel}
                    onClick={handleGoBack}
                    className={'text-text-primary'}
                    content={{
                        icon: <GlowIcon Icon={Icons.ArrowLeft} type={'large'} />,
                    }}
                />
                <GlowTitle text={title} fontSize={'title4'} />
                <GlowClickable
                    ariaLabel={goBackAriaLabel}
                    onClick={handleGoBack}
                    className={'text-text-primary'}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                    }}
                />
            </div>
            <div className={'flex flex-col gap-0.25'}>{children}</div>
        </div>
    );
};
