'use client';

import { useEffect, useState } from 'react';
import { useReferencedState } from './useReferencedState';

type ScrollDirectionResults = 'up' | 'down' | undefined;
export type UseScrollDirectionResult = { scrollDirection: ScrollDirectionResults; scrollDifference: number };

export const useScrollDirection: () => UseScrollDirectionResult = () => {
    const [scrollDirection, setScrollDirection] = useState<ScrollDirectionResults>(undefined);
    const [scrollDifference, setScrollDifference] = useState<number>(0);
    const [lastScrollY, setLastScrollY] = useReferencedState<number>(0);

    useEffect(() => {
        const handleScrollDirection = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY.current) {
                setScrollDirection('down');
            } else if (currentScrollY < lastScrollY.current) {
                setScrollDirection('up');
            }

            setScrollDifference(currentScrollY - lastScrollY.current);
            setLastScrollY(currentScrollY);
        };
        window.addEventListener('scroll', handleScrollDirection);

        return () => {
            window.removeEventListener('scroll', handleScrollDirection);
        };
    }, [lastScrollY, setLastScrollY]);

    return { scrollDirection, scrollDifference };
};
