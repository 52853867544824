import { z } from 'zod';

export const LocalitiesAutocompleteDTO = z.object({
    localities: z.array(
        z.object({
            public_id: z.string(),
            description: z.string(),
        }),
    ),
});

export type LocalitiesAutocompleteDTO = z.infer<typeof LocalitiesAutocompleteDTO>;
