import clsx from 'clsx';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component, Image as ImageType } from '@headless-workspace/typings';
import { GlowTextBody } from '../../atoms';
import { PropsWithAriaLabel, PropsWithClassName } from '../../props';
import { CardBanner } from '../card';

export type Brandorama = {
    id?: string;
    brandName: string;
    image: ImageType;
    href: string;
};

type GlowBrandoramaBannerProps = PropsWithClassName & PropsWithAriaLabel & Brandorama;

/**
 * Component called Cards/Brand on FIGMA
 * A component that renders Brandorama component.
 * @param brandName - The name of Brandorama.
 * @param image - The url & alternative text of brandorama image.
 * @param href - The url of the brand page.
 * @param ariaLabel - The aria-label of the brandorama.
 * @param className - Additional styles for the container.
 */

export const GlowBrandoramaBanner: Component<GlowBrandoramaBannerProps> = ({
    brandName,
    image,
    href,
    ariaLabel,
    className,
}) => {
    return (
        <li className={clsx('list-none inline-flex', className)}>
            <CardBanner
                classNames={{
                    container: clsx(
                        'relative pointer-events-none before:absolute before:w-full before:h-full',
                        'hover:before:rounded-0.5 hover:before:border-bold hover:before:border-border-brand hover:before:z-hoverBeforeBorder',
                    ),
                    wrapper: clsx(
                        'flex flex-col',
                        'h-heightBrandoramaCard overflow-hidden',
                        'border-medium border-solid border-border-primary rounded-0.5 pointer-events-auto',
                    ),
                }}
                href={href}
                ariaLabel={ariaLabel}
                image={{
                    src: image,
                    classNames: {
                        wrapper: 'relative w-brandoramaCard aspect-16/9',
                        image: 'object-cover h-full',
                    },
                    sizes: tailwindTheme.width.brandoramaCard,
                    fill: true,
                    loading: 'lazy',
                }}
            >
                <div className={'flex flex-col items-center justify-center flex-1 my-0.5 px-1.5'}>
                    <GlowTextBody
                        className={clsx(
                            'items-center justify-center max-h-3',
                            'max-w-brandoramaBrandName line-clamp-2 text-center',
                        )}
                        text={brandName}
                        fontWeight={'bold'}
                    />
                </div>
            </CardBanner>
        </li>
    );
};
