import { BucketConfig, Locale } from '@headless-workspace/config';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { z } from 'zod';
import { Repository, Result, ResultFailure, ResultSuccess } from '../types';
import { fetchNetStorageData } from './NetStorageHelpers';
import { mapMetadataSEO, MetaDataSEOByKeyDTO, MetadataSEOValue } from './seo';

type SEOProps = {
    path: string;
};

export interface NetStorageRepositorySpec {
    getMetadataSEO({ path }: SEOProps): Promise<Result<MetadataSEOValue>>;

    getCountrySpecificities<T extends z.ZodTypeAny, K>(
        locale: Locale,
        fileName: string,
        type: T,
        mapper: (value: z.infer<T>) => K,
    ): Promise<Result<K>>;
}

export class NetStorageRepository extends Repository implements NetStorageRepositorySpec {
    path = '';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getMetadataSEO({ path }: SEOProps): Promise<Result<MetadataSEOValue>> {
        try {
            const data = await fetchNetStorageData({
                mainDirectoryPath: BucketConfig.directory.seo,
                fileName: path,
            });
            const value = MetaDataSEOByKeyDTO.parse(data);

            return ResultSuccess(mapMetadataSEO(value));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailure(apiError.code, apiError.message);
        }
    }

    async getCountrySpecificities<T extends z.ZodTypeAny, K>(
        locale: Locale,
        fileName: string,
        type: T,
        mapper: (value: z.infer<T>) => K,
    ): Promise<Result<K>> {
        try {
            const data = await fetchNetStorageData({
                mainDirectoryPath: BucketConfig.directory.countrySpecificities,
                subDirectory: locale,
                fileName,
            });
            const value = type.parse(data);

            return ResultSuccess(mapper(value));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailure(apiError.code, apiError.message);
        }
    }
}
