import React from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowSkeleton } from '../../atoms';

export const ProductTileImgSkeleton: Component = () => {
    return (
        <GlowSkeleton
            containerClassName={
                'mx-auto h-productTileImgMobile w-productTileImgMobile tablet:h-productTileImg tablet:w-productTileImg'
            }
        />
    );
};

export const ProductTileContentSkeleton: Component = () => {
    return (
        <>
            <GlowSkeleton width={'4rem'} height={'1.5rem'} />
            <GlowSkeleton width={'6.938rem'} height={'0.938rem'} />
            <GlowSkeleton width={'6.938rem'} height={'0.938rem'} />
        </>
    );
};
