import { z } from 'zod';

export const ShopperTokenDTO = z.object({
    token: z.object({
        accessToken: z.string(),
        refreshToken: z.string(),
        type: z.string(),
        customerId: z.string(),
        expiresIn: z.number(),
    }),
});

export type ShopperTokenDTO = z.infer<typeof ShopperTokenDTO>;
