import { DisplayDTO } from './DisplayDTO';

export enum DisplayDevice {
    Mobile = 'mobile',
    TabletDesktop = 'tablet-desktop',
    All = 'all-device',
}

export const mapDeviceDisplay = (display: DisplayDTO): DisplayDevice => {
    if (display.length === 1) {
        return display.find((item) => item === 'webmo') ? DisplayDevice.Mobile : DisplayDevice.TabletDesktop;
    }
    return DisplayDevice.All;
};
