import { Locale } from '@headless-workspace/config';

const getIntervalInSecondsFromNow = (timestamp: number): number => {
    const currentTime = new Date().getTime();
    return Math.trunc((timestamp - currentTime) / 1000);
};

const secondsInOneDay = 86400;
const secondsInOneHour = 3600;
const secondsInOneMinute = 60;
const isoStringLength = 19;

const extractsDateFromSeconds = (
    timeInSeconds: number,
): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
} => {
    const days = Math.floor(timeInSeconds / secondsInOneDay);
    const hours = Math.floor((timeInSeconds % secondsInOneDay) / secondsInOneHour);
    const minutes = Math.floor((timeInSeconds % secondsInOneHour) / secondsInOneMinute);
    const seconds = Math.floor(timeInSeconds % secondsInOneMinute);

    return { days, hours, minutes, seconds };
};

const isValidDate = (date: Date | string | number): boolean => {
    return !isNaN(new Date(date).getTime());
};

/**
 * Formats a custom date Date to ISO 8601 date format.
 * @param date - The custom date
 * @returns The formatted date string in ISO 8601 format (in format yyyy-MM-dd'T'HH:mm:ss'Z').
 */
const formatDateToISODateString = (date: Date): string => {
    return date.toISOString().slice(0, isoStringLength) + 'Z';
};

/**
 * Formats a custom date string to ISO 8601 date format.
 * @param dateStringCustom - The custom date string to be formatted (in the format "MM:DD:YYYY:hh:mm").
 * @returns The formatted date string in ISO 8601 format.
 */
const formatToISODate = (dateStringCustom: string): string => {
    const [month, day, year, hours, minutes] = dateStringCustom.split(':').map(Number);
    const date = new Date(year, month - 1, day, hours, minutes);
    return date.toISOString();
};

/**
 * Get the local timezone offset in hours.
 *
 * @returns The local timezone offset in hours.
 * It is positive if the local time zone is behind UTC, and negative if the local time zone is ahead of UTC
 */
const getLocalTimezoneOffsetInHour = (): number => {
    const date = new Date();
    return date.getTimezoneOffset() / 60;
};

/**
 * Converts an ISO date string to a timestamp, including the local timezone offset.
 *
 * @param ISODate - The ISO date string to convert.
 * @returns The timestamp in milliseconds or 0 if ISODate is null.
 */
const isoToTimestampWithTZ = (ISODate: string | null): number => {
    if (!ISODate) {
        return 0;
    }
    const date = new Date(ISODate);
    date.setHours(date.getHours() - getLocalTimezoneOffsetInHour());
    return date.getTime();
};

const isISODate = (isoDate: string): boolean => {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (!isoDateRegex.test(isoDate)) {
        return false;
    }

    const date = new Date(isoDate);
    return isValidDate(date) && date.toISOString() === isoDate;
};

const toISODate = (dateStr: string, timeStr: string): string => {
    return new Date(`${dateStr}T${timeStr}`).toISOString().split('.')[0] + 'Z';
};

const formatDateToDayMonth = (locale: Locale, date: Date): string => {
    return date.toLocaleDateString(locale, { day: 'numeric', month: 'long' });
};

const formatDateToShortDateString = (locale: Locale, date: Date): string => {
    return new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    }).format(date);
};

/**
 * Formats a date string from DD/MM/YYYY to YYYY-MM-DD.
 * @param date - The date string to be formatted (in the format "DD/MM/YYYY").
 * @return The formatted date string in the format "YYYY-MM-DD".
 */
const formatDateFromDMYToYMD = (date?: string): string | undefined => {
    if (!date) {
        return undefined;
    }

    const [day, month, year] = date.split('/');
    if (!day || !month || !year) {
        return undefined;
    }

    return `${year}-${month}-${day}`;
};

function formatDateTimeWithHourMinute(locale: Locale, at: string, date: Date) {
    const formatterDate = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).format(date);

    const formatterTime = new Intl.DateTimeFormat(locale, {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
    }).format(date);

    return `${formatterDate} ${at} ${formatterTime}`;
}

export const DateHelpers = {
    getIntervalInSecondsFromNow,
    extractsDateFromSeconds,
    isValidDate,
    formatToISODate,
    getLocalTimezoneOffsetInHour,
    isoToTimestampWithTZ,
    isISODate,
    toISODate,
    formatDateToDayMonth,
    formatDateToShortDateString,
    formatDateFromDMYToYMD,
    formatDateToISODateString,
    formatDateTimeWithHourMinute,
};
