import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableBannerCard, ComposableBannerCardProps, ComposableCard } from '../../composables';

/**
 * GlowUnderBanner component renders a banner with various customizable elements.
 *
 * @param {string} className - Additional class names for styling the container.
 * @param {React.ReactNode} titleElement - The main title element of the banner.
 * @param {React.ReactNode} subTitleElement - The subtitle element of the banner.
 * @param {React.ReactNode} labelElement - The label element within the banner content.
 * @param {React.ReactNode} contentElement - The main content element of the banner.
 * @param {React.ReactNode} legalMentionElement - The legal mention element displayed at the bottom of the banner.
 * @param {React.ReactNode} button - The button element to be displayed in the banner.
 */
export const GlowUnderBanner: Component<ComposableBannerCardProps> = ({
    className,
    title,
    subTitle,
    label,
    content,
    legalMention,
    button,
}: ComposableBannerCardProps) => {
    return (
        <ComposableBannerCard.Container className={clsx('flex-1', className)}>
            <ComposableBannerCard.MainBlock className={'p-1 pb-0.5 gap-0.5'}>
                <ComposableCard.Wrapper className={'flex flex-col gap-0.5'}>
                    <ComposableBannerCard.Title
                        content={title}
                        fontSize={'body'}
                        fontWeight={'bold'}
                        TagName={'h2'}
                        className={'line-clamp-2'}
                        innerHTML
                    />
                    <ComposableBannerCard.SubTitle content={subTitle} />
                </ComposableCard.Wrapper>
                <ComposableBannerCard.Content label={label} content={content} className={'line-clamp-2'} text />
                <ComposableBannerCard.Button button={button} />
                <ComposableBannerCard.MentionLabel>{legalMention}</ComposableBannerCard.MentionLabel>
            </ComposableBannerCard.MainBlock>
        </ComposableBannerCard.Container>
    );
};
