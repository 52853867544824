'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { GlowTextBodySmall, GlowTextCaption, PropsWithClassName, typographyStyle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { VariantProps } from 'class-variance-authority';

type PriceInformationProps = PropsWithClassName & {
    price: string;
    label: string;
    shouldDisplayVat?: boolean;
    fontWeight?: VariantProps<typeof typographyStyle>['fontWeight'];
};

export const PriceInformation: Component<PriceInformationProps> = ({
    label,
    price,
    shouldDisplayVat,
    className,
    fontWeight,
}) => {
    const t = useTranslations('Header.basket.banner');
    return (
        <div className={clsx('flex flex-row justify-between', className)}>
            <div className={'flex flex-col gap-0.25'}>
                <GlowTextBodySmall fontWeight={fontWeight} text={label} />
                {shouldDisplayVat && <GlowTextCaption color={'tertiary'} text={t('label.vat')} fontSize={'caption'} />}
            </div>

            <GlowTextBodySmall fontWeight={fontWeight} text={price} />
        </div>
    );
};
