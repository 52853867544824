'use client';

import { useState, useEffect } from 'react';

export const IS_SEARCH_OPEN = 'data-is-search-open';

export const useIsSearchOpen = (): boolean => {
    const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const checkIfSearchOpen = () => {
            if (document.body.getAttribute(IS_SEARCH_OPEN)) {
                setSearchIsOpen(true);
            } else {
                setSearchIsOpen(false);
            }
        };

        checkIfSearchOpen();

        const observer = new MutationObserver(() => {
            checkIfSearchOpen();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: [IS_SEARCH_OPEN] });

        return () => {
            observer.disconnect();
        };
    }, []);

    return searchIsOpen;
};
