import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { DecoderHelpers, PredicateHelpers } from '@headless-workspace/utils';
import { CategoriesContainerDTO, CategoryDTO } from './CategoriesDTO';
import { CategoryDeepLevel } from './CategoryDeepLevel';
import { CategoryValue, mapCategory } from './CategoryValue';

export interface CatalogCategoriesRepositorySpec {
    fetchAllCategories(locale: string, isPreviewable?: boolean): Promise<Result<CategoryValue[]>>;

    fetchCategoriesByLevel(locale: string, level: CategoryDeepLevel): Promise<Result<CategoryValue[]>>;
}

export class CatalogCategoriesRepository extends Repository implements CatalogCategoriesRepositorySpec {
    path = '/catalog/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async fetchAllCategories(locale: string, isPreviewable = false): Promise<Result<CategoryValue[]>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/categories`, {
                locale,
                level: CategoryDeepLevel.Ternary,
                isPreviewable,
            });
            const categoriesContainer = CategoriesContainerDTO.parse(response);
            const categories = DecoderHelpers.safeParseArray(categoriesContainer.categories, CategoryDTO);

            if (categories.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(categories.map(mapCategory).filter(PredicateHelpers.notNullOrUndefined));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async fetchCategoriesByLevel(locale: string, level: CategoryDeepLevel): Promise<Result<CategoryValue[]>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/categories`, {
                locale,
                level,
                isPreviewable: false,
            });
            const categoriesContainer = CategoriesContainerDTO.parse(response);
            const categories = DecoderHelpers.safeParseArray(categoriesContainer.categories, CategoryDTO);

            if (categories.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(categories.map(mapCategory).filter(PredicateHelpers.notNullOrUndefined));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
