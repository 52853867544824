'use client';

import { ReactNode, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { GlowIcon, GlowTextBody, GlowTextBodySmall } from '../../atoms';
import { IconComponentType, PropsWithTestId } from '../../props';
import { GlowClickable } from '../cta';

type Option<T> = {
    id: T;
    label?: string;
    Icon?: IconComponentType;
    altIcon?: string;
};
type SegmentedPickerProps<T> = PropsWithTestId & {
    options: Option<T>[];
    value: T;
    onChange: (value: T) => void;
    containerClassName?: string;
    childrenClassName?: string;
    size?: 'small' | 'medium';
};

export const GlowSegmentedPicker = <T,>({
    options,
    value,
    onChange,
    size = 'medium',
    containerClassName,
    childrenClassName,
    dataTestId,
}: SegmentedPickerProps<T>): ReactNode => {
    const getLabelElement = useCallback(
        (option: Option<T>) => {
            if (option.label) {
                return size === 'small' ? (
                    <GlowTextBodySmall text={option.label} TagName={'span'} />
                ) : (
                    <GlowTextBody text={option.label} TagName={'span'} />
                );
            }
            return null;
        },
        [size],
    );

    const renderedOptions = useMemo(() => {
        return options.map((option, index) => {
            const isSelected = option.id === value;
            return (
                <li className="flex-1" key={index}>
                    <GlowClickable
                        className={clsx(
                            'py-0.25 px-0.5 w-full justify-center rounded-0.25 gap-0.25',
                            childrenClassName,
                            isSelected ? 'bg-surface-primary' : 'hover:bg-surface-neutral-hover',
                        )}
                        onClick={() => onChange(option.id)}
                        dataTestId={`theme-picker-option-${option.id}`}
                        content={{
                            icon: option.Icon ? (
                                option.label ? (
                                    <GlowIcon Icon={option.Icon} type={size} />
                                ) : (
                                    <GlowIcon Icon={option.Icon} type={size} alt={option.altIcon} />
                                )
                            ) : null,
                            labelElement: getLabelElement(option),
                        }}
                    />
                </li>
            );
        });
    }, [options, value, onChange, size, childrenClassName, getLabelElement]);

    return (
        <ul
            className={clsx(
                'inline-flex justify-center w-full rounded-0.5 gap-0.25 p-0.25 bg-surface-neutral text-text-primary',
                containerClassName,
            )}
            data-testid={dataTestId}
        >
            {renderedOptions}
        </ul>
    );
};
