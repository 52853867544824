'use client';

import { createContext, useContext } from 'react';
import { FunctionHelpers } from '@headless-workspace/utils';

export type LayoutHeaderOffsetContextType = {
    headerOffset: number;
    setHeaderOffset: (offset: number) => void;
};

export type LayoutStateContextType = {
    header: {
        headerOffset: LayoutHeaderOffsetContextType['headerOffset'];
    };
};

export type LayoutActionContextType = {
    header: {
        setHeaderOffset: LayoutHeaderOffsetContextType['setHeaderOffset'];
    };
};

const layoutStateContextInitialState: LayoutStateContextType = {
    header: {
        headerOffset: 0,
    },
};

const layoutActionContextInitialState: LayoutActionContextType = {
    header: {
        setHeaderOffset: FunctionHelpers.emptyFn,
    },
};

// Context for UI elements that are outside the main tag,
// but visible in the viewport (for instance header, footer, ...)
export const LayoutStateContext = createContext<LayoutStateContextType>(layoutStateContextInitialState);
export const LayoutActionContext = createContext<LayoutActionContextType>(layoutActionContextInitialState);

export const useLayoutStateContext = () => useContext(LayoutStateContext);
export const useLayoutActionContext = () => useContext(LayoutActionContext);
