const DATA_TEST_IDS: Record<string, string> = {
    accountUserConnectionButton: 'account-user-connection-button',
    addToBasketButton: 'add-to-basket-button',
    addToBasketFixed: 'add-to-basket-fixed',
    backToHomepageLink: 'back-to-homepage-link',
    backToTopButton: 'back-to-top-button',
    connectionBannerLoginButton: 'connection-banner-login-button',
    closeSearchPanelButton: 'close-search-panel-button',
    headerWishlistLink: 'header-wishlist-link',
    newsletterMailInput: 'newsletter-mail-input',
    newsletterModal: 'newsletter-modal',
    newsletterSection: 'newsletter-section',
    newsletterSubscriptionButton: 'newsletter-subscription-button',
    newsletterSubscriptionConfirmButton: 'newsletter-subscription-confirm-button',
    newsletterSubscriptionConfirmMessage: 'newsletter-subscription-confirm-message',
    newsletterSubscriptionEditButton: 'newsletter-subscription-edit-button',
    recentSearchesClearButton: 'recent-searches-clear-button',
    recentSearchesContainer: 'recent-searches-container',
    recentSearchesTitle: 'recent-searches-title',
    searchBar: 'search-bar',
    searchProductsBanner: 'search-products-banner',
    searchShowAllProductsButton: 'search-show-all-products-button',
    wishlistButton: 'wishlist-button',
    wishlistLoginModal: 'wishlist-login-modal',
    wishlistLoginModalConnectionButton: 'wishlist-login-modal-connection-button',
    wishlistProductNumberIcon: 'wishlist-product-number-icon',
};

export const DataTestConfig = {
    testIds: DATA_TEST_IDS,
};
