'use client';

import { ReactNode } from 'react';
import clsx from 'clsx';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Node } from '@headless-workspace/typings';
import { EmblaOptionsType } from 'embla-carousel';
import { CarouselNavigationButton } from '../CarouselNavigationButton';
import { CarouselLogic } from './useCarouselLogic';

type UIFullScreenMobileCarouselProps<T> = PropsWithClassName &
    CarouselLogic & {
        items: T[];
        renderItem: (item: T, index: number) => Node;
        options?: EmblaOptionsType;
        prevBtnAriaLabel: string;
        nextBtnAriaLabel: string;
        thumbArialLabel: string;
        navigationButtonClassName?: string;
        showDot?: boolean;
        iconType?: 'large' | 'medium';
        variant?: 'primary' | 'secondary' | 'tertiary' | 'transparent';
    };

export const UIFullScreenMobileCarousel = <T,>({
    className,
    emblaRef,
    nextBtnDisabled,
    prevBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
    selectedIndex,
    items,
    renderItem,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    navigationButtonClassName,
    showDot = true,
    iconType = 'large',
    variant = 'primary',
}: UIFullScreenMobileCarouselProps<T>): ReactNode => {
    return (
        <section className={clsx('flex flex-row items-center', 'gap-0 mx-auto', className)} aria-label={'carousel'}>
            {!nextBtnDisabled && (
                <CarouselNavigationButton
                    type={'prev'}
                    onClick={onPrevButtonClick}
                    disabled={prevBtnDisabled}
                    className={navigationButtonClassName}
                    ariaLabel={prevBtnAriaLabel}
                    iconType={iconType}
                    variant={variant}
                />
            )}
            <div className={'flex flex-col gap-1 overflow-hidden'}>
                <div
                    className={'w-full m-auto overflow-hidden'}
                    ref={emblaRef}
                    role={'group'}
                    aria-roledescription={'carousel'}
                >
                    <ul className={clsx('flex flex-row gap-0.5', 'touch-pan-y touch-pinch-zoom')}>
                        {items.map((item, index) => {
                            return (
                                <li className={clsx(!nextBtnDisabled && 'last:mr-0.5')} key={`carousel-item-${index}`}>
                                    {renderItem(item, index)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {showDot && (
                    <div className={'flex justify-center gap-0.25 p-0.25'}>
                        {items.map((_, index) => (
                            <span
                                key={`dots-item-${index}`}
                                className={clsx(
                                    'w-iconChipSmall h-iconChipSmall bg-text-tertiary rounded-1',
                                    'ease-in-out transition-transform duration-moderate3',
                                    index === selectedIndex && '!w-animatedIconDotPdp',
                                )}
                            />
                        ))}
                    </div>
                )}
            </div>
            {!nextBtnDisabled && (
                <CarouselNavigationButton
                    type={'next'}
                    onClick={onNextButtonClick}
                    disabled={nextBtnDisabled}
                    className={navigationButtonClassName}
                    ariaLabel={nextBtnAriaLabel}
                    iconType={iconType}
                    variant={variant}
                />
            )}
        </section>
    );
};
