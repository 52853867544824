import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '../types';

const enum Routes {
    Ping = 'ping',
}

export interface CatalogPingRepositorySpec {
    ping(): Promise<Result<boolean>>;
}

export class CatalogPingRepository extends Repository implements CatalogPingRepositorySpec {
    readonly path = '/catalog/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async ping(): Promise<Result<boolean>> {
        try {
            await this.datasource.getResource(`${this.path}/${Routes.Ping}`);
            return ResultSuccess(true);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError);
        }
    }
}
