import { z } from 'zod';

export type VipBrandsDTO = z.infer<typeof VipBrandsDTO>;

export const VipBrandsDTO = z.record(
    z.string(),
    z.object({
        pdp: z
            .object({
                logobanner: z.boolean(),
            })
            .optional(),
        hub: z
            .object({
                logobanner: z.boolean(),
            })
            .optional(),
        universe: z
            .object({
                logobanner: z.boolean(),
            })
            .optional(),
        brandlisting: z
            .object({
                logobanner: z.boolean(),
            })
            .optional(),
        hideproductflag: z.boolean().optional(),
        hidepromotionflag: z.boolean().optional(),
        filteruppercase: z.boolean().optional(),
        enableEndOfDate: z.boolean().optional(),
        hidesocialsharing: z.boolean().optional(),
        brandLogoPosition: z.string().optional(),
        productAllBlackPrice: z.boolean().optional(),
        enablePromotionPercentage: z.boolean().optional(),
        enablePricePromoInfo: z.boolean().optional(),
        showVipFooterLogo: z
            .object({
                enable: z.boolean(),
                imageSrc: z.union([z.string(), z.record(z.string())]),
            })
            .optional(),
        whiteLogoChanel: z.boolean().optional(),
        squaredShade: z.boolean().optional(),
        hideFromSearch: z.boolean().optional(),
        productNameArialFont: z.boolean().optional(),
        blackAddToCartButton: z.boolean().optional(),
        carouselTitlesArial: z.boolean().optional(),
        hideSeoTitleOnCategoryPage: z.boolean().optional(),
        underlineBlackTab: z.boolean().optional(),
        hideReviewTab: z.boolean().optional(),
        isNotSocialProof: z.boolean().optional(),
    }),
);
