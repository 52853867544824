export enum UserRevalidationKey {
    User = '/user',
    Basket = '/baskets/summary',
    FavoriteStore = '/stores/favorite',
    Wishlist = '/wishlists/summary',
    NotifyMe = '/notify-me',
    NotifyMeDisabledForCountry = '/notify-me/disabled-for-country',
    Delivery = '/delivery',
    Holidays = '/holidays',
    ProductRecommendations = '/product-recommendations',
    RecommendedProducts = '/recommended-products',
    NewsletterSlot = '/content/slots/newsletter-content-global',
    UnusedKey = '/unused', // DO NOT USE THIS KEY FOR REVALIDATION
}
