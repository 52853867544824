import { envConfig } from '@headless-workspace/config';
import { FunctionHelpers } from '@headless-workspace/utils';
import { ApiErrorPayloadDecoder } from '../../dto';
import { AuthorizationStrategy, CacheStrategy, QueryParamStrategy, RefreshTokenStrategy } from '../../strategy';
import {
    ApiBehaviourDelegate,
    ApiError,
    ApiRequestOptions,
    ApiResponse,
    createApiErrorFromError,
    MAX_RETRY_ATTEMPTS,
    MAX_RETRY_ERROR_CODE,
    MAX_RETRY_ERROR_MESSAGE,
    RETRY_DELAYS,
    RETRY_STATUS,
} from '../api';
import { HeadersName, HeadersRecord } from '../config';
import { DEFAULT_HEADERS, DEFAULT_STATUS_CODE, HttpMethod } from '../http';

const ARRAY_PARAMS_SEPARATOR = '+';
const QUERY_PARAMS_START_SEPARATOR = '?';
const QUERY_PARAMS_DEFAULT_SEPARATOR = '&';

export class BffBehaviourDelegate implements ApiBehaviourDelegate {
    constructor(
        readonly authStrategy: Partial<AuthorizationStrategy>,
        readonly refreshTokenStrategy: Partial<RefreshTokenStrategy>,
        readonly cacheStrategy: CacheStrategy,
        readonly queryParamStrategy: Partial<QueryParamStrategy>,
    ) {}

    buildUrl(baseUrl: string, path: string, params?: Record<string, unknown> | undefined): string {
        if (!params) {
            return `${baseUrl}${path}`;
        }
        return Object.entries(params).reduce((acc, current, currentIndex) => {
            const [key, value] = current;
            const separator = currentIndex === 0 ? QUERY_PARAMS_START_SEPARATOR : QUERY_PARAMS_DEFAULT_SEPARATOR;

            if (key === 'isPreviewable') {
                if (value) {
                    const effectiveDate = this.queryParamStrategy.provideParams?.()['effectiveDate'];
                    return effectiveDate ? `${acc}${separator}effectiveDate=${effectiveDate}` : acc;
                } else {
                    return acc;
                }
            }
            if (typeof value === 'string') {
                return `${acc}${separator}${key}=${encodeURIComponent(value)}`;
            }
            if (Array.isArray(value)) {
                return `${acc}${separator}${key}=${value.join(ARRAY_PARAMS_SEPARATOR)}`;
            }

            return `${acc}${separator}${key}=${encodeURIComponent(JSON.stringify(value))}`;
        }, `${baseUrl}${path}`);
    }

    beforeCallResource(): HeadersRecord {
        const headers: HeadersRecord = {};

        const apiKeyHeader = this.authStrategy.provideAuthorizationHeaders?.()[HeadersName.ApiKey];
        const shopperTokenHeader = this.authStrategy.provideAuthorizationHeaders?.()[HeadersName.ShopperToken];

        if (apiKeyHeader) {
            headers[HeadersName.ApiKey] = apiKeyHeader;
        }
        if (shopperTokenHeader) {
            headers[HeadersName.ShopperToken] = shopperTokenHeader;
        }

        return headers;
    }

    async retryResource(
        fetchUrl: RequestInfo | URL,
        fetchOptions: RequestInit,
        retryStatus: number[],
        delayMs: number[],
        attempts: number,
        previousResponse?: Response,
    ): Promise<Response> {
        if (attempts === 0) {
            if (previousResponse) {
                return previousResponse;
            }
            throw new ApiError(
                DEFAULT_STATUS_CODE,
                {
                    errors: [{ errorCode: MAX_RETRY_ERROR_CODE, errorMessage: MAX_RETRY_ERROR_MESSAGE }],
                },
                fetchUrl.toString(),
            );
        }
        const result = await fetch(fetchUrl, fetchOptions);
        if (!result.ok && retryStatus.includes(result.status)) {
            const currentDelay = delayMs.pop();
            if (currentDelay) {
                await FunctionHelpers.delay(currentDelay);
            }
            if (this.refreshTokenStrategy.provideRefreshToken) {
                const token = await this.refreshTokenStrategy.provideRefreshToken();
                if (!token) {
                    return result;
                }
                return await this.retryResource(
                    fetchUrl,
                    {
                        ...fetchOptions,
                        headers: { ...fetchOptions.headers, [HeadersName.ShopperToken]: token.token.accessToken },
                    },
                    retryStatus,
                    delayMs,
                    attempts - 1,
                    result,
                );
            } else {
                return result;
            }
        }
        return result;
    }

    async callResource(url: string, method: HttpMethod, options: ApiRequestOptions = {}): Promise<ApiResponse> {
        const { body, cache, headers, tags } = options;

        const initOptions = body ? { body: JSON.stringify(body) } : {};

        let responseStatusCode: number | undefined;

        try {
            const fetchOptions = {
                credentials: this.authStrategy.provideCredentials?.()?.credentials,
                method,
                headers: {
                    ...DEFAULT_HEADERS,
                    ...this.beforeCallResource(),
                    ...headers,
                },
                signal: AbortSignal.timeout(envConfig.fetchTimeoutMs),
                cache: cache ?? this.cacheStrategy.cache,
                ...(tags && { next: { tags } }),
                ...initOptions,
            };

            const result = await this.retryResource(url, fetchOptions, RETRY_STATUS, RETRY_DELAYS, MAX_RETRY_ATTEMPTS);

            responseStatusCode = result.status;

            if (!result.ok) {
                const json = (await result.json()) as unknown;
                const parsedErrorPayload = ApiErrorPayloadDecoder.safeParse(json);
                throw new ApiError(
                    responseStatusCode,
                    parsedErrorPayload.success ? parsedErrorPayload.data : undefined,
                    url,
                );
            }

            if (result.status === 204) {
                return {
                    status: result.status,
                    data: {},
                };
            } else if (result.status === 201) {
                try {
                    const json = (await result.json()) as unknown;
                    return {
                        status: result.status,
                        data: json,
                    };
                } catch (error) {
                    return {
                        status: result.status,
                        data: {},
                    };
                }
            }
            const json = (await result.json()) as unknown;

            return {
                status: result.status,
                data: json,
            };
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            if (error instanceof Error) {
                throw createApiErrorFromError(error, responseStatusCode);
            }
            throw new ApiError(responseStatusCode ?? DEFAULT_STATUS_CODE);
        }
    }
}
