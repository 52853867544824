import { MetaDataSEOByKeyDTO } from './MetadataSeoDTO';

type MetadataOpenGraphValue = {
    title: string;
    description: string;
    url: string;
    siteName: string;
    type: string;
    images: {
        url: string;
    }[];
};

type MetadataTwitterValue = {
    card: string;
    title: string;
    description: string;
};

type MetadataVerificationValue = {
    google: string;
};

type MetadataAlternatesValue = {
    canonical: string;
    languages: Record<string, string>;
};

/**
 * Represents an array of objects that define the HTTP-Equiv meta tags for SEO.
 */
export type MetadataHttpsEquivValue = {
    httpEquiv: string;
    content: string;
};

/**
 * Represents an array of objects that define the schema type for SEO entities.
 */
export type MetadataSchemaValue = {
    '@context': string;
    '@type': string;
    name: string;
    alternateName?: string;
    legalName?: string;
    url: string;
    sameAs?: string[];
    potentialAction?: {
        '@type': string;
        target: string;
        'query-input': string;
    };
    logo?: string;
    contactPoint?: {
        '@type': string;
        contactType: string;
        telephone: string;
        email: string;
    };
};

type MetadataRobotsValue = {
    index: boolean;
    follow: boolean;
    nocache: boolean;
};

type MetadataAppLinksValue = {
    ios?: {
        url: string;
        app_store_id: string;
    };
    android?: {
        package: string;
        app_name: string;
    };
    web?: {
        url: string;
        should_fallback: boolean;
    };
};

/**
 * Represents an SEO entity with metadata, schemas, and httpsEquivs.
 */
export type MetadataSEOValue = {
    title: string;
    description: string;
    keywords: string;
    openGraph: MetadataOpenGraphValue;
    twitter: MetadataTwitterValue;
    verification: MetadataVerificationValue;
    alternates: MetadataAlternatesValue;
    httpsEquivs: MetadataHttpsEquivValue[];
    schemas: MetadataSchemaValue[];
    robots: MetadataRobotsValue;
    appLinks?: MetadataAppLinksValue;
};

export const mapMetadataSEO = (dto: MetaDataSEOByKeyDTO): MetadataSEOValue => {
    return {
        ...dto,
    };
};

/**
 * Represents the extended metadata for SEO values.
 */
export type ExtendMetadata = {
    schemas: MetadataSchemaValue[];
    httpsEquivs: MetadataHttpsEquivValue[];
};
