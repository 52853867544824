import { LocalitiesAutocompleteDTO } from './LocalitiesAutocompleteDTO';

export type LocalityValue = Readonly<{
    id: string;
    value: string;
}>;

export const mapLocalities = (localitiesDTO: LocalitiesAutocompleteDTO): LocalityValue[] => {
    return localitiesDTO.localities.map((localityDTO) => ({
        id: localityDTO.public_id,
        value: localityDTO.description,
    }));
};
