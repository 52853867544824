'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { getCountryFlagIcon, GlowClickable, GlowIcon, GlowTextBodySmall } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { UrlHelpers } from '@headless-workspace/utils';
import { CountryValue } from '../../../server';
import { CommonLocaleSwitcherButton, LocaleSwitcherButtonProps } from './LocaleSwitcherButton';

type LocaleSwitcherHeaderButtonProps = CommonLocaleSwitcherButton &
    Pick<LocaleSwitcherButtonProps, 'showLanguage'> & {
        onOpenMenu: () => void;
        hostUrl: string | null;
    };

export const getCountryIconByUrl = (countries: CountryValue[], href: string | null) => {
    if (!href) {
        return null;
    }

    const tld = UrlHelpers.getTLD(href);
    const country = countries.find((country) => country.tld === `.${tld}`);
    return country ? getCountryFlagIcon(country.code) : null;
};

export const LocaleSwitcherHeaderButton: Component<LocaleSwitcherHeaderButtonProps> = ({
    className,
    countries,
    showLanguage,
    onOpenMenu,
    hostUrl,
}) => {
    const icon = getCountryIconByUrl(countries, hostUrl);
    const t = useTranslations('Common.country');

    if (!icon) {
        return null;
    }

    return (
        <GlowClickable
            className={clsx('gap-0.5', className)}
            content={{
                icon: (
                    <GlowIcon
                        className={'h-countrySwitcherFlag w-countrySwitcherFlag rounded-infinity'}
                        Icon={icon}
                        type={'fullSize'}
                    />
                ),
                labelElement: showLanguage && <GlowTextBodySmall text={t('language')} TagName={'span'} />,
            }}
            onClick={onOpenMenu}
        />
    );
};
