'use client';

import { useTranslations } from 'next-intl';
import { buildRoute, DataTestConfig } from '@headless-workspace/config';
import {
    GlowClickable,
    GlowTextBodySmall,
    GlowTitle,
    PropsWithClassName,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useIsMounted } from 'usehooks-ts';
import { useRecentSearches } from './useRecentSearches';

export type RecentSearchesProps = PropsWithClassName & PropsWithLocale;

// FIXME: rename to HistorySearches and rename all related files
export const RecentSearches: Component<RecentSearchesProps> = ({ locale }) => {
    const t = useTranslations('Header.search.recent');
    const { recentSearches, clearRecentSearches } = useRecentSearches();
    const isMounted = useIsMounted();

    const onClearRecentSearches = () => {
        clearRecentSearches();
    };

    return (
        <section className={'flex flex-row items-start gap-0.5 flex-1'}>
            {isMounted() && recentSearches.length > 0 && (
                <div
                    className={
                        'flex flex-col flex-1 tablet:flex-initial items-baseline justify-between tablet:justify-normal gap-x-0.5'
                    }
                    data-testid={DataTestConfig.testIds.recentSearchesContainer}
                >
                    <div className={'flex flex-row gap-0.5'}>
                        <GlowTitle
                            text={t('title')}
                            fontSize={'title5'}
                            className={'py-0.5'}
                            dataTestId={DataTestConfig.testIds.recentSearchesTitle}
                        />
                        <GlowClickable
                            className={'py-0.5'}
                            id={'clear-search-button'}
                            onClick={onClearRecentSearches}
                            content={{
                                labelElement: (
                                    <GlowTextBodySmall
                                        text={t('action.clear')}
                                        textDecoration={'underline'}
                                        TagName={'span'}
                                    />
                                ),
                            }}
                            dataTestId={DataTestConfig.testIds.recentSearchesClearButton}
                        />
                    </div>
                    <ul className={'flex flex-col'}>
                        {recentSearches.map((search) => (
                            <li key={search.id} className={'py-0.5'}>
                                <GlowClickable
                                    href={`${buildRoute(locale, 'search')}${search.label}`}
                                    content={{
                                        labelElement: (
                                            <GlowTextBodySmall
                                                text={search.label}
                                                className={'break-all hover:text-underline'}
                                            />
                                        ),
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </section>
    );
};
