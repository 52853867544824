'use client';

import { useEffect, useState } from 'react';
import { PropsWithClassName, PropsWithStyle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import DOMPurify from 'isomorphic-dompurify';
import { HTMLString } from './../../../server';

export type InnerHTMLProps = PropsWithClassName &
    PropsWithStyle & {
        rawHtml: HTMLString;
    };

export const InnerHTML: Component<InnerHTMLProps> = ({ className, rawHtml, style }) => {
    const [content, setContent] = useState(rawHtml.content);

    useEffect(() => {
        setContent(rawHtml.content);
    }, [rawHtml.content]);

    return (
        <div className={className} style={style} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
    );
};
