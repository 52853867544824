import { z } from 'zod';

const CustomerLoyaltyDTOStatus = z.union([
    z.literal('PRE_ACHAT'),
    z.literal('WHITE'),
    z.literal('BLACK'),
    z.literal('GOLD'),
    z.literal('STANDARD'),
    z.literal('STAR'),
]);

export const CustomerDTO = z.object({
    customerId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    favoriteStoreId: z.string().optional(),
    isSubscribedByEmail: z.boolean().optional(),
    loyalty: z
        .object({
            currentPoints: z.number(),
            pointsToNextDiscount: z.number(),
            status: CustomerLoyaltyDTOStatus.optional(),
        })
        .optional(),
    gender: z.string().optional(),
    type: z.string().optional(),
    hasFavoriteAddress: z.boolean().optional(),
    hasFavoriteCreditCard: z.boolean().optional(),
    hasOffers: z.boolean().optional(),
    creationDate: z.string().optional(),
});

export type CustomerDTO = z.infer<typeof CustomerDTO>;
