import { PredicateHelpers, StringHelpers } from '@headless-workspace/utils';
import { FooterColumnsDTO } from './FooterSlotsDTO';

export type FooterLinksValue = Readonly<{
    id: string;
    title: string;
    links: {
        id: string;
        label: string;
        href: string;
    }[];
}>;

export const mapFooterLinks = (dto: FooterColumnsDTO): FooterLinksValue[] => {
    return dto.details.items
        .map((item, index) => {
            const firstElement = item.items.shift();
            if (!firstElement) {
                return null;
            }
            return {
                id: `links-${index}`,
                title: StringHelpers.decodeSpecialChars(firstElement.title),
                links: item.items.map((element, subIndex) => ({
                    id: `link-${index}-${subIndex}`,
                    label: StringHelpers.decodeSpecialChars(element.title),
                    href: element.link,
                })),
            };
        })
        .filter(PredicateHelpers.notNullOrUndefined);
};
