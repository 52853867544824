const PARSE_NUMBER_RADIX = 10;

const parseNumber = (value: string): number | undefined => {
    const parsedNumber = parseInt(value, PARSE_NUMBER_RADIX);
    return !isNaN(parsedNumber) ? parsedNumber : undefined;
};

export const NumberHelpers = {
    parseNumber,
};
