import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { PropsWithFontFamily } from '../../props';
import { GlowTextBody, GlowTextCaption } from '../typography';

export type GlowPriceProps = PropsWithFontFamily & {
    price?: string;
    pricePerUnit?: string;
    priceBeforeDiscount?: string;
    discountPercentageLabel?: string;
    priceAlwaysBlack?: boolean;
    enableDiscountInfo?: boolean;
    className?: string;
};

export const GlowPrice: Component<GlowPriceProps> = ({
    price,
    pricePerUnit,
    priceBeforeDiscount,
    discountPercentageLabel,
    priceAlwaysBlack,
    enableDiscountInfo,
    fontFamily = 'brand',
    className,
}) => {
    return (
        <div className={clsx('flex flex-col', className)}>
            <GlowTextBody
                fontWeight={'bold'}
                text={price}
                color={!priceAlwaysBlack && priceBeforeDiscount ? 'promotion' : 'primary'}
                fontFamily={fontFamily}
            />
            <GlowTextCaption
                color={'tertiary'}
                text={pricePerUnit}
                fontFamily={fontFamily}
                className={'max-w-basketProductInformation'}
            />
            {discountPercentageLabel && priceBeforeDiscount && (
                <div className={'flex flex-row gap-0.25'}>
                    <GlowTextCaption text={priceBeforeDiscount} fontFamily={fontFamily} />
                    {enableDiscountInfo && (
                        <GlowTextCaption
                            className={'px-0.25 bg-background-error-red rounded-0.25'}
                            color={'promotion'}
                            text={discountPercentageLabel}
                            fontFamily={fontFamily}
                            fontWeight={'bold'}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
