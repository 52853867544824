import React, { ReactNode } from 'react';
import { PropsWithClassName } from '../props';

export const addClassName = (node: ReactNode | ReactNode[], className: string): ReactNode | ReactNode[] => {
    if (typeof node === 'string' || typeof node === 'number' || typeof node === 'boolean') {
        return node;
    } else if (React.isValidElement(node)) {
        const element = node as React.ReactElement<PropsWithClassName>;
        return React.cloneElement(element, {
            // @ts-expect-error This node is a ReactElement, so it should have props
            className: `${node.props.className ?? ''} ${className}`,
        });
    } else if (Array.isArray(node)) {
        return node.map((item) => addClassName(item, className));
    } else {
        return node;
    }
};

export const extractContent = (element: ReactNode) => {
    if (React.isValidElement(element)) {
        // @ts-expect-error This node is a ReactElement, so it should have rawHtml, children or text
        return element.props?.rawHtml?.content || element.props?.children || element.props?.text || '';
    }
    return element;
};
