'use client';

import React from 'react';
import clsx from 'clsx';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import * as RadixUI from './RadixUISlider';

export type CarouselSliderProps = PropsWithClassName & {
    value: number;
    defaultValue: number;
    max: number;
    step: number;
    onSlideUpdate: (value: number) => void;
    onSlideCommit: (value: number) => void;
    thumbArialLabel: string;
};

export const CarouselSlider: Component<CarouselSliderProps> = ({
    className,
    value,
    defaultValue,
    max,
    step,
    thumbArialLabel,
    ...props
}) => {
    const onSlideChange = (value: number[]) => {
        props.onSlideUpdate(value[0]);
    };

    const onSlideCommit = (value: number[]) => {
        props.onSlideCommit(value[0]);
    };

    return (
        <RadixUI.Slider
            className={clsx(
                'relative',
                'flex items-center',
                'w-full h-carouselSlider',
                'touch-none select-none',
                className,
            )}
            defaultValue={[defaultValue]}
            value={[value]}
            max={max}
            step={step}
            onValueChange={onSlideChange}
            onValueCommit={onSlideCommit}
        >
            <RadixUI.Track
                className={clsx(
                    'relative',
                    'h-carouselSliderBar w-full rounded-infinity',
                    'bg-surface-neutral',
                    'overflow-hidden',
                )}
            />
            <RadixUI.Thumb
                className={clsx(
                    'block',
                    'h-carouselSliderDraggableBar w-carouselSliderDraggableBar rounded-infinity',
                    'bg-surface-brand hover:bg-surface-brand-hover active:bg-surface-brand-pressed',
                    'shadow cursor-pointer',
                )}
                aria-label={thumbArialLabel}
            />
        </RadixUI.Slider>
    );
};
