import { CsrfProtectionStrategy } from '..';
import { CSRF_PROTECTION_HEADER_VALUE, HeadersName } from '../../datasource';

export const apiRouteCsrfProtectionStrategy: CsrfProtectionStrategy = {
    validateOrigin(request: Request) {
        const hostHeaderValue = request.headers.get(HeadersName.Host) ?? request.headers.get(HeadersName.ForwardedHost);
        const originHeaderValue = request.headers.get(HeadersName.Origin);

        const hostOrigin = originHeaderValue ? new URL(originHeaderValue).host : undefined;

        return hostHeaderValue === hostOrigin;
    },
    validateHeaders(request: Request) {
        const csrfProtectionHeaderValue = request.headers.get(HeadersName.CsrfProtection);

        return csrfProtectionHeaderValue === CSRF_PROTECTION_HEADER_VALUE;
    },
};
