'use client';

import { MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { DataTestConfig } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';
import { GlowButton, GlowClickable, GlowIcon, GlowIconSkeleton, Icons, PropsWithAriaLabel } from '../../../../src';

type GlowWishlistIconProps = PropsWithAriaLabel & {
    onClick: () => void;
    isInWishlist: boolean;
    type?: 'button' | 'clickableIcon';
};

const TIMER = 1500;

export const GlowWishlistButton: Component<GlowWishlistIconProps> = ({
    ariaLabel,
    onClick,
    isInWishlist,
    type = 'clickableIcon',
}) => {
    const [isUpdating, setIsUpdating] = useState(false);

    const onClickEvent = (event: MouseEvent) => {
        event.preventDefault();
        setIsUpdating(true);
        onClick();

        setTimeout(() => {
            setIsUpdating(false);
        }, TIMER);
    };

    if (type === 'button') {
        return (
            <GlowButton
                className={isInWishlist ? 'fill-text-primary' : 'fill-surface-primary'}
                variant={'secondary'}
                dataTestId={DataTestConfig.testIds.wishlistButton}
                onClick={onClickEvent}
                Icon={Icons.Heart}
                SkeletonIcon={<GlowIconSkeleton Icon={Icons.HeartAnimation} type={'medium'} />}
                isLoading={isUpdating}
                disabled={isUpdating}
            />
        );
    }

    return (
        <GlowClickable
            dataTestId={DataTestConfig.testIds.wishlistButton}
            className={'h-fit text-text-primary'}
            ariaLabel={ariaLabel}
            onClick={onClickEvent}
            disabled={isUpdating}
            content={{
                icon: isUpdating ? (
                    <GlowIconSkeleton Icon={Icons.HeartAnimation} />
                ) : (
                    <GlowIcon
                        Icon={Icons.Heart}
                        type={'large'}
                        fill={isInWishlist ? 'fill-text-primary text-text-primary' : 'none'}
                        className={clsx(
                            isInWishlist ? 'hover:fill-surface-brand-hover' : 'hover:fill-surface-primary-hover',
                        )}
                    />
                ),
            }}
        />
    );
};
