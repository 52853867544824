import { z } from 'zod';
import { DynamicBaseDTO } from './DynamicBaseDTO';
import { DynamicIconDTO } from './DynamicIconDTO';
import { DynamicLabelDTO } from './DynamicLabelDTO';
import { DynamicUrlDTO } from './DynamicUrlDTO';
import { HeaderConnectionBannerItemDTO } from './HeaderConnectionBannerItemDTO';

const HeaderSlotIdDTO = z.union([
    z.literal('storesItem'),
    z.literal('myBeautyProgramItem'),
    z.literal('myAccountItem'),
]);

const HeaderSlotDTO = z.object({
    id: HeaderSlotIdDTO,
    iconName: z.string(),
    label: z.string(),
    url: z.string(),
    dynamicLabel: z.array(DynamicLabelDTO).optional(),
    displayRules: z.string().optional(),
    labelDisplayRules: z.string().optional(),
    extraLabel: DynamicLabelDTO.optional(),
    defaultOverlayIconName: z.string().optional(),
    dynamicOverlayIcons: z.array(DynamicIconDTO).optional(),
    dynamicUrl: z.array(DynamicUrlDTO).optional(),
});

export type HeaderSlotDTO = z.infer<typeof HeaderSlotDTO>;

export const HeaderSlotsDTO = z.object({
    items: z.array(HeaderSlotDTO).default([]),
    connectionBannerItems: z.array(HeaderConnectionBannerItemDTO).default([]),
    storeBannerFavoriteStoreItem: z.array(z.string()).default([]),
    dynamicOfferRules: DynamicBaseDTO.optional(),
});

export type HeaderSlotsDTO = z.infer<typeof HeaderSlotsDTO>;
