import clsx from 'clsx';
import {
    GlowClickable,
    GlowIcon,
    GlowIconSkeleton,
    GlowIconWithChip,
    GlowSkeleton,
    GlowTextBodySmall,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithIcon,
    PropsWithSkeleton,
    PropsWithTestId,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type UIHoverBannerButtonProps = PropsWithAriaLabel &
    PropsWithClassName &
    PropsWithIcon &
    PropsWithSkeleton &
    PropsWithTestId &
    (
        | {
              isLoading: false;
              label?: string;
              href: string;
              disabled?: boolean;
              labelClassName?: string;
              hasChip?: boolean;
          }
        | {
              isLoading: true;
          }
    );

export const UIHoverBannerButton: Component<UIHoverBannerButtonProps> = ({
    className,
    ariaLabel,
    Icon,
    skeleton,
    ...props
}) => {
    if (props.isLoading) {
        return (
            <div className={clsx('flex flex-row items-center', 'p-0.5', className)}>
                <GlowIconSkeleton Icon={Icon} />
                <GlowSkeleton
                    height={skeleton?.height}
                    width={skeleton?.width}
                    containerClassName={clsx('pl-0.25', skeleton?.containerClassName)}
                    itemClassName={skeleton?.itemClassName}
                />
            </div>
        );
    }

    return (
        <GlowClickable
            className={clsx('p-0.5 gap-0.25', 'fill-text-primary text-text-primary', className)}
            ariaLabel={ariaLabel}
            href={props.href}
            disabled={props.disabled}
            content={{
                labelElement: props.label && (
                    <GlowTextBodySmall text={props.label} className={clsx('whitespace-nowrap', props.labelClassName)} />
                ),
                icon: props.hasChip ? (
                    <GlowIconWithChip Icon={Icon} type={'large'} hasDigit={false} />
                ) : (
                    <GlowIcon Icon={Icon} type={'large'} />
                ),
            }}
            dataTestId={props.dataTestId}
        />
    );
};
