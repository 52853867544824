import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowChip, GlowChipProps, GlowIcon, GlowIconProps } from '../../atoms';

type GlowIconWithChipProps = GlowIconProps &
    GlowChipProps & {
        iconClassName?: string;
        iconAlt?: string;
        chipAriaLabel?: string;
        chipClassName?: string;
    };

export const GlowIconWithChip: Component<GlowIconWithChipProps> = ({
    Icon,
    type = 'large',
    fill,
    height,
    width,
    chipAriaLabel,
    chipClassName,
    iconClassName,
    iconAlt,
    ...props
}) => {
    const chipPositionClass = props.hasDigit ? 'left-1.25 -top-0.25' : 'left-1.5';

    return (
        <div className={'relative'} data-testid={'iconChipId'}>
            <div className={clsx('absolute cursor-pointer', chipPositionClass)}>
                <GlowChip ariaLabel={chipAriaLabel} className={chipClassName} {...props} />
            </div>
            <GlowIcon
                Icon={Icon}
                type={type}
                fill={fill}
                height={height}
                width={width}
                className={iconClassName}
                alt={iconAlt}
            />
        </div>
    );
};
