'use client';

import React, { useContext } from 'react';
import clsx from 'clsx';
import { BURGER_MENU_ID } from '@headless-workspace/config';
import { UIContext } from '@headless-workspace/core-ui';
import { GlowClickable, GlowIcon, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const BurgerMenuHeader: Component = () => {
    const {
        menuModal: { close },
    } = useContext(UIContext);

    const onCrossClick = () => {
        close(BURGER_MENU_ID);
    };

    return (
        <div className={clsx('flex flex-row justify-between items-center p-1')}>
            <GlowIcon Icon={Icons.LogoSephora} className={'h-sephoraLogoSmall text-text-primary'} type={'fullSize'} />
            <GlowClickable
                onClick={onCrossClick}
                className={'text-text-primary'}
                content={{
                    icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                }}
            />
        </div>
    );
};
