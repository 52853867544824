'use client';

import { useContext } from 'react';
import { useTranslations } from 'next-intl';
import { ThemeColor } from '@headless-workspace/config';
import { GlowSegmentedPicker, GlowTextBody, IconComponentType, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ThemeContext, ThemeMode } from '../../providers';

export const UIThemeSwitcher: Component = () => {
    const t = useTranslations('Common.theme');
    const { themeMode, setTheme } = useContext(ThemeContext);

    const viewOptions: { id: ThemeMode; label: string; Icon: IconComponentType }[] = [
        { id: ThemeColor.Light, label: t('label.light'), Icon: Icons.Sun },
        { id: ThemeColor.Dark, label: t('label.dark'), Icon: Icons.Moon },
        { id: 'system', label: t('label.system'), Icon: Icons.System },
    ];
    return (
        <section className={'flex flex-col flex-start gap-0.5'}>
            <GlowTextBody fontWeight={'bold'} text={t('title')} color={'primary-inverse-fixed'} TagName={'h3'} />
            <GlowSegmentedPicker
                options={viewOptions}
                value={themeMode}
                onChange={setTheme}
                size={'small'}
                dataTestId={'theme-picker'}
                containerClassName={'max-w-themeContainer'}
            />
        </section>
    );
};
