import { z } from 'zod';

export const ProductDecoder = z.object({
    id: z.string(),
    title: z.string(),
    description: z.string(),
    price: z.object({
        amount: z.number(),
        currencyCode: z.string(),
    }),
});

export type ProductDto = z.infer<typeof ProductDecoder>;
