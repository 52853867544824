import { ShopperTokenDTO } from '@headless-workspace/data';

export type ShopperTokenEntity = {
    token: {
        accessToken: string;
        refreshToken: string;
        type: string;
        customerId: string;
        expiresIn: number;
    };
};

export const mapShopperTokenEntity = (dto: ShopperTokenDTO): ShopperTokenEntity => {
    return {
        token: {
            accessToken: dto.token.accessToken,
            refreshToken: dto.token.refreshToken,
            type: dto.token.type,
            customerId: dto.token.customerId,
            expiresIn: dto.token.expiresIn,
        },
    };
};
