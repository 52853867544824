export enum DataLayerEvent {
    Click = 'click',
}

export enum DataLayerEventCategory {
    Search = 'search',
    Banners = 'banners',
    Navigation = 'navigation',
    Newsletter = 'newsletter',
    Wishlist = 'Wishlist',
    InternalMarketing = 'Internal Marketing',
    Animation = 'animation',
    ClientAccount = 'client_account',
    languageSwitcher = 'language_switcher',
    countrySwitcher = 'country_switcher',
}

export enum DataLayerEventLabel {
    Null = 'null',
    Confirm = 'confirmation',
    Init = 'initiate',
}

export enum DataLayerCustomerCategory {
    New = 'new client',
    Repeat = 'repeat client',
    NotLogged = '',
}

export enum DataLayerCustomerLoyaltyCategory {
    UnactiveBuyer = 'unactive_buyer',
    UnactiveNonBuyer = 'unactive_non_buyer',
    White = 'WHITE',
    Black = 'BLACK',
    Gold = 'GOLD',
}

export enum DataLayerIsConnected {
    Authenticated = 'authentified',
    Anonymous = 'anonymous',
}

export enum DataLayerYesNo {
    Yes = 'y',
    No = 'n',
}
