import {
    defaultServerAuthStrategy,
    defaultServerQueryParamStrategy,
    defaultServerRefreshTokenStrategy,
    ShopperTokenDTO,
} from '@headless-workspace/data';
import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '../types';
import { mapShopperTokenEntity, ShopperTokenEntity } from './ShopperTokenEntity';
import { TokenType } from './TokenType';

export interface ShopperTokenRepositorySpec {
    getRefreshedToken(refreshToken: string, locale: string): Promise<Result<ShopperTokenEntity>>;

    getToken(locale: string): Promise<Result<ShopperTokenEntity>>;

    getTokenFromSessionId(locale: string, sessionId: string, email?: string): Promise<Result<ShopperTokenEntity>>;

    revokeToken(locale: string, refreshToken: string): Promise<Result<boolean>>;
}

export class ShopperTokenRepository extends Repository implements ShopperTokenRepositorySpec {
    path = '/login/v1';

    constructor() {
        super(defaultServerAuthStrategy, defaultServerRefreshTokenStrategy, defaultServerQueryParamStrategy);
    }

    async getRefreshedToken(refreshToken: string, locale: string): Promise<Result<ShopperTokenEntity>> {
        try {
            const response = await this.datasource.createResource(
                `${this.path}/token`,
                { refreshToken },
                {
                    locale,
                    type: TokenType.Refresh,
                },
            );
            const refreshTokenResult = mapShopperTokenEntity(ShopperTokenDTO.parse(response));
            return ResultSuccess(refreshTokenResult);
        } catch (error) {
            const apiError = this.handleApiError(error);
            if (apiError.status === 404) {
                return await this.getToken(locale);
            } else {
                return ResultFailureFromApiError(apiError, { locale });
            }
        }
    }

    async getToken(locale: string): Promise<Result<ShopperTokenEntity>> {
        try {
            const response = await this.datasource.createResource(
                `${this.path}/token`,
                {},
                {
                    locale,
                    type: TokenType.Guest,
                },
            );
            const tokenResult = mapShopperTokenEntity(ShopperTokenDTO.parse(response));
            return ResultSuccess(tokenResult);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async getTokenFromSessionId(
        locale: string,
        sessionId: string,
        email?: string,
    ): Promise<Result<ShopperTokenEntity>> {
        try {
            const response = await this.datasource.createResource(
                `${this.path}/token`,
                { sessionId, email },
                {
                    locale,
                    type: TokenType.SessionId,
                },
            );
            const tokenResult = mapShopperTokenEntity(ShopperTokenDTO.parse(response));
            return ResultSuccess(tokenResult);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async revokeToken(locale: string, refreshToken: string): Promise<Result<boolean>> {
        try {
            const response = await this.datasource.createResource(
                `${this.path}/token/revoke`,
                { refreshToken },
                {
                    locale,
                },
            );
            mapShopperTokenEntity(ShopperTokenDTO.parse(response));
            return ResultSuccess(true);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
