import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowClickable, GlowIcon, GlowTextBody, Icons, PropsWithFontFamily } from '../../../../src';

export type GlowProductRatingProps = PropsWithFontFamily & {
    rating: number;
    reviewCount: number;
    ariaLabel: string;
    reviewsText: string;
    noReviewsText: string;
    onClick: () => void;
};

const MAX_RATING = 5;

/**
 * @param props - Contains the following:
 * @param props.rating - The rating value to display.
 * @param props.reviewCount - The number of reviews.
 * @param props.ariaLabel - The aria label for the rating.
 * @param props.reviewsText - The text to display for reviews.
 * @param props.noReviewsText - The text to display when there are no reviews.
 * @param props.onClick - The function to call when the rating is clicked.
 */
export const GlowProductRating: Component<GlowProductRatingProps> = ({
    rating,
    reviewCount,
    ariaLabel,
    reviewsText,
    noReviewsText,
    fontFamily,
    onClick,
}) => {
    if (reviewCount <= 0) {
        return <GlowTextBody fontFamily={fontFamily} fontWeight={'medium'} text={noReviewsText} />;
    }

    return (
        <GlowClickable
            className={clsx('flex', 'gap-0.5')}
            onClick={onClick}
            ariaLabel={ariaLabel}
            content={{
                labelElement: (
                    <>
                        <span className={'flex'}>
                            <GlowIcon Icon={Icons.Star} type={'medium'} />
                            <GlowTextBody fontFamily={fontFamily} fontWeight={'bold'} text={rating} TagName={'span'} />
                            <GlowTextBody
                                fontFamily={fontFamily}
                                fontWeight={'medium'}
                                text={`/${MAX_RATING}`}
                                TagName={'span'}
                            />
                        </span>

                        <GlowTextBody
                            fontWeight={'medium'}
                            fontFamily={fontFamily}
                            textDecoration={'underline'}
                            text={`(${reviewCount} ${reviewsText})`}
                            TagName={'span'}
                        />
                    </>
                ),
            }}
        />
    );
};
