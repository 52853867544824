import React from 'react';
import { GlowTypography } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type StoreOpenHoursProps = {
    openHours: { day: string; hours?: string }[];
    textSize?: 'small' | 'medium';
};

export const UIStoreOpenHours: Component<StoreOpenHoursProps> = ({ openHours, textSize = 'medium' }) => {
    const fontSize = textSize === 'small' ? 'bodySmall' : 'body';

    return (
        <ul className={'flex flex-col gap-0.25'}>
            {openHours.map(({ day, hours }, index) => (
                <li key={day} className={'flex'}>
                    <GlowTypography
                        text={day}
                        fontWeight={index === 0 ? 'bold' : 'medium'}
                        className={'flex flex-wrap w-openHoursDayLabel'}
                        fontSize={fontSize}
                    />
                    {hours && (
                        <GlowTypography text={hours} fontWeight={index === 0 ? 'bold' : 'medium'} fontSize={fontSize} />
                    )}
                </li>
            ))}
        </ul>
    );
};
