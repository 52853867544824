'use client';

import { GlowUnderBanner, CardBannerProps, GenericCard } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';

/**
 * UIUnderBanner component renders a banner with an image and additional content.
 * It uses the UIContainerBanner component as a wrapper and includes a GlowImageBanner
 * and GlowUnderBanner components for displaying the image and additional content respectively.
 *
 * @param {CardBannerProps} props - The properties passed to the component.
 * @param {string} props.href - The URL to navigate to when the banner is clicked.
 * @param {string} props.image - The source URL of the image to be displayed in the banner.
 * @param {string} props.className - Additional class names to apply to the banner.
 * @param {object} props.dataLayer - Data layer object for tracking purposes.
 * @param {object} props.rest - Additional properties to be spread onto the UIContainerBanner component.
 */

export const UIUnderBanner: Component<CardBannerProps> = (props: CardBannerProps) => {
    return (
        <GenericCard
            {...props}
            classNames={{
                container: 'flex-col tablet:flex-row tablet:items-center rounded-0.5 overflow-hidden',
                imgWrapper: 'w-full tablet:w-underBannerImg aspect-3/2',
                cta: 'w-full tablet:w-underBannerImg',
            }}
            sizes={`(max-width: ${tailwindTheme.screens.tablet}) 100vw, ${tailwindTheme.width.underBannerImg}`}
        >
            <GlowUnderBanner {...props} />
        </GenericCard>
    );
};
