// eslint-disable-next-line @nx/enforce-module-boundaries
import colors from '../../../../tokens/colors.json';

export type ColorCSSVariable = keyof typeof colors;

export const getColorCSSVariables = (color: ColorCSSVariable) => {
    return colors[color];
};

export enum ThemeColor {
    Light = 'light',
    Dark = 'dark',
}

export type ThemeSetting = {
    value: ThemeColor;
    isSystem: boolean;
};

export const DEFAULT_THEME_SETTING: ThemeSetting = {
    value: ThemeColor.Light,
    isSystem: false,
};
