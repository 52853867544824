import { SearchConfig } from '@headless-workspace/config';
import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { DecoderHelpers } from '@headless-workspace/utils';
import { VipBrandsValue } from '../config';
import { mapPopularSearch, PopularSearchDTO, PopularSearchesContainerDTO, PopularSearchValue } from './popular';
import {
    mapSearchSuggestions,
    SearchProductDTO,
    SearchSuggestionContainerDTO,
    SearchSuggestionsValue,
    TextSuggestionDTO,
} from './suggestions';

export interface SearchRepositorySpec {
    fetchPopular(locale: string): Promise<Result<PopularSearchValue[]>>;

    fetchSearchSuggestions(
        locale: string,
        query: string | null,
        vipBrands: VipBrandsValue,
    ): Promise<Result<SearchSuggestionsValue>>;
}

const EMPTY_LIMIT = 0;

export class SearchRepository extends Repository implements SearchRepositorySpec {
    path = '/catalog/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async fetchPopular(locale: string): Promise<Result<PopularSearchValue[]>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/search`, {
                locale,
                limit: EMPTY_LIMIT,
            });

            const popularSearchesContainer = PopularSearchesContainerDTO.parse(response);
            const popularSearches = DecoderHelpers.safeParseArray(
                popularSearchesContainer.searchSuggestions.popular,
                PopularSearchDTO,
            );

            if (popularSearches.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(popularSearches.map(mapPopularSearch));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async fetchSearchSuggestions(
        locale: string,
        query: string | null,
        vipBrands: VipBrandsValue,
    ): Promise<Result<SearchSuggestionsValue>> {
        if (!query) {
            return ResultEmpty();
        }

        try {
            const response = await this.datasource.getResource(`${this.path}/search`, {
                locale,
                query,
                limit: SearchConfig.suggestionsNumberLimit,
            });

            const searchSuggestionsResponse = SearchSuggestionContainerDTO.parse(response);

            const brands = DecoderHelpers.safeParseArray(
                searchSuggestionsResponse.searchSuggestions.brands,
                TextSuggestionDTO,
            );
            const products = DecoderHelpers.safeParseArray(
                searchSuggestionsResponse.searchSuggestions.products,
                SearchProductDTO,
            );
            const categories = DecoderHelpers.safeParseArray(
                searchSuggestionsResponse.searchSuggestions.categories,
                TextSuggestionDTO,
            );
            const terms = DecoderHelpers.safeParseArray(
                searchSuggestionsResponse.searchSuggestions.terms,
                TextSuggestionDTO,
            );

            if (products.length === 0 && terms.length === 0 && brands.length === 0 && categories.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(
                mapSearchSuggestions(
                    {
                        searchSuggestions: {
                            brands,
                            products,
                            categories,
                            terms,
                        },
                    },
                    vipBrands,
                ),
            );
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
