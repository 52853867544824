import { z } from 'zod';

export const FeatureFlagDTO = z.object({
    isOpenBarServicesEnabled: z.boolean().default(true),
    isClickAndCollectDisplayed: z.boolean().default(true),
    isWishlistDisplayed: z.boolean().default(true),
    countrySelector: z
        .object({
            enabled: z.boolean().default(false),
            value: z.object({
                title: z.string(),
                description: z.string(),
                countries: z.array(
                    z.object({
                        name: z.string().optional(),
                        code: z.enum(['DK', 'SE']),
                        tld: z.string(),
                    }),
                ),
            }),
        })
        .optional(),
});

export type FeatureFlagDTO = z.infer<typeof FeatureFlagDTO>;
