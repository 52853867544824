import { Logger } from '@headless-workspace/utils';
import { ErrorHandlerStrategy, ErrorHandlerStrategyOptions } from './errorHandlerStrategy';

const CLIENT_LOGGER_LABEL = 'Client Logger Error';

export const provideClientErrorStrategy = (logger: Logger, fallbackUrl: string): ErrorHandlerStrategy => {
    return {
        onError(error: Error, options?: ErrorHandlerStrategyOptions): void {
            if (typeof window !== 'undefined') {
                // Report error to logger
                logger.error(CLIENT_LOGGER_LABEL, error);

                // Try to recover from error
                if (options?.shouldRecover()) {
                    if (options?.canRetry()) {
                        window.location.reload();
                    } else {
                        options?.onFallback();
                        window.location.href = fallbackUrl;
                    }
                }
            }
        },
    };
};
