'use client';

import clsx from 'clsx';
import { ToastWithTimer, useToastActionsContext, useToastStateContext } from '@headless-workspace/core-ui';
import { GlowNotificationToast } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const ToastContainer: Component = () => {
    const { closeToast } = useToastActionsContext();
    const { toasts } = useToastStateContext();

    const handleClose = (toast: ToastWithTimer) => () => {
        closeToast(toast.id);
    };

    return (
        <div
            className={clsx(
                'fixed flex gap-1 flex-col',
                'max-tablet:bottom-1 max-tablet:w-fullViewport max-tablet:left-0 max-tablet:px-1',
                'tablet:top-headerFixedTablet tablet:right-1 tablet:justify-center tablet:mt-1 tablet:w-auto',
                'desktop:top-headerDesktop',
            )}
        >
            {toasts.map((toast) => (
                <GlowNotificationToast
                    key={toast.id}
                    label={{ text: toast.label }}
                    toastType={toast.type}
                    onClose={handleClose(toast)}
                    className={clsx(
                        toast.options?.animation && 'animate-slide-in-bottom tablet:animate-slide-in-right',
                    )}
                />
            ))}
        </div>
    );
};
