'use client';

import { createContext, useContext } from 'react';
import { ToastType } from '@headless-workspace/glow-ds';

export type Toast = {
    id: string;
    label: React.ReactNode;
    type: ToastType;
    options?: {
        animation?: boolean;
        persistent?: boolean;
        duration?: number;
    };
};
export interface ToastWithTimer extends Toast {
    timer?: NodeJS.Timeout;
}
interface ToastActionsContext {
    showToast: (toast: Toast) => string;
    closeToast: (id: string) => void;
    clearAllToasts: () => void;
}

interface ToastStateContext {
    toasts: ToastWithTimer[];
}

export const ToastActionsContext = createContext<ToastActionsContext>({
    showToast: () => '',
    closeToast: () => null,
    clearAllToasts: () => null,
});
export const ToastStateContext = createContext<ToastStateContext>({ toasts: [] });

export const useToastActionsContext = () => useContext(ToastActionsContext);
export const useToastStateContext = () => useContext(ToastStateContext);
