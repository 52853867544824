import { z } from 'zod';
import { DynamicIconDTO } from './DynamicIconDTO';
import { DynamicUrlDTO } from './DynamicUrlDTO';

export const HeaderConnectionBannerItemDTO = z.object({
    id: z.string(),
    label: z.string(),
    ariaLabel: z.string(),
    iconName: z.string(),
    url: z.string().optional(),
    isObfuscated: z.boolean().default(false),
    dataLayerLabel: z.string().optional(),
    dynamicOverlayIcons: z.array(DynamicIconDTO).optional(),
    dynamicUrl: z.array(DynamicUrlDTO).optional(),
});

export type HeaderConnectionBannerItemDTO = z.infer<typeof HeaderConnectionBannerItemDTO>;
