'use client';

import { buildRoute } from '@headless-workspace/config';
import { GlowHighlightText, GlowSearchTag, GlowTitle, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { TextSuggestionValue } from '../../../../server';

type SuggestProps = PropsWithLocale & {
    title: string;
    suggestions: TextSuggestionValue[];
    category: string;
};

export const Suggest: Component<SuggestProps> = ({ title, suggestions, category, locale }) => {
    if (suggestions.length === 0) {
        return null;
    }

    return (
        <div className={'flex flex-col gap-1'}>
            <GlowTitle text={title} fontSize={'title5'} />
            <ul className={'flex flex-row flex-wrap gap-0.5'}>
                {suggestions.map(({ name, id, href }) => (
                    <li key={`${category}-${id}`}>
                        <GlowSearchTag
                            text={<GlowHighlightText text={name} />}
                            href={href ?? `${buildRoute(locale, 'search')}${name}`}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
