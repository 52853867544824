import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    GlowClickable,
    GlowImage,
    GlowPrice,
    GlowTextBody,
    GlowTextBodySmall,
    PropsWithClassName,
    PropsWithImageTagName,
    PropsWithProduct,
} from '../../../../src';

type GlowBasketProductPlotProps = PropsWithImageTagName &
    PropsWithProduct &
    PropsWithClassName & {
        textQuantityLabel?: string;
        discountPercentageLabel?: string;
    };

const IMAGE_SIZE = 80;

/**
 * Component called plotProduit_cart on FIGMA
 */

export const GlowBasketProductPlot: Component<GlowBasketProductPlotProps> = ({
    priceAlwaysBlack,
    enableDiscountInfo,
    isArialFont,
    image,
    brand,
    price,
    priceBeforeDiscount,
    pricePerUnit,
    name,
    description,
    href,
    discountPercentageLabel,
    textQuantityLabel,
    ImageTagName,
    className,
}) => {
    const fontFamily = isArialFont ? 'arial' : 'brand';

    return (
        <div className={'flex flex-row items-start gap-1 py-0.5 px-1'}>
            <GlowClickable
                href={href}
                content={{
                    icon: (
                        <GlowImage
                            image={image}
                            className={'w-basketProductImg h-basketProductImg'}
                            width={IMAGE_SIZE}
                            height={IMAGE_SIZE}
                            TagName={ImageTagName}
                        />
                    ),
                }}
            />
            <div className={'flex flex-col gap-0.5 w-full'}>
                <div className={clsx('flex justify-between gap-x-0.5', className)}>
                    <GlowClickable
                        href={href}
                        content={{
                            labelElement: (
                                <div className={'flex flex-col'}>
                                    <GlowTextBody
                                        fontWeight={'bold'}
                                        text={brand.name}
                                        fontFamily={fontFamily}
                                        className={'line-clamp-1 max-w-basketProductInformation'}
                                    />
                                    <GlowTextBodySmall
                                        color={'tertiary'}
                                        text={name}
                                        fontFamily={fontFamily}
                                        className={'line-clamp-2 max-w-basketProductInformation'}
                                    />
                                </div>
                            ),
                        }}
                    />
                    <GlowPrice
                        price={price}
                        pricePerUnit={pricePerUnit}
                        priceBeforeDiscount={priceBeforeDiscount}
                        discountPercentageLabel={discountPercentageLabel}
                        priceAlwaysBlack={priceAlwaysBlack}
                        enableDiscountInfo={enableDiscountInfo}
                        fontFamily={fontFamily}
                    />
                </div>
                <div className={clsx('flex justify-between', className)}>
                    <GlowTextBodySmall
                        color={'tertiary'}
                        text={description}
                        fontFamily={fontFamily}
                        className={'line-clamp-1 max-w-basketProductInformation'}
                    />
                    <GlowTextBodySmall text={textQuantityLabel} fontFamily={fontFamily} />
                </div>
            </div>
        </div>
    );
};
