'use client';

import { CountrySelectorValue } from '@headless-workspace/core-domain';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { DataLayerEventCategory } from '../../dataLayer';
import { LocaleSwitcherButton, LocaleSwitcherButtonProps } from '../LocaleSwitcherButton';
import { LocaleSwitcherMenu } from '../LocaleSwitcherMenu';

const COUNTRY_BUTTON_DATA_LAYER_ACTION = 'click_flag';
const COUNTRY_ITEM_BUTTON_DATA_LAYER_ACTION = 'selection_country';

type CountrySwitcherProps = Pick<LocaleSwitcherButtonProps, 'className' | 'displayType' | 'hostUrl'> &
    PropsWithLocale & {
        countrySelector: CountrySelectorValue;
    };

export const CountrySwitcher: Component<CountrySwitcherProps> = ({
    className,
    displayType,
    hostUrl,
    locale,
    countrySelector,
}) => {
    return (
        <LocaleSwitcherButton
            className={className}
            countries={countrySelector.countries}
            displayType={displayType}
            menu={
                <LocaleSwitcherMenu
                    title={countrySelector.title}
                    description={countrySelector.description}
                    showCode={false}
                    countries={countrySelector.countries}
                    locale={locale}
                    dataLayer={{
                        category: DataLayerEventCategory.countrySwitcher,
                        action: COUNTRY_ITEM_BUTTON_DATA_LAYER_ACTION,
                    }}
                />
            }
            hostUrl={hostUrl}
            dataLayer={{
                category: DataLayerEventCategory.countrySwitcher,
                action: COUNTRY_BUTTON_DATA_LAYER_ACTION,
            }}
        />
    );
};
