import React from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowIcon, IconType, PropsWithIcon } from '../../../../src';

export type WishlistIconAnimationProps = PropsWithIcon & {
    type?: IconType;
};

export const GlowIconSkeleton: Component<WishlistIconAnimationProps> = ({ Icon, type = 'large' }) => {
    return (
        <div className={'relative w-iconLarge h-iconLarge overflow-hidden'}>
            <div
                className={
                    /* This top-[-100%] left-[-100%] rotate-[50deg]
                       are  Special case is used to manage animation
                       to not add to DS.
                    */
                    'absolute top-[-100%] left-[-100%] w-full h-full bg-light-gradient rotate-[50deg] animate-lightMove'
                }
            ></div>
            <GlowIcon
                Icon={Icon}
                type={type}
                fill={'fill-background-skeleton'}
                className={'fill-background-skeleton'}
            />
        </div>
    );
};
