import { EnvConfig } from '@headless-workspace/config';

export type CacheStrategy = {
    cache: RequestCache;
};

export const provideCacheStrategy = (envConfig: EnvConfig): CacheStrategy => {
    return {
        cache: envConfig.defaultCachePolicy,
    };
};
