import { ApiError } from '@headless-workspace/data';
import { Result, ResultFailure } from './Result';

export const ResultFailureFromApiError = (apiError: ApiError, extra?: Record<string, unknown>): Result => {
    return ResultFailure(apiError.code, apiError.message, {
        ...extra,
        url: apiError.url,
        tips: apiError.tips,
        date: new Date().toISOString(),
    });
};
