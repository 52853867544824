const getClientSideCookie = (name: string): string => {
    if (typeof document !== 'undefined') {
        return (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith(`${name}=`))
                ?.split(`${name}=`)[1] ?? ''
        );
    }
    return '';
};

/**
 * Check empty cookie value set by SFCC as ""
 */
const isNotEmptyDoubleQuotes = (value?: string): boolean => {
    return value !== '""';
};

export const CookieHelpers = {
    getClientSideCookie,
    isNotEmptyDoubleQuotes,
};
