import React from 'react';

export type IconComponentType = React.ComponentType<{
    className?: string;
    style?: React.CSSProperties;
    fill?: string;
    height?: string | number;
    width?: string | number;
    alt?: string;
}>;

export type PropsWithIcon = {
    Icon: IconComponentType;
};
