'use client';

import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel, { UseEmblaCarouselType } from 'embla-carousel-react';
import { usePrevNextButtons } from '../usePrevNextButtons';

export type CarouselLogic = {
    emblaRef?: UseEmblaCarouselType[0];
    emblaApi?: UseEmblaCarouselType[1];
    prevBtnDisabled: boolean;
    nextBtnDisabled: boolean;
    onPrevButtonClick: () => void;
    onNextButtonClick: () => void;
    selectedIndex: number;
};

export const useCarouselLogic = (options?: EmblaOptionsType): CarouselLogic => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, ...options });
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick, selectedIndex } =
        usePrevNextButtons(emblaApi);

    return {
        emblaRef,
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
        selectedIndex,
        emblaApi,
    };
};
