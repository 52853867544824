'use client';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DEFAULT_THEME_SETTING, ThemeColor, ThemeSetting } from '@headless-workspace/config';
import { CookieName, setClientCookie } from '@headless-workspace/core-domain';
import { Component } from '@headless-workspace/typings';
import { ThemeContext, ThemeMode } from './ThemeContext';

type ThemeProviderProps = {
    children: React.ReactNode;
    initialTheme?: ThemeSetting;
};

const getSystemTheme = (): ThemeColor =>
    window.matchMedia('(prefers-color-scheme: dark)').matches ? ThemeColor.Dark : ThemeColor.Light;

export const ThemeProvider: Component<ThemeProviderProps> = ({
    initialTheme = DEFAULT_THEME_SETTING,
    children,
}: ThemeProviderProps) => {
    const [themeSetting, setThemeSetting] = useState<ThemeSetting>(initialTheme);

    const applyTheme = useCallback((mode: ThemeColor) => {
        document.documentElement.setAttribute('data-theme', mode);
    }, []);

    const handleThemeChange = useCallback(
        (theme: ThemeMode) => {
            setThemeSetting(() => {
                const newThemeSetting: ThemeSetting =
                    theme === 'system'
                        ? { value: getSystemTheme(), isSystem: true }
                        : { value: theme, isSystem: false };

                applyTheme(newThemeSetting.value);
                setClientCookie(CookieName.ColorSetting, JSON.stringify(newThemeSetting), 365);

                return newThemeSetting;
            });
        },
        [applyTheme],
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleSystemThemeChange = () => {
            if (themeSetting.isSystem) {
                handleThemeChange('system');
            }
        };

        mediaQuery.addEventListener('change', handleSystemThemeChange);
        return () => mediaQuery.removeEventListener('change', handleSystemThemeChange);
    }, [themeSetting.isSystem, handleThemeChange]);

    const contextValue = useMemo(
        () => ({
            themeMode: themeSetting.isSystem ? ('system' as ThemeMode) : themeSetting.value,
            setTheme: handleThemeChange,
        }),
        [themeSetting, handleThemeChange],
    );

    return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};
