'use client';

import { useCallback } from 'react';

export const useOnKeyDownListener = (callback: (() => void) | undefined, key: string) => {
    const handleUserKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === key) {
                callback?.();
            }
        },
        [callback, key],
    );

    return {
        addListener: () => window.addEventListener('keydown', handleUserKeyPress),
        removeListener: () => window.removeEventListener('keydown', handleUserKeyPress),
    };
};
