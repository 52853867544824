'use client';

import { useState } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableToast, getToastConfig, GlowToastProps } from '../../composables';

type GlowInlineToastProps = GlowToastProps & {
    hasCloseBtn?: boolean;
};

export const GlowInlineToast: Component<GlowInlineToastProps> = ({
    toastType,
    label,
    className,
    hasCloseBtn,
    ...props
}) => {
    const { toastIcon, backgroundClassName, role, ariaLive } = getToastConfig(toastType);
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => setIsVisible(false);

    if (!isVisible) {
        return null;
    }

    return (
        <ComposableToast.Container
            ariaLive={ariaLive}
            className={clsx(className, backgroundClassName)}
            role={role}
            {...props}
        >
            <ComposableToast.Icon Icon={toastIcon} />
            <ComposableToast.Label label={label} />
            {hasCloseBtn && <ComposableToast.CloseButton onClose={handleClose} />}
        </ComposableToast.Container>
    );
};
