'use client';

import { GlowMenuButton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { CommonLocaleSwitcherButton } from './LocaleSwitcherButton';
import { getCountryIconByUrl } from './LocaleSwitcherHeaderButton';

type LocaleSwitcherBurgerMenuButtonProps = CommonLocaleSwitcherButton & {
    onOpenMenu: () => void;
    countryLabel: string;
    hostUrl: string | null;
};

export const LocaleSwitcherBurgerMenuButton: Component<LocaleSwitcherBurgerMenuButtonProps> = ({
    className,
    countries,
    countryLabel,
    onOpenMenu,
    hostUrl,
}) => {
    const icon = getCountryIconByUrl(countries, hostUrl);

    if (!icon) {
        return null;
    }

    return (
        <GlowMenuButton
            className={className}
            iconClassName={'rounded-infinity'}
            Icon={icon}
            label={countryLabel}
            onClick={onOpenMenu}
        />
    );
};
