import {
    AuthorizationStrategy,
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
    QueryParamStrategy,
    RefreshTokenStrategy,
} from '@headless-workspace/data';
import {
    ConfigByKeyContainerDTO,
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '../../../src';

export interface ConfigByKeyRepositorySpec {
    getConfigByKey<T>(key: string, params: Record<string, unknown>, decoder: (value: unknown) => T): Promise<Result<T>>;
}

export abstract class ConfigByKeyRepository extends Repository implements ConfigByKeyRepositorySpec {
    path = '/settings/v1';

    constructor(
        readonly authStrategy: Partial<AuthorizationStrategy> = memoryCacheAuthStrategy,
        readonly refreshTokenStrategy: Partial<RefreshTokenStrategy> = memoryCacheRefreshTokenStrategy,
        readonly queryParamStrategy: Partial<QueryParamStrategy> = memoryCacheQueryParamStrategy,
    ) {
        super(authStrategy, refreshTokenStrategy, queryParamStrategy);
    }

    async getConfigByKey<T>(
        key: string,
        params: Record<string, unknown>,
        decoder: (value: unknown) => T,
    ): Promise<Result<T>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/configs/${key}`, params);
            const config = ConfigByKeyContainerDTO.parse(response);
            const value = decoder(config.value);
            if (value === undefined || value === null) {
                return ResultEmpty();
            }
            if (Array.isArray(value) && value.length === 0) {
                return ResultEmpty();
            }
            if (typeof value === 'string' && value === '') {
                return ResultEmpty();
            }
            if (typeof value === 'object' && Object.keys(value).length === 0) {
                return ResultEmpty();
            }
            return ResultSuccess(value);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { ...params });
        }
    }
}
