import React, { PropsWithChildren } from 'react';
import { Component } from '@headless-workspace/typings';
import {
    GlowLink,
    isPropsWithFormSubmit,
    isPropsWithHref,
    PropsWithAriaControls,
    PropsWithAriaExpanded,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithCTA,
    PropsWithId,
    PropsWithStyle,
    PropsWithSuppressHydrationWarning,
    PropsWithTestId,
} from '../../../../src';

type GlowCTAProps = PropsWithAriaLabel &
    PropsWithClassName &
    PropsWithId &
    PropsWithTestId &
    PropsWithStyle &
    PropsWithSuppressHydrationWarning &
    PropsWithCTA &
    PropsWithChildren &
    PropsWithAriaControls &
    PropsWithAriaExpanded;

/**
 * Generic flat CTA that will render a button or anchor depending on the props
 * @param ariaLabel - Aria label for the button or anchor
 * @param className - Additional styles for the button or anchor
 * @param id - ID of the button or the anchor
 * @param style - Additional styles for the button or anchor
 * @param suppressHydrationWarning - If true, suppress the hydration warning
 * @param children - Content of the button or anchor
 * @param dataTestId - data-testid for the button or anchor
 * @param dataLayerId - Data layer id for the button or anchor
 * @param dataLayerSubId - Data layer sub-id for the button or anchor
 * @param dataLayerLabel - Data layer label for the button or anchor
 * @param dataLayerAction - Data layer action for the button or anchor
 * @param ariaControls - ID of the element controlled by the button
 * @param ariaExpanded - If the element controlled by the button is expanded
 * @param props - Contains the following:
 * @param props.onMouseEnter - Function to be called when the mouse enters the button
 * @param props.onMouseLeave - Function to be called when the mouse leaves the button
 * @param props.onClick - Function to be called when the button is clicked
 * @param props.onKeyDown - Function to be called when a key is pressed
 * @param props.href - URL to navigate to when the anchor is clicked
 * @param props.isObfuscated - If true, the anchor will have an obscured URL
 */
export const GlowCTA: Component<GlowCTAProps> = ({
    ariaLabel,
    className,
    id,
    dataTestId,
    style,
    suppressHydrationWarning = false,
    children,
    dataLayerId,
    dataLayerSubId,
    dataLayerLabel,
    dataLayerAction,
    ariaControls,
    ariaExpanded,
    ...props
}) => {
    if (isPropsWithHref(props)) {
        return (
            <GlowLink
                id={id}
                dataTestId={dataTestId}
                className={className}
                ariaLabel={ariaLabel}
                style={style}
                href={props.href}
                isObfuscated={props.isObfuscated}
                dataLayerId={dataLayerId}
                dataLayerSubId={dataLayerSubId}
                dataLayerLabel={dataLayerLabel}
                dataLayerAction={dataLayerAction}
                disabled={props.disabled}
            >
                {children}
            </GlowLink>
        );
    }

    if (isPropsWithFormSubmit(props)) {
        return (
            <button
                id={id}
                data-testid={dataTestId}
                className={className}
                aria-label={ariaLabel}
                style={style}
                suppressHydrationWarning={suppressHydrationWarning}
                type={'submit'}
                form={props.formId}
                data-layer-id={dataLayerId}
                data-layer-sub-id={dataLayerSubId}
                data-layer-label={dataLayerLabel}
                data-layer-action={dataLayerAction}
            >
                {children}
            </button>
        );
    }

    return (
        <button
            id={id}
            data-testid={dataTestId}
            className={className}
            aria-label={ariaLabel}
            style={style}
            suppressHydrationWarning={suppressHydrationWarning}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onKeyDown={props.onKeyDown}
            type={'button'}
            disabled={props.disabled}
            data-layer-id={dataLayerId}
            data-layer-sub-id={dataLayerSubId}
            data-layer-label={dataLayerLabel}
            data-layer-action={dataLayerAction}
            aria-controls={ariaControls}
            aria-expanded={ariaExpanded}
        >
            {children}
        </button>
    );
};
