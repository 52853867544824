import { Component } from '@headless-workspace/typings';
import { TabsContent } from '@radix-ui/react-tabs';
import { PropsWithId } from '../../../../src';

export type TabContentProps = Required<PropsWithId> & {
    Content: Component;
};

export const TabContent: Component<TabContentProps> = ({ id, Content }) => {
    return (
        <TabsContent value={id}>
            <Content />
        </TabsContent>
    );
};
