import { DebounceHelpers } from '@headless-workspace/utils';
import { CommonDI, LoggerType, UserCookiesRepositoryType } from '../../../DI';

const DEBOUNCE_DELAY_MS = 2500;
const LOGGER_ERROR_LABEL = 'revalidateSessionDebounced';

export const revalidateSessionDebounced = DebounceHelpers.debounceCallback(
    async (locale: string, revalidationKey: string) => {
        try {
            await CommonDI.get(UserCookiesRepositoryType).revalidateSessionCookies(locale, revalidationKey);
        } catch (error) {
            CommonDI.get(LoggerType).info(LOGGER_ERROR_LABEL, error);
        }
    },
    DEBOUNCE_DELAY_MS,
);
