import { AuthorizationStrategy } from './authorizationStrategy';
import { QueryParamStrategy } from './queryParamStrategy';
import { RefreshTokenStrategy } from './refreshTokenStrategy';

export type RequestStrategy = {
    authStrategy: Partial<AuthorizationStrategy>;
    refreshTokenStrategy: Partial<RefreshTokenStrategy>;
    queryParamStrategy: Partial<QueryParamStrategy>;
};

export const initRequestStrategy = (): RequestStrategy => {
    return {
        authStrategy: {},
        refreshTokenStrategy: {},
        queryParamStrategy: {},
    };
};
