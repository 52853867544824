import { MemoryCacheKey } from '@headless-workspace/config';
import { AccessTokenCacheDTO, AccessTokenLastUpdateCacheDTO } from './CacheDTO';

const serverCacheMap: Map<string, string | number | undefined> = new Map();

export const serverMemoryCache = {
    getAccessToken(): string | undefined {
        const cachedAccessToken = serverCacheMap.get(MemoryCacheKey.AccessToken);
        const parseResult = AccessTokenCacheDTO.safeParse(cachedAccessToken);
        return parseResult.success ? parseResult.data : undefined;
    },
    getAccessTokenTTL(): number | undefined {
        const cachedAccessTokenLastUpdate = serverCacheMap.get(MemoryCacheKey.AccessTokenLastUpdate);
        const parseResult = AccessTokenLastUpdateCacheDTO.safeParse(cachedAccessTokenLastUpdate);
        return parseResult.success ? parseResult.data : undefined;
    },
    setAccessToken(accessToken: string): void {
        serverCacheMap.set(MemoryCacheKey.AccessToken, accessToken);
        serverCacheMap.set(MemoryCacheKey.AccessTokenLastUpdate, Date.now());
    },
};
