import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowClickable, GlowIcon } from '../../../../../src';
import { ButtonBaseProps, IconButtonProps } from './buttonProps';
import { iconButtonStyle } from './iconButtonStyle';

/**
 * Icon Button component
 * @param Icon - Icon to be displayed on the right side of the text
 * @param size - Size of the button. Default is 'large'
 * @param variant - Variant of the button. Default is 'primary'
 * @param fill - Fill color of the icon
 * @param isSpinnerActive - Flag to show spinner. Default is false
 * @param className - Additional styles
 * @param isCTA - If false, the button will not be clickable
 * @param props - Action to be performed when the button is clicked (href, onClick, ...)
 */
export const IconButton: Component<ButtonBaseProps & IconButtonProps> = ({
    Icon,
    size = 'large',
    variant = 'primary',
    fill,
    SkeletonIcon,
    isLoading = false,
    className,
    isCTA = true,
    ...props
}) => {
    const iconElement = isLoading ? SkeletonIcon : <GlowIcon Icon={Icon} type={'medium'} fill={fill} />;

    if (!isCTA) {
        return (
            <div className={clsx(iconButtonStyle({ size, variant }), className)} data-testid={props.dataTestId}>
                {iconElement}
            </div>
        );
    }

    return (
        <GlowClickable
            className={clsx(iconButtonStyle({ size, variant }), className)}
            content={{
                icon: iconElement,
            }}
            {...props}
        />
    );
};
