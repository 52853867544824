import { DefaultValueConfig } from '@headless-workspace/config';
import { PredicateHelpers, UrlHelpers } from '@headless-workspace/utils';
import {
    DynamicIconValue,
    DynamicLabelValue,
    HeaderSlotDTO,
    HeaderSlotType,
    mapDynamicHref,
    mapDynamicIcon,
    mapDynamicLabel,
    mapHeaderSlotType,
} from '../countrySpecificities';
import { DynamicHrefValue } from './DynamicHrefValue';

type HeaderBaseSlotValue = {
    type: HeaderSlotType;
    displayClassName?: string;
};

type HeaderSlotLabelValue = {
    label: string;
    dynamicLabel?: DynamicLabelValue[];
    labelDisplayClassName?: string;
    extraLabel?: DynamicLabelValue;
};

type HeaderSlotIconValue = {
    iconName: string;
    overlayIconName?: string;
    dynamicOverlayIcons?: DynamicIconValue[];
};

type HeaderSlotHrefValue = {
    href: string;
    dynamicHref?: DynamicHrefValue[];
};

export type HeaderSlotValue = HeaderBaseSlotValue & HeaderSlotLabelValue & HeaderSlotIconValue & HeaderSlotHrefValue;

export const mapHeaderSlot = (dto: HeaderSlotDTO): HeaderSlotValue | null => {
    const type = mapHeaderSlotType(dto.id);
    if (!type) {
        return null;
    }

    return {
        type,
        iconName: dto.iconName,
        label: dto.label,
        href: UrlHelpers.createPathFromEnv(dto.url) ?? DefaultValueConfig.href,
        dynamicLabel: dto.dynamicLabel?.map(mapDynamicLabel).filter(PredicateHelpers.notNullOrUndefined),
        displayClassName: dto.displayRules,
        labelDisplayClassName: dto.labelDisplayRules,
        extraLabel: mapDynamicLabel(dto.extraLabel),
        overlayIconName: dto.defaultOverlayIconName,
        dynamicOverlayIcons: dto.dynamicOverlayIcons?.map(mapDynamicIcon).filter(PredicateHelpers.notNullOrUndefined),
        dynamicHref: dto.dynamicUrl?.map(mapDynamicHref).filter(PredicateHelpers.notNullOrUndefined),
    };
};
