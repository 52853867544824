import { ApiErrorPayloadDto } from '../../dto';
import { HttpStatus, HttpStatusMap } from '../http';

export const DEFAULT_ERROR_STATUS = 500;
export const DEFAULT_ERROR_MESSAGE = 'Internal error';

export class ApiError extends Error {
    public readonly code: string;
    public readonly tips: string | undefined = undefined;

    constructor(
        public readonly status: HttpStatus,
        payload: ApiErrorPayloadDto = {
            errors: [
                {
                    errorCode: HttpStatusMap[status] ?? HttpStatusMap[DEFAULT_ERROR_STATUS],
                    errorMessage: DEFAULT_ERROR_MESSAGE,
                },
            ],
        },
        public readonly url?: string,
    ) {
        super(payload.errors.map((error) => error.errorMessage).join());
        // Needed to use instanceof
        Object.setPrototypeOf(this, ApiError.prototype);

        this.code = payload.errors[0].errorCode;
        this.tips = payload.errors[0].tips;
    }
}
