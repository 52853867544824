import { Component } from '@headless-workspace/typings';
import { GlowChip, GlowImage, GlowTextBody, GlowTextBodySmall, GlowTextCaption } from '../../atoms';
import { GlowCTA } from '../cta/GlowCTA';
import { SelectorProps } from './SelectorProps';

const IMG_SIZE = 40;

/**
 * A component that renders a product selector.
 * @param titleContent - Contains the title of the selector and its font weight.
 * @param caption - Contains the caption of the selector text and its color.
 * @param chipWithText - Contains chip color and text.
 * @param RightElement - The element displayed on the right.
 * @param onClick - The function to be called when the selector is clicked.
 * @param fontFamily - Font family for selector texts.
 * @param props - Contains the following:
 * @param props.image - The image on the left.
 * @param props.imageClassName - Additional styles for the image.
 * @param props.shapeClassName - The style of the shape on the left.
 */
export const GlowSelector: Component<SelectorProps> = ({
    titleContent,
    caption,
    chipWithText,
    RightElement,
    onClick,
    fontFamily = 'brand',
    ...props
}) => {
    const className =
        'flex justify-center items-center w-full gap-0.5 px-1 py-0.5 bg-background-form outline outline-border-primary outline-medium rounded-0.5';
    const content = (
        <>
            {'image' in props ? (
                <GlowImage
                    image={props.image}
                    imgClassName={props.imageClassName}
                    height={IMG_SIZE}
                    width={IMG_SIZE}
                    TagName={props.ImageTagName}
                />
            ) : 'shapeClassName' in props ? (
                <span className={props.shapeClassName} />
            ) : null}

            <span className={'flex flex-col flex-1'}>
                <span className={'flex flex-col items-start tablet:flex-row tablet:items-center tablet:gap-0.5'}>
                    <GlowTextBody
                        text={titleContent.label}
                        fontWeight={titleContent.fontWeight}
                        className={'text-left'}
                        fontFamily={fontFamily}
                        TagName={'span'}
                    />
                    {caption && (
                        <GlowTextCaption
                            text={caption.text}
                            color={caption.color}
                            fontFamily={fontFamily}
                            TagName={'span'}
                        />
                    )}
                </span>
                {chipWithText && (
                    <div className={'flex gap-0.5 items-center'}>
                        <GlowChip hasDigit={false} color={chipWithText.chipColor} size={'medium'} />
                        <GlowTextBodySmall text={chipWithText.label} fontFamily={fontFamily} TagName={'span'} />
                    </div>
                )}
            </span>

            {RightElement}
        </>
    );

    if (!onClick) {
        return <div className={className}>{content}</div>;
    }

    return (
        <GlowCTA onClick={onClick} className={className}>
            {content}
        </GlowCTA>
    );
};
