import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowIcon, GlowClickable, PropsWithAriaLabel, PropsWithOnClick, Icons } from '../../../../src';

type NavigationButtonProps = Required<PropsWithAriaLabel> &
    Pick<PropsWithOnClick, 'onClick'> & {
        reverse?: boolean;
    };

export const NavigationButton: Component<NavigationButtonProps> = ({ ariaLabel, onClick, reverse }) => {
    const icon = <GlowIcon Icon={Icons.ChevronRight} />;

    return (
        <div
            className={clsx(
                'flex-none',
                'px-0.5',
                'hidden desktopS:group-hover:block',
                'absolute z-[1] top-1/2 -translate-y-1/2',
                reverse ? 'left-0 rotate-180' : 'right-0',
            )}
        >
            <GlowClickable
                className={'bg-surface-primary p-0.5 border-medium border-solid border-border-primary rounded-0.5'}
                ariaLabel={ariaLabel}
                content={{ icon }}
                onClick={onClick}
            />
        </div>
    );
};
