'use client';

import React from 'react';
import clsx from 'clsx';
import { DefaultValueConfig } from '@headless-workspace/config';
import { GlowClickable, GlowTextBodySmall, PropsWithAriaLabel } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useHover } from 'usehooks-ts';
import { CategoryValue } from '../../../../server';
import { DataLayerClassName } from '../../dataLayer';
import { MenuSubCategoriesListItems } from '../MenuSubCategoriesListItems';

type HeaderMenuListItemProps = PropsWithAriaLabel & {
    title: string;
    iconUrl?: string;
    color?: string;
    href: string;
    seeAllUrl?: string;
    dataLayerId?: string;
    subCategories: CategoryValue[];
    isSeeAllEnabled?: boolean;
};

export const HeaderMenuListItem: Component<HeaderMenuListItemProps> = ({
    title,
    iconUrl,
    color,
    href,
    seeAllUrl,
    ariaLabel = '',
    dataLayerId,
    subCategories,
    isSeeAllEnabled,
}) => {
    const listItemRef = React.useRef<HTMLLIElement>(null);
    // @ts-expect-error useHover takes a ref as an argument, so it should expect a null
    const isHover = useHover(listItemRef);

    return (
        <li ref={listItemRef} className={clsx('group h-full list-none')}>
            <span className={clsx('flex items-center flex-row', 'h-full px-1', 'whitespace-nowrap')}>
                {iconUrl && <img alt={DefaultValueConfig.alt} src={iconUrl} />}
                <GlowClickable
                    className={clsx('flex h-full', DataLayerClassName.categoryNavigation)}
                    ariaLabel={ariaLabel}
                    href={href}
                    dataLayerId={dataLayerId}
                    content={{
                        labelElement: (
                            <GlowTextBodySmall
                                text={title}
                                className={clsx(
                                    'flex items-center',
                                    'h-full',
                                    'border-solid border-background-l0 border-b-strong',
                                    isHover && 'border-border-brand',
                                )}
                                style={{
                                    color,
                                    ...(isHover && color ? { borderBottomColor: color } : undefined),
                                }}
                                TagName={'span'}
                            />
                        ),
                    }}
                />
            </span>
            {subCategories.length > 0 && (
                <div
                    className={clsx(
                        'invisible flex absolute left-0 w-full delay-moderate2',
                        'max-h-headerPanel',
                        'top-headerDesktop',
                        'bg-surface-primary shadow-banner',
                        'group-hover:visible',
                    )}
                >
                    <ul
                        className={clsx(
                            'flex flex-col flex-wrap',
                            'w-maxWidth max-h-headerPanel',
                            'm-auto mt-0 py-2 px-1.5',
                            'justify-start items-start content-start',
                            'overflow-hidden gap-x-2.5 gap-y-1',
                        )}
                    >
                        <MenuSubCategoriesListItems
                            categoryDataLayerId={dataLayerId}
                            categories={subCategories}
                            seeAllUrl={seeAllUrl}
                            isSeeAllEnabled={isSeeAllEnabled}
                        />
                    </ul>
                </div>
            )}
        </li>
    );
};
