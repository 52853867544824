export type FeatureFlipping = {
    isDebugServerErrorEnabled: boolean;
    isSessionBridgeEnabled: boolean;
    isSessionBridgeDebugEnabled: boolean;
    isOutputLoggingEnabled: boolean;
    isTimeMachineEnabled: boolean;
    isOpentelemetryEnabled: boolean;
    isAppVersionEnabled: boolean;
    isReachFiveEnabled: boolean;
    isThemeSwitcherEnabled: boolean;
};

export const createFeatureFlipping = (): FeatureFlipping => {
    return {
        isDebugServerErrorEnabled: process.env['DEBUG_SERVER_ERROR_ENABLED'] === 'true',
        isSessionBridgeEnabled: process.env['SESSION_BRIDGE_ENABLED'] === 'true',
        isSessionBridgeDebugEnabled: process.env['SESSION_BRIDGE_DEBUG_ENABLED'] === 'true',
        isOutputLoggingEnabled: process.env['OUTPUT_LOGGING_ENABLED'] === 'true',
        isTimeMachineEnabled: process.env['TIME_MACHINE_ENABLED'] === 'true',
        isOpentelemetryEnabled: process.env['OPENTELEMETRY_ENABLED'] === 'true',
        isAppVersionEnabled: process.env['DISPLAY_APP_VERSION_ENABLED'] === 'true',
        isReachFiveEnabled: process.env['REACH_FIVE_ENABLED'] === 'true',
        isThemeSwitcherEnabled: process.env['THEME_SWITCHER_ENABLED'] === 'true',
    };
};

export const featureFlipping = createFeatureFlipping();
