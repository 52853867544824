export default {
  "brand": "text-text-brand",
  "bronze": "text-text-bronze",
  "corail": "text-text-corail",
  "disabled": "text-text-disabled",
  "error": "text-text-error",
  "gold": "text-text-gold",
  "infos": "text-text-infos",
  "primary": "text-text-primary",
  "primary-fixed": "text-text-primary-fixed",
  "primary-inverse": "text-text-primary-inverse",
  "primary-inverse-fixed": "text-text-primary-inverse-fixed",
  "promotion": "text-text-promotion",
  "rose": "text-text-rose",
  "secondary": "text-text-secondary",
  "secondary-inverse": "text-text-secondary-inverse",
  "secondary-inverse-fixed": "text-text-secondary-inverse-fixed",
  "sephora-collection": "text-text-sephora-collection",
  "silver": "text-text-silver",
  "success": "text-text-success",
  "tertiary": "text-text-tertiary",
  "tertiary-inverse": "text-text-tertiary-inverse",
  "tertiary-inverse-fixed": "text-text-tertiary-inverse-fixed",
  "violet": "text-text-violet",
  "warning": "text-text-warning",
  "yellow": "text-text-yellow"
}