// FIXME: we use fr-FR meta-data we didn't have fr_CH meta-data yet

export const fr_CH_NavRoutes = {
    home: 'fr',
    connection: 'fr/connexion',
    storesAndServices: 'fr/tous-les-magasins',
    wishlist: 'fr/wishlist',
    basket: 'fr/panier',
    payment: '', // FIXME: To Update
    account: 'fr/mon-compte',
    orders: 'fr/mes-achats',
    followOrder: 'fr',
    loyalty: 'fr/Programme-carte-fidelite.html',
    offers: 'fr',
    profile: 'fr/profil',
    mail: 'fr/profil/?is_communication_link=true ',
    help: 'fr/aide-sephora',
    allServices: 'fr/nos-services-en-magasin.html',
    storeInfo: 'fr/information-magasin',
    allStores: 'fr/tous-les-magasins/',
    countries: 'fr/pays.html',
    beautyTips: 'fr',
    logout: 'fr/deconnexion/?redirectToReferer=true',
    loyaltyProgram: 'fr',
    allBrands: 'fr/marques/de-a-a-z/',
    clickAndCollect: 'fr/services-commande.html',
    securePayment: 'fr/paiement-securise.html',
    returnProduct: 'fr/aide-sephora/?question=order-topic5-question1',
    privacyPolicy: 'fr/donnees-personnelles.html',
    search: 'fr',
    reviewConditions: '',
};
