import React from 'react';
import Script from 'next/script';
import { appsflyerAfterInitScript, appsflyerInitScript } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';

export type AppFlyerScriptsType = {
    appLinks: { keyValue: string; enabled: boolean };
};

export const AppFlyerScripts: Component<AppFlyerScriptsType> = async ({ appLinks }) => {
    return (
        <>
            <Script id={'appsflyer-init-script'} strategy={'beforeInteractive'} async={true}>
                {appsflyerInitScript(appLinks.keyValue)}
            </Script>
            <Script id={'appsflyer-after-init-script'} strategy={'afterInteractive'} async={true}>
                {appsflyerAfterInitScript}
            </Script>
        </>
    );
};
