'use client';

import { useContext } from 'react';
import clsx from 'clsx';
import { DataTestConfig } from '@headless-workspace/config';
import { GlowClickable, GlowIcon, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useScroll } from '../../hooks';
import { UIContext } from '../../providers';

type UIBackToTopProps = {
    ariaLabel: string;
};

export const BACK_TO_TOP_ID = 'back-to-top';

export const UIBackToTop: Component<UIBackToTopProps> = ({ ariaLabel }) => {
    const { isVisible, firstScrollUserEnd } = useScroll();
    const {
        menuModal: { isOpen: isMenuModalOpen },
    } = useContext(UIContext);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (isMenuModalOpen) {
        return null;
    }

    return (
        <GlowClickable
            className={clsx(
                'bg-surface-brand hover:bg-surface-brand-hover active:bg-surface-brand-pressed',
                'fixed bottom-4 right-1 rounded-0.5',
                'transition-display z-backToTop p-0.75',
                isVisible && firstScrollUserEnd ? 'flex' : 'hidden',
            )}
            onClick={scrollToTop}
            content={{
                icon: <GlowIcon Icon={Icons.ChevronDown} className={'fill-text-primary-inverse rotate-180'} />,
            }}
            ariaLabel={ariaLabel}
            id={BACK_TO_TOP_ID}
            dataTestId={DataTestConfig.testIds.backToTopButton}
        />
    );
};
