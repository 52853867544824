import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import { initRequestStrategy } from '@headless-workspace/data';
import { LocalitiesAutocompleteDTO } from './LocalitiesAutocompleteDTO';
import { LocalityDetails, mapLoyaltyDetails } from './LocalityDetails';
import { LocalityDetailsDTO } from './LocalityDetailsDTO';
import { LocalityValue, mapLocalities } from './LocalityValue';
import { SearchGeolocationsAddressDTO } from './SearchGeolocationAddressDTO';
import { mapSearchGeolocationAddress, SearchGeolocationAddressValue } from './SearchGeolocationAddressValue';

export interface SearchGeolocationRepositorySpec {
    fetchAddressFromGeolocation(
        latitude: number,
        lng: number,
        apiKey: string,
    ): Promise<Result<SearchGeolocationAddressValue>>;

    fetchAddressAutoComplete(
        query: string,
        apiKey: string,
        customDescription: string,
        country: string[],
    ): Promise<Result<LocalityValue[]>>;

    fetchLocalityDetails(localityId: string, apiKey: string): Promise<Result<LocalityDetails>>;
}

export class SearchGeolocationRepository extends Repository implements SearchGeolocationRepositorySpec {
    path = '/localities';

    constructor(apiBaseUrl: string, requestStrategy = initRequestStrategy()) {
        super(
            requestStrategy.authStrategy,
            requestStrategy.refreshTokenStrategy,
            requestStrategy.queryParamStrategy,
            apiBaseUrl,
        );
    }

    async fetchAddressAutoComplete(
        query: string,
        apiKey: string,
        customDescription: string,
        countries: string[],
    ): Promise<Result<LocalityValue[]>> {
        const countriesComponents = countries.map((country) => `country:${country}`).join('|');
        try {
            const response = await this.datasource.getResource(`${this.path}/autocomplete`, {
                key: apiKey,
                input: query,
                custom_description: customDescription,
                components: countriesComponents,
            });
            const localities = LocalitiesAutocompleteDTO.parse(response);

            if (localities.localities.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(mapLocalities(localities));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError);
        }
    }

    async fetchAddressFromGeolocation(
        latitude: number,
        longitude: number,
        apiKey: string,
    ): Promise<Result<SearchGeolocationAddressValue>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/geocode`, {
                key: apiKey,
                latlng: `${latitude},${longitude}`,
            });
            const addressFromGeolocationResponse =
                SearchGeolocationsAddressDTO.parse(response).results.map(mapSearchGeolocationAddress);

            if (addressFromGeolocationResponse.length === 0) {
                return ResultEmpty();
            }

            // Woosmap car return multiple address for one latitude and longitude. We use the first one
            return ResultSuccess(addressFromGeolocationResponse[0]);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError);
        }
    }

    async fetchLocalityDetails(localityId: string, apiKey: string): Promise<Result<LocalityDetails>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/details`, {
                key: apiKey,
                public_id: localityId,
            });

            const localityDetailsDTO = LocalityDetailsDTO.parse(response);

            return ResultSuccess(mapLoyaltyDetails(localityDetailsDTO));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError);
        }
    }
}
