import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { PropsWithClassName } from '../../props';
import styles from './list.module.css';

type GlowListProps<T> = PropsWithClassName & {
    items: T[];
    renderItem: (item: T, index: number) => ReactNode | ReactNode[];
};

export const GlowList = <T,>({ className, items, renderItem }: GlowListProps<T>) => {
    return (
        <ul className={clsx('flex flex-col flex-nowrap ml-1.5', styles.listContainer, className)}>
            {items.map((item, index) => (
                <li className="list-disc" key={`list-${index}`}>
                    {renderItem(item, index)}
                </li>
            ))}
        </ul>
    );
};
