'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { CountrySelectorValue } from '@headless-workspace/core-domain';
import { UIBurgerMenuItem } from '@headless-workspace/core-ui';
import { GlowMenuButton, GlowTextBody, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import {
    BurgerMenuConnectionItem,
    BurgerMenuHeader,
    CountrySwitcher,
    DataLayerClassName,
    LanguageSwitcher,
} from '../../../../client';
import { CategoryValue } from '../categoryNavigation';
import { BurgerMenuDataLayerLabel } from './BurgerMenuDataLayerLabel';

type BurgerMenuProps = PropsWithLocale & {
    categories: CategoryValue[];
    countrySelector?: CountrySelectorValue;
    hostUrl: string | null;
};

const BurgerMenuItem = ({ className, ...props }: Parameters<typeof GlowMenuButton>[0]) => (
    <GlowMenuButton
        dataLayerId={props.dataLayerId}
        className={clsx('bg-background-l2 px-1 text-text-primary fill-text-primary', className)}
        {...props}
    />
);

export const BurgerMenu: Component<BurgerMenuProps> = ({ locale, categories, countrySelector, hostUrl }) => {
    const t = useTranslations('Header');

    return (
        <div className={'flex flex-col gap-0.25 w-sideMenuMobile tablet:w-full bg-background-neutral'}>
            <BurgerMenuHeader />
            <GlowTextBody text={t('navigation.label.account')} className={'p-1'} />
            <BurgerMenuConnectionItem locale={locale} />
            <BurgerMenuItem
                label={t('navigation.link.myFavorites')}
                Icon={Icons.Heart}
                iconFill={'none'}
                dataLayerLabel={BurgerMenuDataLayerLabel.myFavorites}
                className={DataLayerClassName.burgerMenuItem}
                href={buildRoute(locale, 'wishlist')}
            />
            <BurgerMenuItem
                label={t('navigation.link.followOrder')}
                Icon={Icons.Delivery}
                dataLayerLabel={BurgerMenuDataLayerLabel.followOrder}
                className={DataLayerClassName.burgerMenuItem}
                href={buildRoute(locale, 'followOrder')}
            />
            {countrySelector ? (
                <CountrySwitcher
                    className={'bg-background-l2 px-1'}
                    countrySelector={countrySelector}
                    displayType={'burgerMenu'}
                    hostUrl={hostUrl}
                    locale={locale}
                />
            ) : (
                <LanguageSwitcher className={'bg-background-l2 px-1'} displayType={'burgerMenu'} hostUrl={hostUrl} />
            )}
            {categories.length > 0 && (
                <>
                    <GlowTextBody text={t('navigation.label.categories')} className={'p-1'} />
                    {categories
                        .filter((category) => category.showInMenu)
                        .map((category) => {
                            const hasSubCategories = category.subCategories.filter((sub) => sub.showInMenu).length > 0;

                            return (
                                <UIBurgerMenuItem
                                    id={category.id}
                                    hasSubCategories={hasSubCategories}
                                    color={category.color}
                                    key={category.id}
                                    label={category.name}
                                    dataLayerId={category.dataLayerId}
                                    className={hasSubCategories ? '' : DataLayerClassName.categoryNavigation}
                                    href={category.url ?? buildRoute(locale, 'home')}
                                />
                            );
                        })}
                </>
            )}
            <GlowTextBody text={t('navigation.label.others')} className={'p-1'} />
            <BurgerMenuItem
                label={t('navigation.link.storesAndServices')}
                Icon={Icons.Pin}
                href={buildRoute(locale, 'storesAndServices')}
            />

            <BurgerMenuItem
                label={t('navigation.link.helpAndContact')}
                Icon={Icons.Help}
                className={DataLayerClassName.burgerMenuItem}
                dataLayerLabel={BurgerMenuDataLayerLabel.helpAndContact}
                href={buildRoute(locale, 'help')}
            />
        </div>
    );
};
