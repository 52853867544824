import { z } from 'zod';

export const ImageDTO = z
    .object({
        alt: z.string().optional(),
        default: z.string().optional(),
        small: z.string().optional(),
        medium: z.string().optional(),
        large: z.string().optional(),
    })
    .optional();

export type ImageDTO = z.infer<typeof ImageDTO>;
