import React from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowClickable, GlowIcon, GlowIconProps, GlowTextBody } from '../../../../src';

type GlowReinsuranceListItemProps = GlowIconProps & {
    // image: string; // FIXME: replace Icon by image url in the US FHEAD-470
    title: string;
    description: string;
    href: string;
    ctaLabel: string;
};

export const GlowReinsuranceListItem: Component<GlowReinsuranceListItemProps> = ({
    Icon,
    title,
    description,
    href,
    ctaLabel,
}) => {
    return (
        <li className={'flex flex-row gap-0.5 w-full'}>
            <span className={'p-0.375'}>
                <GlowIcon Icon={Icon} type={'large'} className={'text-text-primary'} />
            </span>
            <div className={'flex flex-col gap-0.5'}>
                <div>
                    <GlowTextBody text={title} fontWeight={'bold'} className={'line-clamp-1'} />
                    <GlowTextBody text={description} className={'line-clamp-2'} />
                </div>

                <GlowClickable
                    href={href}
                    content={{
                        labelElement: (
                            <GlowTextBody text={ctaLabel} textDecoration={'underline'} className={'line-clamp-2'} />
                        ),
                    }}
                />
            </div>
        </li>
    );
};
