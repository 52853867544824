const HEADLESS_API_VERSION = 'v1';

const HEADLESS_API_PATH = `headless/api/${HEADLESS_API_VERSION}`;

export const ApiConfig = {
    headless: {
        basePath: '/',
        paths: {
            token: `${HEADLESS_API_PATH}/token`,
            user: `${HEADLESS_API_PATH}/user`,
        },
        endpoints: {
            cookies: 'cookies',
            session: 'session',
            refresh: 'refresh',
        },
    },
} as const;
