import { ReactNode } from 'react';
import { VariantProps } from 'class-variance-authority';
import {
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithCTA,
    PropsWithFontFamily,
    PropsWithIcon,
    PropsWithTestId,
} from '../../../../../src';
import { buttonStyle } from './buttonStyle';
import { iconButtonStyle } from './iconButtonStyle';

export type ButtonBaseProps = PropsWithClassName &
    PropsWithTestId &
    PropsWithCTA &
    Partial<PropsWithAriaLabel> & {
        fill?: string;
        isCTA?: boolean;
    };

export type ButtonProps = Partial<PropsWithIcon> &
    VariantProps<typeof buttonStyle> &
    Partial<PropsWithFontFamily> & {
        label: string;
        isSpinnerActive?: boolean;
    };

export type IconButtonProps = PropsWithIcon &
    VariantProps<typeof iconButtonStyle> & {
        SkeletonIcon?: ReactNode;
        isLoading?: boolean;
    };

export type GlowButtonProps = ButtonBaseProps & (ButtonProps | IconButtonProps);

export const isButtonProps = (props: ButtonProps | IconButtonProps): props is ButtonProps => {
    return (props as ButtonProps).label !== undefined;
};
