import { LocalePrefix } from './LocalePrefix';

export type EnvConfig = {
    headlessVersion: string;
    apiBaseUrl: string;
    apiKey: string;
    baseHref: string;
    hostUrl: string;
    clientApiKey: string;
    cookieDomain: string;
    defaultCachePolicy: RequestCache;
    i18nLocaleDetectionEnabled: boolean;
    i18nLocalePrefix: LocalePrefix;
    runtimeEnvironment: string;
    buildEnvironment: string;
    trackingBaseUrl: string;
    trackingPath: string;
    trackingAnalyticsPath: string;
    trackingMediaPath: string;
    booxiApiKey: string;
    woosmapApiBaseUrl: string;
    woosmapApiKey: string;
    fetchTimeoutMs: number;
    netStorageSecret: string;
    netStorageDomain: string;
    netStorageUploadAccountId: string;
    netStorageCPCode: string;
    netStorageUID: string;
    netStorageENV: string;
    timeMachineEnabled: boolean;
    opentelemetryEnabled: boolean;
    appVersion?: string;
    reachFiveClientId: string;
    reachFiveDomain: string;
    serverEncryptionIV: string;
    serverEncryptionSalt: string;
    serverEncryptionSecret: string;
};

const DEFAULT_CACHE_POLICY: RequestCache = 'no-store';
const DEFAULT_RUNTIME_ENVIRONMENT = 'prod';
const DEFAULT_BUILD_ENVIRONMENT = 'prd';
const DEFAULT_FETCH_TIMEOUT_MS = 30000;
const DEFAULT_BASE_HREF = '';

export const createEnvConfig = (): EnvConfig => {
    return {
        headlessVersion: process.env['NEXT_PUBLIC_HEADLESS_VERSION'],
        apiBaseUrl: process.env['API_BASE_URL'],
        apiKey: process.env['API_KEY'],
        baseHref: process.env['BASE_HREF'] ?? DEFAULT_BASE_HREF,
        hostUrl: process.env['HOST_BASE_URL'],
        clientApiKey: process.env['CLIENT_API_KEY'],
        cookieDomain: process.env['COOKIE_DOMAIN'],
        defaultCachePolicy: process.env['DEFAULT_CACHE_POLICY'] ?? DEFAULT_CACHE_POLICY,
        i18nLocaleDetectionEnabled: process.env['I18N_LOCALE_DETECTION_ENABLED'] === 'true',
        i18nLocalePrefix: (process.env['I18N_LOCALE_PREFIX'] as LocalePrefix) ?? LocalePrefix.Always,
        runtimeEnvironment: process.env['RUNTIME_ENV'] ?? DEFAULT_RUNTIME_ENVIRONMENT,
        buildEnvironment: process.env['ENV_BUILD'] ?? DEFAULT_BUILD_ENVIRONMENT,
        trackingBaseUrl: process.env['TRACKING_BASE_URL'],
        trackingPath: process.env['TRACKING_PATH'],
        trackingAnalyticsPath: process.env['TRACKING_ANALYTICS_PATH'],
        trackingMediaPath: process.env['TRACKING_MEDIA_PATH'],
        booxiApiKey: process.env['BOOXI_API_KEY'],
        woosmapApiBaseUrl: process.env['WOOSMAP_API_BASE_URL'],
        woosmapApiKey: process.env['WOOSMAP_API_KEY'],
        fetchTimeoutMs: Number(process.env['FETCH_TIMEOUT_MS'] ?? DEFAULT_FETCH_TIMEOUT_MS),
        netStorageSecret: process.env['NETSTORAGE_SECRET'],
        netStorageDomain: process.env['NETSTORAGE_DOMAIN'],
        netStorageUploadAccountId: process.env['NETSTORAGE_UPLOAD_ACCOUNT_ID'],
        netStorageCPCode: process.env['NETSTORAGE_CP_CODE'],
        netStorageUID: process.env['NETSTORAGE_UID'],
        netStorageENV: process.env['NETSTORAGE_ENV'],
        timeMachineEnabled: process.env['TIME_MACHINE_ENABLED'] === 'true',
        opentelemetryEnabled: process.env['OPENTELEMETRY_ENABLED'] === 'true',
        appVersion: process.env['NEXT_PUBLIC_APP_VERSION'],
        reachFiveClientId: process.env['REACH_FIVE_CLIENT_ID'],
        reachFiveDomain: process.env['REACH_FIVE_DOMAIN'],
        serverEncryptionIV: process.env['SERVER_ENCRYPTION_IV'],
        serverEncryptionSalt: process.env['SERVER_ENCRYPTION_SALT'],
        serverEncryptionSecret: process.env['SERVER_ENCRYPTION_SECRET'],
    };
};

export const envConfig = createEnvConfig();

export const isServer = (): boolean => !!process.env['NEXT_RUNTIME'];
