'use client';

import { PropsWithChildren, useMemo, useState } from 'react';
import { Component } from '@headless-workspace/typings';
import {
    LayoutActionContext,
    LayoutActionContextType,
    LayoutStateContext,
    LayoutStateContextType,
} from './LayoutContext';

export const LayoutProvider: Component<PropsWithChildren> = ({ children }) => {
    const [headerOffset, setHeaderOffset] = useState(0);

    const layoutStateProviderValue: LayoutStateContextType = useMemo(
        () => ({
            header: {
                headerOffset: headerOffset,
            },
        }),
        [headerOffset],
    );

    const layoutActionProviderValue: LayoutActionContextType = useMemo(
        () => ({
            header: {
                setHeaderOffset: setHeaderOffset,
            },
        }),
        [],
    );

    return (
        <LayoutStateContext.Provider value={layoutStateProviderValue}>
            <LayoutActionContext.Provider value={layoutActionProviderValue}>{children}</LayoutActionContext.Provider>
        </LayoutStateContext.Provider>
    );
};
