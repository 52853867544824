import { CountryCode } from '@headless-workspace/config';
import { FunctionHelpers } from '@headless-workspace/utils';
import { PropsWithIcon } from '../../../props';
import AESvg from './ae.svg?react';
import ATSvg from './at.svg?react';
import BESvg from './be.svg?react';
import BGSvg from './bg.svg?react';
import BHSvg from './bh.svg?react';
import BRSvg from './br.svg?react';
import CASvg from './ca.svg?react';
import CHSvg from './ch.svg?react';
import CNSvg from './cn.svg?react';
import CZSvg from './cz.svg?react';
import DESvg from './de.svg?react';
import DKSvg from './dk.svg?react';
import EESvg from './ee.svg?react';
import ESSvg from './es.svg?react';
import FISvg from './fi.svg?react';
import FRSvg from './fr.svg?react';
import GRSvg from './gr.svg?react';
import HKSvg from './hk.svg?react';
import HRSvg from './hr.svg?react';
import HUSvg from './hu.svg?react';
import IESvg from './ie.svg?react';
import ITSvg from './it.svg?react';
import KWSvg from './kw.svg?react';
import LTSvg from './lt.svg?react';
import LUSvg from './lu.svg?react';
import LVSvg from './lv.svg?react';
import MCSvg from './mc.svg?react';
import NLSvg from './nl.svg?react';
import NOSvg from './no.svg?react';
import PLSvg from './pl.svg?react';
import PTSvg from './pt.svg?react';
import ROSvg from './ro.svg?react';
import SASvg from './sa.svg?react';
import SESvg from './se.svg?react';
import SISvg from './si.svg?react';
import SKSvg from './sk.svg?react';
import TRSvg from './tr.svg?react';
import UASvg from './ua.svg?react';
import UKSvg from './uk.svg?react';
import USSvg from './us.svg?react';

export const getCountryFlagIcon = (countryCode: CountryCode | undefined): PropsWithIcon['Icon'] => {
    if (countryCode === undefined) {
        return FunctionHelpers.nullFn;
    }
    return countryFlagIcons[countryCode] ?? FunctionHelpers.nullFn;
};

export const countryFlagIcons: Record<CountryCode, PropsWithIcon['Icon']> = {
    [CountryCode.AE]: AESvg,
    [CountryCode.AT]: ATSvg,
    [CountryCode.BE]: BESvg,
    [CountryCode.BG]: BGSvg,
    [CountryCode.BH]: BHSvg,
    [CountryCode.BR]: BRSvg,
    [CountryCode.CA]: CASvg,
    [CountryCode.CH]: CHSvg,
    [CountryCode.CN]: CNSvg,
    [CountryCode.CZ]: CZSvg,
    [CountryCode.DE]: DESvg,
    [CountryCode.DK]: DKSvg,
    [CountryCode.EE]: EESvg,
    [CountryCode.ES]: ESSvg,
    [CountryCode.FI]: FISvg,
    [CountryCode.FR]: FRSvg,
    [CountryCode.GB]: UKSvg,
    [CountryCode.GR]: GRSvg,
    [CountryCode.HK]: HKSvg,
    [CountryCode.HR]: HRSvg,
    [CountryCode.HU]: HUSvg,
    [CountryCode.IE]: IESvg,
    [CountryCode.IT]: ITSvg,
    [CountryCode.KW]: KWSvg,
    [CountryCode.LT]: LTSvg,
    [CountryCode.LU]: LUSvg,
    [CountryCode.LV]: LVSvg,
    [CountryCode.MC]: MCSvg,
    [CountryCode.NL]: NLSvg,
    [CountryCode.NO]: NOSvg,
    [CountryCode.PL]: PLSvg,
    [CountryCode.PT]: PTSvg,
    [CountryCode.RO]: ROSvg,
    [CountryCode.SA]: SASvg,
    [CountryCode.SE]: SESvg,
    [CountryCode.SI]: SISvg,
    [CountryCode.SK]: SKSvg,
    [CountryCode.TR]: TRSvg,
    [CountryCode.UA]: UASvg,
    [CountryCode.US]: USSvg,
};
