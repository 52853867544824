import { z } from 'zod';

export const MediaDTO = z.object({
    type: z.union([z.literal('IMAGE'), z.literal('VIDEO')]),
    url: z.string(),
    alt: z.string().optional(),
    title: z.string().optional(),
});

export type MediaDTO = z.infer<typeof MediaDTO>;
