'use client';

import { ReactNode, useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { COUNTRY_SWITCHER_MENU_ID, Position } from '@headless-workspace/config';
import { SideMenuModal, useUIContext } from '@headless-workspace/core-ui';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { CountryValue } from '../../../server';
import { DataLayerEvent, useDataLayer } from '../dataLayer';
import { LocaleSwitcherBurgerMenuButton } from './LocaleSwitcherBurgerMenuButton';
import { LocaleSwitcherHeaderButton } from './LocaleSwitcherHeaderButton';
import { LocaleSwitcherMenuDataLayerParams } from './LocaleSwitcherMenuDataLayerParams';

export type CommonLocaleSwitcherButton = PropsWithClassName & {
    countries: CountryValue[];
};

export type LocaleSwitcherButtonProps = CommonLocaleSwitcherButton &
    PropsWithClassName &
    LocaleSwitcherMenuDataLayerParams & {
        menu: ReactNode | ReactNode[];
        displayType?: 'header' | 'burgerMenu';
        showLanguage?: boolean;
        hostUrl: string | null;
    };

/**
 * Component that renders a button to open the language/country switcher menu.
 * @param countries - List of countries to display.
 * @param menu - Menu to display when the button is clicked.
 * @param className - Additional styles to apply to the button.
 * @param displayType - Type (from a visual point of view) of button to display. Can be 'header' or 'burgerMenu', default is 'header'.
 * @param showLanguage - Whether to show the language name in the button.
 * @param hostUrl - Current host URL.
 * @param dataLayer - Data layer parameters.
 */
export const LocaleSwitcherButton: Component<LocaleSwitcherButtonProps> = ({
    countries,
    menu,
    className,
    displayType = 'header',
    showLanguage,
    hostUrl,
    dataLayer,
}) => {
    const {
        menuModal: { addAndOpen: addAndOpenMenu },
    } = useUIContext();
    const { trackEvent } = useDataLayer();

    const t = useTranslations('Common.country');
    const countryName = t('name');

    const onOpenMenu = useCallback(() => {
        addAndOpenMenu(
            COUNTRY_SWITCHER_MENU_ID,
            SideMenuModal(
                Position.Right,
                { default: menu },
                'flex flex-col w-full tablet:w-sideMenuWithScrollbarTablet',
            ),
        );
        trackEvent(DataLayerEvent.Click, {
            category: dataLayer.category,
            action: dataLayer.action,
            event_type: 'header',
        });
    }, [addAndOpenMenu, dataLayer.action, dataLayer.category, menu, trackEvent]);

    switch (displayType) {
        case 'burgerMenu':
            return (
                <LocaleSwitcherBurgerMenuButton
                    className={className}
                    countries={countries}
                    countryLabel={showLanguage ? t('nameWithLanguage') : countryName}
                    onOpenMenu={onOpenMenu}
                    hostUrl={hostUrl}
                />
            );

        case 'header':
            return (
                <LocaleSwitcherHeaderButton
                    className={className}
                    countries={countries}
                    showLanguage={showLanguage}
                    onOpenMenu={onOpenMenu}
                    hostUrl={hostUrl}
                />
            );
    }
};
