export type RedDotValue = Readonly<EnableRedDot>;

export type EnableRedDot = {
    enable?: boolean;
    redDotText?: string;
};

export const mapRedDotValue = (enable?: boolean, redDotText?: string): RedDotValue => {
    return {
        enable,
        redDotText,
    };
};
