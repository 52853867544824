import { useCallback } from 'react';
import { useSessionStorage } from 'usehooks-ts';

export const SESSION_STORAGE_KEY = 'IS_SITE_PREVIEW_CLOSED';

export const useSitePreviewStorage = () => {
    const [isSitePreviewClosed, setIsSitePreviewClosed] = useSessionStorage(SESSION_STORAGE_KEY, false);

    const closeSitePreview = useCallback(() => setIsSitePreviewClosed(true), [setIsSitePreviewClosed]);

    return { isSitePreviewClosed, closeSitePreview };
};
