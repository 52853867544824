import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { cva, VariantProps } from 'class-variance-authority';
import { GlowIcon, GlowTypography, IconType, PropsWithFontFamily } from '../../../../src';
import { getIcon, IconName } from '../../res/icons';

export type TagSize = 'small' | 'medium' | 'large';
export type TagType = 'success' | 'warning' | 'error' | 'info' | 'neutral' | 'marketing';

type FontSize = 'caption' | 'bodySmall';

const typeStyles = cva('rounded-0.25 flex items-center gap-0.25 w-fit', {
    variants: {
        type: {
            success: 'bg-background-success text-text-success',
            warning: 'bg-background-warning text-text-warning',
            error: 'bg-background-error-red text-text-error',
            info: 'bg-background-infos text-text-infos',
            neutral: 'bg-background-neutral text-text-secondary',
            marketing: 'bg-background-inverse text-text-primary-inverse',
        },
        size: {
            small: 'px-0.25',
            medium: 'p-0.25',
            large: 'px-0.5 py-0.375',
        },
    },
    defaultVariants: {
        type: 'marketing',
    },
});

const sizeStyles: Record<TagSize, { fontSize: FontSize; iconSize?: IconType }> = {
    small: {
        fontSize: 'caption',
    },
    medium: {
        fontSize: 'caption',
        iconSize: 'small',
    },
    large: {
        fontSize: 'bodySmall',
        iconSize: 'medium',
    },
};

export type GlowTagProps = VariantProps<typeof typeStyles> &
    Partial<PropsWithFontFamily> & {
        label: string;
        type: TagType;
    } & ({ size: Exclude<TagSize, 'small'>; iconName?: IconName } | { size: Extract<TagSize, 'small'> });

/**
 * Component called Tag on FIGMA
 * This component renders a tag with customizable label, type, size, and optional icon.
 *
 * @component
 * @param {GlowTagProps} props - The properties object for the GlowTag component.
 * @param {string} props.label - The text label to be displayed within the tag.
 * @param {TagType} props.type - The type of the tag, which determines the color scheme.
 * Possible values include "success", "warning", "error", "info", "neutral", and "marketing".
 * @param {TagSize} props.size - The size of the tag, which affects the font size and padding.
 * Possible values include "small", "medium", and "large".
 * @param {IconName} [props.iconName] - The name of the icon to be displayed within the tag.
 * This is optional and will only be displayed if provided and the size is not "small".
 *
 * @returns {JSX.Element} A JSX element representing the styled tag.
 *
 * @example
 * <GlowTag label="New" type="success" size="small" />
 */

export const GlowTag: Component<GlowTagProps> = ({ label, type, size = 'large', fontFamily = 'brand', ...props }) => {
    const { fontSize, iconSize } = sizeStyles[size];

    return (
        <div className={clsx('rounded-0.25 flex items-center gap-0.25 w-fit', typeStyles({ type, size }))}>
            {'iconName' in props && <GlowIcon Icon={getIcon(props.iconName)} type={iconSize} />}
            <GlowTypography
                text={label}
                fontSize={fontSize}
                className={'text-inherit'}
                fontWeight={'bold'}
                fontFamily={fontFamily}
            />
        </div>
    );
};
