import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableBannerCard, ComposableBannerCardProps, ComposableCard } from '../../composables';

/**
 * GlowBrandCorner component.
 *
 * This component renders a banner card with a title, label, content, and a button.
 *
 * @param {ComposableBannerCardProps} props - The properties for the GlowBrandCorner component.
 * @param {string} props.title - The title of the banner.
 * @param {string} props.label - The label of the banner.
 * @param {string} props.content - The content of the banner.
 * @param {object} props.button - The button properties.
 * @param {object} props.fontFamily - The fontFamily properties.
 */
export const GlowBrandCorner: Component<ComposableBannerCardProps> = ({
    className,
    title,
    label,
    content,
    button,
    fontFamily,
}: ComposableBannerCardProps) => {
    return (
        <ComposableBannerCard.Container className={clsx('flex-1 my-auto', className)}>
            <ComposableBannerCard.MainBlock className={'p-1 gap-1'}>
                <ComposableCard.Wrapper className={'flex flex-col gap-0.5'}>
                    <ComposableBannerCard.Title
                        content={title}
                        fontSize={'title4'}
                        fontWeight={'bold'}
                        className={'line-clamp-2'}
                        TagName={'h2'}
                        innerHTML
                        fontFamily={fontFamily}
                    />
                    <ComposableBannerCard.Content
                        label={label}
                        content={content}
                        className={'line-clamp-3 desktopS:line-clamp-2'}
                        text
                        fontFamily={fontFamily}
                    />
                </ComposableCard.Wrapper>
                <ComposableBannerCard.Button fontFamily={fontFamily} button={button} width={'fit'} />
            </ComposableBannerCard.MainBlock>
        </ComposableBannerCard.Container>
    );
};
