'use client';

import React, { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useFixedHeader, useIsSearchOpen, useTailwindBreakpoint } from '../../hooks';

type UIHeaderShellProps = PropsWithChildren &
    PropsWithClassName & {
        topBannerChildren?: ReactNode | ReactNode[];
        fixedChildren?: ReactNode | ReactNode[];
    };

export const UIHeaderShell: Component<UIHeaderShellProps> = ({
    className,
    topBannerChildren,
    fixedChildren,
    children,
}) => {
    const fixedHeaderRef = useRef<HTMLDivElement>(null);
    const persistentBannerRef = useRef<HTMLDivElement>(null);
    const isDesktop = useTailwindBreakpoint('desktop');
    const isTablet = useTailwindBreakpoint('tablet');
    const isSearchOpen = useIsSearchOpen();
    const { translate, isDesktopHeaderVisible } = useFixedHeader(
        fixedHeaderRef,
        persistentBannerRef,
        isDesktop,
        isSearchOpen,
    );

    useEffect(() => {
        if (isTablet && !isDesktop) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [isDesktop, isSearchOpen, isTablet]);

    return (
        <header className={clsx('contents flex-col bg-background-l0 mx-auto w-full', className)}>
            {isSearchOpen && (
                <div
                    id={'animation-close-fixed-search'}
                    className={
                        'tablet:hidden fixed h-fullViewport w-full bg-background-l0 delay-fast2 duration-moderate2 opacity-0'
                    }
                />
            )}
            <div
                className={clsx(isDesktop && isSearchOpen && !isDesktopHeaderVisible && 'hidden')}
                ref={persistentBannerRef}
            >
                {topBannerChildren}
            </div>
            <div
                ref={fixedHeaderRef}
                className={clsx(
                    'flex flex-col',
                    'h-headerFixedMobile tablet:h-headerFixedTablet desktop:h-headerDesktop',
                    'sticky desktop:relative tablet:z-headerFixed',
                    !isDesktopHeaderVisible && 'desktop:!fixed desktop:left-0 desktop:right-0',
                    'bg-background-l0',
                    (!isDesktopHeaderVisible || isSearchOpen) && 'sticky',
                    !isSearchOpen && 'top-0 z-headerFixed',
                    'border-b-medium border-solid border-b-border-primary',
                )}
                style={
                    isDesktop && !isDesktopHeaderVisible
                        ? { transform: translate }
                        : { transform: 'translate(0rem, 0rem)' }
                }
            >
                {fixedChildren}
            </div>
            <div className={clsx('bg-background-l0 relative')}>{children}</div>
            {isSearchOpen && (
                <div className={'fixed w-fullViewport h-fullViewport -top-1 tablet:top-auto z-searchOpenOverlay'} />
            )}
        </header>
    );
};
