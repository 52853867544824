export const tr_TR_NavRoutes = {
    home: '',
    connection: 'giris-yap',
    storesAndServices: 'magazalar-bul',
    wishlist: 'oneri-listesi',
    basket: 'sepetim',
    payment: '', // FIXME: To Update
    countrySwitcher: '',
    offers: '',
    account: 'hesabım',
    orders: 'siparis-gecmisi',
    followOrder: 'siparis-gecmisi?isGuestOrderTracking=true',
    loyalty: 'sephora-kartim.html',
    profile: 'hesap-olustur',
    help: 'bize-ulasin',
    allServices: 'sephoraservis.html',
    storeInfo: 'magazalar-hakkında',
    mail: '',
    allStores: 'magazalar-bul',
    beautyTips: 'sephora-kampanyalar.html',
    countries: 'international.html',
    logout: 'cıkıs-yap?redirectToReferer=true',
    allBrands: 'a-dan-z-ye-markalar',
    clickAndCollect: 'tikla-magazadan-al',
    securePayment: 'guvenli-odeme',
    returnProduct: 'guvenli-odeme',
    privacyPolicy: 'KVK-politikasi.html',
    loyaltyProgram: '',
    search: 'ara?q=',
    reviewConditions: '',
};
