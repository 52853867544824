import { CountryCode } from './CountryCode';

export const DEFAULT_LOCALE = 'fr-FR';
export const DEFAULT_COUNTRY = CountryCode.FR;

export const SUPPORTED_LOCALES = {
    DEFAULT: DEFAULT_LOCALE,
    de_DE: 'de-DE',
    de_CH: 'de-CH',
    fr_CH: 'fr-CH',
    tr_TR: 'tr-TR',
    da_DK: 'da-DK',
    sv_SE: 'sv-SE',
    el_GR: 'el-GR',
    ro_RO: 'ro-RO',
    pl_PL: 'pl-PL',
    cs_CZ: 'cs-CZ',
    pt_PT: 'pt-PT',
    es_ES: 'es-ES',
    it_IT: 'it-IT',
} as const;

export const SUPPORTED_LANGUAGES = {
    de: 'de',
    fr: 'fr',
} as const;

export const SUPPORTED_TLDS = {
    de: 'de',
    fr: 'fr',
    ch: 'ch',
    tr: 'tr',
    dk: 'dk',
    se: 'se',
    gr: 'gr',
    ro: 'ro',
    pl: 'pl',
    cz: 'cz',
    pt: 'pt',
    es: 'es',
    it: 'it',
} as const;

export const DEFAULT_DOMAIN_CONFIG_MAP = {
    defaultLocale: SUPPORTED_LOCALES.DEFAULT,
    languages: [],
};

export const DOMAIN_CONFIG_MAP = {
    [SUPPORTED_TLDS.fr]: DEFAULT_DOMAIN_CONFIG_MAP,
    [SUPPORTED_TLDS.de]: {
        defaultLocale: SUPPORTED_LOCALES.de_DE,
        languages: [],
    },
    [SUPPORTED_TLDS.ch]: {
        defaultLocale: SUPPORTED_LOCALES.de_CH,
        languages: [SUPPORTED_LANGUAGES.de, SUPPORTED_LANGUAGES.fr],
    },
    [SUPPORTED_TLDS.tr]: {
        defaultLocale: SUPPORTED_LOCALES.tr_TR,
        languages: [],
    },
    [SUPPORTED_TLDS.dk]: {
        defaultLocale: SUPPORTED_LOCALES.da_DK,
        languages: [],
    },
    [SUPPORTED_TLDS.se]: {
        defaultLocale: SUPPORTED_LOCALES.sv_SE,
        languages: [],
    },
    [SUPPORTED_TLDS.gr]: {
        defaultLocale: SUPPORTED_LOCALES.el_GR,
        languages: [],
    },
    [SUPPORTED_TLDS.ro]: {
        defaultLocale: SUPPORTED_LOCALES.ro_RO,
        languages: [],
    },
    [SUPPORTED_TLDS.pl]: {
        defaultLocale: SUPPORTED_LOCALES.pl_PL,
        languages: [],
    },
    [SUPPORTED_TLDS.cz]: {
        defaultLocale: SUPPORTED_LOCALES.cs_CZ,
        languages: [],
    },
    [SUPPORTED_TLDS.pt]: {
        defaultLocale: SUPPORTED_LOCALES.pt_PT,
        languages: [],
    },
    [SUPPORTED_TLDS.es]: {
        defaultLocale: SUPPORTED_LOCALES.es_ES,
        languages: [],
    },
    [SUPPORTED_TLDS.it]: {
        defaultLocale: SUPPORTED_LOCALES.it_IT,
        languages: [],
    },
};

export const locales = Object.values({ ...SUPPORTED_LOCALES, ...SUPPORTED_LANGUAGES });
export const localesWithoutLanguages = Object.values(SUPPORTED_LOCALES);

export type Locale = (typeof SUPPORTED_LOCALES)[keyof typeof SUPPORTED_LOCALES];
export type Language = (typeof SUPPORTED_LANGUAGES)[keyof typeof SUPPORTED_LANGUAGES];

const UNLIMITED_LOCALES = [SUPPORTED_LOCALES.cs_CZ, SUPPORTED_LOCALES.pl_PL, SUPPORTED_LOCALES.de_DE] as const;

export type UnlimitedLocale = (typeof UNLIMITED_LOCALES)[number];
export type StandardLocale = Exclude<Locale, UnlimitedLocale>;
export type CountryKeyValue<T> = {
    [key: string]: T;
};

export function isStandardLoyaltyLocale(locale: Locale): locale is StandardLocale {
    return !UNLIMITED_LOCALES.includes(locale as UnlimitedLocale);
}

export function isUnlimitedLoyaltyLocale(locale: Locale): locale is UnlimitedLocale {
    return UNLIMITED_LOCALES.includes(locale as UnlimitedLocale);
}
