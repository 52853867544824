import {
    CountryCode,
    DEFAULT_COUNTRY,
    CountryKeyValue,
    Locale,
    Language,
    SUPPORTED_LOCALES,
    SUPPORTED_LANGUAGES,
} from '@headless-workspace/config';

const LOCALE_SEPARATOR = '-';

/**
 * Parse a locale string to retrieve the language
 * Example : `fr-FR` will be parsed to `fr`, `es-ES` will be parsed to `es`
 * @param {string} locale
 * @return {string} language
 */
const parseLanguage = (locale: Locale): string => {
    const [lang] = locale.split(LOCALE_SEPARATOR);
    return lang;
};

/**
 * Parse a locale string to retrieve the country code
 * Example : `fr-FR` will be parsed to `FR`, fr-ES will be parsed to `ES`
 * @param {string} locale
 * @return {CountryCode} country code
 */
const parseCountry = (locale: Locale): CountryCode => {
    const [, country] = locale.split(LOCALE_SEPARATOR);
    return (country as CountryCode) ?? DEFAULT_COUNTRY;
};

const retrieveDataByCountry = <T>(json: CountryKeyValue<T>, locale: Locale): T | null => {
    if (!json || Object.keys(json).length === 0) {
        return null;
    }
    const countryCode = parseCountry(locale);

    if (json[countryCode]) {
        return json[countryCode];
    }

    const language = parseLanguage(locale);

    if (json[language]) {
        return json[language];
    }

    return null;
};

const mapLocaleToLanguage = (locale: Locale): string => {
    const localeMapping: Record<string, string> = {
        [SUPPORTED_LOCALES.fr_CH]: SUPPORTED_LANGUAGES.fr,
        [SUPPORTED_LOCALES.de_CH]: SUPPORTED_LANGUAGES.de,
    };

    return localeMapping[locale as string] || '';
};

export const getMappedLocale = (headers: Headers, locale: Locale | Language): Locale => {
    const host = `${headers.get('x-forwarded-host')}`;

    if (host.endsWith('.ch')) {
        const localeMapping: Record<string, string> = {
            [SUPPORTED_LANGUAGES.fr]: SUPPORTED_LOCALES.fr_CH,
            [SUPPORTED_LANGUAGES.de]: SUPPORTED_LOCALES.de_CH,
        };

        return (localeMapping[locale] as Locale) || locale;
    }

    return locale as Locale;
};

export const LanguageHelpers = {
    parseLanguage,
    parseCountry,
    retrieveDataByCountry,
    mapLocaleToLanguage,
    getMappedLocale,
};
