import { DefaultValueConfig } from '@headless-workspace/config';
import { MediaDTO } from './MediaDTO';

export enum MediaType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export type MediaValue = {
    type: MediaType;
    url: string;
    src: string;
    alt: string;
    id?: string;
    title?: string;
};

export const isImage = (media: MediaValue): boolean => {
    return media.type === MediaType.IMAGE;
};

export const isVideo = (media: MediaValue | MediaDTO): boolean => {
    return media.type === MediaType.VIDEO;
};

export const getYoutubeImgUrl = (url: string) => `https://img.youtube.com/vi/${getYoutubeId(url)}/hqdefault.jpg`;

export const getYoutubeId = (url: string) => {
    return /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?|]*).*/.exec(
        url,
    )?.[1];
};

export const mapMedia = (dto: MediaDTO): MediaValue => {
    return {
        type: dto.type as MediaType,
        url: isVideo(dto) ? `https://www.youtube.com/embed/${getYoutubeId(dto.url)}?enablejsapi=1` : dto.url,
        alt: dto.alt ?? DefaultValueConfig.alt,
        title: dto.title,
        src: isVideo(dto) ? getYoutubeImgUrl(dto.url) : dto.url,
        id: isVideo(dto) ? getYoutubeId(dto.url) : undefined,
    };
};
