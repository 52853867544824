import { z } from 'zod';

export const FavoriteStoreCookieDTO = z.object({
    id: z.string(),
    name: z.string(),
    status: z.enum(['open', 'closed']),
    message: z.string().optional(),
});

export type FavoriteStoreCookieDTO = z.infer<typeof FavoriteStoreCookieDTO>;
