'use client';

import { PropsWithChildren, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Component, Node } from '@headless-workspace/typings';
import { VariantProps } from 'class-variance-authority';
import {
    GlowIcon,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTypography,
    GlowTypographyProps,
    typographyStyle,
} from '../atoms';
import { GlowButton, GlowClickable } from '../molecules';
import { buttonStyle } from '../molecules/cta/buttons/buttonStyle';
import { PropsWithClassName, PropsWithFontFamily } from '../props';
import { Icons } from '../res/icons';
import { extractContent } from '../utils';

export enum BannerType {
    Main = 'main',
    Under = 'under',
    BrandCorner = 'brandCorner',
    GWP = 'GWP',
}

export type BannerCTA = {
    label: string;
    href: string;
};

type BannerCardTagsProps = {
    tags?: BannerCTA[];
};

type BannerCardContainerProps = PropsWithClassName & PropsWithChildren;

export type ComposableBannerCardProps = PropsWithClassName &
    Partial<PropsWithFontFamily> & {
        title?: ReactNode;
        subTitle?: ReactNode;
        label?: ReactNode;
        content?: Node;
        legalMention?: ReactNode;
        button?: BannerCTA;
        tags?: BannerCTA[];
        accordion?: AccordionProps;
    };

/**
 * A container component for the BannerCard.
 *
 * @param {BannerCardContainerProps} props - The properties for the BannerCard container.
 * @param {string} [props.className] - Additional class names to apply to the container.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the container.
 *
 * @returns {JSX.Element} The rendered container component.
 */
const Container: Component<BannerCardContainerProps> = ({ className, children }) => {
    return <div className={clsx('flex w-full flex-col items-start gap-y-0.5', className)}>{children}</div>;
};

type BannerCardHeaderContentProps = PropsWithChildren & PropsWithClassName;

/**
 * Header component for the BannerCard.
 *
 * @param {BannerCardHeaderContentProps} props - The properties for the Header component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the Header.
 * @param {string} [props.className] - Additional class names to apply to the Header component.
 * @returns {JSX.Element} The rendered Header component.
 */
const MainBlock: Component<BannerCardHeaderContentProps> = ({ children, className }) => {
    return <div className={clsx('flex flex-col w-full rounded-0.5 bg-background-l2', className)}>{children}</div>;
};

type BannerCardTitleProps = GlowTypographyProps & {
    content: ReactNode;
    fontFamily?: VariantProps<typeof typographyStyle>['fontFamily'];
};

/**
 * Component that renders a title and subtitle within a banner card.
 *
 * @param {BannerCardTitleProps} props - The properties for the BannerCardHeader component.
 * @param {React.ReactNode} props.title - The element containing the title content.
 * @param {React.ReactNode} props.subTitle - The element containing the subtitle content.
 * @param {string} [props.fontSize] - The font size for the title.
 * @param {string} [props.fontWeight] - The font weight for the title.
 * @param {React.ElementType} [props.TagName] - The HTML tag to use for the title.
 * @param {string} [props.className] - Additional CSS classes to apply to the container.
 *
 */
const Title: Component<BannerCardTitleProps> = ({ content, fontSize, fontWeight, TagName, className, fontFamily }) => {
    const titleElement = extractContent(content);
    if (!titleElement) {
        return null;
    }
    return (
        <GlowTypography
            className={className}
            innerHTML={content}
            fontSize={fontSize}
            fontWeight={fontWeight}
            TagName={TagName}
            fontFamily={fontFamily}
        />
    );
};

type BannerCardSubTitleProps = {
    content: ReactNode;
    fontFamily?: VariantProps<typeof typographyStyle>['fontFamily'];
};
export const SubTitle: Component<BannerCardSubTitleProps> = ({ content, fontFamily }) => {
    const subTitleElement = extractContent(content);

    if (!subTitleElement) {
        return null;
    }

    return (
        <GlowTextBody
            className={'hidden desktopS:line-clamp-1'}
            innerHTML={content}
            fontWeight={'bold'}
            TagName={'div'}
            fontFamily={fontFamily}
        />
    );
};

type BannerCardContentProps = GlowTypographyProps & {
    label?: ReactNode;
    content?: Node;
    fontFamily?: VariantProps<typeof typographyStyle>['fontFamily'];
};

/**
 * A functional component that renders a banner card content.
 *
 * @component
 * @param {BannerCardContentProps} props - The properties for the BannerCard content.
 * @param {React.ReactNode} props.labelElement - The label element to be displayed.
 * @param {React.ReactNode} props.contentElement - The content element to be displayed.
 */
const Content: Component<BannerCardContentProps> = ({
    label,
    content,
    fontSize = 'body',
    fontWeight,
    TagName = 'div',
    className,
    fontFamily,
}: BannerCardContentProps) => {
    return (
        <div className={'flex flex-row gap-0.75 items-center'}>
            {extractContent(label) && (
                <div
                    className={clsx(
                        'flex flex-col justify-center items-center text-center',
                        'h-homeBannerLabel w-homeBannerLabel',
                        'bg-background-neutral rounded-0.25',
                        'px-0.75',
                        'line-clamp-1',
                        'font-brand font-bold',
                        'text-text-primary',
                    )}
                >
                    {label}
                </div>
            )}
            <GlowTypography
                className={clsx('flex-1', className)}
                innerHTML={content}
                fontSize={fontSize}
                fontWeight={fontWeight}
                TagName={TagName}
                fontFamily={fontFamily}
            />
        </div>
    );
};

type BannerCardButtonProps = VariantProps<typeof buttonStyle> &
    PropsWithClassName & {
        button?: BannerCTA;
        fontFamily?: VariantProps<typeof typographyStyle>['fontFamily'];
    };

/**
 * Button component that renders a `GlowButton` if the `button` prop is provided.
 *
 * @param {BannerCardButtonProps} props - The properties for the Button component.
 * @param {object} props.button - The button object containing label and href.
 * @param {string} props.button.label - The label for the button.
 * @param {string} props.button.href - The URL the button links to.
 * @param {string} [props.variant='secondary'] - The variant of the button, defaults to 'secondary'.
 */
const Button: Component<BannerCardButtonProps> = ({
    button,
    variant = 'secondary',
    size = 'large',
    width = 'auto',
    className,
    fontFamily,
}: BannerCardButtonProps) => {
    return (
        button && (
            <GlowButton
                fontFamily={fontFamily}
                className={className}
                {...button}
                variant={variant}
                size={size}
                width={width}
            />
        )
    );
};

type BannerCardMentionLabelProps = PropsWithChildren & {
    classNames?: {
        empty?: string;
        noEmpty?: string;
    };
};

/**
 * A component that conditionally renders its children based on their content.
 * If the children have content, it renders them inside a span with a specified class name.
 * If the children are empty, it renders an empty span with a different class name.
 *
 * @param {BannerCardMentionLabelProps} props - The properties for the component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the span.
 * @param {Object} [props.classNames] - An optional object containing class names.
 * @param {string} [props.classNames.noEmpty] - The class name to apply when children have content.
 * @param {string} [props.classNames.empty] - The class name to apply when children are empty.
 */
const MentionLabel: Component<BannerCardMentionLabelProps> = ({
    children,
    classNames = {},
}: BannerCardMentionLabelProps) => {
    const { noEmpty, empty } = classNames;

    return extractContent(children) ? <span className={noEmpty}>{children}</span> : <span className={empty} />;
};

/**
 * Component that renders a list of tags as buttons.
 *
 * @component
 * @param {BannerCardTagsProps} props - The properties for the Tags component.
 * @param {Array<{ label: string, href: string }>} props.tags - An array of tag objects, each containing a label and href.
 */
const Tags: Component<BannerCardTagsProps> = ({ tags }: BannerCardTagsProps) => {
    if (!tags) {
        return null;
    }

    return (
        <div
            className={clsx(
                'flex flex-row tablet:flex-wrap',
                'max-w-mainBannerFastAccessLinks tablet:max-w-mainBannerFastAccessLinksTablet desktopS:max-w-none',
                '-ml-1 tablet:ml-0',
                'gap-0.5',
                'scrollbar-hide overflow-x-scroll',
            )}
        >
            {tags.map(({ label, href }, index) => (
                <GlowButton
                    key={`${label}-${index}`}
                    className={'first:ml-1 last:mr-1 tablet:first:ml-0 tablet:last:mr-0'}
                    label={label}
                    href={href}
                    variant={'onElement'}
                    size={'medium'}
                    fontSize={'bodySmall'}
                />
            ))}
        </div>
    );
};

type AccordionProps = {
    title: string;
    content: ReactNode;
};

type BannerCardAccordionProps = {
    accordion?: AccordionProps;
};

/**
 * Accordion component that displays a clickable header and expandable content.
 *
 * @component
 * @param {BannerCardAccordionProps} props - The properties for the Accordion component.
 * @param {Object} props.accordion - The accordion data.
 * @param {string} props.accordion.title - The title of the accordion.
 * @param {string} props.accordion.description - The description of the accordion.
 *
 * @example
 * <Accordion accordion={{ title: 'Accordion Title', description: 'Accordion Description' }} />
 */
const Accordion: Component<BannerCardAccordionProps> = ({ accordion }: BannerCardAccordionProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    if (!accordion) {
        return null;
    }

    return (
        <div className={'flex flex-col gap-0.125 mt-0.25'}>
            <GlowClickable
                onClick={toggleExpand}
                content={{
                    labelElement: (
                        <>
                            <GlowTextBodySmall text={accordion.title} className={'text-wrap'} TagName={'span'} />
                            <GlowIcon
                                Icon={Icons.ChevronDown}
                                type={'medium'}
                                className={clsx('duration-moderate1 ease-linear', isExpanded && 'rotate-180')}
                            />
                        </>
                    ),
                }}
            />
            {isExpanded && <GlowTextBodySmall text={accordion.content} className={'text-wrap'} color={'tertiary'} />}
        </div>
    );
};

export const ComposableBannerCard = {
    Container,
    MainBlock,
    Title,
    SubTitle,
    Content,
    Button,
    MentionLabel,
    Tags,
    Accordion,
};
