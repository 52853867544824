'use client';

import { GlowBrandCorner, CardBannerProps, GenericCard, PropsWithFontFamily } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';

type UIBrandCornerProps = CardBannerProps & PropsWithFontFamily;

export const UIBrandCorner: Component<UIBrandCornerProps> = (props) => {
    return (
        <GenericCard
            {...props}
            classNames={{
                container: 'flex-col tablet:flex-row tablet:items-start rounded-0.5 overflow-hidden',
                imgWrapper: 'w-full tablet:w-brandCornerImg aspect-16/9',
                cta: ' w-full tablet:w-brandCornerImg',
            }}
            sizes={`(max-width: ${tailwindTheme.screens.tablet}) 100vw, ${tailwindTheme.width.brandCornerImg}`}
            fill
        >
            <GlowBrandCorner {...props} />
        </GenericCard>
    );
};
