import React from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowSkeleton } from '../../atoms';

export const GlowDropDownSearchSkeleton: Component = () => {
    return (
        <div className={'top-full w-full flex flex-col flex-1 gap-y-1 p-0.75 bg-background-l1'}>
            <GlowSkeleton width={'70%'} height={'1.5rem'} />
            <GlowSkeleton width={'80%'} height={'1.5rem'} />
            <GlowSkeleton width={'40%'} height={'1.5rem'} />
            <GlowSkeleton width={'60%'} height={'1.5rem'} />
        </div>
    );
};
