import { PredicateHelpers, UrlHelpers } from '@headless-workspace/utils';
import { DynamicHrefValue, mapDynamicHref } from './DynamicHrefValue';
import { DynamicIconValue, mapDynamicIcon } from './DynamicIconValue';
import { HeaderConnectionBannerItemDTO } from './HeaderConnectionBannerItemDTO';

export enum HeaderConnectionBannerItemId {
    MyAccount = 'myAccount',
    MyOrders = 'myOrders',
    FollowMyOrder = 'followMyOrder',
    MyLoyaltyProgram = 'myLoyaltyProgram',
    MyOffers = 'myOffers',
    MakeAnAppointment = 'makeAnAppointment',
    MySettings = 'mySettings',
    MyMessages = 'myMessages',
    Help = 'help',
    MyNews = 'myNews',
}

const mapHeaderConnectionBannerItemId = (id: string): HeaderConnectionBannerItemId | null => {
    switch (id) {
        case 'myAccount':
            return HeaderConnectionBannerItemId.MyAccount;
        case 'myOrders':
            return HeaderConnectionBannerItemId.MyOrders;
        case 'followMyOrder':
            return HeaderConnectionBannerItemId.FollowMyOrder;
        case 'myLoyaltyProgram':
            return HeaderConnectionBannerItemId.MyLoyaltyProgram;
        case 'myOffers':
            return HeaderConnectionBannerItemId.MyOffers;
        case 'makeAnAppointment':
            return HeaderConnectionBannerItemId.MakeAnAppointment;
        case 'mySettings':
            return HeaderConnectionBannerItemId.MySettings;
        case 'myMessages':
            return HeaderConnectionBannerItemId.MyMessages;
        case 'help':
            return HeaderConnectionBannerItemId.Help;
        case 'myNews':
            return HeaderConnectionBannerItemId.MyNews;
        default:
            return null;
    }
};

export type HeaderConnectionBannerItemValue = {
    id: HeaderConnectionBannerItemId;
    label: string;
    ariaLabel: string;
    iconName: string;
    href?: string;
    isObfuscated: boolean;
    dataLayerLabel?: string;
    dynamicOverlayIcons?: DynamicIconValue[];
    dynamicHref?: DynamicHrefValue[];
};

export const mapHeaderConnectionBannerItem = (
    dto: HeaderConnectionBannerItemDTO,
): HeaderConnectionBannerItemValue | null => {
    const id = mapHeaderConnectionBannerItemId(dto.id);
    if (!id) {
        return null;
    }

    return {
        id,
        label: dto.label,
        ariaLabel: dto.ariaLabel,
        iconName: dto.iconName,
        href: UrlHelpers.createPathFromEnv(dto.url),
        isObfuscated: dto.isObfuscated,
        dataLayerLabel: dto.dataLayerLabel,
        dynamicOverlayIcons: dto.dynamicOverlayIcons?.map(mapDynamicIcon).filter(PredicateHelpers.notNullOrUndefined),
        dynamicHref: dto.dynamicUrl?.map(mapDynamicHref).filter(PredicateHelpers.notNullOrUndefined),
    };
};
