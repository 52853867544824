import { CountrySpecificitiesRule } from './CountrySpecificitiesRule';
import { DynamicHrefValue } from './DynamicHrefValue';
import { DynamicIconDTO } from './DynamicIconDTO';

export type DynamicIconValue = {
    iconName: string;
    rule: CountrySpecificitiesRule;
    displayClassName?: string;
};

export const mapDynamicIcon = (dto: DynamicIconDTO | undefined): DynamicIconValue | undefined => {
    if (!dto) {
        return undefined;
    }

    return {
        iconName: dto.iconName,
        rule: {
            key: dto.rule.key,
            value: dto.rule.value,
        },
        displayClassName: dto.displayRules,
    };
};

export const isDynamicIconValue = (value: DynamicIconValue | DynamicHrefValue): value is DynamicIconValue => {
    return 'iconName' in value && !!value.iconName;
};
