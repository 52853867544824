import { Locale } from '@headless-workspace/config';
import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '@headless-workspace/core-domain';
import { RequestStrategy } from '@headless-workspace/data';
import { CustomerDTO } from './CustomerDTO';
import { CustomerValue, mapCustomer } from './CustomerValue';

export interface CustomerRepositorySpec {
    getCustomerById(locale: Locale, customerId: string): Promise<Result<CustomerValue>>;

    deleteFavoriteStore(locale: Locale, customerId: string): Promise<Result<CustomerValue>>;

    subscribeToNewsletter(locale: Locale, email: string): Promise<Result<boolean>>;
}

export class CustomerRepository extends Repository implements CustomerRepositorySpec {
    path = '/customer/v1';

    constructor(requestStrategy: RequestStrategy) {
        super(requestStrategy.authStrategy, requestStrategy.refreshTokenStrategy, requestStrategy.queryParamStrategy);
    }

    async getCustomerById(locale: Locale, customerId: string): Promise<Result<CustomerValue>> {
        try {
            const userResponse = await this.datasource.getResource(`${this.path}/customers/${customerId}`, { locale });
            const userResult = CustomerDTO.parse(userResponse);

            return ResultSuccess(mapCustomer(userResult, locale));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async subscribeToNewsletter(locale: Locale, email: string): Promise<Result<boolean>> {
        try {
            await this.datasource.createResource(
                `${this.path}/subscriptions`,
                { email, action: 'subscribe' },
                { type: 'newsletter', locale },
            );
            return ResultSuccess(true);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async deleteFavoriteStore(locale: Locale, customerId: string): Promise<Result<CustomerValue>> {
        try {
            const userResponse = await this.datasource.patchResource(
                `${this.path}/customers/${customerId}`,
                { favoriteStoreId: '' },
                { locale },
            );
            const userResult = CustomerDTO.parse(userResponse);

            return ResultSuccess(mapCustomer(userResult, locale));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
