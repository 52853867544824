export const BucketConfig = {
    directory: {
        seo: 'seo',
        countrySpecificities: 'country-specificities',
    },
    filename: {
        featuresFlags: 'features-flags.json',
        headerSlots: 'header-slots.json',
    },
};
