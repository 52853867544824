import { z } from 'zod';
import { FooterSlotDTOTypes } from './FooterSlotDTOTypes';

const FooterReinsuranceDTO = z.object({
    type: FooterSlotDTOTypes.Reinsurance,
    details: z.object({
        items: z.array(
            z.object({
                image: z.object({
                    default: z.string(),
                }),
                title: z.string(),
                description: z.string(),
                link: z.string(),
                ctaLabel: z.string(), // FIXME: should be renamed in US FHEAD-470
            }),
        ),
    }),
});

const FooterColumnsDTO = z.object({
    type: FooterSlotDTOTypes.Columns,
    details: z.object({
        items: z.array(
            z.object({
                items: z.array(
                    z.object({
                        title: z.string(),
                        link: z.string(),
                    }),
                ),
            }),
        ),
    }),
});

const FooterComplianceDTO = z.object({
    type: FooterSlotDTOTypes.Compliance,
    details: z.object({
        items: z.array(
            z.object({
                title: z.string(),
                link: z.string(),
            }),
        ),
    }),
});

const FooterSocialDTO = z.object({
    type: FooterSlotDTOTypes.Social,
    details: z.object({
        items: z.array(
            z.object({
                image: z.object({
                    icon: z.string(),
                }),
                link: z.string(),
            }),
        ),
    }),
});

export const FooterSlotDTO = z.union([FooterReinsuranceDTO, FooterColumnsDTO, FooterComplianceDTO, FooterSocialDTO]);

export const FooterSlotsDTO = z.object({
    slots: z.array(FooterSlotDTO),
});

export const FooterSlotsContainerDTO = z.object({
    slots: z.array(z.unknown()),
});

export type FooterReinsuranceDTO = z.infer<typeof FooterReinsuranceDTO>;
export type FooterColumnsDTO = z.infer<typeof FooterColumnsDTO>;
export type FooterComplianceDTO = z.infer<typeof FooterComplianceDTO>;
export type FooterSocialDTO = z.infer<typeof FooterSocialDTO>;
export type FooterSlotsDTO = z.infer<typeof FooterSlotsDTO>;
