import { IsomorphicLogger } from '../logger';
import { StoreSchedule } from './StoreSchedule';

type StoreOpeningInfoLabel = {
    days: string[];
    today: string;
    openUntil: string;
    opens: string;
    opensAt: string;
    tomorrow: string;
};

type StoreOpeningInfoReturn = {
    isOpenNow: boolean;
    statusPhrase: string;
};

const INVALID_TIME_FORMAT = 'INVALID_TIME_FORMAT';

function isStoreOpen(schedule: StoreSchedule[]): boolean {
    const now = new Date();
    const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes();
    const todaySchedule = schedule[0]; // current day is always first in schedule

    if (!todaySchedule.workingHours) return false;

    return todaySchedule.workingHours.some(({ from, to }) => {
        const [fromHours, fromMinutes] = from.split(':').map(Number);
        const [toHours, toMinutes] = to.split(':').map(Number);

        if ([fromHours, fromMinutes, toHours, toMinutes].some(isNaN)) {
            new IsomorphicLogger().warn(INVALID_TIME_FORMAT, { from, to });
            return false;
        }

        const fromTime = fromHours * 60 + fromMinutes;
        const toTime = toHours * 60 + toMinutes;

        return currentTimeInMinutes >= fromTime && currentTimeInMinutes <= toTime;
    });
}

function getNextOpening(schedule: StoreSchedule[]): { dayIndex: number; openTime: string } | null {
    for (let i = 1; i < schedule.length; i++) {
        const nextSchedule = schedule[i];
        if (nextSchedule.workingHours?.length) {
            return { dayIndex: i, openTime: nextSchedule.workingHours[0].from };
        }
    }
    return null;
}

// FIXME: Consider returning data instead of a string to facilitate internationalization using i18n.
function formatStoreOpeningPhrase(isOpenNow: boolean, schedule: StoreSchedule[], label: StoreOpeningInfoLabel): string {
    if (isOpenNow) {
        const todaySchedule = schedule[0];

        if (todaySchedule.workingHours?.length) {
            const closingTime = todaySchedule.workingHours[0].to;
            return `${label.today} ${label.openUntil} ${closingTime}`;
        }
    }

    const nextOpening = getNextOpening(schedule);

    if (nextOpening) {
        const isTomorrow = nextOpening.dayIndex === 1;
        const nextOpenDay = isTomorrow ? label.tomorrow : label.days[schedule[nextOpening.dayIndex].day - 1];

        return `${label.opens} ${nextOpenDay} ${label.opensAt} ${nextOpening.openTime}`;
    }

    return '';
}

function getStoreOpeningInfo(schedule: StoreSchedule[], label: StoreOpeningInfoLabel): StoreOpeningInfoReturn {
    const isOpenNow = isStoreOpen(schedule);
    const statusPhrase = formatStoreOpeningPhrase(isOpenNow, schedule, label);

    return { isOpenNow, statusPhrase };
}

export const StoreHelpers = {
    getStoreOpeningInfo,
};
