'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { GlowClickable, GlowTextBodySmall, GlowTitle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useClientData } from '../../providers';

export const PopularSearches: Component = () => {
    const t = useTranslations('Header.search.popular');
    const {
        clientValues: { popularSearches },
    } = useClientData();

    if (popularSearches.length === 0) {
        return null;
    }

    return (
        <section className={'flex flex-col'}>
            <GlowTitle text={t('title')} fontSize={'title5'} className={'py-0.5'} />
            <ul>
                {popularSearches.map(({ name, link }, index) => (
                    <li key={`${name}-${index}`} className={'py-0.5'}>
                        <GlowClickable
                            href={link}
                            content={{
                                labelElement: (
                                    <GlowTextBodySmall text={name} className={'break-all hover:text-underline'} />
                                ),
                            }}
                        />
                    </li>
                ))}
            </ul>
        </section>
    );
};
