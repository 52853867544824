'use client';

import { CardBannerProps, GenericCard, GlowMarketingBanner } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';

export const UIMarketingBanner: Component<CardBannerProps> = (props: CardBannerProps) => {
    return (
        <GenericCard
            {...props}
            classNames={{
                container: 'tablet:items-start overflow-hidden tablet:flex-row flex-col',
                imgWrapper: 'tablet:w-marketingBannerImg aspect-16/9',
                cta: 'tablet:w-marketingBannerImg',
            }}
            sizes={`(max-width: ${tailwindTheme.screens.tablet}) 100vw, ${tailwindTheme.width.marketingBannerImg}`}
            fill
        >
            <GlowMarketingBanner {...props} />
        </GenericCard>
    );
};
