import { HTMLString } from '../HTMLString';
import { FooterSocialDTO } from './FooterSlotsDTO';

export type FooterSocialNetworkValue = Readonly<{
    id: string;
    iconRaw: HTMLString;
    href: string;
}>;

export const mapFooterSocialNetwork = (dto: FooterSocialDTO): FooterSocialNetworkValue[] => {
    return dto.details.items.map((item, index) => ({
        id: `social-network-${index}`,
        iconRaw: {
            content: item.image.icon,
        },
        href: item.link,
    }));
};
