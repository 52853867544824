export const de_DE_NavRoutes = {
    home: '',
    connection: 'einloggen',
    storesAndServices: 'Storefinder',
    wishlist: 'wunschliste',
    basket: 'warenkorb',
    payment: '', // FIXME: To Update
    account: 'mein-konto',
    orders: 'meine-bestellungen',
    followOrder: 'meine-bestellungen?isGuestOrderTracking=true',
    loyalty: 'Sephora-Unlimited.html',
    offers: 'on/demandware.store/Sites-Sephora_DE-Site/de_DE/Offers-Show',
    profile: 'meine-persoenlichen-informationen',
    mail: '',
    help: 'faq-sephora',
    allServices: 'sephora-store-services.html',
    storeInfo: 'store-information',
    allStores: 'Storefinder',
    countries: 'international/international_DE.html',
    beautyTips: 'sale',
    logout: 'ausloggen?redirectToReferer=true',
    loyaltyProgram: 'on/demandware.store/Sites-Sephora_DE-Site/de_DE/LoyaltyDashboard-Show',
    allBrands: 'marken/alle-marken-von-a---z-brands',
    clickAndCollect: 'click-and-collect',
    securePayment: 'https://faq.sephora.de/s/article/Welche-Zahlungsm%C3%B6glichkeiten-gibt-es',
    returnProduct: 'https://faq.sephora.de/s/article/Wie-kann-ich-ein-Produkt-zur%C3%BCckgeben',
    privacyPolicy: 'datenschutzerklaerung/donnees-personnelles_DE.html',
    search: 'suche?q=',
    reviewConditions: '',
};
