const getStylePropertyValue = (element: HTMLElement, property: string): number => {
    return Number(element.style.getPropertyValue(property).split('rem')[0]) || 0;
};

const setStylePropertyValue = (element: HTMLElement, property: string, value: number): void => {
    element.style.setProperty(property, `${value}rem`);
};

export enum ScrollPosition {
    Start = 'start',
    End = 'end',
    Middle = 'middle',
}

const getHorizontalScrollPosition = (element: HTMLElement): ScrollPosition => {
    const { scrollLeft, scrollWidth, clientWidth } = element;

    if (scrollLeft === 0) {
        return ScrollPosition.Start;
    } else if (scrollLeft + clientWidth >= scrollWidth) {
        return ScrollPosition.End;
    } else {
        return ScrollPosition.Middle;
    }
};

export const HtmlHelpers = {
    getStylePropertyValue,
    setStylePropertyValue,
    getHorizontalScrollPosition,
};
