import clsx from 'clsx';
import { DataTestConfig } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';
import { cva, VariantProps } from 'class-variance-authority';
import { PropsWithAriaLabel, PropsWithClassName } from '../../props';
import { GlowTextCaption } from '../typography';

export const chipStyle = cva('', {
    variants: {
        color: {
            success: 'bg-text-success',
            error: 'bg-text-error',
            promotion: 'bg-surface-promotion',
        },
        size: {
            small: 'min-w-iconChipSmall min-h-iconChipSmall max-w-iconChipSmall max-h-iconChipSmall',
            medium: 'min-w-iconChipMedium min-h-iconChipMedium max-w-iconChipMedium max-h-iconChipMedium',
        },
    },
    defaultVariants: {
        color: 'success',
        size: 'small',
    },
});

export type GlowChipProps = PropsWithAriaLabel &
    PropsWithClassName &
    VariantProps<typeof chipStyle> &
    (
        | {
              hasDigit: true;
              count: number;
          }
        | {
              hasDigit: false;
          }
    );

export const GlowChip: Component<GlowChipProps> = ({
    className,
    ariaLabel,
    color = 'promotion',
    size = 'small',
    ...props
}) => {
    if (!props.hasDigit) {
        return (
            <div
                aria-label={ariaLabel}
                className={clsx(
                    'flex justify-center items-center',
                    'px-0.25',
                    'rounded-infinity bg-surface-promotion',
                    chipStyle({ color, size }),
                    className,
                )}
                data-testid={'chip'}
            />
        );
    }

    return props.count > 0 ? (
        <div
            className={clsx(
                'flex justify-center items-center',
                'w-iconChipWithDigit h-iconChipWithDigit',
                'px-0.25',
                'rounded-infinity bg-surface-promotion',
                className,
            )}
            data-testid={'chip'}
        >
            <GlowTextCaption
                TagName={'span'}
                text={props.count}
                color={'primary-inverse-fixed'}
                className={'tracking-normal'}
                aria-label={ariaLabel}
                dataTestId={DataTestConfig.testIds.wishlistProductNumberIcon}
            />
        </div>
    ) : null;
};
