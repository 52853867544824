import clsx from 'clsx';
import { GlowButton, GlowIcon, Icons, PropsWithAriaLabel, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type CarouselNavigationButtonProps = PropsWithClassName &
    PropsWithAriaLabel & {
        type: 'prev' | 'next';
        onClick: () => void;
        disabled: boolean;
        iconType?: 'large' | 'medium';
        variant?: 'primary' | 'secondary' | 'tertiary' | 'transparent';
    };

export const CarouselNavigationButton: Component<CarouselNavigationButtonProps> = ({
    className,
    ariaLabel,
    type,
    onClick,
    disabled,
    iconType = 'large',
    variant = 'primary',
}) => {
    return (
        <GlowButton
            className={clsx('p-0.5 rounded-0.5', className)}
            onClick={onClick}
            disabled={disabled}
            Icon={() => (
                <GlowIcon Icon={Icons.ChevronRight} className={clsx(type === 'prev' && 'rotate-180')} type={iconType} />
            )}
            ariaLabel={ariaLabel}
            variant={disabled ? 'disabled' : variant}
            size={'medium'}
        />
    );
};
