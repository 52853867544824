import { z } from 'zod';
import { SearchProductsDTO } from './SearchProductsDTO';
import { TextSuggestionsDTO } from './TextSuggestionsDTO';

export const SearchSuggestionsDTO = z.object({
    searchSuggestions: z.object({
        brands: TextSuggestionsDTO,
        products: SearchProductsDTO,
        categories: TextSuggestionsDTO,
        terms: TextSuggestionsDTO,
    }),
});

export const SearchSuggestionContainerDTO = z.object({
    searchSuggestions: z.object({
        brands: z.array(z.unknown()).optional(),
        products: z.array(z.unknown()).optional(),
        categories: z.array(z.unknown()).optional(),
        terms: z.array(z.unknown()).optional(),
    }),
});

export type SearchSuggestionsDTO = z.infer<typeof SearchSuggestionsDTO>;
