export const HttpStatusMap: Record<number, string> = {
    400: 'BAD_REQUEST',
    401: 'UNAUTHORIZED',
    403: 'FORBIDDEN',
    404: 'NOT_FOUND',
    405: 'METHOD_NOT_ALLOWED',
    409: 'CONFLICT',
    500: 'INTERNAL',
} as const;

export type HttpStatus = keyof typeof HttpStatusMap;
