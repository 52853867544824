export enum CountryCode {
    AE = 'AE',
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    BH = 'BH',
    BR = 'BR',
    CA = 'CA',
    CH = 'CH',
    CN = 'CN',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    EE = 'EE',
    ES = 'ES',
    FI = 'FI',
    FR = 'FR',
    GB = 'GB',
    GR = 'GR',
    HK = 'HK',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IT = 'IT',
    KW = 'KW',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MC = 'MC',
    NL = 'NL',
    NO = 'NO',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    SA = 'SA',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    TR = 'TR',
    UA = 'UA',
    US = 'US',
}
