'use client';

import { useState, useEffect } from 'react';

export const useIsClassInBody = (className: string): boolean => {
    const [hasClass, setHasClass] = useState<boolean>(false);

    useEffect(() => {
        const checkClass = () => {
            if (document.body.classList.contains(className)) {
                setHasClass(true);
            } else {
                setHasClass(false);
            }
        };

        checkClass();

        const observer = new MutationObserver(() => {
            checkClass();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, [className]);

    return hasClass;
};
