import { envConfig } from '@headless-workspace/config';
import { ApiError, ContentFile, ContentType } from '@headless-workspace/data';
import { DOMParser, MIME_TYPE } from '@xmldom/xmldom';
import * as crypto from 'crypto';
import { Result, ResultFailure, ResultSuccess } from '../types';

interface NetStorageHeaders {
    accept: string;
    'X-Akamai-ACS-Action': string;
    'X-Akamai-ACS-Auth-Data': string;
    'X-Akamai-ACS-Auth-Sign': string;
    Host: string;
}

/**
 * Creates the headers object for NetStorage requests.
 *
 * @param domain - The domain for the NetStorage request.
 * @param path - The path for the NetStorage request.
 * @param secret - The secret key for authentication.
 * @param uid - The user ID for authentication.
 * @param acsAction - The ACS action for the request.
 * @param uploadAccountId - The upload account ID for the request.
 * @returns The headers object containing the necessary headers for the NetStorage request.
 */
const createNetStorageHeaders = (
    domain: string,
    path: string,
    secret: string,
    uid: string,
    acsAction: string,
    uploadAccountId: string,
): { headers: NetStorageHeaders } => {
    const hmac = crypto.createHmac('sha256', secret);
    const now = Math.round(Date.now() / 1000);
    const authData = `5, 0.0.0.0, 0.0.0.0, ${now}, ${uid}, ${uploadAccountId}`;
    const signString = path + '\n' + 'x-akamai-acs-action:' + acsAction + '\n';
    const data = hmac.update(authData + signString);
    const headers: NetStorageHeaders = {
        accept: ContentType.XML,
        'X-Akamai-ACS-Action': acsAction,
        'X-Akamai-ACS-Auth-Data': authData,
        'X-Akamai-ACS-Auth-Sign': data.digest('base64'),
        Host: domain,
    };
    return { headers };
};

/**
 * Executes a storage action on a specified domain and path.
 * @param domain - The domain to execute the storage action on.
 * @param path - The path to the resource on the domain.
 * @param acsAction - The ACS action to perform.
 * @param format - The format of the response data ('xml' or 'json').
 * @returns A Promise that resolves to the parsed response data.
 * @throws If an error occurs during the execution of the storage action.
 */
export const execStorageAction = async (
    domain: string,
    path: string,
    acsAction: string,
    format: ContentFile,
): Promise<Result<string>> => {
    const options: RequestInit = {
        method: 'GET',
        headers: new Headers(
            Object.entries(
                createNetStorageHeaders(
                    domain,
                    path,
                    envConfig.netStorageSecret,
                    envConfig.netStorageUID,
                    acsAction,
                    envConfig.netStorageUploadAccountId,
                ).headers,
            ),
        ),
    };

    try {
        const res = await fetch(`https://${domain}${path}`, options);

        if (format === ContentFile.XML) {
            return ResultSuccess(await res.text());
        } else {
            return ResultSuccess(await res.json());
        }
    } catch (err) {
        return ResultFailure('Network error or invalid file content format');
    }
};

/**
 * Parses XML string and returns the name of the latest file.
 * @param xml - The XML string to parse.
 * @returns The name of the latest file, or null if no file is found.
 */
export const lastCreatedFolder = (xml: string): string | null => {
    const doc = new DOMParser().parseFromString(xml, MIME_TYPE.XML_APPLICATION);
    const files = doc?.getElementsByTagName('file');

    if (!files || files.length === 0) {
        throw new ApiError(500, {
            errors: [
                {
                    errorCode: 'EMPTY_FILES',
                    errorMessage: 'Empty files',
                },
            ],
        });
    }

    let latestFile: Element | null = null;
    let latestDate: Date | null = null;

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.getAttribute('name');

        // Parse the date from the file name
        const fileDate = fileName ? new Date(fileName) : null;

        if (fileDate && (!latestDate || fileDate > latestDate)) {
            latestFile = file;
            latestDate = fileDate;
        }
    }

    return latestFile ? latestFile.getAttribute('name') : null;
};

/**
 * Fetches net storage data from the specified directory and file.
 * @param mainDirectoryPath - The path of the main directory where the file is located. Should not start and end with a '/'.
 * @param subDirectory - The subdirectory where the file is located. Should not start and end with a '/'.
 * @param fileName - The name of the file to fetch.
 * @throws An error if the file content format is invalid.
 */
export const fetchNetStorageData = async ({
    mainDirectoryPath,
    subDirectory,
    fileName,
}: {
    mainDirectoryPath: string;
    subDirectory?: string;
    fileName: string;
}): Promise<string> => {
    const mainDirectory = await execStorageAction(
        envConfig.netStorageDomain,
        `/${envConfig.netStorageCPCode}/${envConfig.netStorageENV}/${mainDirectoryPath}/`,
        'version=1&action=dir',
        ContentFile.XML,
    );

    if (mainDirectory.type === 'failure') {
        throw new ApiError(500, {
            errors: [
                {
                    errorCode: mainDirectory.meta.code,
                    errorMessage: mainDirectory.meta.message,
                },
            ],
        });
    }

    if (mainDirectory.type === 'empty') {
        throw new ApiError(500, {
            errors: [
                {
                    errorCode: 'EMPTY_FOLDERS',
                    errorMessage: 'Empty folders',
                },
            ],
        });
    }

    const mostRecentName = lastCreatedFolder(mainDirectory.data);

    let filePath = mostRecentName ? `${mainDirectoryPath}/${mostRecentName}` : mainDirectoryPath;
    if (subDirectory) {
        filePath += `/${subDirectory}/${fileName}`;
    } else {
        filePath += `/${fileName}`;
    }

    const fileContent = await execStorageAction(
        envConfig.netStorageDomain,
        `/${envConfig.netStorageCPCode}/${envConfig.netStorageENV}/${filePath}`,
        'version=1&action=download',
        ContentFile.JSON,
    );

    if (fileContent.type === 'failure') {
        throw new ApiError(500, {
            errors: [
                {
                    errorCode: fileContent.meta.code,
                    errorMessage: fileContent.meta.message,
                },
            ],
        });
    }

    if (fileContent.type === 'empty') {
        throw new ApiError(500, {
            errors: [
                {
                    errorCode: 'EMPTY_FILES',
                    errorMessage: 'Empty files',
                },
            ],
        });
    }

    return fileContent.data;
};
