import { GlowFastAccessListItem } from '@headless-workspace/glow-ds';
import { Component, Image } from '@headless-workspace/typings';

type UIFastAccessListProps = {
    fastAccessLinks: {
        label: string;
        subLabel?: string;
        href: string;
        image: Image;
    }[];
};

export const UIFastAccessList: Component<UIFastAccessListProps> = ({ fastAccessLinks }) => {
    return (
        <ul className={'flex justify-evenly px-1 py-0.75 w-full'}>
            {fastAccessLinks.map(({ label, href, image, subLabel }, index) => {
                return (
                    <GlowFastAccessListItem
                        image={image}
                        href={href}
                        label={label}
                        subLabel={subLabel}
                        key={`fast-access-${label}-${index}`}
                    />
                );
            })}
        </ul>
    );
};
