export type Result<T = never> = ResultSuccess<T> | ResultFailure | ResultEmpty;

export type ResultSuccess<T = never> = { type: 'success'; data: T };

export type ResultEmpty = { type: 'empty' };

export type ResultFailure = {
    type: 'failure';
    meta: {
        code: string;
        message?: string;
        extra?: Record<string, unknown>;
    };
};

export const ResultSuccess = <T>(data: T): ResultSuccess<T> => ({
    type: 'success',
    data,
});

export const ResultFailure = (code: string, message?: string, extra?: Record<string, unknown>): ResultFailure => ({
    type: 'failure',
    meta: {
        code,
        message,
        extra,
    },
});

export const ResultEmpty = (): ResultEmpty => ({
    type: 'empty',
});

export const unwrapResultSuccess = <T>(result: Result<T>): T | undefined => {
    if (result.type === 'success') {
        return result.data;
    }
    return undefined;
};
