'use client';

import React from 'react';
import { GlowSkeleton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

const PRODUCT_TILE_IMG_SIZE_MOBILE = 80;

export const SearchResultItemSkeleton: Component = () => {
    return (
        <>
            <GlowSkeleton width={PRODUCT_TILE_IMG_SIZE_MOBILE} height={PRODUCT_TILE_IMG_SIZE_MOBILE} />
            <div className={'flex flex-col gap-0.75 w-full'}>
                <div className={'flex flex-row'}>
                    <GlowSkeleton width={'90%'} height={'1.2rem'} containerClassName={'w-full'} />
                    <GlowSkeleton width={'40%'} height={'1.2rem'} containerClassName={'w-full'} />
                </div>
                <GlowSkeleton width={'40%'} height={'0.9rem'} />
                <GlowSkeleton width={'40%'} height={'0.9rem'} />
            </div>
        </>
    );
};
