import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type UIFooterShellProps = PropsWithClassName & PropsWithChildren;

export const UIFooterShell: Component<UIFooterShellProps> = ({ className, children }) => {
    return (
        <footer className={clsx('flex flex-col', 'inset-x-0 bottom-0', 'w-full relative', className)}>
            {children}
        </footer>
    );
};
