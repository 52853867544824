import { DefaultValueConfig } from '@headless-workspace/config';
import { UrlHelpers } from '@headless-workspace/utils';
import { CountrySpecificitiesRule } from './CountrySpecificitiesRule';
import { DynamicLabelDTO } from './DynamicLabelDTO';

export type DynamicLabelValue = {
    text: string;
    href: string;
    rule: CountrySpecificitiesRule;
    displayClassName?: string;
};

export const mapDynamicLabel = (dto: DynamicLabelDTO | undefined): DynamicLabelValue | undefined => {
    if (!dto) {
        return undefined;
    }

    return {
        text: dto.text,
        href: UrlHelpers.createPathFromEnv(dto.url) ?? DefaultValueConfig.href,
        rule: {
            key: dto.rule.key,
            value: dto.rule.value,
        },
        displayClassName: dto.displayRules,
    };
};
