'use client';

import { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers, UrlHelpers } from '@headless-workspace/utils';
import { DataLayerEventCategory, useAppLocale } from '../../../../client';
import { LocaleSwitcherButton, LocaleSwitcherButtonProps } from '../LocaleSwitcherButton';
import { LocaleSwitcherMenu } from '../LocaleSwitcherMenu';

const LANGUAGE_BUTTON_DATA_LAYER_ACTION = 'click_flag';
const LANGUAGE_ITEM_BUTTON_DATA_LAYER_ACTION = 'selection_language';

export type LanguageSwitcherProps = Pick<LocaleSwitcherButtonProps, 'className' | 'displayType' | 'hostUrl'>;

export const LanguageSwitcher: Component<LanguageSwitcherProps> = (props) => {
    const t = useTranslations('Header.languageSwitcher');
    const locale = useAppLocale();

    const countries = useMemo(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let { languages } = UrlHelpers.getDomainConfig(window.location.hostname);

        if (!languages.length) {
            return;
        }

        const currentLanguage = LanguageHelpers.parseLanguage(locale);
        languages = languages.filter((language) => language !== currentLanguage);

        if (!languages.length) {
            return;
        }

        const code = LanguageHelpers.parseCountry(locale);
        const tld = `.${code.toLowerCase()}`;

        return languages.map((language) => {
            return { code, tld, name: t(`countries.${language}`), language };
        });
    }, [t, locale]);

    if (!countries) {
        return null;
    }

    return (
        <LocaleSwitcherButton
            {...props}
            countries={countries}
            menu={
                <LocaleSwitcherMenu
                    title={t('title')}
                    description={t('description')}
                    countries={countries}
                    locale={locale}
                    dataLayer={{
                        category: DataLayerEventCategory.languageSwitcher,
                        action: LANGUAGE_ITEM_BUTTON_DATA_LAYER_ACTION,
                    }}
                />
            }
            showLanguage
            dataLayer={{
                category: DataLayerEventCategory.languageSwitcher,
                action: LANGUAGE_BUTTON_DATA_LAYER_ACTION,
            }}
        />
    );
};
