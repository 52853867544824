'use client';

import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { CountryCode, Locale } from '@headless-workspace/config';
import { CookieName, getClientCookieValue, setClientCookie } from '@headless-workspace/core-domain';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers } from '@headless-workspace/utils';
import { CountryContext, CountryContextValueType } from './CountryContext';

export const createRedirectLocaleValue = (locale: Locale, countryCode: CountryCode): string => {
    return `${locale.replace(/-/gi, '_')}-${countryCode}`;
};

export const setRedirectLocaleCookie = (countryCode: CountryCode, locale: Locale) => {
    const redirectLocaleValue = createRedirectLocaleValue(locale, countryCode);
    setClientCookie(CookieName.RedirectLocale, redirectLocaleValue);
    return redirectLocaleValue;
};

export const getRedirectLocaleCountryCode = (redirectLocale?: string): CountryCode | undefined => {
    return redirectLocale?.split('-')[1] as CountryCode | undefined;
};

export const getRedirectLocaleCookie = () => {
    return getClientCookieValue(CookieName.RedirectLocale);
};

export type CountryProviderProps = PropsWithChildren & PropsWithLocale;

export const CountryProvider: Component<CountryProviderProps> = ({ children, locale }) => {
    const [countryCode, setCountryCode] = useState<CountryCode | undefined>();

    const changeCountry = useCallback((countryCode: CountryCode, locale: Locale) => {
        const newRedirectionLocale = setRedirectLocaleCookie(countryCode, locale);
        setCountryCode(getRedirectLocaleCountryCode(newRedirectionLocale));
    }, []);

    useEffect(() => {
        const redirectLocaleCookie = getRedirectLocaleCookie();
        if (!redirectLocaleCookie) {
            changeCountry(LanguageHelpers.parseCountry(locale), locale);
        } else {
            setCountryCode(getRedirectLocaleCountryCode(redirectLocaleCookie));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const providerValue: CountryContextValueType = useMemo(
        () => ({
            changeCountry,
            countryCode,
        }),
        [changeCountry, countryCode],
    );

    return <CountryContext.Provider value={providerValue}>{children}</CountryContext.Provider>;
};
