import { ServerActionName } from '@headless-workspace/config';
import { Result, ResultFailure } from './Result';
import { RevalidationServerActionType } from './RevalidationServerAction';

export type ResultServerActionType<T = never> = Result<T> & {
    action: ServerActionName;
    revalidation?: { key?: string };
};

export type ResultServerActionSuccessOrEmpty<T> = Exclude<ResultServerActionType<T>, ResultFailure>;

export const ResultServerAction = <T>(
    result: Result<T>,
    action: ServerActionName,
    revalidation?: RevalidationServerActionType,
): ResultServerActionType<T> => {
    const revalidationField = revalidation ? { key: revalidation.revalidationKey } : undefined;
    return {
        ...result,
        action,
        revalidation: revalidationField,
    };
};

export const isResultServerAction = <T>(result: unknown): result is ResultServerActionType<T> =>
    (result as ResultServerActionType<T>)?.action !== undefined &&
    (result as ResultServerActionType<T>)?.type !== undefined;
