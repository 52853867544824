import React, { PropsWithChildren, ReactNode } from 'react';
import { Component } from '@headless-workspace/typings';

type UIDefaultLayoutProps = PropsWithChildren & {
    sideMenuModal?: ReactNode;
    header?: ReactNode;
    footer?: ReactNode;
    modal?: ReactNode;
    toasts?: ReactNode;
};

export const UIDefaultLayout: Component<UIDefaultLayoutProps> = ({
    children,
    sideMenuModal,
    header,
    footer,
    toasts,
}) => {
    return (
        <>
            {sideMenuModal}
            {header}
            <main className={'flex flex-col desktop:items-center desktop:*:w-maxWidth'}>{children}</main>
            {footer}
            {toasts}
        </>
    );
};
