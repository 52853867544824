import 'reflect-metadata';
import { ConstructorArguments, Deps } from '@headless-workspace/typings';
import { Container } from 'typedi';
import { deps } from './dependencyInjection';

export * from './dependencyInjection';

// TODO: refactor
// DI instantiation will be factorized in the 2nd step implementation of the DI refactoring
export class CommonDI {
    static deps: Readonly<Deps<ReturnType<typeof deps>>> = {} as Deps<ReturnType<typeof deps>>;
    static instance: Readonly<CommonDI | null> = null;

    getDependency<Type extends keyof typeof CommonDI.deps>(
        type: Extract<Type, string>,
        ...args: ConstructorArguments<ReturnType<typeof deps>[Type]>
    ): (typeof CommonDI.deps)[Type] {
        if (!CommonDI.deps[type]) {
            const Constructor = deps()[type] as new (
                ...args: ConstructorArguments<ReturnType<typeof deps>[Type]>
            ) => (typeof CommonDI.deps)[Type];
            CommonDI.deps = { ...CommonDI.deps, [type]: new Constructor(...args) };
            Container.set<(typeof CommonDI.deps)[typeof type]>(type, CommonDI.deps[type]);
        }
        return Container.get<(typeof CommonDI.deps)[Type]>(type);
    }

    static get<Type extends keyof typeof CommonDI.deps>(
        type: Extract<Type, string>,
        ...args: ConstructorArguments<ReturnType<typeof deps>[Type]>
    ): (typeof CommonDI.deps)[Type] {
        if (!CommonDI.instance) {
            CommonDI.instance = new CommonDI();
        }
        return CommonDI.instance.getDependency(type, ...args);
    }
}
