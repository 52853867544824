'use client';

import React, { ChangeEvent, ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { GlowClickable, GlowIcon, GlowInput, GlowTextBodySmall, GlowTextCaption } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export type UIUnderlinedInputRef = {
    updateInputValue: (value: string) => void;
};

type UIUnderlinedInputProps = {
    label: string;
    onSubmit?: (input: string) => void;
    onChange?: (input: string) => void;
    Icon?: React.ComponentType<{
        fill?: string;
    }>;
    placeholder?: string;
    inputId?: string;
    ref: ForwardedRef<UIUnderlinedInputRef>;
};

export const UIUnderlinedInput: Component<UIUnderlinedInputProps> = forwardRef(
    ({ onChange, onSubmit, label, placeholder, Icon, inputId }, ref) => {
        const [searchInput, setSearchInput] = useState('');
        useImperativeHandle(ref, () => {
            return {
                updateInputValue: setSearchInput,
            };
        });

        const onInputSubmit = () => {
            onSubmit?.(searchInput);
        };

        const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
            setSearchInput(event.target.value);
            onChange?.(event.target.value);
        };

        return (
            <div className={'flex flex-col gap-0.5'}>
                <GlowTextBodySmall text={label} />
                <div
                    className={clsx(
                        'flex flex-row overflow-hidden rounded-0.5',
                        'outline outline-border-primary outline-medium',
                        'focus-within:outline-bold focus-within:outline-border-selected',
                    )}
                >
                    <GlowInput
                        id={inputId}
                        className={clsx('flex-1 outline-none p-0.75', 'bg-background-form text-text-primary')}
                        value={searchInput}
                        onChange={onSearchChange}
                        autoComplete={'off'}
                        type={'search'}
                        inputMode={'search'}
                        enterKeyHint={'search'}
                    />
                    {Icon && (
                        <GlowClickable
                            onClick={onInputSubmit}
                            content={{
                                icon: <GlowIcon Icon={Icon} type={'medium'} />,
                            }}
                            className={'bg-background-form pr-0.75 text-text-primary'}
                        />
                    )}
                </div>
                <GlowTextCaption text={placeholder} />
            </div>
        );
    },
);
