export const it_IT_NavRoutes = {
    home: '',
    connection: 'accedi',
    storesAndServices: 'sephora-store',
    wishlist: 'wishlist',
    basket: 'carrello',
    payment: '', // FIXME: To Update
    account: 'account',
    orders: 'i-miei-ordini',
    countries: 'international.html',
    offers: '',
    mail: '',
    loyaltyProgram: '',
    followOrder: 'on/demandware.store/Sites-Sephora_IT-Site/it_IT/Order-TrackMyOrder',
    loyalty: 'it-sephora-loyalty.html',
    profile: 'dettagli-account',
    help: 'aiuto',
    allServices: 'servizi-in-store.html',
    storeInfo: 'informazioni-store',
    allStores: 'sephora-store',
    beautyTips: 'offerte-di-bellezza',
    logout: 'esci/?redirectToReferer=true',
    allBrands: 'dalla-a-alla-z',
    clickAndCollect: 'click-and-collect',
    securePayment: 'https://faq.sephora.it/s/article/%C3%88-sicuro-pagare-su-sephora-it',
    returnProduct: 'https://faq.sephora.it/s/article/Come-restituire-un-prodotto',
    privacyPolicy: 'privacy-cookies-policy_IT.html',
    search: 'cerca/?q=',
    reviewConditions: '',
};
