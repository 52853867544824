import { featureFlipping, isServer } from '@headless-workspace/config';
import { trace } from '@opentelemetry/api';
import { LogFn, Logger, LogLevel } from './Logger';

const TRACER_NAME = 'headless-server-tracer';

const TRACER_SPAN_ATTRIBUTE_NAME = 'extra-attribute';

/**
 * OpenTelemetry Logger
 */
export class IsomorphicLogger implements Logger {
    tracer = trace.getTracer(TRACER_NAME);

    /**
     * Handle log execution with OpenTelemetry span
     * @param level the log level, 'info', 'warn' or 'error'
     * @param label the span label name
     * @param params the span params
     */
    handleOpenTelemetryLog = (level: LogLevel, label: string, ...params: unknown[]): void => {
        if (isServer()) {
            const span = this.tracer.startSpan(label);
            span.setAttributes({ level });
            params.forEach((param, index) => {
                if (param instanceof Error) {
                    span.recordException(param);
                } else {
                    span.setAttribute(`${TRACER_SPAN_ATTRIBUTE_NAME}-${index}`, JSON.stringify(param, null, 2));
                }
            });
            span.end();
        }
    };

    handleOutputLog = (level: LogLevel, label: string, logFn: LogFn, ...params: unknown[]): void => {
        if (featureFlipping.isOutputLoggingEnabled) {
            const time = new Date().toISOString();
            return logFn(JSON.stringify({ level, time, label, params }, null, 2));
        }
    };

    info(label: string, ...params: unknown[]): void {
        this.handleOpenTelemetryLog(LogLevel.Info, label, ...params);
        this.handleOutputLog(LogLevel.Info, label, console.log, ...params);
    }

    warn(label: string, ...params: unknown[]): void {
        this.handleOpenTelemetryLog(LogLevel.Warn, label, ...params);
        this.handleOutputLog(LogLevel.Warn, label, console.warn, ...params);
    }

    error(label: string, ...params: unknown[]): void {
        this.handleOpenTelemetryLog(LogLevel.Error, label, ...params);
        this.handleOutputLog(LogLevel.Error, label, console.error, ...params);
    }
}
