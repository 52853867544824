'use client';

import { SessionStorageKey } from '@headless-workspace/config';
import { useLocalStorage } from 'usehooks-ts';
import { RecentSearch } from '../../../../server';

const MAX_RECENT_SEARCHES_COUNT = 5;

export const useRecentSearches = () => {
    const [storedRecentSearches, setStoredRecentSearches] = useLocalStorage<RecentSearch[]>(
        SessionStorageKey.RecentSearches,
        [],
    );

    const clearRecentSearches = () => {
        setStoredRecentSearches([]);
    };

    const addRecentSearch = (searchTerm: string) => {
        const id = searchTerm;
        setStoredRecentSearches((searches) =>
            [{ id, label: searchTerm }, ...searches.filter((search) => search.id !== id)].slice(
                0,
                MAX_RECENT_SEARCHES_COUNT,
            ),
        );
    };

    return {
        addRecentSearch,
        clearRecentSearches,
        recentSearches: storedRecentSearches.slice(0, MAX_RECENT_SEARCHES_COUNT),
    };
};
