'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { GlowButton, GlowTitle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { SearchBrandsSkeleton } from './SearchBrandsSkeleton';
import { SearchCategoriesSkeleton } from './SearchCategoriesSkeleton';
import { SearchResultItemSkeleton } from './SearchResultItemSkeleton';
import { SearchSuggestionSkeleton } from './SearchSuggestionSkeleton';

type SearchSuggestionsSkeletonProps = {
    searchInput: string;
    onSearch: (search: string) => void;
};
export const SearchSuggestionsContainerSkeleton: Component<SearchSuggestionsSkeletonProps> = ({
    searchInput,
    onSearch,
}) => {
    const t = useTranslations('Header.search');

    return (
        <>
            <section className={'flex flex-col flex-1 gap-1.5 flex-grow-3'}>
                <GlowTitle text={t('label.products')} fontSize={'title5'} />
                <ul className={'grid grid-cols-1 tablet:grid-cols-2 gap-y-1.5 gap-x-2.5 justify-center items-start'}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <li className={'w-full'} key={`product-suggestion-${index}`}>
                            <div className={'flex flex-row flex-1 items-center gap-1 py-0.5'}>
                                <SearchResultItemSkeleton />
                            </div>
                        </li>
                    ))}
                </ul>
                <GlowButton
                    className={'w-maxContent'}
                    label={t('action.viewAllProducts')}
                    onClick={() => onSearch(searchInput)}
                />
            </section>
            <section className={'flex flex-col gap-1.5 flex-1'}>
                <GlowTitle text={t('label.suggestions')} fontSize={'title5'} />
                <div className={'flex flex-col gap-0.5 w-full'}>
                    <SearchSuggestionSkeleton />
                </div>
                <GlowTitle text={t('label.categories')} fontSize={'title5'} />

                <div className={'flex flex-col gap-0.75 w-full'}>
                    <SearchCategoriesSkeleton />
                </div>
                <GlowTitle text={t('label.brands')} fontSize={'title5'} />
                <div className={'flex flex-row gap-0.5 w-full'}>
                    <SearchBrandsSkeleton />
                </div>
            </section>
        </>
    );
};
