'use client';

import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { cva } from 'class-variance-authority';
import { GlowIcon, GlowInput, GlowTextBody, GlowTextCaption, Icons, PropsWithClassName } from '../../../../src';

export type CheckboxType = 'default' | 'error';
export type CheckboxPosition = 'left' | 'right';

const containerClasses = cva('flex items-center gap-0.5 w-full cursor-pointer', {
    variants: {
        position: {
            left: 'flex-row',
            right: 'flex-row-reverse justify-between',
        },
    },
    defaultVariants: {
        position: 'left',
    },
});

const checkboxClasses = cva(
    'flex w-checkbox h-checkbox rounded-0.25 text-text-primary-inverse items-center justify-center border-solid border-bold',
    {
        variants: {
            state: {
                default: 'border-border-brand',
                error: 'border-border-error',
            },
            checked: {
                true: 'bg-surface-brand border-background-brand',
                false: '',
            },
        },
        defaultVariants: {
            state: 'default',
            checked: false,
        },
    },
);

export type GlowCheckboxProps = PropsWithClassName & {
    label?: string | number;
    checked: boolean;
    onChange?: () => void;
    name?: string;
    id: string;
    type?: CheckboxType;
    position?: CheckboxPosition;
    helperText?: string;
};

export const GlowCheckbox: Component<GlowCheckboxProps> = ({
    label,
    checked = false,
    onChange,
    name,
    id,
    type = 'default',
    position = 'left',
    helperText,
    className,
    ...props
}) => {
    const checkboxState = checked ? 'default' : type;

    const onChangeHandler = () => {
        if (onChange) {
            onChange();
        }
    };

    return (
        <div className={'w-fit flex flex-col gap-0.5 has-[:focus-visible]:outline has-[:focus-visible]:rounded-0.25'}>
            <label htmlFor={id} className={clsx(containerClasses({ position }), className)}>
                <GlowInput
                    type={'checkbox'}
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={onChangeHandler}
                    className={'sr-only'}
                    {...props}
                />
                <span
                    className={clsx(
                        checkboxClasses({ state: checkboxState, checked }),
                        className,
                        !checked && 'hover:bg-surface-primary-hover',
                    )}
                >
                    {checked && (
                        <GlowIcon Icon={Icons.Vector} type={'medium'} className={'text-text-primary-inverse'} />
                    )}
                </span>
                <GlowTextBody text={label} TagName={'span'} />
            </label>
            {type === 'error' && helperText && !checked && (
                <div className={'flex flex-row gap-0.25 text-text-error'}>
                    <GlowIcon Icon={Icons.Warning} type={'small'} />
                    <GlowTextCaption text={helperText} color={'error'} />
                </div>
            )}
            {type === 'default' && helperText && <GlowTextCaption text={helperText} color={'primary'} />}
        </div>
    );
};
