import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableBannerCard, ComposableBannerCardProps } from '../../composables';

/**
 * GlowMainBanner component renders a main banner with various customizable elements.
 *
 * @param {ComposableBannerCardProps} props - The properties for the banner component.
 * @param {string} [props.className] - Additional class names for styling the banner container.
 * @param {React.ReactNode} props.title - The main title element of the banner.
 * @param {React.ReactNode} props.subTitle - The subtitle element of the banner.
 * @param {React.ReactNode} props.label - The label element within the banner content.
 * @param {React.ReactNode} props.content - The main content element of the banner.
 * @param {React.ReactNode} props.legalMention - The legal mention element of the banner.
 * @param {React.ReactNode} props.button - The button element within the banner.
 * @param {Array<string>} props.tags - The tags associated with the banner.
 *
 */
export const GlowMainBanner: Component<ComposableBannerCardProps> = ({
    className,
    title,
    subTitle,
    label,
    content,
    legalMention,
    button,
    tags,
}: ComposableBannerCardProps) => {
    return (
        <ComposableBannerCard.Container
            className={clsx(
                'absolute desktopS:right-5.5 tablet:right-3 tablet:bottom-auto bottom-1',
                'tablet:w-mainBannerCard desktopS:w-mainBannerCardDesktop px-1 tablet:px-0',
                className,
            )}
        >
            <ComposableBannerCard.MainBlock
                className={'desktop:gap-1 px-1 pt-0.75 desktop:px-1.5 desktop:pt-1 gap-0.5 l'}
            >
                <ComposableBannerCard.Title
                    content={title}
                    fontSize={'title3'}
                    fontWeight={'bold'}
                    TagName={'h2'}
                    className={'line-clamp-2'}
                    innerHTML
                />
                <ComposableBannerCard.SubTitle content={subTitle} />
                <ComposableBannerCard.Content label={label} content={content} className={'line-clamp-2'} text />
                <ComposableBannerCard.Button button={button} />
                <ComposableBannerCard.MentionLabel classNames={{ empty: 'pb-1', noEmpty: 'pb-0.5' }}>
                    {legalMention}
                </ComposableBannerCard.MentionLabel>
            </ComposableBannerCard.MainBlock>
            <ComposableBannerCard.Tags tags={tags} />
        </ComposableBannerCard.Container>
    );
};
