'use client';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { buildRoute, BURGER_MENU_ID, Position } from '@headless-workspace/config';
import { CountrySelectorValue } from '@headless-workspace/core-domain';
import {
    SideMenuModal,
    SideMenuModalList,
    SideMenuModalMap,
    UIContext,
    useOnBreakpointChange,
} from '@headless-workspace/core-ui';
import { GlowMenuModal, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { BurgerSubMenu, DataLayerClassName } from '../../../../client';
import { BurgerMenu } from '../burgerMenu';
import { CategoryValue } from '../categoryNavigation';

type SideMenuModalContainerProps = PropsWithLocale & {
    categories: CategoryValue[];
    countrySelector?: CountrySelectorValue;
    hostUrl: string | null;
};

export const SideMenuModalContainer: Component<SideMenuModalContainerProps> = ({
    categories,
    locale,
    countrySelector,
    hostUrl,
}) => {
    const {
        menuModal: { close, add, sideMenuModalList: sideMenuModalListHydrated, hydrate },
    } = useContext(UIContext);
    const [isHydrated, setIsHydrated] = useState(false);

    const burgerMenu = useMemo(() => {
        const burgerMenuContainerClassName =
            'flex flex-col bg-background-l3 tablet:w-sideMenuMobile desktopS:w-sideMenuWithScrollbarTablet';
        const map: SideMenuModalMap = {
            default: (
                <BurgerMenu
                    categories={categories}
                    locale={locale}
                    countrySelector={countrySelector}
                    hostUrl={hostUrl}
                />
            ),
        };
        for (const category of categories) {
            if (category?.subCategories.length > 0 && category.showInMenu) {
                map[category.id] = (
                    <BurgerSubMenu
                        href={category.url ?? buildRoute(locale, 'home')}
                        title={category.name}
                        subCategories={category.subCategories}
                        dataLayerId={category.dataLayerId}
                        className={DataLayerClassName.categoryNavigation} // FIXME: Used for "see all" behavior that should be considered as level 1. Will be removed in the future.
                    />
                );
            }
        }
        return SideMenuModal(Position.Left, map, burgerMenuContainerClassName);
    }, [categories, countrySelector, hostUrl, locale]);

    const baseSideMenuModalList: SideMenuModalList = useMemo(
        () => ({
            [BURGER_MENU_ID]: burgerMenu,
        }),
        [burgerMenu],
    );

    useEffect(() => {
        if (!isHydrated) {
            hydrate(baseSideMenuModalList);
            setIsHydrated(true);
        }
    }, [hydrate, isHydrated, baseSideMenuModalList]);

    const sideMenuModalList = useMemo(
        () => (isHydrated ? sideMenuModalListHydrated : baseSideMenuModalList),
        [baseSideMenuModalList, isHydrated, sideMenuModalListHydrated],
    );

    useOnBreakpointChange(
        'desktop',
        useCallback(
            (isDesktop) => {
                if (isDesktop) {
                    close(BURGER_MENU_ID, true);
                } else {
                    add(BURGER_MENU_ID, burgerMenu);
                }
            },
            [burgerMenu, close, add],
        ),
        true,
    );

    useEffect(() => {
        if (Object.keys(sideMenuModalList).some((id) => sideMenuModalList[id].isOpen)) {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
            document.body.style.paddingRight = `0px`;
        }
    }, [sideMenuModalList]);

    return Object.keys(sideMenuModalList).map((id) => {
        return (
            <GlowMenuModal
                key={id}
                className={sideMenuModalList[id].className}
                onClickOutside={() => close(id)}
                {...sideMenuModalList[id]}
            />
        );
    });
};
