'use client';

import { MouseEvent, PropsWithChildren, RefObject, useRef } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { PropsWithClassName, PropsWithStyle } from '../../props';

type HorizontalScrollContainerProps = PropsWithClassName &
    PropsWithChildren &
    PropsWithStyle & {
        ulRef?: RefObject<HTMLUListElement | null>;
    };

export const GlowHorizontalScrollContainer: Component<HorizontalScrollContainerProps> = ({
    className,
    children,
    style,
    ulRef,
}) => {
    const carouselRef = useRef<HTMLDivElement>(null);

    const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        const startX = e.pageX - (carouselRef.current?.offsetLeft || 0);
        const scrollLeft = carouselRef.current?.scrollLeft || 0;

        const handleMouseMove = (e: globalThis.MouseEvent) => {
            if (carouselRef.current) {
                const x = e.pageX - (carouselRef.current.offsetLeft || 0);
                const walk = x - startX;
                carouselRef.current.scrollLeft = scrollLeft - walk;
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <div
            ref={carouselRef}
            className={'overflow-x-scroll active:cursor-grabbing scrollbar-hide relative'}
            onMouseDown={(ev) => handleMouseDown(ev)}
            role={'presentation'}
        >
            <ul className={clsx('flex', className)} style={style} ref={ulRef}>
                {children}
            </ul>
        </div>
    );
};
