'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute, Locale } from '@headless-workspace/config';
import { getCountryFlagIcon, GlowClickable, GlowIcon, GlowTextBodySmall } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers } from '@headless-workspace/utils';

type CountriesLinkProps = {
    className?: string;
    locale: Locale;
};

export const CountriesLink: Component<CountriesLinkProps> = ({ className, locale }) => {
    const countryCode = LanguageHelpers.parseCountry(locale);
    const t = useTranslations('Common.country');
    const countryName = t('name');

    return (
        <GlowClickable
            className={clsx('gap-0.5', className)}
            href={buildRoute(locale, 'countries')}
            content={{
                labelElement: (
                    <GlowTextBodySmall
                        className={'whitespace-nowrap'}
                        TagName={'span'}
                        text={countryName}
                        textDecoration={'underline'}
                        color={'secondary-inverse-fixed'}
                    />
                ),
                icon: (
                    <GlowIcon
                        className={'h-countrySwitcherFlag w-countrySwitcherFlag rounded-infinity'}
                        Icon={getCountryFlagIcon(countryCode)}
                        type={'fullSize'}
                    />
                ),
            }}
        />
    );
};
