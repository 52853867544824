import { z } from 'zod';
import { LocaleDTO } from './LocaleDTO';

export const RefreshTokenDTO = z
    .object({
        refreshToken: z.string(),
    })
    .merge(LocaleDTO);

export type RefreshTokenDTO = z.infer<typeof RefreshTokenDTO>;
