'use client';

import { useEffect } from 'react';
import { AsyncData, InitSessionValue, ResultEmpty, UserSessionRepositorySpec } from '@headless-workspace/core-domain';
import useSWRImmutable from 'swr/immutable';
import { useAppLocale } from '../i18n';
import { useClientData } from '../providers';

type UseUserSessionReturnType = AsyncData<InitSessionValue>;

const INIT_USER_SESSION_KEY = 'user/init';

export const useUserSession = (userSessionRepository: UserSessionRepositorySpec): UseUserSessionReturnType => {
    const locale = useAppLocale();
    const { setSessionInit } = useClientData();

    const { isLoading, data } = useSWRImmutable(INIT_USER_SESSION_KEY, () =>
        userSessionRepository.createSession(locale),
    );

    const isSessionInit = data?.type === 'success' && data.data.sessionInit;

    useEffect(() => {
        if (isSessionInit) {
            setSessionInit(true);
        }
    }, [isSessionInit, setSessionInit]);

    if (!data || data.type !== 'success') {
        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
