'use client';

import clsx from 'clsx';
import { GlowMainBanner, CardBanner, CardBannerProps } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

const MAIN_BANNER = 'main banner';

/**
 * UIMainBanner component renders a main banner with an image and additional content.
 * It uses UIContainerBanner to wrap the content and applies specific class names for styling.
 *
 * @param {CardBannerProps} props - The properties for the UIMainBanner component.
 * @param {string} props.classNames - Custom class names for styling the banner.
 * @param {string} props.image - The image URL to be displayed in the banner.
 * @param {object} props.dataLayer - Data layer object for tracking banner actions.
 * @param {string} props.href - The URL to navigate to when the banner is clicked.
 *
 */
export const UIMainBanner: Component<CardBannerProps> = ({
    classNames,
    image,
    dataLayer,
    className,
    ...props
}: CardBannerProps) => {
    return (
        <CardBanner
            dataLayer={{ ...dataLayer, action: MAIN_BANNER }}
            classNames={{
                container: clsx('flex overflow-hidden justify-center items-center relative w-full', className),
                wrapper: 'flex justify-end items-center w-full overflow-hidden',
            }}
            image={{
                ...image,
                classNames: {
                    wrapper: clsx(
                        'h-full w-full',
                        'object-cover object-left',
                        'aspect-mainBannerMobile tablet:aspect-mainBannerTablet desktopS:aspect-mainBanner',
                    ),
                    cta: 'w-full',
                },
                sizes: '100vw',
                priority: true,
            }}
        >
            <GlowMainBanner {...props} />
        </CardBanner>
    );
};
