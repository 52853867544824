'use client';

import { Component } from '@headless-workspace/typings';
import { UserSessionRepositoryType } from '../../DI/dependencyInjection';
import { CommonDI } from '../../DI/index';
import { useHashObserver } from './hooks';
import { useUserSession } from './user';

export const LayoutDidMount: Component = () => {
    useUserSession(CommonDI.get(UserSessionRepositoryType));
    useHashObserver();

    return null;
};
