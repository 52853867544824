export const MEMORY_CACHE_TTL_SECONDS = 1200;

export enum MemoryCacheKey {
    AccessToken = 'accessToken',
    AccessTokenLastUpdate = 'accessTokenLastUpdate',
}

type TTLExpiresOptions = {
    compareTime: number;
    ttl: number;
};

export const isTTLExpired = (
    lastUpdateTime?: number,
    options: TTLExpiresOptions = { compareTime: Date.now(), ttl: MEMORY_CACHE_TTL_SECONDS * 1000 }
) => !lastUpdateTime || isNaN(lastUpdateTime) || options.compareTime > lastUpdateTime + options.ttl;
