import { StringHelpers } from '@headless-workspace/utils';
import { FooterReinsuranceDTO } from './FooterSlotsDTO';

export type FooterReinsuranceValue = Readonly<{
    id: string;
    image: string;
    title: string;
    description: string;
    href: string;
    ctaLabel: string;
}>;

export const mapFooterReinsurance = (dto: FooterReinsuranceDTO): FooterReinsuranceValue[] => {
    return dto.details.items.map((item, index) => ({
        id: `reinsurance-${index}`,
        image: item.image.default,
        title: StringHelpers.decodeSpecialChars(item.title),
        description: StringHelpers.decodeSpecialChars(item.description),
        href: item.link,
        ctaLabel: StringHelpers.decodeSpecialChars(item.ctaLabel),
    }));
};
