'use client';

import { GlowIcon, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { UserSephoraCard, UserSephoraCardUnlimited } from '../../../server/lib/customer/CustomerValue';

type FidIconProps = {
    userCard?: UserSephoraCard | UserSephoraCardUnlimited;
    displayPreBuyIcon: boolean;
};

const FIDS = {
    [UserSephoraCard.White]: Icons.WhiteFid,
    [UserSephoraCard.Black]: Icons.BlackFid,
    [UserSephoraCard.Gold]: Icons.GoldFid,
    [UserSephoraCard.PreBuy]: Icons.MultiFid,
    [UserSephoraCardUnlimited.Standard]: Icons.BlackFid,
    [UserSephoraCardUnlimited.Star]: Icons.GoldFid,
};

export const FidIcon: Component<FidIconProps> = ({ userCard, displayPreBuyIcon }) => {
    // Don't display icon when 'displayPreBuyIcon' is set to false and user doesn't have loyalty card or not connected
    if (!displayPreBuyIcon && (!userCard || userCard === UserSephoraCard.PreBuy)) {
        return null;
    }

    const icon = userCard ? FIDS[userCard] : Icons.MultiFid;

    return <GlowIcon Icon={icon} type={'large'} />;
};
