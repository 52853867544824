'use client';

import React, { useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { BURGER_MENU_ID } from '@headless-workspace/config';
import { GlowClickable, GlowIcon, Icons, PropsWithAriaLabel } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { UIContext, useTailwindBreakpoint } from '../../../../src';

type UIBurgerMenuButtonProps = PropsWithAriaLabel;

export const UIBurgerMenuButton: Component<UIBurgerMenuButtonProps> = ({ ariaLabel }) => {
    const isDesktop = useTailwindBreakpoint('desktop');

    const {
        menuModal: { open, close, sideMenuModalList },
    } = useContext(UIContext);

    useEffect(() => {
        if (isDesktop && sideMenuModalList[BURGER_MENU_ID]?.isOpen) {
            close(BURGER_MENU_ID);
        }
    }, [isDesktop, close, sideMenuModalList]);

    const onOpenMenu = useCallback(() => {
        open(BURGER_MENU_ID);
    }, [open]);

    return (
        <GlowClickable
            className={clsx('flex desktop:hidden', 'justify-center items-center', 'text-text-primary')}
            ariaLabel={ariaLabel}
            onClick={onOpenMenu}
            content={{
                icon: <GlowIcon Icon={Icons.Burger} type={'large'} />,
            }}
        />
    );
};
