import { CookieName } from './CookieName';

const ONE_DAY = 24 * 60 * 60 * 1000;

export const getClientCookieValue = (key: CookieName): string | undefined => {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
        const [cookieKey, cookieValue] = cookie.trim().split('=');

        if (cookieKey === key) {
            return decodeURIComponent(cookieValue);
        }
    }

    return undefined;
};

export const setClientCookie = (
    key: CookieName,
    value: string,
    days?: number,
    path?: string,
    domain?: string,
    secure?: boolean
): void => {
    let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * ONE_DAY);
        cookieString += `; expires=${date.toUTCString()}`;
    }
    if (path) {
        cookieString += `; path=${path}`;
    }
    if (domain) {
        cookieString += `; domain=${domain}`;
    }
    if (secure) {
        cookieString += '; secure';
    }

    document.cookie = cookieString;
};
