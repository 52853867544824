import clsx from 'clsx';
import {
    Brandorama,
    GlowBrandoramaBanner,
    GlowContainerSection,
    GlowHorizontalScrollContainer,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type UIBrandoramaContainerProps = {
    title: string;
    subtitle: string;
    button: {
        label: string;
        href: string;
    };
    brandoramaTiles: Brandorama[];
    className?: string;
};

export const UIBrandoramaContainer: Component<UIBrandoramaContainerProps> = ({
    title,
    subtitle,
    button,
    brandoramaTiles,
    className,
}) => (
    <GlowContainerSection
        title={title}
        subtitle={subtitle}
        button={button}
        className={clsx('gap-1.5 tablet:gap-2', className)}
        titleContainerClassName={'px-1 desktop:px-1.5'}
    >
        <GlowHorizontalScrollContainer className={'gap-1.5 desktopS:gap-1.25 justify-between'}>
            {brandoramaTiles.map((brandorama) => (
                <GlowBrandoramaBanner
                    className={clsx('first:pl-1 desktop:first:pl-1.5 last:pr-1 desktopS:last:pr-1.5')}
                    key={`brand-${brandorama.id}`}
                    {...brandorama}
                />
            ))}
        </GlowHorizontalScrollContainer>
    </GlowContainerSection>
);
