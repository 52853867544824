'use client';

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserInfo, UserInfoErrors, getUserInfoServerAction } from '../../../server';
import { useSafeSWR } from '../hooks';
import { UserRevalidationKey } from './UserRevalidationKey';

type UseUserInfoReturnType = AsyncData<UserInfo>;

export const useUserInfo = (): UseUserInfoReturnType => {
    const t = useTranslations('Common.user.error');
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.User,
        ServerActionName.getUserInfo,
        getUserInfoServerAction,
    );

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    UserInfoErrors.Default,
                    error instanceof Error ? error.message : t('defaultError'),
                ),
            };
        }

        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
