import { envConfig } from '@headless-workspace/config';
import crypto from 'crypto';
import { IsomorphicLogger } from '../logger';

const LOGGER_ERROR_LABEL = 'EncryptionHelper';

const ENCRYPTION_ALGORITHM = 'AES-256-CBC';
const ENCRYPTION_DIGEST = 'SHA-256';

const ITERATIONS_NUMBER = 1000;
const KEY_LENGTH = 32;

/**
 * Encrypts a given value using AES-256-CBC algorithm.
 *
 * @param {string} value - The value to be encrypted.
 * @param {string} [secret=envConfig.serverEncryptionSecret] - The secret key used for encryption.
 * @param {string} [salt=envConfig.serverEncryptionSalt] - The salt used for key derivation.
 * @param {string} [initVector=envConfig.serverEncryptionIV] - The initialization vector for the cipher.
 * @returns {string | undefined} - The encrypted value in base64 format, or undefined if encryption fails.
 */
const encrypt = (
    value: string,
    secret: string = envConfig.serverEncryptionSecret,
    salt: string = envConfig.serverEncryptionSalt,
    initVector: string = envConfig.serverEncryptionIV,
): string | undefined => {
    try {
        const encryptionKey = crypto.pbkdf2Sync(
            secret,
            Buffer.from(salt, 'hex'),
            ITERATIONS_NUMBER,
            KEY_LENGTH,
            ENCRYPTION_DIGEST,
        );
        const cipher = crypto.createCipheriv(ENCRYPTION_ALGORITHM, encryptionKey, Buffer.from(initVector, 'hex'));
        let encrypted = cipher.update(value, 'utf8', 'base64');
        encrypted += cipher.final('base64');
        return encrypted;
    } catch (error) {
        if (error instanceof Error) {
            new IsomorphicLogger().error(LOGGER_ERROR_LABEL, error);
        }
        return undefined;
    }
};

/**
 * Decrypts a given encrypted value using AES-256-CBC algorithm.
 *
 * @param {string} encryptedValue - The value to be decrypted.
 * @param {string} [secret=envConfig.serverEncryptionSecret] - The secret key used for decryption.
 * @param {string} [salt=envConfig.serverEncryptionSalt] - The salt used for key derivation.
 * @param {string} [initVector=envConfig.serverEncryptionIV] - The initialization vector for the cipher.
 * @returns {string | undefined} - The decrypted value in utf8 format, or undefined if decryption fails.
 */
const decrypt = (
    encryptedValue: string,
    secret: string = envConfig.serverEncryptionSecret,
    salt: string = envConfig.serverEncryptionSalt,
    initVector: string = envConfig.serverEncryptionIV,
): string | undefined => {
    try {
        const encryptionKey = crypto.pbkdf2Sync(
            secret,
            Buffer.from(salt, 'hex'),
            ITERATIONS_NUMBER,
            KEY_LENGTH,
            ENCRYPTION_DIGEST,
        );
        const decipher = crypto.createDecipheriv(ENCRYPTION_ALGORITHM, encryptionKey, Buffer.from(initVector, 'hex'));
        const decrypted = decipher.update(encryptedValue, 'base64', 'utf8');
        return decrypted + decipher.final('utf8');
    } catch (error) {
        if (error instanceof Error) {
            new IsomorphicLogger().error(LOGGER_ERROR_LABEL, error);
        }
        return undefined;
    }
};

/**
 * Helper object for encryption and decryption operations.
 */
export const EncryptionHelpers = {
    encrypt,
    decrypt,
};
