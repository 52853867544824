/**
 * Calculates the number of loyalty points for a given product price.
 * @param {number} price product price
 * @param {number} loyaltyPointsPerCurrency loyalty points per currency
 * @return {number} calculated loyalty program points according to loyaltyPointsPerCurrency
 */
const calcPoints = (price: number, loyaltyPointsPerCurrency = 1): number => {
    if (!price) {
        return 0;
    }
    return Math.floor(price / loyaltyPointsPerCurrency);
};

export const LoyaltyHelpers = {
    calcPoints,
};
