import { Component } from '@headless-workspace/typings';
import { Button } from './Button';
import { IconButton } from './IconButton';
import { GlowButtonProps, isButtonProps } from './buttonProps';

/**
 * GlowButton component
 */
export const GlowButton: Component<GlowButtonProps> = (props) => {
    if (isButtonProps(props)) {
        return <Button {...props} />;
    }

    return <IconButton {...props} />;
};
