import {
    NetStorageRepository,
    NetStorageRepositorySpec,
    SessionCookiesRepository,
    SessionCookiesRepositorySpec,
    ShopperTokenRepository,
    ShopperTokenRepositorySpec,
    UserCookiesRepository,
    UserCookiesRepositorySpec,
    UserSessionRepository,
    UserSessionRepositorySpec,
} from '@headless-workspace/core-domain';
import { RequestStrategy } from '@headless-workspace/data';
import { IsomorphicLogger, Logger } from '@headless-workspace/utils';
import {
    CatalogCategoriesRepository,
    CatalogCategoriesRepositorySpec,
    CrossBorderRepository,
    CrossBorderRepositorySpec,
    CustomerRepository,
    CustomerRepositorySpec,
    FooterSlotsRepository,
    FooterSlotsRepositorySpec,
    RedDotRepository,
    RedDotRepositorySpec,
    SearchGeolocationRepository,
    SearchGeolocationRepositorySpec,
    SearchRepository,
    SearchRepositorySpec,
    VipBrandsRepository,
    VipBrandsRepositorySpec,
} from '../../src';

export const CrossBorderRepositoryType = 'CrossBorderRepository';
export const CatalogCategoriesRepositoryType = 'CatalogCategoriesRepository';
export const CustomerRepositoryType = 'CustomerRepository';
export const FooterSlotsRepositoryType = 'FooterSlotsRepository';
export const LoggerType = 'Logger';
export const NetStorageRepositoryType = 'NetStorageRepository';
export const RedDotRepositoryType = 'RedDotRepository';
export const SearchGeolocationRepositoryType = 'SearchGeolocationRepository';
export const SearchRepositoryType = 'SearchRepository';
export const SessionCookiesRepositoryType = 'SessionCookiesRepository';
export const ShopperTokenRepositoryType = 'ShopperTokenRepository';
export const UserCookiesRepositoryType = 'UserCookiesRepository';
export const UserSessionRepositoryType = 'UserSessionRepository';
export const VipBrandsRepositoryType = 'VipBrandsRepository';

export const deps = () => ({
    [CrossBorderRepositoryType]: CrossBorderRepository as new () => CrossBorderRepositorySpec,
    [CatalogCategoriesRepositoryType]: CatalogCategoriesRepository as new () => CatalogCategoriesRepositorySpec,
    [CustomerRepositoryType]: CustomerRepository as new (requestStrategy: RequestStrategy) => CustomerRepositorySpec,
    [FooterSlotsRepositoryType]: FooterSlotsRepository as new () => FooterSlotsRepositorySpec,
    [LoggerType]: IsomorphicLogger as new () => Logger,
    [NetStorageRepositoryType]: NetStorageRepository as new () => NetStorageRepositorySpec,
    [RedDotRepositoryType]: RedDotRepository as new () => RedDotRepositorySpec,
    [SearchGeolocationRepositoryType]: SearchGeolocationRepository as new (
        apiBaseUrl: string,
    ) => SearchGeolocationRepositorySpec,
    [SearchRepositoryType]: SearchRepository as new () => SearchRepositorySpec,
    [SessionCookiesRepositoryType]: SessionCookiesRepository as new (
        requestStrategy: RequestStrategy,
    ) => SessionCookiesRepositorySpec,
    [ShopperTokenRepositoryType]: ShopperTokenRepository as new () => ShopperTokenRepositorySpec,
    [UserCookiesRepositoryType]: UserCookiesRepository as new () => UserCookiesRepositorySpec,
    [UserSessionRepositoryType]: UserSessionRepository as new () => UserSessionRepositorySpec,
    [VipBrandsRepositoryType]: VipBrandsRepository as new () => VipBrandsRepositorySpec,
});
