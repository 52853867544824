import { FooterLinksValue, mapFooterLinks } from './FooterLinksValue';
import { FooterReinsuranceValue, mapFooterReinsurance } from './FooterReinsuranceValue';
import { FooterSlotDTOTypes } from './FooterSlotDTOTypes';
import { FooterSlotsDTO } from './FooterSlotsDTO';
import { FooterSocialNetworkValue, mapFooterSocialNetwork } from './FooterSocialNetworkValue';
import { FooterComplianceValue, mapFooterCompliance } from './footerLegalLinks/FooterComplianceValue';

export type FooterSlotsValue = Readonly<{
    reinsurance: FooterReinsuranceValue[];
    links: FooterLinksValue[];
    compliance: FooterComplianceValue[];
    socialNetwork: FooterSocialNetworkValue[];
}>;

export const mapFooterSlots = (dto: FooterSlotsDTO): FooterSlotsValue => {
    return dto.slots.reduce<FooterSlotsValue>(
        (acc, slot) => {
            return {
                reinsurance:
                    slot.type === FooterSlotDTOTypes.Reinsurance.value ? mapFooterReinsurance(slot) : acc.reinsurance,
                links: slot.type === FooterSlotDTOTypes.Columns.value ? mapFooterLinks(slot) : acc.links,
                compliance:
                    slot.type === FooterSlotDTOTypes.Compliance.value ? mapFooterCompliance(slot) : acc.compliance,
                socialNetwork:
                    slot.type === FooterSlotDTOTypes.Social.value ? mapFooterSocialNetwork(slot) : acc.socialNetwork,
            };
        },
        {
            reinsurance: [],
            links: [],
            compliance: [],
            socialNetwork: [],
        },
    );
};
