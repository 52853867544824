'use client';

import { useSWRConfig } from 'swr';
import { UserRevalidationKey } from './UserRevalidationKey';

export const useUserDataRevalidation = () => {
    const { mutate } = useSWRConfig();

    const revalidateAll = async () => {
        await Promise.all([
            mutate(UserRevalidationKey.User),
            mutate(UserRevalidationKey.Basket),
            mutate(UserRevalidationKey.FavoriteStore),
            mutate(UserRevalidationKey.Wishlist),
        ]);
    };

    return { revalidateAll };
};
