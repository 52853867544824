import { featureFlipping } from '@headless-workspace/config';
import { ApiError, DEFAULT_STATUS_CODE } from '../../datasource';

export const createApiErrorFromError = (error: Error, responseStatusCode?: number): ApiError => {
    return new ApiError(responseStatusCode ?? DEFAULT_STATUS_CODE, {
        errors: [
            {
                errorCode: error.name.toUpperCase(),
                errorMessage: error.message,
                tips: featureFlipping.isDebugServerErrorEnabled ? error.stack : undefined,
            },
        ],
    });
};
