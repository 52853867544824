import { SearchGeolocationAddressDTO } from './SearchGeolocationAddressDTO';

export type SearchGeolocationAddressValue = Readonly<{
    country: string;
    state: string;
    locality: string;
    postalCode: string;
    route: string;
    streetNumber: string;
}>;

enum SearchGeolocationAddressKey {
    country = 'country',
    state = 'state',
    locality = 'locality',
    postalCode = 'postal_codes',
    route = 'route',
    streetNumber = 'street_number',
}

export const mapSearchGeolocationAddress = (dto: SearchGeolocationAddressDTO): SearchGeolocationAddressValue => {
    const mappedAddressValues: { [key: string]: string } = dto.address_components
        .map((addressComponent) => ({
            [addressComponent.types[0]]: addressComponent.long_name,
        }))
        .reduce(function (result, item) {
            const key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
        });

    return {
        country: mappedAddressValues[SearchGeolocationAddressKey.country],
        state: mappedAddressValues[SearchGeolocationAddressKey.state],
        locality: mappedAddressValues[SearchGeolocationAddressKey.locality],
        postalCode: mappedAddressValues[SearchGeolocationAddressKey.postalCode],
        route: mappedAddressValues[SearchGeolocationAddressKey.route],
        streetNumber: mappedAddressValues[SearchGeolocationAddressKey.streetNumber],
    };
};
