'use client';

import { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute, isUnlimitedLoyaltyLocale } from '@headless-workspace/config';
import { GlowDivider, GlowMenuButton, GlowTextBody, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { getConnectedUserInfo, isConnectedUserInfo, isUserGold } from '../../../../server';
import { DataLayerClassName } from '../../dataLayer';
import { useUserInfo } from '../../user';

type BurgerMenuConnectionItemProps = PropsWithLocale;

export const BurgerMenuConnectionItem: Component<BurgerMenuConnectionItemProps> = ({ locale }) => {
    const t = useTranslations('Header.navigation');
    const { result: userInfoResult } = useUserInfo();

    const isConnected = userInfoResult.type === 'success' && isConnectedUserInfo(userInfoResult.data);

    const label = useMemo(() => {
        const userInfo = getConnectedUserInfo(userInfoResult);
        const isUnlimitedGold = userInfo && isUnlimitedLoyaltyLocale(locale) && isUserGold(userInfo);
        if (isConnected) {
            if (isUnlimitedGold) {
                return (
                    <div className={'flex gap-0.5'}>
                        <GlowTextBody text={t('link.myAccount')} />
                        <GlowDivider vertical />
                        <GlowTextBody text={t('link.myStatus')} />
                        <GlowTextBody text={'GOLD'} fontWeight={'bold'} />
                    </div>
                );
            }
            return t('link.myAccount');
        }
        return t('link.login');
    }, [userInfoResult, locale, isConnected, t]);

    const href = isConnected ? buildRoute(locale, 'account') : buildRoute(locale, 'connection');

    return (
        <GlowMenuButton
            label={label}
            Icon={Icons.Profile}
            href={href}
            className={clsx(
                'px-1',
                'bg-background-l2 fill-text-primary text-text-primary',
                DataLayerClassName.connection,
            )}
        />
    );
};
