import { VipBrandsValue } from '../../config';
import { mapSearchProduct, SearchProductValue } from './SearchProductValue';
import { SearchSuggestionsDTO } from './SearchSuggestionsDTO';
import { mapTextSuggestion, TextSuggestionValue } from './TextSuggestionValue';

export type SearchSuggestionsValue = Readonly<{
    brands: TextSuggestionValue[];
    products: SearchProductValue[];
    categories: TextSuggestionValue[];
    terms: TextSuggestionValue[];
}>;

export const mapSearchSuggestions = (dto: SearchSuggestionsDTO, vipBrands: VipBrandsValue): SearchSuggestionsValue => {
    return {
        brands: dto.searchSuggestions.brands.map(mapTextSuggestion),
        products: dto.searchSuggestions.products.map((dto) => mapSearchProduct(dto, vipBrands)),
        categories: dto.searchSuggestions.categories.map(mapTextSuggestion),
        terms: dto.searchSuggestions.terms.map(mapTextSuggestion),
    };
};
