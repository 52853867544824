'use client';

import { createRef, ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { PropsWithId } from '../../../../src';
import { TabContent, TabContentProps } from './TabContent';
import { TabLabel, TabLabelProps } from './TabLabel';

type GetPropsWithoutId<T extends PropsWithId> = Omit<T, 'id'>;
type Ref = RefObject<HTMLButtonElement | null>;
type Nodes = ReactNode[];

type IndexOrId = string | number;

type Item = Required<PropsWithId> & {
    index: number;
    ref: Ref;
};

type Items = Map<IndexOrId, Item>;

export type Tab = GetPropsWithoutId<TabLabelProps> & GetPropsWithoutId<TabContentProps>;

export const useTabs = (idPrefix: string, tabs: Tab[], defaultIndex = 0) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const { items, labels, content, maxIndex } = useMemo(() => {
        const items: Items = new Map();
        const labels: Nodes = [];
        const content: Nodes = [];
        const maxIndex = tabs.length - 1;

        tabs.forEach(({ label, Content }, index) => {
            const id = `${idPrefix}-${index}`;
            const ref = createRef<HTMLButtonElement>();
            const item: Item = { index, id, ref };

            items.set(index, item);
            items.set(id, item);

            labels.push(<TabLabel key={id} ref={ref} id={id} label={label} />);
            content.push(<TabContent key={id} id={id} Content={Content} />);
        });

        return { items, labels, content, maxIndex };
    }, [idPrefix, tabs]);

    const current = useMemo(() => items.get(currentIndex)?.id, [currentIndex, items]);

    const { isFirst, isLast } = useMemo(
        () => ({ isFirst: currentIndex === 0, isLast: currentIndex === maxIndex }),
        [currentIndex, maxIndex],
    );

    const show = useCallback(
        (indexOrId: IndexOrId) => {
            const item = items.get(indexOrId);

            if (item) {
                item.ref.current?.scrollIntoView({ block: 'nearest' });
                setCurrentIndex(item.index);
            }
        },
        [setCurrentIndex, items],
    );

    const showPrevious = useCallback(() => show(currentIndex - 1), [currentIndex, show]);

    const showNext = useCallback(() => show(currentIndex + 1), [currentIndex, show]);

    useEffect(() => {
        show(defaultIndex as number);
    }, [defaultIndex, show]);

    return { labels, content, current, isFirst, isLast, show, showPrevious, showNext };
};
