'use client';

import { useEffect } from 'react';

export const useDetectScroll = (containerId: string, contentId: string, isMobile: boolean, detectScrollOffset = 10) => {
    useEffect(() => {
        const container = document.getElementById(containerId);
        const content = document.getElementById(contentId);

        const handleScroll = () => {
            if (isMobile && content && container && container.scrollTop > detectScrollOffset) {
                content.blur();
            }
        };

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [containerId, contentId, isMobile]);
};
