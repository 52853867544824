import { z } from 'zod';

export const ProductTypeDTO = z.enum([
    'PRODUCT_SET',
    'MONO_SKU',
    'MULTI_SKU_SIZE',
    'MULTI_SKU_SHADE_UNI',
    'MULTI_SKU_SHADE_DIFF',
]);

export type ProductTypeDTO = z.infer<typeof ProductTypeDTO>;
