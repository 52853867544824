export const pt_PT_NavRoutes = {
    home: '',
    connection: 'login',
    storesAndServices: 'lojas-sephora',
    wishlist: 'wishlist',
    basket: 'carrinho',
    payment: '', // FIXME: To Update
    account: 'aminhaconta',
    orders: 'as-minhas-encomendas',
    followOrder: 'on/demandware.store/Sites-Sephora_ES-Site/pt_PT/Order-TrackMyOrder',
    loyalty: 'loyalty-program.html',
    offers: '',
    profile: 'perfil',
    mail: '',
    help: 'faq-sephora',
    allServices: 'nossos-servicos.html',
    storeInfo: 'informacao-loja',
    allStores: 'lojas-sephora',
    countries: 'internacional.html',
    beautyTips: 'campanhas',
    logout: 'logu/?redirectToReferer=true',
    loyaltyProgram: '',
    allBrands: 'marcas/marcas-de-a-z-brands',
    clickAndCollect: 'experiencia-online',
    securePayment: 'https://faq.sephora.pt/s/article/Quais-os-m%C3%A9todos-de-pagamento-aceites-no-site-e-em-lojas',
    returnProduct: 'experiencia-online',
    privacyPolicy: 'test/privacidade-e-cookies.html',
    search: 'procurar/?q=',
    reviewConditions: '',
};
