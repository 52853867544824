export type LogFn = (...data: unknown[]) => void;

export enum LogLevel {
    Error = 'error',
    Info = 'info',
    Warn = 'warn',
}

export interface Logger {
    info(label: string, ...params: unknown[]): void;
    warn(label: string, ...params: unknown[]): void;
    error(label: string, ...params: unknown[]): void;
}
