import { ReactNode } from 'react';
import { ToastType } from '@headless-workspace/glow-ds';
import { Toast, useToastActionsContext } from '../providers/toastProvider/ToastContext';

export const useToasts = () => {
    const { showToast, clearAllToasts, closeToast } = useToastActionsContext();

    return {
        notifyError(id: string, label: ReactNode, options?: Toast['options']) {
            return showToast({ id, label, type: ToastType.Error, options });
        },
        notifySuccess(id: string, label: ReactNode, options?: Toast['options']) {
            return showToast({ id, label, type: ToastType.Success, options });
        },
        notifyInfo(id: string, label: ReactNode, options?: Toast['options']) {
            return showToast({ id, label, type: ToastType.Info, options });
        },
        clearAllToasts,
        closeToast,
    };
};
