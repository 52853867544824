export const sv_SE_NavRoutes = {
    home: '',
    connection: 'logga-in',
    storesAndServices: 'alla-butiker',
    wishlist: 'wishlist',
    basket: 'varukorg',
    payment: '', // FIXME: To Update
    account: 'mitt-konto',
    orders: 'mina-kop',
    followOrder: 'on/demandware.store/Sites-Sephora_Scandinavia-Site/sv_SE/Order-TrackMyOrder',
    loyalty: 'loyalty-scandinavia.html',
    offers: '',
    profile: 'profil',
    mail: '',
    help: '/faq-sephora',
    allServices: 'sephora-store-services.html',
    storeInfo: 'butik',
    allStores: 'alla-butiker',
    countries: 'international_SC.html',
    beautyTips: 'rea',
    logout: 'logga-ut/?redirectToReferer=true',
    loyaltyProgram: '',
    allBrands: 'marken/varumarken-fran-a---o-brands',
    clickAndCollect: 'click-and-collect',
    securePayment: 'https://faq.sephora.se/s/article/Vilka-betals%C3%A4tt-%C3%A4r-till%C3%A5tna?language=sv',
    returnProduct: 'https://faq.sephora.se/s/article/Vilka-betals%C3%A4tt-%C3%A4r-till%C3%A5tna?language=sv',
    privacyPolicy: 'cookies_SC.html',
    search: 'sok/?q=',
    reviewConditions: '',
};
