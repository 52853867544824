export const el_GR_NavRoutes = {
    home: '',
    connection: 'syndesh',
    storesAndServices: 'katasthmata-sephora',
    wishlist: 'wishlist',
    basket: 'kalathi-agoron',
    payment: '', // FIXME: To Update
    account: 'on/demandware.store/Sites-Sephora_RO-Site/el_GR/Account-Show',
    orders: 'oi-paraggelies',
    followOrder: 'oi-paraggelies?isGuestOrderTracking=true',
    loyalty: 'discover-loyalty-card.html',
    profile: 'o-logariasmos-mou',
    offers: '',
    mail: 'profil/?is_communication_link=true',
    help: 'syxnes-erotiseis',
    allServices: 'in-store-services.html',
    loyaltyProgram: '',
    storeInfo: 'plhrofories-katasthmaton',
    allStores: 'katasthmata-sephora',
    beautyTips: 'monadikes-prosfores',
    countries: 'international-ro.html',
    logout: 'aposyndesh/?redirectToReferer=true',
    allBrands: 'brands/a---z/brands/',
    clickAndCollect: 'on/demandware.store/Sites-Sephora_RO-Site/el_GR/click-and-collect',
    securePayment: 'syxnes-erotiseis',
    returnProduct: 'syxnes-erotiseis',
    privacyPolicy: 'politiki-aporritou-cookies.html',
    search: 'anazhthsh?q=',
    reviewConditions: '',
};
