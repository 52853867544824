// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = (): void => {};

const nullFn = (): null => null;

const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

const numberToPx = (value: number): string => `${value}px`;

export const FunctionHelpers = {
    emptyFn,
    nullFn,
    delay,
    numberToPx,
};
