import React, { isValidElement } from 'react';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { GlowTypographyProps, typographyStyle } from './GlowTypographyProps';

/**
 * Represents a GlowTypography component.
 *
 * @param className - The additional CSS class name for the component.
 * @param fontFamily - The font family variant for the component.
 * @param fontSize - The font size variant for the component.
 * @param fontWeight - The font weight variant for the component.
 * @param textDecoration - The text decoration variant for the component.
 * @param TagName - The HTML tag name to be used for the component.
 * @param color - The color variant for the component.
 * @param text - The text content of the component.
 * @param innerHTML - The HTML content of the component.
 * @param dataTestId - The data-testid attribute for the component.
 * @param role - The role attribute for the component.
 * @param props - Additional props to be spread to the underlying HTML element.
 * @returns The rendered GlowTypography component.
 */
export const GlowTypography: Component<GlowTypographyProps> = ({
    className,
    fontFamily,
    fontSize,
    fontWeight,
    textDecoration,
    TagName = 'p',
    color = 'primary',
    text,
    innerHTML,
    dataTestId,
    role,
    ...props
}) => {
    if (!text && !innerHTML) {
        return null;
    }

    const classNames = typographyStyle({ fontFamily, fontSize, textDecoration, fontWeight, className, color });

    if (typeof innerHTML !== 'string' && isValidElement(innerHTML)) {
        // @ts-expect-error innerHtml is a ReactNode, in this case it should always have a rawHtml prop
        const htmlString = innerHTML.props?.rawHtml?.content ?? innerHTML;

        if (typeof htmlString === 'string') {
            return (
                <TagName
                    className={classNames}
                    dangerouslySetInnerHTML={{ __html: StringHelpers.removeOuterHTMLTag(htmlString) }}
                    data-testid={dataTestId}
                    role={role}
                    {...props}
                />
            );
        }
    }
    return (
        <TagName className={classNames} data-testid={dataTestId} role={role} {...props}>
            {text || innerHTML}
        </TagName>
    );
};
