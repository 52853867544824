'use client';

import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useLayoutActionContext } from '../providers';
import { useScrollDirection } from './useScrollDirection';

const SEARCH_MENU_ID = 'search-menu';

const createPlaceholderElement = () => {
    if (typeof document !== 'undefined') {
        const placeholderElement = document.createElement('div');
        placeholderElement.classList.add('h-headerDesktop');
        return placeholderElement;
    }
};

export const useFixedHeader = (
    fixedHeaderRef: RefObject<HTMLElement | null>,
    persistentBannerRef: RefObject<HTMLElement | null>,
    isDesktop: boolean,
    isSearchOpen: boolean,
) => {
    const [isDesktopHeaderVisible, setIsHeaderDesktopVisible] = useState(true);
    const [translate, setTranslate] = useState('0');
    const [headerHeight, setHeaderHeight] = useState(0);
    const { scrollDirection, scrollDifference } = useScrollDirection();
    const {
        header: { setHeaderOffset },
    } = useLayoutActionContext();

    // Get header (+ search menu if open) height
    useEffect(() => {
        const searchMenu = document.getElementById(SEARCH_MENU_ID);

        const searchMenuHeight = searchMenu ? searchMenu.getBoundingClientRect().height : 0;
        const fixedHeaderHeight = fixedHeaderRef.current ? fixedHeaderRef.current.getBoundingClientRect().height : 0;
        const height = fixedHeaderHeight + searchMenuHeight;

        setHeaderHeight(height);
    }, [fixedHeaderRef, isSearchOpen]);

    // Check for desktop header visibility
    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsHeaderDesktopVisible(entry.isIntersecting);
        });

        if (persistentBannerRef.current) {
            observer.observe(persistentBannerRef.current as HTMLElement);
        }

        return () => observer.disconnect();
    }, [persistentBannerRef]);

    const computeTranslate = useCallback(() => {
        if (fixedHeaderRef.current) {
            const currentTranslation = fixedHeaderRef.current.getBoundingClientRect().top;

            // Apply translation only if :
            // - scrolling down
            // - header is not yet completely hidden
            if (scrollDirection === 'down' && currentTranslation > -headerHeight) {
                // Translate the header up, until it is completely hidden
                const translation = Math.max(currentTranslation - scrollDifference, -headerHeight);
                setTranslate(`translate(0, ${translation}px)`);
            }

            // Apply translation only if :
            // - scrolling up
            // - header is not yet completely visible
            if (scrollDirection === 'up' && currentTranslation < 0) {
                // Translate the header down, until it is completely visible
                const translation = Math.min(currentTranslation + Math.abs(scrollDifference), 0);
                setTranslate(`translate(0, ${translation}px)`);
            }
        }
    }, [fixedHeaderRef, headerHeight, scrollDifference, scrollDirection]);

    useEffect(() => {
        window.addEventListener('scroll', computeTranslate);
        return () => {
            window.removeEventListener('scroll', computeTranslate);
        };
    }, [computeTranslate]);

    // Create a div element as a placeholder for the fixed header (to avoid CLS)
    const fixedHeaderPlaceholder = useMemo(createPlaceholderElement, []);

    // Only display placeholder on desktop and hide it when header is visible
    useEffect(() => {
        if (fixedHeaderRef && isDesktop && fixedHeaderPlaceholder) {
            fixedHeaderRef.current?.after(fixedHeaderPlaceholder);
            isDesktopHeaderVisible
                ? fixedHeaderPlaceholder.classList.add('hidden')
                : fixedHeaderPlaceholder.classList.remove('hidden');
        }
    }, [fixedHeaderPlaceholder, fixedHeaderRef, isDesktop, isDesktopHeaderVisible]);

    // Compute header offset (the height of header visible in the viewport) and store it in layoutContext
    useEffect(() => {
        if (fixedHeaderRef.current) {
            const currentTranslation = fixedHeaderRef.current.getBoundingClientRect().top;
            const headerOffset = headerHeight + currentTranslation;
            setHeaderOffset(headerOffset);
        }
    }, [fixedHeaderRef, headerHeight, setHeaderOffset, translate]);

    return { translate, isDesktopHeaderVisible };
};
