import { HeaderConnectionBannerItemId } from '@headless-workspace/core-domain';

export const ConnectionDataLayerLabel = {
    [HeaderConnectionBannerItemId.MyAccount]: 'my account',
    [HeaderConnectionBannerItemId.MyOrders]: 'layer_order',
    [HeaderConnectionBannerItemId.FollowMyOrder]: 'layer_track_my_order',
    [HeaderConnectionBannerItemId.MyLoyaltyProgram]: 'my account loyalty program',
    [HeaderConnectionBannerItemId.MyOffers]: 'my offers',
    [HeaderConnectionBannerItemId.MakeAnAppointment]: 'take an appointment',
    [HeaderConnectionBannerItemId.MySettings]: 'my parameters',
    [HeaderConnectionBannerItemId.MyMessages]: 'my communications',
    [HeaderConnectionBannerItemId.Help]: 'need help',
    [HeaderConnectionBannerItemId.MyNews]: 'my news',
};
