import { PredicateHelpers } from '@headless-workspace/utils';
import { DynamicTagValue } from './DynamicTagValue';
import { HeaderConnectionBannerItemValue, mapHeaderConnectionBannerItem } from './HeaderConnectionBannerItemValue';
import { HeaderSlotValue, mapHeaderSlot } from './HeaderSlotValue';
import { HeaderSlotsDTO } from './HeaderSlotsDTO';

export type HeaderSlotsValue = {
    rightItems: HeaderSlotValue[];
    connectionBannerItems: HeaderConnectionBannerItemValue[];
    storeBannerFavoriteStoreItems: string[];
    dynamicOfferRules?: DynamicTagValue;
};

export const mapHeaderSlots = (dto: HeaderSlotsDTO): HeaderSlotsValue => {
    return {
        rightItems: dto.items.map(mapHeaderSlot).filter(PredicateHelpers.notNullOrUndefined),
        connectionBannerItems: dto.connectionBannerItems
            .map(mapHeaderConnectionBannerItem)
            .filter(PredicateHelpers.notNullOrUndefined),
        storeBannerFavoriteStoreItems: dto.storeBannerFavoriteStoreItem,
        dynamicOfferRules: dto.dynamicOfferRules,
    };
};
