import { HeadersName } from '../datasource';

export type AuthorizationStrategy = {
    provideAuthorizationHeaders: () => Partial<Record<HeadersName, string>>;
    provideCredentials: () => { credentials: RequestCredentials } | undefined;
};

export const defaultServerAuthStrategy: Partial<AuthorizationStrategy> = {};

export const memoryCacheAuthStrategy: Partial<AuthorizationStrategy> = {};
