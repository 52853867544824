import React from 'react';
import { Component } from '@headless-workspace/typings';

type GlowProgressBarProps = {
    value: number;
    max?: number;
};

/**
 * A component that renders a progress bar.
 * @param value - The current progress value.
 * @param max - The maximum value of the progress bar. Defaults to 100.
 */
export const GlowProgressBar: Component<GlowProgressBarProps> = ({ value, max = 100 }) => {
    const calculatedProgress = value >= max ? 100 : (value / max) * 100;

    return (
        <div role={'progressbar'} className={'relative w-full h-progressBar rounded-infinity bg-background-tertiary'}>
            <div
                className={'absolute h-full bg-background-inverse rounded-infinity'}
                style={{ width: `${calculatedProgress}%` }}
            />
        </div>
    );
};
