export enum DataLayerKey {
    Country = 'country',
    EnvChannel = 'env_channel',
    EnvCountry = 'env_country',
    EnvLanguage = 'env_language',
    EnvPlatform = 'env_platform',
    EnvTemplate = 'env_template',
    EnvWork = 'env_work',
    FavoriteCreditCardDeclared = 'favorite_credit_card_declared',
    FavoriteOrderAddressDeclared = 'favorite_order_address_declared',
    PageName = 'page_name',
    PageTopCat = 'page_top_cat',
    UserCardOwner = 'user_card_owner',
    UserEmail = 'user_email',
    UserEmailOptin = 'user_email_optin',
    UserGender = 'user_gender',
    UserId = 'user_id',
    UserIdentified = 'user_identified',
    UserInscriptionDate = 'user_inscription_date',
    UserLogged = 'user_logged',
    UserLoyaltyCategory = 'user_loyalty_category',
    UserNewCustomer = 'user_newcustomer',
    UserPurchaseFrequency = 'user_purchase_frequency',
    UserStore = 'user_store',
    UserType = 'user_type',
    UserWithOffer = 'user_with_offer',
    ProductDisplayType = 'product_display_type',
    ProductPid = 'product_pid',
    EnvHeadless = 'env_headless',
}
