'use client';

import { GlowGWPBanner, CardBannerProps, GenericCard } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';

/**
 * UIGWP component renders a UIContainerBanner with a GlowImageBanner and GlowGWP.
 *
 * @param {UIContainerBannerProps} props - The properties for the UIGWP component.
 * @param {string} props.image - The image URL to be displayed in the banner.
 * @param {string} props.className - Additional CSS classes to apply to the banner.
 * @param {object} props.dataLayer - Data layer object for tracking purposes.
 */

export const UIGWPBanner: Component<CardBannerProps> = (props: CardBannerProps) => {
    return (
        <GenericCard
            {...props}
            classNames={{
                container: 'tablet:items-start overflow-hidden flex-none',
                imgWrapper: 'w-gwpImg aspect-1/1',
                cta: 'tablet:w-gwpImg',
            }}
            sizes={`(max-width: ${tailwindTheme.screens.tablet}) 100vw, ${tailwindTheme.width.gwpImg}`}
            fill
        >
            <GlowGWPBanner {...props} />
        </GenericCard>
    );
};
