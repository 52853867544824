'use client';

import { PropsWithChildren, useMemo, useState } from 'react';
import { TOAST_DEFAULT_DURATION } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';
import { Toast, ToastActionsContext, ToastStateContext, ToastWithTimer } from './ToastContext';

type ToastProviderProps = PropsWithChildren;

const defaultToastOptions: Toast['options'] = {
    animation: true,
    persistent: false,
    duration: TOAST_DEFAULT_DURATION,
};

export const ToastProvider: Component<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<ToastWithTimer[]>([]);

    const showToast = (incomingToast: Toast) => {
        const toast = { ...incomingToast, options: { ...defaultToastOptions, ...incomingToast.options } };

        const timer = toast.options.persistent
            ? undefined
            : setTimeout(() => {
                  setToasts((v) => v.filter((toastItem) => toastItem.id !== toast.id));
              }, toast.options.duration);

        const newToast = { ...toast, timer, onClose: () => closeToast(toast.id) };
        setToasts((prevToasts) => [...prevToasts, newToast]);

        return newToast.id;
    };

    const closeToast = (id: string) => {
        const toast = toasts.find((toastItem) => toastItem.id === id);
        if (toast?.timer) {
            clearTimeout(toast.timer);
        }

        setToasts((prevToasts) => prevToasts.filter((toastItem) => toastItem.id !== id));
    };

    const clearAllToasts = () => {
        toasts.forEach((toast) => {
            if (toast.timer) {
                clearTimeout(toast.timer);
            }
        });

        setToasts([]);
    };

    const actions = useMemo(() => ({ showToast, closeToast, clearAllToasts }), []);

    return (
        <ToastActionsContext.Provider value={actions}>
            <ToastStateContext.Provider value={{ toasts }}>{children}</ToastStateContext.Provider>
        </ToastActionsContext.Provider>
    );
};
