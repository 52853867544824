'use client';

import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { CardBanner, CardBannerProps } from './CardBanner';

interface GenericBannerProps extends CardBannerProps {
    children: React.ReactNode;
    classNames?: {
        container?: string;
        imgWrapper?: string;
        cta?: string;
    };
    fill?: boolean;
    sizes: string;
}

/**
 * GenericBanner component is a reusable intermediate component for rendering banners.
 *
 * @param {GenericBannerProps} props - The properties for the GenericBanner component.
 */
export const GenericCard: Component<GenericBannerProps> = ({
    image,
    children,
    classNames,
    sizes,
    fill,
    ...props
}: GenericBannerProps) => {
    return (
        <CardBanner
            classNames={{
                container: clsx(
                    'flex flex-1 flex-row items-start',
                    'border-border-primary border-medium border-solid rounded-0.5',
                    'h-auto',
                    'bg-background-l2',
                    classNames?.container,
                ),
            }}
            image={{
                ...image,
                classNames: {
                    wrapper: clsx('object-cover overflow-hidden flex', classNames?.imgWrapper),
                    cta: clsx('h-full', classNames?.cta),
                },
                sizes,
                fill,
            }}
            {...props}
        >
            {children}
        </CardBanner>
    );
};
