'use client';

import { useCallback, useEffect } from 'react';
import { DataLayerConfig, Locale, obfuscateLinks } from '@headless-workspace/config';
import { HeaderConnectionBannerItemId } from '@headless-workspace/core-domain';
import { Component } from '@headless-workspace/typings';
import { CookieHelpers } from '@headless-workspace/utils';
import { ClientEnvKey, DataLayerYesNo, useClientData, useDeviceType, useUserInfo } from '../../../client';
import { getConnectedUserInfo, hasFidelityProgram, isConnectedUserInfo } from '../../../server';
import { getCustomerCategory, getUserLoyaltyCategory } from './DataLayerEventParamsFactory';
import { DataLayerUtils } from './DataLayerUtils';
import {
    ConnectionDataLayerLabel,
    DataLayerClassName,
    DataLayerEvent,
    DataLayerEventCategory,
    DataLayerEventLabel,
    DataLayerIsConnected,
    DataLayerKey,
    useDataLayer,
} from './index';

const EMPTY_VALUE = '';
const VALUE_FOR_UNLOGGED_USER = '-1';
const USER_ID_KEY = 'encrypteduserid';

type HeaderDidMountProps = {
    dataLayerConnectionAction: string;
    trackEvent: ReturnType<typeof useDataLayer>['trackEvent'];
    locale: Locale;
    addVariables: ReturnType<typeof useDataLayer>['addVariables'];
};

export const HeaderDidMount: Component<HeaderDidMountProps> = ({
    dataLayerConnectionAction,
    trackEvent,
    locale,
    addVariables,
}) => {
    const device = useDeviceType();
    const [language, country] = locale.split('-');
    const { getClientEnvVariable } = useClientData();
    const buildEnv = getClientEnvVariable(ClientEnvKey.BuildEnvironment);
    const countryCode = country ? country.toLowerCase() : EMPTY_VALUE;
    const { result: userInfoResult } = useUserInfo();
    const userInfo = getConnectedUserInfo(userInfoResult);

    useEffect(() => {
        if (typeof document !== 'undefined') {
            obfuscateLinks();
        }
    }, []);

    useEffect(() => {
        addVariables({
            [DataLayerKey.Country]: countryCode,
            [DataLayerKey.EnvCountry]: countryCode,
            [DataLayerKey.EnvLanguage]: language,
            [DataLayerKey.EnvWork]: DataLayerConfig.envWork[buildEnv],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        addVariables({
            [DataLayerKey.EnvPlatform]: device,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device]);

    useEffect(() => {
        const isUserConnected = isConnectedUserInfo(userInfo);
        const loyaltyCategory = getUserLoyaltyCategory(userInfo).toLowerCase();

        addVariables({
            [DataLayerKey.UserLogged]: DataLayerUtils.mapBooleanToYesNo(isUserConnected),
            [DataLayerKey.UserIdentified]: isUserConnected
                ? DataLayerIsConnected.Authenticated
                : DataLayerIsConnected.Anonymous,
            [DataLayerKey.UserLoyaltyCategory]: loyaltyCategory,
        });

        if (userInfo) {
            const hasFidelity = hasFidelityProgram(userInfo);
            const isSubscribed = userInfo.isSubscribedByEmail;
            const customerCategory = getCustomerCategory(userInfo);
            const customerEncryptedEmail = userInfo.customerEncryptedEmail;
            const userPurchaseFrequency = userInfo.ordersCount ? String(userInfo.ordersCount) : EMPTY_VALUE;
            const userStore = DataLayerUtils.mapStringToYesNo(userInfo.favoriteStoreId);

            addVariables({
                [DataLayerKey.UserCardOwner]: DataLayerUtils.mapBooleanToYesNo(hasFidelity),
                [DataLayerKey.UserEmailOptin]: DataLayerUtils.mapBooleanToYesNo(isSubscribed),
                [DataLayerKey.UserEmail]: customerEncryptedEmail,
                [DataLayerKey.UserNewCustomer]: customerCategory,
                [DataLayerKey.UserPurchaseFrequency]: userPurchaseFrequency,
                [DataLayerKey.UserStore]: userStore,
                [DataLayerKey.UserGender]: userInfo.gender ?? EMPTY_VALUE,
                [DataLayerKey.UserType]: userInfo.type ?? EMPTY_VALUE,
                [DataLayerKey.FavoriteOrderAddressDeclared]: DataLayerUtils.mapBooleanToYesNo(
                    userInfo.hasFavoriteAddress,
                ),
                [DataLayerKey.FavoriteCreditCardDeclared]: DataLayerUtils.mapBooleanToYesNo(
                    userInfo.hasFavoriteCreditCard,
                ),
                [DataLayerKey.UserWithOffer]: DataLayerUtils.mapBooleanToYesNo(userInfo.hasOffers),
                [DataLayerKey.UserInscriptionDate]: userInfo.inscriptionDate ?? EMPTY_VALUE,
                [DataLayerKey.UserId]: CookieHelpers.getClientSideCookie(USER_ID_KEY),
            });
        } else {
            addVariables({
                [DataLayerKey.UserStore]: VALUE_FOR_UNLOGGED_USER,
                [DataLayerKey.FavoriteOrderAddressDeclared]: VALUE_FOR_UNLOGGED_USER,
                [DataLayerKey.FavoriteCreditCardDeclared]: VALUE_FOR_UNLOGGED_USER,
                [DataLayerKey.UserCardOwner]: DataLayerYesNo.No,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const connectionEventClick = useCallback(() => {
        trackEvent(DataLayerEvent.Click, {
            id: 'click',
            category: DataLayerEventCategory.Banners,
            action: dataLayerConnectionAction,
        });
    }, [dataLayerConnectionAction, trackEvent]);

    const connectionBannerEventClick = useCallback(
        (dataLayerLabel: string) => () => {
            let eventCategory = DataLayerEventCategory.Banners;
            let eventAction = dataLayerConnectionAction;

            if (dataLayerLabel === ConnectionDataLayerLabel[HeaderConnectionBannerItemId.MyOrders]) {
                eventCategory = DataLayerEventCategory.ClientAccount;
                eventAction = 'order_history';
            } else if (dataLayerLabel === ConnectionDataLayerLabel[HeaderConnectionBannerItemId.FollowMyOrder]) {
                eventCategory = DataLayerEventCategory.ClientAccount;
                eventAction = 'ongoing_order';
            }

            trackEvent(DataLayerEvent.Click, {
                id: 'click',
                label: dataLayerLabel,
                category: eventCategory,
                action: eventAction,
            });
        },
        [dataLayerConnectionAction, trackEvent],
    );

    // Manage category of level 1
    const categoryEventClick = useCallback(
        (dataLayerId: string) => () => {
            trackEvent(DataLayerEvent.Click, {
                id: 'click',
                category: DataLayerEventCategory.Navigation,
                action: `click-${dataLayerId}`,
            });
        },
        [trackEvent],
    );

    // Manage category of level 2 and 3
    const nestedCategoryEventClick = useCallback(
        (dataLayerId: string, dataLayerSubId: string) => () => {
            trackEvent(DataLayerEvent.Click, {
                id: 'click',
                category: DataLayerEventCategory.Navigation,
                action: `click-${dataLayerId}`,
                label: dataLayerId,
                event_extra_dim1: dataLayerSubId,
            });
        },
        [trackEvent],
    );

    useEffect(() => {
        const eventClickHandlerReferences: [Element, EventListener][] = [];

        // Add event listeners for connections
        DataLayerUtils.addEventListeners(
            `.${DataLayerClassName.connection}`,
            () => connectionEventClick,
            eventClickHandlerReferences,
        );

        // Add event listeners for connection banners & burger menu items
        DataLayerUtils.addEventListeners(
            `.${DataLayerClassName.connectionBanner}, .${DataLayerClassName.burgerMenuItem}`,
            (element) => {
                const dataLayerLabel = element.getAttribute('data-layer-label') ?? DataLayerEventLabel.Null;
                return connectionBannerEventClick(dataLayerLabel);
            },
            eventClickHandlerReferences,
        );

        // Add event listeners for category navigation
        DataLayerUtils.addEventListeners(
            `.${DataLayerClassName.categoryNavigation}`,
            (element) => {
                const dataLayerId = element.getAttribute('data-layer-id') ?? DataLayerEventLabel.Null;
                return categoryEventClick(dataLayerId);
            },
            eventClickHandlerReferences,
        );

        // Add event listeners for nested category navigation
        DataLayerUtils.addEventListeners(
            `.${DataLayerClassName.nestedCategoryNavigation}`,
            (element) => {
                const dataLayerId = element.getAttribute('data-layer-id') ?? DataLayerEventLabel.Null;
                const dataLayerSubId = element.getAttribute('data-layer-sub-id') ?? DataLayerEventLabel.Null;
                return nestedCategoryEventClick(dataLayerId, dataLayerSubId);
            },
            eventClickHandlerReferences,
        );

        return () => {
            eventClickHandlerReferences.forEach(([element, handler]) => {
                element.removeEventListener('click', handler);
            });
        };
    }, [categoryEventClick, connectionBannerEventClick, connectionEventClick, nestedCategoryEventClick]);

    return null;
};
