import { Component } from '@headless-workspace/typings';
import {
    GlowHighlightText,
    GlowImage,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTextCaption,
    PropsWithImageTagName,
    PropsWithProduct,
} from '../../../../src';
import { GlowCTA } from '../cta/GlowCTA';

type GlowSearchProductProps = PropsWithImageTagName &
    PropsWithProduct & {
        discountPercentageLabel?: string;
        isEngravable: boolean;
        engravingOptionLabel?: string;
    };

const IMAGE_SIZE = 64;

/**
 * Component called plotProduit_search on FIGMA
 * A component that renders a search result item for a product.
 *
 * @param id - The ID of the product.
 * @param name - The name of the product.
 * @param href
 * @param image - The image of the product, containing the URL and the alt text.
 * @param brand - The brand of the product.
 * @param currency - The currency of the product's price.
 * @param price - The price of the product.
 * @param locale - The locale for formatting the price.
 * @param ImageTagName - The tag name for the image.
 * @param priceBeforeDiscount - The price of the product before the discount.
 * @param discountPercentageLabel - The label for the discount percentage.
 * @param isArialFont - A flag indicating whether the font is Arial.
 * @param enableDiscountInfo - A flag indicating whether the discount info is enabled.
 * @param priceAlwaysBlack - A flag indicating whether the price is always black.
 * @param isEngravable - A flag indicating whether the product is engravable.
 * @param engravingOptionLabel - The engraving option label for the product (if it's engravable).
 */
export const GlowSearchProductListItem: Component<GlowSearchProductProps> = ({
    id,
    name,
    href,
    image,
    brand,
    price,
    ImageTagName,
    priceBeforeDiscount,
    discountPercentageLabel,
    isArialFont,
    enableDiscountInfo,
    priceAlwaysBlack,
    isEngravable,
    engravingOptionLabel,
}) => {
    const fontFamily = isArialFont ? 'arial' : 'brand';
    return (
        <li className={'w-full'} key={id}>
            <GlowCTA className={'flex flex-row items-center gap-1 py-0.5'} href={href}>
                <GlowImage image={image} width={IMAGE_SIZE} height={IMAGE_SIZE} TagName={ImageTagName} />
                <div className={'flex flex-row flex-1 gap-0.5 justify-between'}>
                    <div className={'flex flex-col flex-1 gap-y-0.25'}>
                        <GlowTextBody text={brand.name} fontWeight={'bold'} fontFamily={fontFamily} />
                        <GlowTextBodySmall fontFamily={fontFamily} text={<GlowHighlightText text={name} />} />
                        {isEngravable && <GlowTextCaption text={engravingOptionLabel} fontFamily={fontFamily} />}
                    </div>
                    <div className={'flex flex-col items-end'}>
                        <GlowTextBody
                            text={price}
                            fontWeight={'bold'}
                            fontFamily={fontFamily}
                            color={!priceAlwaysBlack && discountPercentageLabel ? 'promotion' : 'primary'}
                        />
                        {discountPercentageLabel && priceBeforeDiscount && (
                            <div className={'flex flex-row gap-0.5'}>
                                <GlowTextCaption
                                    text={priceBeforeDiscount}
                                    fontFamily={fontFamily}
                                    color={'tertiary'}
                                />
                                {enableDiscountInfo && (
                                    <GlowTextCaption
                                        className={'px-0.25 bg-background-error-red rounded-0.25'}
                                        color={'promotion'}
                                        text={discountPercentageLabel}
                                        fontFamily={fontFamily}
                                        fontWeight={'bold'}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </GlowCTA>
        </li>
    );
};
