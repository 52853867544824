'use client';

import React from 'react';
import { GlowSkeleton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const SearchSuggestionSkeleton: Component = () => {
    return (
        <>
            <GlowSkeleton width={'70%'} height={'2rem'} containerClassName={'w-full'} />
            <GlowSkeleton width={'50%'} height={'2rem'} containerClassName={'w-full'} />
            <GlowSkeleton width={'40%'} height={'2rem'} />
            <GlowSkeleton width={'60%'} height={'2rem'} />
        </>
    );
};
