import { envConfig } from '@headless-workspace/config';
import {
    ApiDatasource,
    ApiError,
    AuthorizationStrategy,
    BffDatasource,
    CacheStrategy,
    createApiErrorFromError,
    DEFAULT_STATUS_CODE,
    provideCacheStrategy,
    QueryParamStrategy,
    RefreshTokenStrategy,
} from '@headless-workspace/data';
import { ZodError } from 'zod';

export interface RepositorySpec {
    readonly apiBaseUrl: string;
    readonly path: string;
    readonly datasource: ApiDatasource;

    handleApiError(error: unknown): ApiError;
}

export abstract class Repository implements RepositorySpec {
    abstract readonly path: string;

    protected constructor(
        authStrategy: Partial<AuthorizationStrategy>,
        refreshTokenStrategy: Partial<RefreshTokenStrategy>,
        queryParamStrategy: Partial<QueryParamStrategy>,
        readonly apiBaseUrl: string = envConfig.apiBaseUrl,
        cacheStrategy: CacheStrategy = provideCacheStrategy(envConfig),
        readonly datasource: ApiDatasource = new BffDatasource(
            apiBaseUrl,
            authStrategy,
            refreshTokenStrategy,
            cacheStrategy,
            queryParamStrategy,
        ),
    ) {}

    handleApiError(error: unknown): ApiError {
        if (error instanceof ApiError) {
            return error;
        }
        if (error instanceof ZodError) {
            return new ApiError(
                DEFAULT_STATUS_CODE,
                {
                    errors: [
                        {
                            errorCode: 'PARSING_ERROR',
                            errorMessage: error.issues.map((issue) => `${issue.path} : ${issue.message}`).join(),
                        },
                    ],
                },
                error.issues.map((issue) => issue.path).join('/'),
            );
        }
        if (error instanceof Error) {
            return createApiErrorFromError(error);
        }
        return new ApiError(DEFAULT_STATUS_CODE);
    }
}
