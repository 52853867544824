'use client';

import {
    getCountryFlagIcon,
    GlowClickable,
    GlowIcon,
    GlowTextBody,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers } from '@headless-workspace/utils';
import { CountryValue } from '../../../server';
import { LocaleSwitcherMenuProps } from './LocaleSwitcherMenu';

type LocaleSwitcherMenuItemButtonProps = PropsWithLocale &
    Pick<LocaleSwitcherMenuProps, 'showCode'> & {
        country: CountryValue;
        onClick: () => void;
        isOnCurrentCountry: boolean;
    };

export const LocaleSwitcherMenuItemButton: Component<LocaleSwitcherMenuItemButtonProps> = ({
    locale,
    showCode = true,
    country: { code, name, language },
    onClick,
    isOnCurrentCountry,
}) => {
    const Icon = getCountryFlagIcon(code);

    return (
        <GlowClickable
            className={'h-countrySelectorItem rounded-0.5 p-1 gap-1 border-solid border-border-primary border-medium'}
            content={{
                icon: <GlowIcon className={'h-countrySwitcherFlag w-countrySwitcherFlag'} Icon={Icon} type={'large'} />,
                labelElement: isOnCurrentCountry ? (
                    <GlowTextBody text={name} />
                ) : (
                    <div className={'flex flex-row justify-between flex-1'}>
                        <GlowTextBody text={name} />
                        {showCode && (
                            <GlowTextBody
                                className={'uppercase'}
                                text={language || LanguageHelpers.parseCountry(locale)}
                            />
                        )}
                    </div>
                ),
            }}
            onClick={onClick}
        />
    );
};
