'use client';

import { useEffect, useState } from 'react';
import { TailwindTheme } from '@headless-workspace/theme';
import { useTailwindBreakpoint } from '../hooks';

export const useOnBreakpointChange = (
    breakpoint: keyof TailwindTheme['screens'],
    callback: (matches: boolean) => void,
    initialValue = false,
) => {
    const matches = useTailwindBreakpoint(breakpoint, initialValue);
    const [matchesLast, setMatchesLast] = useState(matches);

    useEffect(() => {
        if (matches !== matchesLast) {
            callback(matches);
        }
        setMatchesLast(matches);
    }, [matches, callback, matchesLast]);
};
