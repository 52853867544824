import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowCTA } from '../molecules/cta/GlowCTA';
import { PropsWithAriaLabel, PropsWithClassName, PropsWithNewDataLayer } from '../props';

type ContainerProps = PropsWithChildren & PropsWithClassName & PropsWithNewDataLayer;

/**
 * Container component that wraps its children with additional data layer attributes.
 *
 * @param {ContainerProps} props - The properties for the Container component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the container.
 * @param {string} [props.className] - Additional class names to apply to the container.
 * @param {object} props.dataLayer - Data layer object for tracking purposes.
 */
const Container: Component<ContainerProps> = ({ children, className, dataLayer }: ContainerProps) => {
    return (
        <div
            className={clsx(className, dataLayer?.className)}
            data-layer-banner-action={dataLayer?.action}
            data-layer-id={dataLayer?.id}
            data-layer-name={dataLayer?.name}
        >
            {children}
        </div>
    );
};

type WrapperProps = PropsWithClassName &
    PropsWithChildren &
    PropsWithAriaLabel & {
        href?: string;
    };

/**
 * Wrapper component that wraps its children with additional data layer attributes.
 *
 * @param {WrapperProps} props - The properties for the Wrapper component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the wrapper.
 * @param {string} [props.className] - Additional class names to apply to the wrapper.
 * @param {string} [props.href] - The URL to navigate to when the wrapper is clicked.
 * @param {string} [props.ariaLabel] - The aria label for the wrapper.
 */
const Wrapper: Component<WrapperProps> = ({ className, children, href, ariaLabel }: WrapperProps) => {
    if (href) {
        return (
            <GlowCTA href={href} ariaLabel={ariaLabel} className={className}>
                {children}
            </GlowCTA>
        );
    }

    if (className) {
        return (
            <div className={className} aria-label={ariaLabel}>
                {children}
            </div>
        );
    }

    return children;
};

export const ComposableCard = {
    Container,
    Wrapper,
};
