'use client';

import { PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react';
import { Component } from '@headless-workspace/typings';
import { ToastProvider } from '../toastProvider';
import { SideMenuModal, SideMenuModalList, UIContext, UIContextValueType } from './UIContext';

export type UIProviderProps = PropsWithChildren;

export const UIProvider: Component<UIProviderProps> = ({ children }) => {
    const [menuModals, setMenuModals] = useState<SideMenuModalList>({});
    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

    /** Menu **/
    const openMenuModal = useCallback(
        (id: string, content?: ReactNode) => {
            setMenuModals({
                ...menuModals,
                [id]: {
                    ...menuModals[id],
                    isOpen: true,
                    map: { ...menuModals[id]?.map, default: content ?? menuModals[id]?.map.default },
                },
            });
            setIsMenuModalOpen(true);
        },
        [menuModals],
    );

    const addMenuModal = useCallback(
        (id: string, menu: SideMenuModal) => {
            setMenuModals({ ...menuModals, [id]: { ...menu, isOpen: false } });
        },
        [menuModals],
    );

    const addAndOpenMenuModal = useCallback(
        (id: string, menu: SideMenuModal) => {
            setMenuModals({ ...menuModals, [id]: { ...menu, isOpen: false } });
            requestAnimationFrame(() => {
                setMenuModals({ ...menuModals, [id]: { ...menu, isOpen: true } });
            });
            setIsMenuModalOpen(true);
        },
        [menuModals],
    );

    const addIfAbsentAndOpenMenuModal = useCallback(
        (id: string, menu: SideMenuModal, override = true) => {
            if (override) {
                setMenuModals({ ...menuModals, [id]: { ...menu, isOpen: true } });
            } else {
                setMenuModals({ ...menuModals, [id]: { ...menuModals[id], isOpen: true } });
            }
            setIsMenuModalOpen(true);
        },
        [menuModals],
    );

    const closeMenuModal = useCallback(
        (id: string, remove = false) => {
            if (menuModals[id]) {
                if (remove) {
                    const updatedMenuModals = { ...menuModals };
                    delete updatedMenuModals[id];
                    setMenuModals(updatedMenuModals);
                } else {
                    setMenuModals({ ...menuModals, [id]: { ...menuModals[id], isOpen: false, navigationStack: [] } });
                }
                setIsMenuModalOpen(
                    Object.keys(menuModals).some((menuId) => (menuId === id ? false : menuModals[menuId].isOpen)),
                );
            }
        },
        [menuModals],
    );

    const navigateMenuModal = useCallback(
        (id: string, contentId: string, content?: ReactNode) => {
            if (menuModals[id]) {
                setMenuModals({
                    ...menuModals,
                    [id]: {
                        ...menuModals[id],
                        navigationStack: [...menuModals[id].navigationStack, contentId],
                        map: { ...menuModals[id].map, [contentId]: content ?? menuModals[id].map[contentId] },
                    },
                });
            }
        },
        [menuModals],
    );

    const goBackMenuModal = useCallback(
        (id: string) => {
            if (menuModals[id]) {
                setMenuModals({
                    ...menuModals,
                    [id]: { ...menuModals[id], navigationStack: menuModals[id].navigationStack.slice(0, -1) },
                });
            }
        },
        [menuModals],
    );

    const providerValue: UIContextValueType = useMemo(() => {
        return {
            menuModal: {
                open: openMenuModal,
                add: addMenuModal,
                addAndOpen: addAndOpenMenuModal,
                addIfAbsentAndOpen: addIfAbsentAndOpenMenuModal,
                close: closeMenuModal,
                navigate: navigateMenuModal,
                goBack: goBackMenuModal,
                hydrate: setMenuModals,
                sideMenuModalList: menuModals,
                isOpen: isMenuModalOpen,
            },
        };
    }, [
        openMenuModal,
        addMenuModal,
        addAndOpenMenuModal,
        addIfAbsentAndOpenMenuModal,
        closeMenuModal,
        navigateMenuModal,
        goBackMenuModal,
        menuModals,
        isMenuModalOpen,
    ]);

    return (
        <UIContext.Provider value={providerValue}>
            <ToastProvider>{children}</ToastProvider>
        </UIContext.Provider>
    );
};
