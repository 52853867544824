export const cs_CZ_NavRoutes = {
    home: '',
    connection: 'prihlaseni',
    storesAndServices: 'sephora-prodejna',
    wishlist: 'oblibene-polozky',
    basket: 'kosik',
    payment: '', // FIXME: To Update
    account: 'uzivatelsky-ucet',
    orders: 'my-orders',
    followOrder: 'on/demandware.store/Sites-Sephora_PL-Site/cs_CZ/Order-TrackMyOrder',
    loyalty: 'zbirejte-body',
    offers: 'moje-vyhody',
    profile: 'detail-uzivatelskeho-uctu',
    mail: '',
    help: 'https://faq.sephora.cz/s/',
    allServices: 'sluzby-v-prodejnach.html',
    storeInfo: 'informace-o-prodejne',
    allStores: 'sephora-prodejna',
    countries: 'international.html',
    beautyTips: 'beauty-offers',
    logout: 'odhlaseni?redirectToReferer=true',
    loyaltyProgram: 'Sephora-Unlimited.html',
    allBrands: 'znacky/znacky-od-a-do-z-brands',
    clickAndCollect: 'nuestros-servicios-tienda-online',
    securePayment: 'https://faq.sephora.cz/s/article/Je-platba-na-sephora-cz-zabezpe%C4%8Den%C3%A1',
    returnProduct: 'https://faq.sephora.cz/s/article/Jak-vr%C3%A1tit-zbo%C5%BE%C3%AD',
    privacyPolicy: 'osobni-udaje-a-soubory-cookies/Osobni-udaje-a+soubory+cookies_CZ.html',
    search: 'vyhledavani?q=',
    reviewConditions: '',
};
