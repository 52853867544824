import { AppConfig } from '@headless-workspace/config';
import { HeadersName } from '../config';

export const CSRF_PROTECTION_HEADER_VALUE = '1';

export const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
    'x-consumer-id': AppConfig.consumerId,
    'x-consumer-version': AppConfig.versionId,
    [HeadersName.CsrfProtection]: CSRF_PROTECTION_HEADER_VALUE,
};

export const DEFAULT_STATUS_CODE = 500;

export enum ContentType {
    JSON = 'application/json',
    XML = 'application/xml',
}

export enum ContentFile {
    JSON = 'json',
    XML = 'xml',
}
