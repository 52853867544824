import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { PropsWithTestId } from '../../props';

type GlowInputProps = React.ComponentPropsWithRef<'input'> & PropsWithTestId;

export const GlowInput: Component<GlowInputProps> = ({ className, dataTestId, ...props }) => {
    return <input className={clsx('text-body font-brand', className)} {...props} data-testid={dataTestId} />;
};
