import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    CommonDropdownProps,
    DropDownOption,
    GlowClickableWithRightIcon,
    GlowDivider,
    GlowHighlightText,
    GlowIcon,
    GlowTextBody,
    Icons,
} from '../../../../src';
import { GlowDropDownSearchSkeleton } from './GlowDropDownSearchSkeleton';

export type DropdownOptionListProps = CommonDropdownProps & {
    id: string;
    selectedLabel: string;
    isOpen: boolean;
    focusedIndex?: number;
    errorMessage?: string;
    listRef: React.RefObject<HTMLUListElement | null>;
    onValueUpdate: (option: DropDownOption) => void;
    isLoading?: boolean;
};

export const DropdownOptionList: Component<DropdownOptionListProps> = ({
    id,
    optionsList,
    selectedLabel,
    isOpen,
    focusedIndex = -1,
    listRef,
    onValueUpdate,
    errorMessage,
    isLoading,
}) => {
    // FIXME: This if should be removed and whether or not to display the list must be controlled by css (e.g. hidden) to avoid html errors
    if (!isOpen) {
        return null;
    }

    const handleOptionClick = (option: DropDownOption) => () => {
        onValueUpdate(option);
    };

    return (
        <div
            className={clsx(
                'absolute w-full outline outline-bold rounded-b-0.5',
                errorMessage ? 'outline-border-error' : 'outline-border-brand',
            )}
        >
            {isLoading ? (
                <GlowDropDownSearchSkeleton />
            ) : (
                <>
                    <GlowDivider className={'bg-border-primary'} />

                    <ul id={`options-list-${id}`} role={'listbox'} ref={listRef}>
                        {optionsList.map((option, index) => (
                            <li
                                key={option.id}
                                role={'option'}
                                id={`option-${index}`}
                                aria-selected={selectedLabel === option.value}
                                tabIndex={focusedIndex === index ? 0 : -1}
                                className={'last-of-type:rounded-b-0.5'}
                            >
                                <GlowClickableWithRightIcon
                                    content={{
                                        icon: option.Icon && <GlowIcon Icon={option.Icon} type={'large'} />,
                                        labelElement: (
                                            <GlowTextBody
                                                text={<GlowHighlightText text={option.value} />}
                                                className={'flex-1 text-left'}
                                                color={selectedLabel === option.value ? 'tertiary' : 'primary'}
                                                TagName={'span'}
                                            />
                                        ),
                                    }}
                                    className={clsx(
                                        'w-full gap-0.5 p-0.75 bg-background-form hover:bg-surface-primary-hover',
                                        selectedLabel === option.value
                                            ? 'cursor-default bg-surface-primary-hover'
                                            : 'cursor-pointer',
                                        focusedIndex === index && 'bg-surface-primary-hover',
                                    )}
                                    rightIcon={Icons.Check}
                                    iconClassName={clsx(
                                        'duration-moderate1 ease-linear text-text-primary',
                                        selectedLabel === option.value ? 'visible' : 'hidden',
                                    )}
                                    onClick={handleOptionClick(option)}
                                    disabled={selectedLabel === option.value}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};
