'use client';

import { Logger, LogLevel } from './Logger';

export const RUM_ERROR_LABEL = 'RUM Error';

export class RumLogger implements Logger {
    info(label: string, value: string): void {
        window.dtrum?.reportCustomError(label, value, LogLevel.Info);
    }

    warn(label: string, value: string): void {
        window.dtrum?.reportCustomError(label, value, LogLevel.Warn);
    }

    error(_: string, error: Error): void {
        window.dtrum?.reportError(error);
    }
}
