import { cva } from 'class-variance-authority';

/**
 * Represents the style configuration for a GlowIconButton component.
 */
export const iconButtonStyle = cva(['w-fit rounded-0.5 duration-slow1 outline'], {
    variants: {
        variant: {
            primary: [
                'text-text-primary-inverse fill-text-primary-inverse',
                'bg-surface-brand hover:bg-surface-brand-hover active:bg-surface-brand-pressed',
                'outline-surface-brand hover:outline-surface-brand-hover active:outline-surface-brand-pressed outline-bold',
            ],
            secondary: [
                'text-text-primary fill-text-primary',
                'bg-surface-primary hover:bg-surface-primary-hover active:bg-surface-primary-pressed',
                'outline-border-brand outline-bold',
            ],
            tertiary: [
                'text-text-primary fill-text-primary',
                'bg-surface-primary hover:bg-surface-primary-hover active:bg-surface-primary-pressed',
                'outline-border-primary outline-medium',
            ],
            disabled: ['fill-text-tertiary text-text-tertiary', 'bg-background-disabled', 'outline-none'],
            transparent: ['fill-text-primary text-text-primary', 'bg-surface-primary', 'outline-none'],
            onElement: [
                'text-text-primary fill-text-primary',
                'bg-surface-primary hover:bg-surface-primary-hover active:bg-surface-primary-pressed',
                'outline-none',
            ],
        },
        size: {
            large: 'p-0.75',
            medium: 'p-0.5',
            small: 'p-0.25',
        },
    },
    defaultVariants: {
        variant: 'primary',
        size: 'large',
    },
});
