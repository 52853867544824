const notNullOrUndefined = <T>(value: T | null | undefined): value is T => {
    return value !== null && value !== undefined;
};

const sortArrayByKey = <T extends { [key: string]: string }>(array: T[], key: keyof T): T[] => {
    return array.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        if (valueA && valueB) {
            return valueA.localeCompare(valueB);
        }

        return 0;
    });
};

export const PredicateHelpers = {
    notNullOrUndefined,
    sortArrayByKey,
};
