'use client';

import { ServerActionName } from '@headless-workspace/config';
import { ResultFailure, ResultServerAction, ResultServerActionType } from '@headless-workspace/core-domain';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { UserRevalidationKey } from '../user';

const DEFAULT_ERROR_MESSAGE = 'An error occurred';

export const useSafeSWRMutation = <Data, ExtraArg>(
    validateKey: UserRevalidationKey,
    actionName: ServerActionName,
    fetcher: MutationFetcher<ResultServerActionType<Data>, string, ExtraArg>,
    onSuccessCallback?: (result: ResultServerActionType<Data>) => void,
) => {
    return useSWRMutation<ResultServerActionType<Data>, unknown, string, ExtraArg>(
        validateKey,
        async (_, { arg }) => {
            try {
                return await fetcher(validateKey, { arg });
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : DEFAULT_ERROR_MESSAGE;
                return ResultServerAction(ResultFailure(actionName, errorMessage), actionName);
            }
        },
        {
            onSuccess: onSuccessCallback,
        },
    );
};
