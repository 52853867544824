import { ApiConfig } from '@headless-workspace/config';
import { initRequestStrategy, SessionActionDTO, SessionInitDTO } from '@headless-workspace/data';
import { Repository, Result, ResultEmpty, ResultFailureFromApiError, ResultSuccess } from '../types';
import { InitSessionValue } from './InitSessionValue';

export interface UserSessionRepositorySpec {
    createSession(locale: string): Promise<Result<InitSessionValue>>;
    deleteSession(locale: string): Promise<Result>;
}

export class UserSessionRepository extends Repository implements UserSessionRepositorySpec {
    path = ApiConfig.headless.paths.user;

    constructor(requestStrategy = initRequestStrategy()) {
        super(
            requestStrategy.authStrategy,
            requestStrategy.refreshTokenStrategy,
            requestStrategy.queryParamStrategy,
            ApiConfig.headless.basePath,
        );
    }

    async createSession(locale: string): Promise<Result<InitSessionValue>> {
        const url = `${this.path}/${ApiConfig.headless.endpoints.session}`;
        try {
            const response = await this.datasource.createResource<
                { locale: string; action: SessionActionDTO },
                SessionInitDTO
            >(url, {
                locale,
                action: 'create',
            });
            const sessionInit = SessionInitDTO.parse(response);
            return ResultSuccess(sessionInit);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async deleteSession(locale: string): Promise<Result> {
        const url = `${this.path}/${ApiConfig.headless.endpoints.session}`;
        try {
            await this.datasource.createResource<{ locale: string; action: SessionActionDTO }, SessionInitDTO>(url, {
                locale,
                action: 'delete',
            });
            return ResultEmpty();
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
