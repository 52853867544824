import { featureFlipping } from '@headless-workspace/config';
import { IsomorphicLogger } from '@headless-workspace/utils';
import { ApiError, DEFAULT_ERROR_STATUS } from './ApiError';

export enum ServerErrorDomain {
    CatalogCategories = 'CatalogCategories',
    CatalogProducts = 'CatalogProducts',
    Config = 'Config',
    Footer = 'Footer',
    Countries = 'Countries',
    Home = 'Home',
    PersistentBanner = 'PersistentBanner',
    SEO = 'SEO',
    Header = 'HEADER',
}

export class ServerError extends ApiError {
    constructor(
        private domain: string,
        errorCode: string,
        errorMessage?: string,
        extra?: Record<string, unknown>,
    ) {
        super(DEFAULT_ERROR_STATUS, {
            errors: [
                {
                    errorCode,
                    errorMessage,
                },
            ],
        });
        if (featureFlipping.isDebugServerErrorEnabled) {
            new IsomorphicLogger().error(domain, { errorCode, errorMessage, extra });
        }
    }
}
