'use client';

import { createContext } from 'react';
import { FunctionHelpers } from '@headless-workspace/utils';
import { PopularSearchValue } from '../../../../server';
import { ClientEnvKey, ClientEnvKeysRecord } from './ClientEnvKeysRecord';
import { ClientFlagsRecordKey } from './ClientFlagsRecord';

export type ClientDataContextValueType = {
    isSessionInit: boolean;
    setSessionInit: (value: boolean) => void;
    clientEnvs: ClientEnvKeysRecord;
    getClientEnvVariable: (ClientEnvKey: ClientEnvKey) => string;
    getClientFlags: (key: ClientFlagsRecordKey) => boolean;
    timeMachineDate: string;
    updateTimeMachineDate: (value: string) => void;
    clientValues: {
        popularSearches: PopularSearchValue[];
    };
};

const ClientDataContextInitialState: ClientDataContextValueType = {
    clientEnvs: {
        [ClientEnvKey.BooxiKey]: '',
        [ClientEnvKey.WoosmapApiBaseUrl]: '',
        [ClientEnvKey.WoosmapKey]: '',
        [ClientEnvKey.ClientApiKey]: '',
        [ClientEnvKey.RuntimeEnvironment]: '',
        [ClientEnvKey.BuildEnvironment]: '',
        [ClientEnvKey.HeadlessVersion]: '',
        [ClientEnvKey.HostUrl]: '',
        [ClientEnvKey.ReachFiveClientId]: '',
        [ClientEnvKey.ReachFiveDomain]: '',
    },
    isSessionInit: false,
    setSessionInit: FunctionHelpers.emptyFn,
    getClientEnvVariable: () => '',
    getClientFlags: () => false,
    timeMachineDate: '',
    updateTimeMachineDate: FunctionHelpers.emptyFn,
    clientValues: {
        popularSearches: [],
    },
};

export const ClientDataContext = createContext<ClientDataContextValueType>(ClientDataContextInitialState);
