'use client';

import { useEffect, useState } from 'react';

export const useIsTouchScreen = () => {
    const [isTouchScreen, setIsTouchScreen] = useState(false);

    useEffect(() => {
        const handleTouch = () => {
            setIsTouchScreen(true);
            window.removeEventListener('touchstart', handleTouch);
        };

        const handleMouse = () => {
            setIsTouchScreen(false);
            window.removeEventListener('mousemove', handleMouse);
        };

        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
            setIsTouchScreen(true);
        } else {
            window.addEventListener('touchstart', handleTouch, { once: true });
            window.addEventListener('mousemove', handleMouse, { once: true });
        }

        return () => {
            window.removeEventListener('touchstart', handleTouch);
            window.removeEventListener('mousemove', handleMouse);
        };
    }, []);

    return isTouchScreen;
};
