export const pl_PL_NavRoutes = {
    home: '',
    connection: 'zaloguj',
    storesAndServices: 'wyszukaj-perfumerie',
    wishlist: 'ulubione',
    basket: 'koszyk',
    account: 'moje-konto',
    payment: '', // FIXME: To Update
    orders: 'moje-zamowienia',
    followOrder: 'on/demandware.store/Sites-Sephora_PL-Site/pl_PL/Order-TrackMyOrder',
    loyalty: 'moje-punkty',
    offers: 'moje-oferty',
    profile: 'szczegoly-konta',
    mail: '',
    help: 'https://faq.sephora.pl/s/',
    allServices: 'uslugi.html',
    storeInfo: 'o-perfumerii',
    allStores: 'wyszukaj-perfumerie',
    beautyTips: 'beauty-offers',
    countries: 'international.html',
    logout: 'wyloguj?redirectToReferer=true',
    loyaltyProgram: 'Sephora-Unlimited.html',
    allBrands: 'marki/od-a-do-z',
    clickAndCollect: 'click-and-collect/click-and-collect',
    securePayment:
        'https://faq.sephora.pl/s/article/Jakie-metody-p%C5%82atno%C5%9Bci-s%C4%85-akceptowane-na-stronie-www-sephora-pl-i-w-perfumeriach-Sephora',
    returnProduct: 'content/reassurance',
    privacyPolicy: 'polityka-prywatnosci/donnees-personnelles.html#Cosmeto',
    search: 'wyniki-wyszukiwania?q=',
    reviewConditions: '',
};
