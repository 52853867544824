import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { ComposableToast, getToastConfig, GlowToastProps } from '../../composables';

type GlowNotificationToastProps = GlowToastProps & {
    onClose?: () => void;
};

export const GlowNotificationToast: Component<GlowNotificationToastProps> = ({
    toastType,
    label,
    onClose,
    className,
}) => {
    const { toastIcon, backgroundClassName, role, ariaLive, fillIcon, borderClass } = getToastConfig(toastType);

    return (
        <ComposableToast.Container
            ariaLive={ariaLive}
            className={clsx(borderClass, backgroundClassName, className)}
            role={role}
        >
            <ComposableToast.Icon Icon={toastIcon} colorClass={fillIcon} />
            <ComposableToast.Label label={label} />
            <ComposableToast.CloseButton onClose={onClose} />
        </ComposableToast.Container>
    );
};
