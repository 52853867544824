'use client';

import { createContext } from 'react';
import { DEFAULT_THEME_SETTING, ThemeColor } from '@headless-workspace/config';
import { IsomorphicLogger } from '@headless-workspace/utils';

const Logger = new IsomorphicLogger();

export type ThemeMode = ThemeColor | 'system';
export type ThemeContextType = {
    themeMode: ThemeMode;
    setTheme: (themeMode: ThemeMode) => void;
};

export const ThemeContext = createContext<ThemeContextType>({
    themeMode: DEFAULT_THEME_SETTING.value,
    setTheme: (theme: ThemeMode) => {
        Logger.warn(`setTheme was called with '${theme}', but no provider is wrapping the component.`);
    },
});
