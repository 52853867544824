const DATA_LAYER_ENV_WORK: Record<string, string> = {
    int: 'dev',
    uat: 'dev',
    e2e: 'dev',
    mco: 'dev',
    stg: 'staging',
    prd: 'prod',
};

export const DataLayerConfig = {
    envWork: DATA_LAYER_ENV_WORK,
};
