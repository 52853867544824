import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { UIBurgerSubMenuShell } from '@headless-workspace/core-ui';
import { GlowMenuButton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { CategoryValue } from '../../../../server';
import { MenuSubCategoriesListItems } from '../MenuSubCategoriesListItems';

type BurgerSubMenuProps = {
    href: string;
    title: string;
    subCategories: CategoryValue[];
    dataLayerId?: string;
    className?: string;
};

export const BurgerSubMenu: Component<BurgerSubMenuProps> = ({
    href,
    title,
    subCategories,
    dataLayerId,
    className,
}) => {
    const t = useTranslations('Header.burgerMenu');
    return (
        <UIBurgerSubMenuShell title={title} goBackAriaLabel={t('ariaLabel.goBack')}>
            <ul className={'flex flex-col gap-0.25'}>
                <li>
                    <GlowMenuButton
                        className={clsx('bg-surface-primary', className)}
                        key={'all'}
                        label={t('label.seeAll')}
                        href={href}
                        dataLayerId={dataLayerId}
                    />
                </li>
                <MenuSubCategoriesListItems categoryDataLayerId={dataLayerId} categories={subCategories} />
            </ul>
        </UIBurgerSubMenuShell>
    );
};
