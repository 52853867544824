import { TextSuggestionDTO } from './TextSuggestionsDTO';

export type TextSuggestionValue = Readonly<{
    name: string;
    href?: string;
    id?: string;
}>;

export const mapTextSuggestion = (dto: TextSuggestionDTO): TextSuggestionValue => {
    return {
        // FIXME: This is a temporary fix for the issue where the id is not unique
        id: dto.id ?? dto.name.replace(/\s/g, '-').toLocaleLowerCase(),
        name: dto.highlights?.name ?? dto.name,
        href: dto.url,
    };
};
