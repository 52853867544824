'use client';

import { useEffect, useState } from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowTextBodySmall } from '../../atoms';
import { PropsWithAriaLabel } from '../../props';
import { GlowClickable } from '../cta';

type BreadcrumbItem = { id: string; label: string; url: string };

type GlowBreadcrumbProps = Required<PropsWithAriaLabel> & {
    breadcrumb: BreadcrumbItem[];
    seeAllAriaLabel: string;
    isMobile?: boolean;
};

const BREADCRUMB_ELLIPSIS = '...';
const SEPARATOR = '/';

/**
 * A component that renders a breadcrumb navigation.
 * @param breadcrumb - Array of breadcrumb items.
 * @param isMobile - If true, the breadcrumb will be collapsed on mobile.
 * @param ariaLabel - Aria-label for the breadcrumb navigation.
 * @param seeAllAriaLabel - Aria-label for the "see all" button.
 */
export const GlowBreadcrumb: Component<GlowBreadcrumbProps> = ({
    breadcrumb,
    ariaLabel,
    seeAllAriaLabel,
    isMobile = false,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const BreadcrumbSeparator = (
        <GlowTextBodySmall TagName={'span'} color={'tertiary'} aria-hidden={true} text={SEPARATOR} />
    );

    useEffect(() => {
        setIsCollapsed(isMobile);
    }, [isMobile]);

    const breadcrumbElements = breadcrumb?.map(
        (breadcrumbItem: BreadcrumbItem, index: number, array: BreadcrumbItem[]): React.ReactElement => {
            const isLastBreadcrumb = index === array.length - 1;

            return (
                <li key={breadcrumbItem.id} className={'py-0.375 inline-flex items-center gap-0.25'}>
                    <GlowClickable
                        href={breadcrumbItem.url}
                        className={'hover:text-underline'}
                        aria-current={isLastBreadcrumb}
                        content={{
                            labelElement: (
                                <GlowTextBodySmall
                                    fontWeight={isLastBreadcrumb ? 'bold' : 'medium'}
                                    text={breadcrumbItem.label}
                                    color={isLastBreadcrumb ? 'primary' : 'tertiary'}
                                />
                            ),
                        }}
                    />
                    {!isLastBreadcrumb && BreadcrumbSeparator}
                </li>
            );
        },
    );
    const handleIsCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <nav aria-label={ariaLabel}>
            <ol className={'flex flex-row flex-wrap items-center gap-0.25 text-text-tertiary'}>
                {isCollapsed && (
                    <li className={'flex flex-row'}>
                        <GlowClickable
                            onClick={handleIsCollapsed}
                            ariaLabel={seeAllAriaLabel}
                            className={'cursor-pointer text-text-primary font-bold pr-0.25'}
                            content={{
                                labelElement: (
                                    <GlowTextBodySmall
                                        TagName={'span'}
                                        color={'tertiary'}
                                        text={BREADCRUMB_ELLIPSIS}
                                        aria-hidden={true}
                                    />
                                ),
                            }}
                        />
                        {BreadcrumbSeparator}
                    </li>
                )}
                {isCollapsed ? breadcrumbElements.at(-1) : breadcrumbElements}
            </ol>
        </nav>
    );
};
