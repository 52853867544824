import { ApiConfig } from '@headless-workspace/config';
import {
    defaultServerAuthStrategy,
    defaultServerQueryParamStrategy,
    defaultServerRefreshTokenStrategy,
    ShopperTokenDTO,
} from '@headless-workspace/data';
import { Repository, Result, ResultFailureFromApiError, ResultSuccess } from '../types';
import { mapShopperTokenEntity, ShopperTokenEntity } from './ShopperTokenEntity';

export interface RefreshTokenRepositorySpec {
    refreshToken(locale: string, refreshToken: string): Promise<Result<ShopperTokenEntity>>;
}

export class RefreshTokenRepository extends Repository implements RefreshTokenRepositorySpec {
    path = ApiConfig.headless.paths.token;

    constructor() {
        super(
            defaultServerAuthStrategy,
            defaultServerRefreshTokenStrategy,
            defaultServerQueryParamStrategy,
            ApiConfig.headless.basePath,
        );
    }

    async refreshToken(locale: string, refreshToken: string): Promise<Result<ShopperTokenEntity>> {
        const url = `${this.path}/${ApiConfig.headless.endpoints.refresh}`;
        try {
            const response = await this.datasource.createResource<
                { locale: string; refreshToken: string },
                ShopperTokenEntity
            >(url, {
                locale,
                refreshToken,
            });

            const tokenResult = mapShopperTokenEntity(ShopperTokenDTO.parse(response));

            return ResultSuccess(tokenResult);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
