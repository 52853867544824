export type CurrencySymbol = '€' | '$';

const getCurrencySymbol = (currency: string): CurrencySymbol | null => {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            return null;
    }
};

export const CurrencyHelpers = {
    getCurrencySymbol,
};
