import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { GlowTextBody, GlowTextCaption } from '../../atoms';
import { PropsWithFontFamily } from '../../props';

export type ProductPricesProps = PropsWithFontFamily & {
    minPrice?: string;
    price?: string;
    priceBeforeDiscount?: string;
    pricePerUnit?: string;
    minimalProductPriceLabel?: string;
    basePriceLabel: string;
    priceAlwaysBlack: boolean;
    discountPercentageLabel?: string;
    totalIndividualPriceLabel?: string;
    legalMentionsLabel?: string;
};

// Component used only to display the price of a product in the Product Tile
export const ProductPrices: Component<ProductPricesProps> = ({
    minPrice,
    price,
    priceBeforeDiscount,
    pricePerUnit,
    fontFamily,
    minimalProductPriceLabel,
    basePriceLabel,
    priceAlwaysBlack,
    discountPercentageLabel,
    totalIndividualPriceLabel,
    legalMentionsLabel,
}) => {
    return (
        <div className={'flex flex-col gap-0.25'}>
            <div>
                {!minPrice && !!price && (
                    <GlowTextBody
                        text={legalMentionsLabel ? StringHelpers.addAsteriks(price) : price}
                        fontWeight={'bold'}
                        fontFamily={fontFamily}
                        color={!priceAlwaysBlack && priceBeforeDiscount ? 'promotion' : 'primary'}
                    />
                )}
                {!!minPrice && (
                    <div className={'flex items-center gap-0.25'}>
                        <GlowTextCaption text={minimalProductPriceLabel} fontFamily={fontFamily} />
                        <GlowTextBody
                            text={legalMentionsLabel ? StringHelpers.addAsteriks(minPrice) : minPrice}
                            fontWeight={'bold'}
                            fontFamily={fontFamily}
                            color={!priceAlwaysBlack && priceBeforeDiscount ? 'promotion' : 'primary'}
                        />
                    </div>
                )}
                {priceBeforeDiscount && !!price && (
                    <div className={'flex flex-col tablet:flex-row'}>
                        <GlowTextCaption
                            text={basePriceLabel}
                            fontFamily={fontFamily}
                            color={'primary'}
                            className={'whitespace-break-spaces'}
                        />
                        <div className={'flex flex-row flex-wrap gap-0.125'}>
                            <GlowTextCaption
                                text={priceBeforeDiscount}
                                fontFamily={fontFamily}
                                color={'primary'}
                                className={'whitespace-break-spaces'}
                            />
                            <GlowTextCaption
                                className={'px-0.25 bg-background-error-red rounded-0.25'}
                                color={'promotion'}
                                text={discountPercentageLabel}
                                fontWeight={'bold'}
                                fontFamily={fontFamily}
                            />
                        </div>
                    </div>
                )}
                <GlowTextCaption
                    text={
                        legalMentionsLabel && pricePerUnit ? StringHelpers.removeAsteriks(pricePerUnit) : pricePerUnit
                    }
                    color={'tertiary'}
                    fontFamily={fontFamily}
                />
            </div>
            <GlowTextCaption
                className={'line-clamp-1'}
                text={totalIndividualPriceLabel}
                color={'primary'}
                fontFamily={fontFamily}
            />
        </div>
    );
};
