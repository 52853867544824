import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { DecoderHelpers } from '@headless-workspace/utils';
import { FooterSlotDTO, FooterSlotsContainerDTO } from './FooterSlotsDTO';
import { FooterSlotsValue, mapFooterSlots } from './FooterSlotsValue';

export interface FooterSlotsRepositorySpec {
    getFooterInformation(locale: string): Promise<Result<FooterSlotsValue>>;
}

export class FooterSlotsRepository extends Repository implements FooterSlotsRepositorySpec {
    path = '/content/v1';

    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getFooterInformation(locale: string): Promise<Result<FooterSlotsValue>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/slots`, {
                locale,
                scope: 'footer',
            });

            const slotsContainer = FooterSlotsContainerDTO.parse(response);
            const slots = DecoderHelpers.safeParseArray(slotsContainer.slots, FooterSlotDTO);

            const footerSlots = mapFooterSlots({ slots });
            if (
                footerSlots.reinsurance.length === 0 &&
                footerSlots.links.length === 0 &&
                footerSlots.compliance.length === 0 &&
                footerSlots.socialNetwork.length === 0
            ) {
                return ResultEmpty();
            }

            return ResultSuccess(footerSlots);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}
