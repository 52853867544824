'use client';

import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { PropsWithClassName, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HeaderMenuListItem } from '../../../../client';
import { CategoryValue } from './CategoryValue';

type CategoryNavigationProps = PropsWithClassName &
    PropsWithLocale & {
        categories: CategoryValue[];
    };

export const CategoryNavigation: Component<CategoryNavigationProps> = ({ className, locale, categories }) => {
    const isDesktop = useTailwindBreakpoint('desktop');

    return (
        isDesktop && (
            <nav className={clsx('h-headerFixedNavDesktop', className)}>
                <ul className={clsx('flex flex-row items-center')}>
                    {categories
                        .filter((category) => category.showInMenu)
                        .map((category) => (
                            <HeaderMenuListItem
                                key={category.id}
                                iconUrl={category.imageUrl}
                                title={category.name}
                                subCategories={category.subCategories}
                                href={category.url ?? buildRoute(locale, 'home')}
                                color={category.color}
                                ariaLabel={category.ariaLabel}
                                dataLayerId={category.dataLayerId}
                                isSeeAllEnabled={category.isSeeAllEnabled}
                                seeAllUrl={category.seeAllUrl}
                            />
                        ))}
                </ul>
            </nav>
        )
    );
};
