import { forwardRef, useContext } from 'react';
import clsx from 'clsx';
import { TabsTrigger } from '@radix-ui/react-tabs';
import { GlowTextBody, PropsWithId } from '../../../../src';
import { IdContext } from './IdProvider';

export type TabLabelProps = Required<PropsWithId> & {
    label: string;
};

export const TabLabel = forwardRef<HTMLButtonElement, TabLabelProps>(({ id, label }, ref) => {
    const isCurrent = useContext(IdContext) === id;

    return (
        <TabsTrigger ref={ref} className={'flex-none relative'} value={id}>
            <GlowTextBody TagName={'span'} text={label} className={'block p-1'} />
            {isCurrent && (
                <span
                    className={clsx(
                        'absolute bottom-0 left-1 right-1',
                        'border-b-strong border-solid border-b-border-brand',
                    )}
                />
            )}
        </TabsTrigger>
    );
});
