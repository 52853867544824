import React from 'react';
import clsx from 'clsx';
import { ColorCSSVariable } from '@headless-workspace/config';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';
import { cva } from 'class-variance-authority';
import { PropsWithAlt, PropsWithClassName, PropsWithIcon } from '../../props';

export type IconType = 'small' | 'medium' | 'large' | 'fullSize';
const iconTypeToSize = (type: IconType) => {
    switch (type) {
        case 'small':
            return {
                height: tailwindTheme.minHeight.iconSmall,
                width: tailwindTheme.minWidth.iconSmall,
            };
        case 'medium':
            return {
                height: tailwindTheme.minHeight.iconMedium,
                width: tailwindTheme.minWidth.iconMedium,
            };
        case 'large':
            return {
                height: tailwindTheme.minHeight.iconLarge,
                width: tailwindTheme.minWidth.iconLarge,
            };
        default:
            return {};
    }
};

const tagStyle = cva('', {
    variants: {
        type: {
            small: 'min-h-iconSmall min-w-iconSmall',
            medium: 'min-h-iconMedium min-w-iconMedium',
            large: 'min-h-iconLarge min-w-iconLarge',
            fullSize: '',
        },
    },
    defaultVariants: {
        type: 'medium',
    },
});

export type GlowIconProps = PropsWithClassName &
    PropsWithAlt &
    PropsWithIcon & {
        fill?: string;
        fillCSSVariable?: ColorCSSVariable;
        type?: IconType;
        height?: string | number;
        width?: string | number;
    };

/**
 * A component that renders an icon.
 * @param Icon - The icon to render.
 * @param type - The size of the icon. Can be 'medium' (24x24), 'large' (32x32) or 'fullSize' (original size).
 * @param fill - The color of the icon.
 * @param fillCSSVariable - The color of the icon as a CSS variable.
 * @param height - The height of the icon. To be used when type = fullSize.
 * @param width - The width of the icon. To be used when type = fullSize.
 * @param className - Additional classes to apply.
 * @param alt - The alt text for the icon.
 */
export const GlowIcon: Component<GlowIconProps> = ({
    Icon,
    type = 'medium',
    fill,
    height,
    width,
    className,
    alt = '',
}) => {
    const size = iconTypeToSize(type);
    const fillProp = fill ? { fill } : {};
    return (
        <Icon
            className={clsx(tagStyle({ type }), className)}
            alt={alt}
            height={height}
            width={width}
            {...size}
            {...fillProp}
        />
    );
};
