import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    PropsWithAriaControls,
    PropsWithAriaExpanded,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithCTA,
    PropsWithCTAContent,
    PropsWithId,
    PropsWithStyle,
    PropsWithSuppressHydrationWarning,
    PropsWithTestId,
} from '../../props';
import { GlowCTA } from './GlowCTA';
import { GlowCTAContent } from './GlowCTAContent';

type GlowClickableProps = PropsWithAriaLabel &
    PropsWithClassName &
    PropsWithStyle &
    PropsWithId &
    PropsWithTestId &
    PropsWithSuppressHydrationWarning &
    PropsWithAriaControls &
    PropsWithAriaExpanded &
    PropsWithCTA & {
        content: PropsWithCTAContent;
    };

/**
 * Clickable component used to make call-to-actions
 * Use this component when you need a CTA with an icon and/or text
 * @param ariaLabel - Aria label for the button or anchor
 * @param className - Additional styles
 * @param style - Inline styles
 * @param id - ID of the button or the anchor
 * @param dataTestId - data-testid for the button or anchor
 * @param suppressHydrationWarning - If true, suppress the hydration warning
 * @param content - Content of the clickable text (icon, label, ...)
 * @param ariaControls - ID of the element controlled by the button
 * @param ariaExpanded - If the element controlled by the button is expanded
 * @param props - Action to be performed when the text is clicked (href, onClick, ...)
 */
export const GlowClickable: Component<GlowClickableProps> = ({
    ariaLabel,
    className,
    style,
    id,
    dataTestId,
    suppressHydrationWarning = false,
    content,
    ariaControls,
    ariaExpanded,
    ...props
}) => {
    return (
        <GlowCTA
            ariaLabel={ariaLabel}
            className={clsx('flex flex-row items-center', className)}
            style={style}
            id={id}
            dataTestId={dataTestId}
            suppressHydrationWarning={suppressHydrationWarning}
            ariaControls={ariaControls}
            ariaExpanded={ariaExpanded}
            {...props}
        >
            <GlowCTAContent {...content} />
        </GlowCTA>
    );
};
