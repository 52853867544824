import { z } from 'zod';

export type ConfigByKeyDTO<T> = {
    key: string;
    value: T;
};

export type ConfigByKeyContainerDTO = z.infer<typeof ConfigByKeyContainerDTO>;
export const ConfigByKeyContainerDTO = z.object({
    key: z.string(),
    value: z.unknown(),
});
