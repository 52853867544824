'use client';

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { KeyboardEventKey } from '@headless-workspace/config';
import { Component, DropDown } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import {
    GlowClickableWithRightIcon,
    GlowIcon,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTextCaption,
    Icons,
    PropsWithIcon,
    useClickOutside,
} from '../../../../src';
import { DropdownOptionList } from './DropdownOptionList';

export type DropDownOption = Partial<PropsWithIcon> & DropDown;

export type CommonDropdownProps = Partial<PropsWithIcon> & {
    id: string;
    optionsList: DropDownOption[];
    isSelectedFailed?: boolean;
};

type GlowDropDownProps = CommonDropdownProps & {
    defaultSelectedValue: string;
    ariaLabel: string;
    title: string;
    helperLabel?: string;
    onValueUpdate: (option: DropDownOption) => void;
};

const matchesSearch = (optionValue: string, searchString: string, matchType: 'startsWith' | 'includes') => {
    const cleanSearchString = StringHelpers.removeSpaceAndLowercase(searchString);
    const cleanedOptionValue = StringHelpers.removeSpaceAndLowercase(optionValue);

    return matchType === 'startsWith'
        ? cleanedOptionValue.startsWith(cleanSearchString)
        : cleanedOptionValue.includes(cleanSearchString);
};

const findMatchingOptions = (
    optionsList: DropDownOption[],
    searchString: string,
    matchType: 'startsWith' | 'includes',
) => {
    return optionsList.filter((option) => matchesSearch(option.value, searchString, matchType));
};

const getMatchingOptions = (optionsList: DropDownOption[], searchString: string) => {
    const startMatches = findMatchingOptions(optionsList, searchString, 'startsWith');
    return startMatches.length > 0 ? startMatches : findMatchingOptions(optionsList, searchString, 'includes');
};

const updateFocusedIndex = (optionsList: DropDownOption[], newSearchString: string) => {
    // Search for options that match the search string (newSearchString)
    const matchingOptions = getMatchingOptions(optionsList, newSearchString);
    if (matchingOptions.length > 0) {
        // Create an array containing the indices of the matching options in optionsList
        return matchingOptions.map((opt) => optionsList.indexOf(opt));
    } else {
        return [];
    }
};
const SINGLE_CHAR_REGEX = /^[a-zA-Z0-9À-ÿ]$/;

export const GlowDropDown: Component<GlowDropDownProps> = ({
    id,
    optionsList,
    onValueUpdate,
    defaultSelectedValue,
    ariaLabel,
    title,
    helperLabel,
    Icon,
    isSelectedFailed = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [searchString, setSearchString] = useState('');

    const listRef = useRef<HTMLUListElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);

    const defaultColor = optionsList.some((option) => option.value === selectedValue) ? 'primary' : 'tertiary';

    useClickOutside(parentRef, () => setIsOpen(false));

    const toggleMenu = () => {
        setIsOpen((prevIsOpen) => {
            if (!prevIsOpen) {
                setFocusedIndex(optionsList.findIndex((opt) => opt.value === selectedValue));
            }
            return !prevIsOpen;
        });

        setSearchString('');
    };

    const handleOptionClick = (option: DropDownOption) => {
        setSelectedValue(option.value);
        setIsOpen(false);
        onValueUpdate(option);
        setSearchString('');
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        const upFocusIndex = (index: number) => (index + 1) % optionsList.length;
        const downFocusIndex = (index: number) => (index - 1 < 0 ? optionsList.length - 1 : index - 1);

        switch (event.key) {
            case KeyboardEventKey.ArrowDown:
                event.preventDefault();
                if (!isOpen) {
                    setIsOpen(true);
                    setFocusedIndex(0);
                } else {
                    setFocusedIndex((prevIndex) => upFocusIndex(prevIndex));
                }
                break;
            case KeyboardEventKey.ArrowUp:
                event.preventDefault();
                if (isOpen) {
                    setFocusedIndex((prevIndex) => downFocusIndex(prevIndex));
                }
                break;
            case KeyboardEventKey.Enter:
            case KeyboardEventKey.Space:
                event.preventDefault();
                if (isOpen && focusedIndex >= 0) {
                    const selectedOption = optionsList[focusedIndex];
                    if (selectedOption) {
                        handleOptionClick(selectedOption);
                    }
                } else {
                    setIsOpen(true);
                }
                break;
            case KeyboardEventKey.Escape:
                event.preventDefault();
                setIsOpen(false);
                break;
            case KeyboardEventKey.Tab:
                if (!isOpen) {
                    return;
                }
                if (event.shiftKey) {
                    setFocusedIndex((prevIndex) => downFocusIndex(prevIndex));
                } else {
                    setFocusedIndex((prevIndex) => upFocusIndex(prevIndex));
                }
                break;
            default:
                event.preventDefault();
                if (event.key.length === 1 && SINGLE_CHAR_REGEX.test(event.key)) {
                    const typedChar = event.key.toLowerCase();
                    const newSearchString = searchString + typedChar;

                    setSearchString((prev) => {
                        if (prev === '') {
                            setFocusedIndex((prevIndex) => {
                                const matchingIndices = updateFocusedIndex(optionsList, newSearchString);
                                // Find the index of the currently selected element in the matching indices array
                                const currentIndex = matchingIndices.indexOf(prevIndex);
                                // Return the next index in a cyclical manner
                                return matchingIndices[(currentIndex + 1) % matchingIndices.length];
                            });
                            return typedChar;
                        } else if (prev === typedChar) {
                            setFocusedIndex((prevIndex) => {
                                const matchingIndices = updateFocusedIndex(optionsList, prev);
                                const currentIndex = matchingIndices.indexOf(prevIndex);
                                return matchingIndices[(currentIndex + 1) % matchingIndices.length];
                            });
                            return prev;
                        } else {
                            const findMatchingIndex = (searchString: string, matchType: 'startsWith' | 'includes') => {
                                return optionsList.findIndex((option) =>
                                    matchesSearch(option.value, searchString, matchType),
                                );
                            };

                            const matchedIndex = findMatchingIndex(newSearchString, 'startsWith');

                            const fallbackIndex =
                                matchedIndex > 0 ? matchedIndex : findMatchingIndex(newSearchString, 'includes');

                            if (fallbackIndex >= 0) {
                                setFocusedIndex(fallbackIndex);
                            }

                            return newSearchString;
                        }
                    });
                }
                break;
        }
    };

    useEffect(() => {
        if (isOpen && focusedIndex >= 0) {
            const focusedOption = listRef.current?.children[focusedIndex] as HTMLElement;

            if (focusedOption) {
                focusedOption.focus();
                focusedOption.scrollIntoView({ block: 'nearest' });
            }
        }
    }, [focusedIndex, isOpen]);

    return (
        <div className={'flex flex-col gap-0.5'}>
            <GlowTextBodySmall text={title} />

            <div
                ref={parentRef}
                className={clsx(
                    'custom-select bg-background-form outline group min-w-dropDown relative z-dropDown',
                    isSelectedFailed
                        ? 'outline-border-error outline-bold'
                        : isOpen
                        ? 'outline-border-brand rounded-t-0.5 outline-bold'
                        : 'outline-border-primary rounded-0.5 outline-medium',
                )}
                role={'combobox'}
                aria-label={defaultSelectedValue}
                aria-activedescendant={focusedIndex >= 0 ? `option-${focusedIndex}` : undefined}
                aria-controls={`options-list-${id}`}
                aria-expanded={isOpen}
                tabIndex={0}
                onKeyDown={handleKeyDown}
            >
                <GlowClickableWithRightIcon
                    ariaLabel={ariaLabel}
                    content={{
                        icon: Icon && <GlowIcon Icon={Icon} type={'large'} />,
                        labelElement: (
                            <GlowTextBody
                                text={selectedValue}
                                className={'flex-1'}
                                color={defaultColor}
                                TagName={'span'}
                            />
                        ),
                    }}
                    className={clsx(
                        'flex relative gap-0.5 p-0.75 bg-background-form group-focus:bg-surface-primary-hover cursor-pointer w-full outline-none z-dropDown',
                        isOpen ? 'rounded-t-0.5' : 'rounded-0.5',
                    )}
                    rightIcon={Icons.ChevronDown}
                    iconClassName={clsx('duration-moderate1 ease-linear fill-text-primary', isOpen && 'rotate-180')}
                    onClick={toggleMenu}
                />

                <DropdownOptionList
                    id={id}
                    optionsList={optionsList}
                    isOpen={isOpen}
                    selectedLabel={selectedValue}
                    focusedIndex={focusedIndex}
                    listRef={listRef}
                    onValueUpdate={handleOptionClick}
                />
            </div>

            <GlowTextCaption text={helperLabel} color={isSelectedFailed ? 'error' : 'primary'} />
        </div>
    );
};
