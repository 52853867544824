import React from 'react';
import { Component } from '@headless-workspace/typings';
import { CurrencyHelpers } from '@headless-workspace/utils';
import { GlowProgressBar, GlowTextBodySmall } from '../../atoms';

type GlowShippingInformationProps = {
    totalBasket: number;
    totalForFreeShipping: number;
    remainderForFreeShippingLabel: string;
    currency: string;
};

/**
 * A component that displays shipping information for a shopping basket.
 *
 * @param totalBasket - The total value of the items in the shopping basket.
 * @param totalForFreeShipping - The minimum total value of the items in the shopping basket to qualify for free shipping.
 * @param remainderForFreeShippingLabel - The label for the remaining amount to qualify for free shipping.
 * @param currency - The currency of the values.
 */
export const GlowShippingInformation: Component<GlowShippingInformationProps> = ({
    totalBasket,
    totalForFreeShipping,
    remainderForFreeShippingLabel,
    currency,
}) => {
    const currencySymbol = CurrencyHelpers.getCurrencySymbol(currency);
    const minShippingPrice = `0${currencySymbol}`;

    return (
        <div className={'flex flex-col gap-0.5 p-1 justify-between bg-surface-neutral rounded-0.5'}>
            {currencySymbol && (
                <div className={'flex flex-row gap-1 items-center'}>
                    <GlowTextBodySmall text={minShippingPrice} />
                    <GlowProgressBar value={totalBasket} max={totalForFreeShipping} />
                    <GlowTextBodySmall text={`${totalForFreeShipping}${currencySymbol}`} />
                </div>
            )}
            <GlowTextBodySmall text={remainderForFreeShippingLabel} />
        </div>
    );
};
