const createSequentialArray = (length: number): number[] => Array.from({ length }, (_, index) => index + 1);

const asyncFind = async <T>(array: T[], predicate: (value: T) => Promise<boolean>): Promise<T | undefined> => {
    for (const item of array) {
        const isMatch = await predicate(item);
        if (isMatch) {
            return item;
        }
    }
    return undefined;
};

const findMatchingValue = (value: string | string[], match: string): boolean => {
    if (Array.isArray(value)) {
        return value.includes(match);
    }
    return value === match;
};

const isNotEmpty = (array: unknown[]): boolean => {
    return array.length > 0;
};

export const ArrayHelpers = {
    createSequentialArray,
    asyncFind,
    findMatchingValue,
    isNotEmpty,
};
