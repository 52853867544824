'use client';

import { AriaAttributes, AriaRole, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowIcon, GlowTextBody, TextProps } from '../atoms';
import { GlowClickable } from '../molecules/cta';
import { IconComponentType, PropsWithClassName } from '../props';
import { Icons } from '../res/icons';

export type GlowToastProps = PropsWithClassName & {
    label: TextProps;
    toastType: ToastType;
};

export enum ToastType {
    Success = 'success',
    Error = 'error',
    Info = 'info',
}

const roles: Record<ToastType, AriaRole> = {
    [ToastType.Success]: 'status',
    [ToastType.Info]: 'status',
    [ToastType.Error]: 'alert',
};

const icons = {
    [ToastType.Success]: Icons.Success,
    [ToastType.Error]: Icons.Warning,
    [ToastType.Info]: Icons.Info,
};

const backgroundClasses = {
    [ToastType.Success]: 'bg-background-success',
    [ToastType.Error]: 'bg-background-error-red',
    [ToastType.Info]: 'bg-background-infos',
};

const ariaLives: Record<ToastType, AriaAttributes['aria-live']> = {
    [ToastType.Success]: 'polite',
    [ToastType.Info]: 'polite',
    [ToastType.Error]: 'assertive',
};

const fillIcons = {
    [ToastType.Success]: 'fill-text-success',
    [ToastType.Error]: 'fill-text-error',
    [ToastType.Info]: 'fill-text-infos',
};

const borderBase = 'border-l-strong border-solid';
const borderClasses = {
    [ToastType.Success]: `border-l-border-success ${borderBase}`,
    [ToastType.Error]: `border-l-border-error ${borderBase}`,
    [ToastType.Info]: `border-l-border-info ${borderBase}`,
};

export const getToastConfig = (toastType: ToastType) => {
    return {
        toastIcon: icons[toastType],
        backgroundClassName: backgroundClasses[toastType],
        role: roles[toastType],
        ariaLive: ariaLives[toastType],
        fillIcon: fillIcons[toastType],
        borderClass: borderClasses[toastType],
    };
};

type ToastIconProps = {
    Icon: IconComponentType;
    colorClass?: string;
};

const ToastIcon: Component<ToastIconProps> = ({ Icon, colorClass }) => {
    return (
        <span className={clsx('flex items-center p-0.75', colorClass)}>
            <GlowIcon Icon={Icon} type={'medium'} />
        </span>
    );
};

type ToastLabelProps = {
    label: TextProps;
};

const ToastLabel: Component<ToastLabelProps> = ({ label }) => {
    return (
        <GlowTextBody
            TagName={'div'}
            className={'flex-1 leading-tight my-0.75 line-clamp-3'}
            innerHTML={label.innerHTML ?? label.text}
        />
    );
};

type ToastCloseButtonProps = {
    onClose?: () => void;
};

const ToastCloseButton: Component<ToastCloseButtonProps> = ({ onClose }) => {
    if (!onClose) return null;
    return (
        <GlowClickable
            className={'flex items-center p-0.75'}
            content={{ icon: <GlowIcon Icon={Icons.Cross} type={'large'} /> }}
            onClick={onClose}
        />
    );
};

type ContainerProps = {
    role: AriaRole;
    ariaLive: AriaAttributes['aria-live'];
} & PropsWithClassName &
    PropsWithChildren;

export const Container: Component<ContainerProps> = ({ className, children, role, ariaLive }) => {
    return (
        <div
            className={clsx('flex items-center justify-between rounded-0.25 w-full max-w-toastContainer ', className)}
            role={role}
            aria-live={ariaLive}
            aria-atomic={'true'}
        >
            {children}
        </div>
    );
};
export const ComposableToast = {
    Container: Container,
    Icon: ToastIcon,
    Label: ToastLabel,
    CloseButton: ToastCloseButton,
} as const;
