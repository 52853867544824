'use client';

import { useTranslations } from 'next-intl';
import { GlowClickable, GlowIcon, GlowTitle, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LocaleSwitcherMenuProps } from './LocaleSwitcherMenu';

type CountryMenuHeaderProps = Pick<LocaleSwitcherMenuProps, 'title'> & {
    onClose: () => void;
};

export const LocaleSwitcherMenuHeader: Component<CountryMenuHeaderProps> = ({ title, onClose }) => {
    const t = useTranslations('Footer.country');
    const text = title ?? t('label.shippingCountry');

    return (
        <div
            className={
                'flex flex-row justify-between items-center py-0.75 px-1 gap-1 border-b-medium border-b-border-subtle border-solid'
            }
        >
            <GlowTitle text={text} fontSize={'title4'} />
            <GlowClickable
                className={'text-text-primary'}
                content={{
                    icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                }}
                onClick={onClose}
            />
        </div>
    );
};
