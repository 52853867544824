import { PropsWithChildren } from 'react';
import { Component } from '@headless-workspace/typings';
import { GlowImageClickable, ImageClickableProps } from '../../atoms';
import { ComposableCard, ComposableBannerCardProps } from '../../composables';
import { NextImageType } from '../../props';
import { PropsWithNewDataLayer } from '../../props';

export type CardBannerProps = ComposableBannerCardProps &
    PropsWithChildren &
    PropsWithNewDataLayer & {
        image: ImageClickableProps;
        TagName?: NextImageType;
        classNames?: {
            container?: string;
            wrapper?: string;
        };
        href?: string;
        ariaLabel?: string;
    };

export const CardBanner: Component<CardBannerProps> = ({
    children,
    image,
    TagName,
    button,
    classNames,
    href,
    ariaLabel,
    ...props
}) => {
    return (
        <ComposableCard.Container className={classNames?.container} {...props}>
            <ComposableCard.Wrapper href={href} ariaLabel={ariaLabel} className={classNames?.wrapper}>
                <GlowImageClickable TagName={TagName} {...button} {...image} />
                {children}
            </ComposableCard.Wrapper>
        </ComposableCard.Container>
    );
};
