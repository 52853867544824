'use client';

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { GlowClickable, GlowIcon, Icons, PropsWithClassName } from '@headless-workspace/glow-ds';
import { tailwindTheme } from '@headless-workspace/theme';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { useTailwindBreakpoint } from '../../hooks';

type UISearchIconButtonProps = PropsWithClassName;

export const UISearchIconButton: Component<UISearchIconButtonProps> = ({ className }) => {
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
    const isMobile = useTailwindBreakpoint('tablet', false, true);

    useEffect(() => {
        const updateIsSearchBarVisible = () => {
            const mobileSearchBar = document.getElementById('mobile-search-bar');
            const fixedHeader = document.getElementById('fixed-header');

            if (mobileSearchBar && fixedHeader) {
                const searchBarBottomY =
                    mobileSearchBar.offsetTop + mobileSearchBar.offsetHeight - fixedHeader.offsetHeight;

                if (searchBarBottomY > 0) {
                    setIsSearchBarVisible(window.scrollY < searchBarBottomY);
                }
            }
        };
        updateIsSearchBarVisible();

        window.addEventListener('scroll', updateIsSearchBarVisible);
        return () => {
            window.removeEventListener('scroll', updateIsSearchBarVisible);
        };
    }, []);

    if (!isMobile) {
        return null;
    }

    const onClick = () => {
        document.body.classList.add('overflow-hidden');
        const mobileSearchBar = document.getElementById('mobile-search-bar');
        const mobileSearchInput = document.getElementById('mobile-search-input');
        const mobileSearchMenu = document.getElementById('mobile-search-menu');
        const header = document.querySelector('header');

        if (mobileSearchBar && mobileSearchMenu && mobileSearchInput && header) {
            header.classList.add('search-isOpen');
            mobileSearchInput.style.display = 'flex';
            mobileSearchMenu.style.display = 'flex';
            mobileSearchInput.focus();
            // HACK IOS: to display search result with iphone top tab && bottom tap
            if (StringHelpers.isDevice('iPhone')) {
                mobileSearchBar.style.height = tailwindTheme.height.dynamicViewportHeight;
            }
        }
    };

    return (
        <GlowClickable
            className={clsx(isSearchBarVisible && 'hidden', 'p-0.5', className)}
            onClick={onClick}
            suppressHydrationWarning
            content={{
                icon: <GlowIcon Icon={Icons.Search} type={'large'} />,
            }}
        />
    );
};
