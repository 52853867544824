import { useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

export const useCarouselNavigation = (options?: EmblaOptionsType) => {
    const [progressValue, setProgressValue] = useState(0);
    const [slidesInView, setSlidesInView] = useState<number[]>([]);
    const [slidesInProgressView, setSlidesInProgressView] = useState<number[]>([]);

    const [emblaRef, emblaApi] = useEmblaCarousel(options, [WheelGesturesPlugin()]);

    const handleCarouselEvent = useCallback((emblaApi: EmblaCarouselType) => {
        const { limit, target, offsetLocation, location, scrollTo, translate, scrollBody } = emblaApi.internalEngine();

        /* Prevent bounce overscroll effect - START */
        let edge: number | null = null;

        if (limit.reachedMax(target.get())) {
            edge = limit.max;
        }
        if (limit.reachedMin(target.get())) {
            edge = limit.min;
        }

        if (edge !== null) {
            offsetLocation.set(edge);
            location.set(edge);
            target.set(edge);
            translate.to(edge);
            translate.toggleActive(false);
            scrollBody.useDuration(0).useFriction(0);
            scrollTo.distance(0, false);
        } else {
            translate.toggleActive(true);
        }
        /* Prevent bounce overscroll effect - END */

        // Update progress value for slider
        const newProgressValue = Math.max(0, Math.min(Math.round(emblaApi.scrollProgress() * 100), 100));
        setProgressValue(newProgressValue);
    }, []);

    // @see, https://github.com/davidjerleke/embla-carousel/issues/26
    // Scroll embla carousel according to slider progress
    const scrollToProgress = useCallback(
        (progress: number) => {
            if (!emblaApi) return;

            const { limit, target, scrollProgress, scrollTo } = emblaApi.internalEngine();
            const currentProgress = scrollProgress.get(target.get());
            const allowedProgress = Math.min(Math.max(progress, 0), 1);
            const progressToTarget = allowedProgress - currentProgress;
            const distance = progressToTarget * limit.length * -1;

            scrollTo.distance(distance, false);
        },
        [emblaApi],
    );

    // Slide change callback
    const onSlideChange = useCallback(
        (value: number) => {
            scrollToProgress(value / 100);
        },
        [scrollToProgress],
    );

    // Slide end callback, triggers when user release slider, used to snap to nearest slide item
    const onSlideEnd = useCallback(
        (value: number) => {
            // direction === -1 if user slides to right (next scroll)
            // direction === 0  if user slides to left (prev scroll)
            const direction = emblaApi?.internalEngine().scrollBody.direction() ?? 0;
            let index = 0;
            const progress = emblaApi?.scrollProgress() ?? 0;
            const scrollSnapList = emblaApi?.scrollSnapList() ?? [];

            for (let i = 0; i < scrollSnapList.length; i++) {
                if (progress > scrollSnapList[i]) {
                    index = i;
                }
            }

            if (direction <= 0) {
                emblaApi?.scrollTo(index + 1);
            } else {
                emblaApi?.scrollTo(index);
            }
        },
        [emblaApi],
    );

    const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off('slidesInView', updateSlidesInView);
            }
            const inView = emblaApi.slidesInView().filter((index) => !slidesInView.includes(index));

            return slidesInView.concat(inView);
        });
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        updateSlidesInView(emblaApi);
        emblaApi.on('slidesInView', (embla) => {
            updateSlidesInView(embla);
            setSlidesInProgressView(embla.slidesInView());
        });
        emblaApi.on('reInit', updateSlidesInView);
        emblaApi.on('scroll', handleCarouselEvent);
        return () => {
            emblaApi
                .off('slidesInView', updateSlidesInView)
                .off('reInit', updateSlidesInView)
                .off('scroll', handleCarouselEvent);
        };
    }, [emblaApi, handleCarouselEvent, updateSlidesInView]);

    return {
        progressValue,
        slidesInView,
        slidesInProgressView,
        emblaRef,
        emblaApi,
        onSlideChange,
        onSlideEnd,
    };
};
