import { NextImageType } from '../../../../src';

type ImgProps = {
    TagName?: 'img';
    height?: number | string;
    width?: number | string;
    fetchPriority?: 'auto' | 'high' | 'low';
};

type NextImageProps = {
    TagName: NextImageType;
    height?: number;
    width?: number;
    priority?: boolean;
    placeholder?: 'empty' | 'blur' | `data:image/${string}`;
    blurDataURL?: string;
    fill?: boolean;
    sizes?: string;
};

export type ImageProps = ImgProps | NextImageProps;

export const isImgProps = (props: ImageProps): props is ImgProps => {
    return (props as ImgProps).TagName === 'img' || (props as ImgProps).TagName === undefined;
};
