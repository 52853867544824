export type ImageWithSrc = {
    src: string;
    alt: string;
};

export type ImageWithSizes = {
    small?: string;
    medium?: string;
    large?: string;
    alt: string;
};

export type Image = ImageWithSrc | ImageWithSizes;

export const isImageWithSrc = (image: Image): image is ImageWithSrc => {
    return (image as ImageWithSrc).src !== undefined;
};
