import { Config } from 'tailwindcss';
import { commonTailwindTheme } from './domains/common/tailwind.theme';
import { discoverTailwindTheme } from './domains/discover/tailwind.theme';

let colors = {};
try {
    colors = require('./tokens/colors.json');
    if (!colors || typeof colors !== 'object') {
        colors = {};
    }
} catch (error) {
    colors = {};
}
export { colors };

type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (x: infer I) => void ? I : never;
type Merge<T> = {
    [K in keyof T]: T[K] extends object ? Merge<T[K]> : T[K];
};

function mergeRecords<T extends Record<string, any>[]>(...records: T): UnionToIntersection<Merge<T[number]>> {
    return records.reduce((acc, record) => {
        Object.keys(record).forEach((key) => {
            if (acc[key] && typeof acc[key] === 'object' && typeof record[key] === 'object') {
                acc[key] = mergeRecords(acc[key], record[key]);
            } else {
                acc[key] = record[key];
            }
        });
        return acc;
    }, {} as any);
}

const themes = mergeRecords(commonTailwindTheme, discoverTailwindTheme);

export const tailwindTheme = {
    colors,
    ...themes,
} satisfies Config['theme'];

export type TailwindTheme = typeof tailwindTheme;
