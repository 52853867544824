'use client';

import React, { PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithDataLayer,
    PropsWithHref,
    PropsWithId,
    PropsWithStyle,
    PropsWithTestId,
} from '../../props';

export type GlowLinkProps = PropsWithStyle &
    PropsWithAriaLabel &
    PropsWithId &
    PropsWithTestId &
    PropsWithChildren &
    PropsWithClassName &
    PropsWithHref &
    PropsWithDataLayer;

/**
 * A component that renders an anchor or span if the link is obfuscated.
 * @param style - Additional styles for the anchor or span.
 * @param ariaLabel - The aria-label for the anchor or span.
 * @param children - The content to render.
 * @param className - Additional classes to apply.
 * @param href - The href for the anchor.
 * @param id - The id for the anchor or span.
 * @param dataTestId - The data-testid for the anchor or span.
 * @param isObfuscated - Whether the link should be obfuscated.
 * @param dataLayerId - The data-layer-id for the anchor.
 * @param dataLayerSubId - The data-layer-sub-id for the anchor.
 * @param dataLayerLabel - The data-layer-label for the anchor.
 * @param dataLayerAction - The data-layer-action for the anchor.
 * @param disabled - Whether the link should be disabled.
 */
export const GlowLink: Component<GlowLinkProps> = ({
    style,
    ariaLabel,
    children,
    className,
    href,
    id,
    dataTestId,
    isObfuscated,
    dataLayerId,
    dataLayerSubId,
    dataLayerLabel,
    dataLayerAction,
    disabled,
}) => {
    const [obfuscatedHref, setObfuscatedHref] = useState<string>();
    useEffect(() => {
        if (isObfuscated) {
            setObfuscatedHref(window.btoa(encodeURIComponent(href)));
        }
    }, [href, isObfuscated]);

    return isObfuscated ? (
        <span
            role={'link'}
            data-obfuscated-href={obfuscatedHref}
            className={clsx(disabled ? 'pointer-events-none cursor-default' : 'cursor-pointer', className)}
            style={style}
            aria-label={ariaLabel}
            data-testid={dataTestId}
            data-layer-id={dataLayerId}
            data-layer-sub-id={dataLayerSubId}
            data-layer-label={dataLayerLabel}
            data-layer-action={dataLayerAction}
        >
            {children}
        </span>
    ) : (
        <a
            className={clsx(disabled && 'pointer-events-none cursor-default', className)}
            href={href}
            aria-label={ariaLabel}
            id={id}
            style={style}
            data-testid={dataTestId}
            data-layer-id={dataLayerId}
            data-layer-sub-id={dataLayerSubId}
            data-layer-label={dataLayerLabel}
            data-layer-action={dataLayerAction}
        >
            {children}
        </a>
    );
};
