export const BURGER_MENU_ID = 'BURGER_MENU_ID';
export const COUNTRY_SWITCHER_MENU_ID = 'COUNTRY_SWITCHER_MENU_ID';
export const PERSONAL_DATA_MENU_ID = 'PERSONAL_DATA_MENU_ID';
export const PRODUCT_INFORMATION_MENU_ID = 'PRODUCT_INFORMATION_MENU_ID';
export const PRODUCT_PRICES_INFORMATION_MENU_ID = 'PRODUCT_PRICES_INFORMATION_MENU_ID';

export const PRODUCT_DETAIL_VISUAL_MODAL_ID = 'PRODUCT_DETAIL_VISUAL_MODAL_ID';
export const WISHLIST_MODAL_ID = 'WISHLIST_MODAL_ID';
export const NEWSLETTER_MODAL_ID = 'NEWSLETTER_MODAL_ID';
export const ADDED_TO_BASKET_MODAL_ID = 'ADDED_TO_BASKET_MODAL_ID';
