'use client';

import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { GlowButton, Icons } from '@headless-workspace/glow-ds';
import { useIsMounted } from 'usehooks-ts';
import { ShopInTheFuture } from './ShopInTheFuture';
import { useSitePreviewStorage } from './useSitePreviewStorage';

export const SitePreview = (): ReactNode => {
    const t = useTranslations('Preview.shopInTheFuture');
    const [showControls, setShowControls] = useState(false);
    const { isSitePreviewClosed, closeSitePreview } = useSitePreviewStorage();
    const isMounted = useIsMounted();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const onClick = () => {
        setShowControls(!showControls);
    };

    const onClose = () => {
        setShowControls(false);
    };
    const onPreviewHide = useCallback(() => {
        closeSitePreview();
    }, [closeSitePreview]);

    useEffect(() => {
        setIsPreviewOpen(!isMounted() || isSitePreviewClosed);
    }, [isMounted, isSitePreviewClosed]);

    if (isPreviewOpen) {
        return null;
    }

    return (
        <div className={'flex flex-col absolute z-sitePreview'}>
            <GlowButton
                Icon={Icons.Setting}
                label={t('label.title')}
                onClick={onClick}
                variant={'tertiary'}
                className={'w-sitePreviewButton'}
            />
            <ShopInTheFuture
                className={clsx(showControls ? 'mt-0.5' : 'hidden')}
                onPreviewClose={onClose}
                onPreviewHide={onPreviewHide}
            />
        </div>
    );
};
