import { CountryCode, Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result, ResultSuccess } from '@headless-workspace/core-domain';
import '@headless-workspace/data';
import {
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
} from '@headless-workspace/data';
import { LanguageHelpers } from '@headless-workspace/utils';

export interface CrossBorderRepositorySpec {
    getIsCrossBorder(locale: Locale): Promise<Result<boolean>>;
}

// FIXME: uncomment this line when SODA delivered the cross border endpoint
// const ENABLE_CROSS_BORDER_KEY = 'enableCrossborder';

const COUNTRIES_IN_HUB = [
    CountryCode.SE,
    CountryCode.DK,
    CountryCode.ES,
    CountryCode.PT,
    CountryCode.RO,
    CountryCode.GR,
    CountryCode.CZ,
    CountryCode.PL,
];

export class CrossBorderRepository extends ConfigByKeyRepository implements CrossBorderRepositorySpec {
    constructor() {
        super(memoryCacheAuthStrategy, memoryCacheRefreshTokenStrategy, memoryCacheQueryParamStrategy);
    }

    async getIsCrossBorder(locale: Locale): Promise<Result<boolean>> {
        // FIXME: uncomment this line and delete the rest of the code when SODA delivered the cross border endpoint
        // return await this.getConfigByKey(ENABLE_CROSS_BORDER_KEY, { locale }, (value) => z.boolean().parse(value));

        const country = LanguageHelpers.parseCountry(locale);
        if (COUNTRIES_IN_HUB.includes(country)) {
            return ResultSuccess(true);
        }
        return ResultSuccess(false);
    }
}
