import { DefaultValueConfig } from '@headless-workspace/config';
import { Image } from '@headless-workspace/typings';
import { ObjectHelpers, StringHelpers } from '@headless-workspace/utils';
import { ImageDTO } from './ImageDTO';

const getImageEncodedUrl = (url?: string) => {
    return url && (StringHelpers.isUrl(url) ? encodeURI(url) : DefaultValueConfig.imagePlaceholder);
};

export const mapImage = (dto?: ImageDTO): Image | undefined => {
    if (!dto || ObjectHelpers.isEmpty(dto)) {
        return undefined;
    }

    if ('default' in dto) {
        return {
            src: getImageEncodedUrl(dto.default),
            alt: dto.alt ?? DefaultValueConfig.alt,
        };
    }

    const imageWithSizes = {
        alt: dto.alt ?? DefaultValueConfig.alt,
        small: getImageEncodedUrl(dto.small),
        medium: getImageEncodedUrl(dto.medium),
        large: getImageEncodedUrl(dto.large),
    };

    return !imageWithSizes.small && !imageWithSizes.medium && !imageWithSizes.large
        ? {
              src: DefaultValueConfig.imagePlaceholder,
              alt: imageWithSizes.alt,
          }
        : imageWithSizes;
};
export const mapURLToImage = (url?: string | null, alt?: string): Image | undefined => {
    if (!url) {
        return undefined;
    }
    return {
        src: getImageEncodedUrl(url),
        alt: alt ?? DefaultValueConfig.alt,
    };
};
