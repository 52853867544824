import { isStandardLoyaltyLocale, Locale } from '@headless-workspace/config';
import { DateHelpers } from '@headless-workspace/utils';
import { HTMLString } from '../HTMLString';
import { CustomerDTO } from './CustomerDTO';
import { customerOfferMock } from './customerOfferMock';

export enum UserSephoraCard {
    PreBuy = 'PRE_ACHAT',
    White = 'WHITE',
    Black = 'BLACK',
    Gold = 'GOLD',
}

export enum UserSephoraCardUnlimited {
    Standard = 'STANDARD',
    Star = 'STAR',
}

type Loyalty = {
    currentPoints: number;
    pointsToNextDiscount: number;
    sephoraCard: UserSephoraCard | UserSephoraCardUnlimited;
};

export type UserOffer = {
    title: HTMLString;
    code: HTMLString;
};

export type UserValue = Readonly<{
    lastName: string;
    firstName: string;
    favoriteStoreId?: string;
    loyalty?: Loyalty;
    offer?: UserOffer;
    isSubscribedByEmail: boolean;
    ordersCount: number;
    gender?: string;
    type?: string;
    hasFavoriteAddress?: boolean;
    hasFavoriteCreditCard?: boolean;
    hasOffers?: boolean;
    inscriptionDate?: string;
}>;

export type CustomerValue = Readonly<
    {
        customerId: string;
        favoriteStoreId?: string;
    } & UserValue
>;

const mapLoyalty = (loyalty: CustomerDTO['loyalty'], locale: Locale): Loyalty | undefined => {
    const hasLoyalty = !!loyalty && !!loyalty.status && loyalty.status !== UserSephoraCard.PreBuy;

    if (!hasLoyalty) {
        return undefined;
    }

    const sephoraCard = isStandardLoyaltyLocale(locale)
        ? (loyalty.status as UserSephoraCard)
        : (loyalty.status as UserSephoraCardUnlimited);

    return {
        currentPoints: loyalty.currentPoints,
        pointsToNextDiscount: loyalty.pointsToNextDiscount,
        sephoraCard,
    };
};

export const mapCustomer = (dto: CustomerDTO, locale: Locale): CustomerValue => {
    return {
        customerId: dto.customerId,
        firstName: dto.firstName,
        lastName: dto.lastName,
        favoriteStoreId: dto.favoriteStoreId,
        loyalty: mapLoyalty(dto.loyalty, locale),
        offer: dto.loyalty?.status === UserSephoraCardUnlimited.Star ? customerOfferMock : undefined, // TODO change this MOCK
        isSubscribedByEmail: dto.isSubscribedByEmail ?? false,
        ordersCount: 0, // FIXME: unmock this when API returns this value
        gender: dto.gender,
        type: dto.type,
        hasFavoriteAddress: dto.hasFavoriteAddress,
        hasFavoriteCreditCard: dto.hasFavoriteCreditCard,
        hasOffers: dto.hasOffers,
        inscriptionDate: DateHelpers.formatDateFromDMYToYMD(dto.creationDate),
    };
};
