'use client';

import { useCallback } from 'react';
import { COUNTRY_SWITCHER_MENU_ID } from '@headless-workspace/config';
import { useCountry, useUIContext } from '@headless-workspace/core-ui';
import { GlowTextBody, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { UrlHelpers } from '@headless-workspace/utils';
import { CountryValue } from '../../../server';
import { DataLayerEvent, DataLayerEventCategory, useDataLayer } from '../dataLayer';
import { useNavigation } from '../navigation';
import { LocaleSwitcherMenuDataLayerParams } from './LocaleSwitcherMenuDataLayerParams';
import { LocaleSwitcherMenuHeader } from './LocaleSwitcherMenuHeader';
import { LocaleSwitcherMenuItemButton } from './LocaleSwitcherMenuItemButton';

const DATA_LAYER_EVENT_TYPE = 'header';

export type LocaleSwitcherMenuProps = PropsWithLocale &
    LocaleSwitcherMenuDataLayerParams & {
        countries: CountryValue[];
        title?: string;
        description?: string;
        showCode?: boolean;
    };

/**
 * A component meant to display a list of {@link LocaleSwitcherMenuItemButton},
 * based on the supported languages.
 * @param {object} props
 * @param {Locale} props.locale - Current app locale.
 * @param {CountryValue[]} props.countries - List of countries buttons to display.
 * @param {string} [props.title] - Modal/menu title.
 * @param {string} [props.description] - Modal/menu description.
 * @param {boolean} [props.showCode] - Show language code or country code.
 * @param {LocaleSwitcherMenuDataLayerParams} props.dataLayer - Data layer parameters.
 * @returns {JSX.Element}
 */
export const LocaleSwitcherMenu: Component<LocaleSwitcherMenuProps> = ({
    locale,
    countries,
    title,
    description,
    showCode,
    dataLayer,
}) => {
    const {
        menuModal: { close: closeMenu },
    } = useUIContext();
    const { trackEvent } = useDataLayer();

    const navigation = useNavigation();
    const { changeCountry, countryCode } = useCountry();

    const closeCountryMenu = useCallback(() => {
        closeMenu(COUNTRY_SWITCHER_MENU_ID);
    }, [closeMenu]);

    const onChangeCountry = useCallback(
        ({ code, tld, language }: CountryValue) =>
            () => {
                closeCountryMenu();

                if (tld) {
                    const { href } = window.location;
                    const baseUrl = UrlHelpers.getCustomBaseUrl(href, tld);
                    const pathname = UrlHelpers.getHrefPathname(href);

                    navigation.push(
                        language
                            ? `${baseUrl}/${language}${UrlHelpers.removeLanguageCode(pathname)}`
                            : `${baseUrl}${pathname}`,
                    );
                } else {
                    changeCountry(code, locale);
                }
                trackEvent(DataLayerEvent.Click, {
                    category: dataLayer.category,
                    action: dataLayer.action,
                    label:
                        dataLayer.category === DataLayerEventCategory.languageSwitcher ? language : code.toLowerCase(),
                    event_type: DATA_LAYER_EVENT_TYPE,
                });
            },
        [changeCountry, closeCountryMenu, dataLayer.action, dataLayer.category, locale, navigation, trackEvent],
    );

    return (
        <div className={'h-fullViewport w-fullViewport tablet:w-full flex flex-col bg-background-l3'}>
            <LocaleSwitcherMenuHeader title={title} onClose={closeCountryMenu} />
            <div className={'flex flex-col p-1 gap-1 bg-background-l3'}>
                {description && <GlowTextBody text={description} />}
                {countries.map((country) => (
                    <LocaleSwitcherMenuItemButton
                        key={country.code}
                        locale={locale}
                        country={country}
                        isOnCurrentCountry={!country.language && country.code === countryCode}
                        showCode={showCode}
                        onClick={onChangeCountry(country)}
                    />
                ))}
            </div>
        </div>
    );
};
