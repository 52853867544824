const LOCALITIES_MIN_LENGTH = 3;

const LOCALITIES_MAX_LENGTH = 4;

const LOCALITIES_DEBOUNCE_TIME_MS = 150;

const LOCALITIES_CUSTOM_DESCRIPTION = 'postal_code:"{name} ({postal_town}) - {administrative_area_level_0}"';

export const localitiesConfig = {
    minLength: LOCALITIES_MIN_LENGTH,
    maxLength: LOCALITIES_MAX_LENGTH,
    debounceTime: LOCALITIES_DEBOUNCE_TIME_MS,
    customDescription: LOCALITIES_CUSTOM_DESCRIPTION,
} as const;
