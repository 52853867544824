'use client';

import { DataLayerEvent } from './DataLayerEvent';
import { DataLayerEventParams } from './DataLayerEventParams';
import { DataLayerKey } from './DataLayerKey';

export interface DataLayerManagerSpec {
    addVariable(name: DataLayerKey, value: string): void;

    addVariables(variables: Partial<Record<DataLayerKey, string>>): void;

    trackEvent(eventName: DataLayerEvent, params: DataLayerEventParams): void;

    showPrivacyCenter(): void;
}

export class DataLayerManager implements DataLayerManagerSpec {
    public variables: Partial<Record<DataLayerKey, string>>;

    constructor() {
        this.variables = {
            [DataLayerKey.EnvChannel]: 'web',
            [DataLayerKey.EnvHeadless]: 'y',
        };
    }

    addVariable(name: DataLayerKey, value: string): void {
        this.variables[name] = value;
        window.tc_vars = this.variables;
    }

    addVariables(variables: Partial<Record<DataLayerKey, string>>): void {
        this.variables = {
            ...this.variables,
            ...variables,
        };
        window.tc_vars = this.variables;
    }

    trackEvent(eventName: DataLayerEvent, params: DataLayerEventParams): void {
        window.tc_events_global(window, eventName, params);
    }

    showPrivacyCenter() {
        window.tC.privacyCenter.showPrivacyCenter();
    }
}
