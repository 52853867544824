import { z } from 'zod';

const MetadataSchemaPotentialActionDTO = z
    .object({
        potentialAction: z
            .object({
                '@type': z.string(),
                target: z.string(),
                'query-input': z.string(),
            })
            .optional(),
    })
    .optional();

const MetadataSchemaLogoDTO = z
    .object({
        logo: z.string().optional(),
        contactPoint: z
            .object({
                '@type': z.string(),
                contactType: z.string(),
                telephone: z.string(),
                email: z.string(),
            })
            .optional(),
    })
    .optional();

const MetadataSchemaSameAsDTO = z.object({
    sameAs: z.array(z.string()).optional(),
});

export const MetadataSchemaDTO = z
    .object({
        '@context': z.string(),
        '@type': z.string(),
        name: z.string(),
        url: z.string(),
        alternateName: z.string().optional(),
        legalName: z.string().optional(),
    })
    .and(MetadataSchemaPotentialActionDTO.and(MetadataSchemaLogoDTO).and(MetadataSchemaSameAsDTO));

export type MetadataSchemaDTO = z.infer<typeof MetadataSchemaDTO>;

export const MetaDataSEOByKeyDTO = z.object({
    title: z.string(),
    description: z.string(),
    keywords: z.string(),
    openGraph: z.object({
        title: z.string(),
        description: z.string(),
        url: z.string(),
        siteName: z.string(),
        type: z.string(),
        images: z.array(
            z.object({
                url: z.string(),
            }),
        ),
    }),
    twitter: z.object({
        card: z.string(),
        title: z.string(),
        description: z.string(),
    }),
    verification: z.object({
        google: z.string(),
    }),
    alternates: z.object({
        canonical: z.string(),
        languages: z.record(z.string()),
    }),
    httpsEquivs: z.array(
        z.object({
            httpEquiv: z.string(),
            content: z.string(),
        }),
    ),
    schemas: z.array(MetadataSchemaDTO),
    robots: z.object({
        index: z.boolean(),
        follow: z.boolean(),
        nocache: z.boolean(),
    }),
    appLinks: z
        .object({
            ios: z
                .object({
                    url: z.string(),
                    app_store_id: z.string(),
                })
                .optional(),
            android: z
                .object({
                    package: z.string(),
                    app_name: z.string(),
                })
                .optional(),
            web: z
                .object({
                    url: z.string(),
                    should_fallback: z.boolean(),
                })
                .optional(),
        })
        .optional(),
});

export type MetaDataSEOByKeyDTO = z.infer<typeof MetaDataSEOByKeyDTO>;
