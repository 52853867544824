import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowClickable, GlowIcon, GlowTypography, Icons } from '../../../../../src';
import { ButtonBaseProps, ButtonProps } from './buttonProps';
import { buttonStyle } from './buttonStyle';

/**
 * Button component
 * @param Icon - Icon to be displayed on the right side of the text
 * @param label - Label of the button
 * @param size - Size of the button. Default is 'large'
 * @param variant - Variant of the button. Default is 'primary'
 * @param width - Width of the button. Can be 'auto', 'full' or 'fit'. Default is 'auto'
 * @param fontSize - Font size of the label. Can be 'body' or 'bodySmall'
 * @param fontFamily - Font family of the label. Can be 'brand' or 'body'
 * @param fill - Fill color of the icon
 * @param isSpinnerActive - Flag to show spinner. Default is false
 * @param className - Additional styles
 * @param isCTA - If false, the button will not be clickable
 * @param props - Action to be performed when the button is clicked (href, onClick, ...)
 */
export const Button: Component<ButtonBaseProps & ButtonProps> = ({
    Icon,
    label,
    size = 'large',
    variant = 'primary',
    width = 'auto',
    fontSize = 'body',
    fontFamily = 'brand',
    fill,
    isSpinnerActive = false,
    className,
    isCTA = true,
    ...props
}) => {
    const iconElement = isSpinnerActive ? (
        <GlowIcon Icon={Icons.Spinner} className={'animate-spin fill-text-primary-inverse'} />
    ) : (
        Icon && <GlowIcon Icon={Icon} type={'medium'} fill={fill} />
    );

    const labelElement = label && (
        <GlowTypography
            fontSize={fontSize}
            text={label}
            fontWeight={'bold'}
            color={null}
            TagName={'span'}
            fontFamily={fontFamily}
        />
    );

    if (!isCTA) {
        return (
            <div
                className={clsx(buttonStyle({ size, variant, width }), 'flex flex-row items-center', className)}
                data-testid={props.dataTestId}
            >
                {iconElement}
                {labelElement}
            </div>
        );
    }

    return (
        <GlowClickable
            className={clsx(buttonStyle({ size, variant, width }), className)}
            content={{
                labelElement: labelElement,
                icon: iconElement,
            }}
            {...props}
        />
    );
};
