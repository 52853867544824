import { useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel';

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean;
    nextBtnDisabled: boolean;
    onPrevButtonClick: () => void;
    onNextButtonClick: () => void;
    selectedIndex: number;
};

type ScrollMode = 'element' | 'block';

/**
 * The @usePrevNextButtons hook is designed to manage the behavior of previous and next buttons in an Embla carousel.
 * @param emblaApi     - The Embla Carousel API, used to interact with the carousel
 * @param scrollMode   - The scroll mode (default 'element'). 'element' means scroll by individual element, 'block' means scroll by block
 * @param slidesInView - An array of the indices of slides currently visible (used in 'block' mode)
 *
 * **Functions:**
 * @loopIndex - Function to loop the index, ensuring it wraps around the carousel when scrolling (when the user reaches the end of the carousel,
 *              it moves back to the first slide, and vice versa.)
 * @scrollTo  - Function to scroll the carousel by a given step (positive for forward, negative for backward)
 */

export const usePrevNextButtons = (
    emblaApi: EmblaCarouselType | undefined,
    scrollMode: ScrollMode = 'element',
    slidesInView: number[] = [],
): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const loopIndex = useCallback((index: number, length: number) => {
        return (index + length) % length;
    }, []);

    const scrollTo = useCallback(
        (step: number) => {
            if (!emblaApi) return;

            const slidesCount = emblaApi.slideNodes().length;

            if (scrollMode === 'element') {
                const newIndex = loopIndex(selectedIndex + step, slidesCount);
                emblaApi.scrollTo(newIndex);
                setSelectedIndex(newIndex);
                return;
            }

            if (scrollMode === 'block' && slidesInView.length > 0) {
                let newIndex;

                if (step < 0) {
                    newIndex = slidesInView[0] - slidesInView.length + 1;
                    newIndex = newIndex < 0 ? 0 : newIndex;
                } else {
                    newIndex = slidesInView[slidesInView.length - 1];
                }

                emblaApi.scrollTo(newIndex);
            }
        },
        [emblaApi, selectedIndex, loopIndex, slidesInView, scrollMode],
    );

    const onPrevButtonClick = useCallback(() => scrollTo(-1), [scrollTo]);
    const onNextButtonClick = useCallback(() => scrollTo(1), [scrollTo]);

    const updateButtonStates = useCallback(
        (emblaApi: EmblaCarouselType) => {
            setPrevBtnDisabled(!emblaApi.canScrollPrev());
            setNextBtnDisabled(!emblaApi.canScrollNext());

            if (scrollMode === 'element') {
                setSelectedIndex(emblaApi.selectedScrollSnap());
            }
        },
        [scrollMode],
    );

    useEffect(() => {
        if (!emblaApi) return;

        updateButtonStates(emblaApi);

        if (emblaApi.on) {
            emblaApi.on('reInit', updateButtonStates).on('select', updateButtonStates);
        }

        return () => {
            if (emblaApi.off) {
                emblaApi.off('reInit', updateButtonStates).off('select', updateButtonStates);
            }
        };
    }, [emblaApi, updateButtonStates]);

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
        selectedIndex,
    };
};
